import i18next, { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackArrow, RightArrow_Icon } from "../../assets/svgs/BackArrow";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import {
  Course_Page,
  Plan_Goal_Page,
  Plan_Location_Page,
  Plan_Target_Weight_Page,
  Plan_Weight_Page,
  Plan_Zones_Page,
  Veg_Or_Non,
} from "../../constants/pageRouteName";
import MobileView from "../../layouts/MobileView";
import { successToast } from "../../modules/toaster";
import ApiService from "../../service/api";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0)?.toUpperCase() + string.slice(1)?.toLowerCase();
};

const Plan = () => {
  const [loading, setLoading] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Not_Selected = t("Not Selected");
  const translatedZones = details.targetZones
    ?.split(", ")
    ?.map((zone) => capitalizeFirstLetter(t(zone.toUpperCase())))
    ?.join(", ");

  const settings = [
    {
      id: 1,
      title: t("SP_Tile_1"),
      selectedItem: t(details.primaryGoal?.toUpperCase() || "Not Selected"),
      actucalItem: details.primaryGoal,
      navigate: Plan_Goal_Page,
    },
    {
      id: 2,
      title: t("SP_Tile_2"),
      selectedItem: translatedZones || Not_Selected,
      actucalItem: details.targetZones,
      navigate: Plan_Zones_Page,
    },
    {
      id: 3,
      title: t("SP_Tile_3"),
      selectedItem: t(details.workoutLocation?.toUpperCase() || "Not Selected"),
      actucalItem: details.workoutLocation,
      navigate: Plan_Location_Page,
    },
    {
      id: 4,
      title: t("SP_Tile_4"),
      selectedItem: details.weight || Not_Selected,
      actucalItem: details.weight,
      navigate: Plan_Weight_Page,
    },
    {
      id: 5,
      title: t("SP_Tile_5"),
      selectedItem: details.desiredWeight || Not_Selected,
      actucalItem: details.desiredWeight,
      navigate: Plan_Target_Weight_Page,
    },
    {
      id: 6,
      title: t("SP_Tile_6"),
      selectedItem: t(details.dietPreference?.toUpperCase() || "Not Selected"),
      actucalItem: details.dietPreference,
      navigate: Veg_Or_Non,
    },
  ];

  const handleNavigate = (data) => {
    window.scrollTo(0, 0);
    navigate(data.navigate, {
      state: {
        isSettings: true,
        data:
          data.id === 4 || data.id === 5
            ? {
                weight: data.actucalItem,
                height: details.height,
              }
            : data.actucalItem,
      },
    });
  };

  const handleUpdateClick = async () => {
    ApiService.updateMealPlanData()
      .then(() => {})
      .catch((err) => {
        if (err?.request?.status === 401) {
          // dispatch(logoutState());
        }
      });
    successToast(t("SP_toast"));
    navigate(Course_Page);
  };

  React.useEffect(() => {
    setTimeout(() => {}, 2000);
  });

  React.useEffect(() => {
    setLoading(true);
    ApiService.getGoalsList()
      .then((res) => {
        setLoading(false);
        setDetails(res.data.DietNeeds);
      })
      .catch((err) => {
        if (err?.request?.status === 401) {
          // dispatch(logoutState());
        }
        setLoading(false);
        setError(err.message || err);
      });
  }, []);

  return (
    <div
      className="flex-grow-1 d-flex flex-column justify-content-between w-100"
      style={{ minHeight: "100dvh" }}
    >
      <MobileView>
        <div className="flex-grow-1">
          <div
            className="d-flex flex-column justify-content-center   py-4"
            style={{ gap: "20px", minHeight: "100dvh" }}
          >
            {loading ? (
              <div className="w-100 d-flex  justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="position-relative main-padding-auth d-flex align-items-center justify-content-center ">
                  <div className="position-absolute start-0 px-4">
                    <button
                      onClick={() => navigate(-1)}
                      className="button"
                      style={{ width: "27px", height: "27px" }}
                    >
                      {BackArrow()}
                    </button>
                  </div>
                  <h3
                    className={`mb-0 text-center w-100 text-inter text-custom-white text-uppercase ${
                      i18next.language === "pt" ? "plan-setting-title-pt" : ""
                    }`}
                    style={{ fontWeight: "600" }}
                  >
                    {t("SP_Title")}
                  </h3>
                </div>
                {error ? (
                  <div>
                    <p className="text-center py-5 text-inter fw-bold text-custom-white text-capitalize">
                      {t("Something went wrong.")}
                    </p>
                  </div>
                ) : (
                  <>
                    <div>
                      {settings.map((data, index) => (
                        <div
                          key={index}
                          className="border-bottom border-1 d-flex justify-content-between align-items-center py-3 border-diet-plan"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleNavigate(data)}
                        >
                          <div className="ps-4">
                            <p
                              className="m-0 text-inter fw-bold text-capitalize"
                              style={{
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#717171",
                              }}
                            >
                              {data.title}
                            </p>
                            <p
                              className="fw-bold m-0 text-inter text-custom-white"
                              style={{ fontSize: "20px", lineHeight: "24px" }}
                            >
                              {data.selectedItem}
                            </p>
                          </div>
                          <div className="pe-4">
                            <div style={{ width: "12px", height: "20px" }}>
                              {RightArrow_Icon()}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="px-4">
                      <p
                        className="text-center mb-2"
                        style={{ color: "#6C6E79", fontSize: "14px" }}
                      >
                        {t("SP_Helper_Text")}
                      </p>
                      <Button
                        classNames={"text-uppercase"}
                        label={t("SP_Button_Text")}
                        onClick={handleUpdateClick}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </MobileView>
    </div>
  );
};

export default Plan;
