import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import { Part_Three_Five } from "../../../constants/pageRouteName";
import PartThreeLayout from "../../../layouts/PartThreeLayout";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import {
  pagePreloadImages,
  placeHolderImages,
  s3Images,
} from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const items = [
  {
    id: 1,
    text: t("Less than 1 hour"),
    value: "Less than 1 hour",
  },
  {
    id: 2,
    text: t("1-2 hours"),
    value: "1-2 hours",
  },
  {
    id: 3,
    text: t("More than 2 hours"),
    value: "More than 2 hours",
  },
];

const Image = () => {
  return (
    <ImageLoader
      placeHolderImage={placeHolderImages.walk}
      src={s3Images.walk}
      alt="lifestyle"
      className="w-100 lifestyle-img"
    />
  );
};

const Walk = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (id) => {
    dispatch(
      setDetailsData({
        walkDaily: { text: items[id - 1].value },
      })
    );
    nextPageRoute(navigate, Part_Three_Five, dispatch, pagePreloadImages.walk);
  };

  return (
    <PartThreeLayout
      title={
        <>
          {t("WL_title_1")}
          <br /> {t("WL_title_2")}
        </>
      }
      items={items}
      handleSelect={handleSelect}
      Image={Image}
      selected={selected?.id}
    />
  );
};

export default Walk;
