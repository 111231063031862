import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TargetZonesSvg } from "../../../assets/svgs/TargetZone";
import Button from "../../../components/Button";
import { Part_Two_Five } from "../../../constants/pageRouteName";
import { updatePlanData } from "../../../modules/apiHelper";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { nextPageRoute } from "../../../utils/navigationTransition";

const tile_Items = [
  { text: "Arms", id: 1 },
  { text: "Belly", id: 2 },
  { text: "Pecs", id: 3 },
  { text: "Legs", id: 4 },
];

const TargetZones = () => {
  const [selected, setSelected] = React.useState([]);
  const { details } = useSelector((state) => state.detailState);
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSelection = (id) => {
    if (selected.includes(id)) {
      isLoggedIn
        ? dispatch(
            setDetailsData({
              targetZones: details.targetZones?.filter(
                (item) => item.id !== tile_Items[id - 1].id
              ),
            })
          )
        : dispatch(
            setDetailsData({
              targetZones: details.targetZones?.filter(
                (item) => item.id !== tile_Items[id - 1].id
              ),
            })
          );
      setSelected(selected.filter((item) => item !== id));
    } else {
      if (isLoggedIn) {
        if (details.targetZones) {
          dispatch(
            setDetailsData({
              targetZones: [...details.targetZones, tile_Items[id - 1]],
            })
          );
        } else {
          dispatch(
            setDetailsData({
              targetZones: [tile_Items[id - 1]],
            })
          );
        }
      } else {
        if (details.targetZones) {
          dispatch(
            setDetailsData({
              targetZones: [...details.targetZones, tile_Items[id - 1]],
            })
          );
        } else {
          dispatch(
            setDetailsData({
              targetZones: [tile_Items[id - 1]],
            })
          );
        }
      }
      setSelected((pre) => [...pre, id]);
    }
  };

  React.useEffect(() => {
    dispatch(setDetailsData({ targetZones: [] }));
  }, []);

  const gotoNextPage = () => {
    location.state?.isSettings
      ? updatePlanData(navigate, dispatch, {
          targetZones: details.targetZones?.map((item) => item.text).join(","),
        })
      : nextPageRoute(navigate, Part_Two_Five);
  };

  React.useEffect(() => {
    if (location.state?.data) {
      const items = location.state.data.replaceAll(" ", "").split(",");
      const selectedItem = tile_Items.filter((item) => {
        return items.includes(item.text);
      });
      if (selectedItem.length > 0) {
        setSelected(selectedItem.map((item) => item.id));
      }
    }
  }, []);

  return (
    <div className="flex-grow-1 pt-2 main-padding-b d-flex flex-column justify-content-between">
      <div className="flex-grow-1 d-flex flex-column">
        <div className="w-100 main-padding d-flex justify-content-center">
          <h1
            className="motivation-header mt-0 text-inter fw-bold text-center main-margin-b"
            style={{ maxWidth: "239px" }}
          >
            {t("TZ_header")}
          </h1>
        </div>
        <div className="d-flex justify-content-center align-items-center px-2 flex-grow-1 main-margin-b">
          <div className="w-100">
            {<TargetZonesSvg {...{ handleClick: handleSelection, selected }} />}
          </div>
        </div>
      </div>
      <div className="w-100 main-padding">
        <Button
          disabled={selected.length === 0}
          onClick={gotoNextPage}
          classNames={"mt-2"}
          label={location.state?.isSettings ? t("SAVE") : t("CONTINUE")}
        />
      </div>
    </div>
  );
};

export default TargetZones;
