import { t } from "i18next";
import React from "react";

const PreparingText = ({ customText }) => {
  return (
    <>
      <hr className="w-100 m-0 mt-4 text-custom-white" />
      <p
        className="py-4 m-0 preparing-text w-100 text-custom-white fw-bold text-inter text-center"
        style={{ backgroundColor: "#28292B" }}
      >
        {customText || t("We Are Preparing Your Plan.")}
      </p>
      <hr className="w-100 mt-0 text-custom-white" />
    </>
  );
};

export default PreparingText;
