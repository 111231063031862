import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import { Part_Three_Eight } from "../../../constants/pageRouteName";
import PartThreeLayout from "../../../layouts/PartThreeLayout";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import {
  pagePreloadImages,
  placeHolderImages,
  s3Images,
} from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const items = [
  {
    id: 1,
    text: t("I only drink tea and coffee"),
    value: "I only drink tea and coffee",
  },
  {
    id: 2,
    text: t("Fewer than 2 glasses of water"),
    value: "Fewer than 2 glasses of water",
  },
  {
    id: 3,
    text: t("2-6 glasses of water"),
    value: "2-6 glasses of water",
  },
  {
    id: 4,
    text: t("7-10 glasses of water"),
    value: "7-10 glasses of water",
  },
  {
    id: 5,
    text: t("More than 10 glasses of water"),
    value: "More than 10 glasses of water",
  },
];

const Image = () => {
  return (
    <div className="d-flex gap-3 align-items-center">
      <ImageLoader
        src={s3Images.water}
        placeHolderImage={placeHolderImages.water}
        alt="Meal"
        style={{ width: "30%", maxHeight: "154px", objectFit: "contain" }}
      />
      <div>
        <p className="part-three-sub-text m-0">
          {t("WT_Subtitle_1")}
          <br />
          <br />
          {t("WT_Subtitle_2")}
        </p>
      </div>
    </div>
  );
};

const Water = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (id) => {
    dispatch(
      setDetailsData({
        water: { text: items[id - 1].value },
      })
    );
    nextPageRoute(
      navigate,
      Part_Three_Eight,
      dispatch,
      pagePreloadImages.water
    );
  };

  return (
    <PartThreeLayout
      title={t("WT_title")}
      handleSelect={handleSelect}
      selected={selected?.id}
      items={items}
      Image={Image}
    />
  );
};

export default Water;
