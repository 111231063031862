import React from "react";
import OtpInput from "react-otp-input";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import ApiService from "../../service/api";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Course_Page } from "../../constants/pageRouteName";
import { addS3Url } from "../../modules/s3";
import { failureToast, successToast } from "../../modules/toaster";
import { checkLogin } from "../../redux/slices/AuthSlice";

const Otp = () => {
  const [loading, setLoading] = React.useState(true);
  const [otp, setOtp] = React.useState("");

  const { details } = useSelector((state) => state.detailState);
  const email = localStorage.getItem("email") ?? "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const handleVerification = async () => {
    setLoading(true);
    await ApiService.verifyOTP({ email: email.toLocaleLowerCase(), otp: otp })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("token", JSON.stringify(response.data));
          if (location.state) {
            location.state?.isNewRegistered &&
              (await ApiService.updateMealPlanData().catch(() => {}));
          }
          await dispatch(checkLogin());
          setTimeout(() => {
            navigate(Course_Page);
          }, 1000);
        }
        setLoading(false);
      })
      .catch((error) => {
        failureToast(error?.request?.response);
        setLoading(false);
      });
  };

  const resendOTP = async () => {
    setOtp("");
    setLoading(true);
    await ApiService.loginUser({ email: email.toLocaleLowerCase() })
      .then((response) => {
        if (response.status === 200) {
          successToast(response.data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        failureToast(error?.request?.response);
        setLoading(false);
      });
  };

  return (
    <div
      style={{ flex: 1 }}
      className="d-flex flex-column justify-content-center text-center align-items-center main-padding header-padding-y pb-0"
    >
      <img
        src={addS3Url("/Logo.png")}
        alt="logo"
        style={{ height: 100, width: 100 }}
        className="mb-1"
      />

      <h3
        className="mt-3 fw-bold text-inter text-custom-white mb-0"
        style={{ fontSize: "28px" }}
      >
        {t("OTP_header")}
      </h3>

      <p
        style={{ fontSize: "14px" }}
        className="mb-3 mt-2 text-inter text-center text-custom-white fw-lighter"
      >
        <Trans
          i18nKey="OTP_subheader"
          components={{ tag: <br /> }}
          values={{ email: email }}
        />
      </p>
      <OtpInput
        inputStyle={{
          width: "72px",
          height: "72px",
          textAlign: "center",
          borderRadius: "6px",
          boxShadow: "none",
          color: "white",
          backgroundColor: "transparent",
          borderColor: "#6A6C84",
          border: "1px solid #6A6C84",
          margin: "0px 5px",
        }}
        placeholder={0}
        inputType="tel"
        value={otp}
        renderSeparator={""}
        onChange={setOtp}
        numInputs={4}
        renderInput={(props) => <input {...props} />}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Button
            onClick={handleVerification}
            disabled={otp.length !== 4}
            label={t("Submit")}
            classNames={"mt-4"}
          />
          <p
            className="mt-4 "
            style={{
              color: "#6C6E79",
              fontWeight: "regular",
              fontSize: "14px",
            }}
          >
            <span
              className="main-text text-decoration-underline text-inter"
              onClick={resendOTP}
            >
              {t("Resend OTP")}
            </span>
          </p>
        </>
      )}
    </div>
  );
};

export default Otp;
