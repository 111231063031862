import { t } from "i18next";
import React from "react";
import { Faq_icon } from "../../assets/svgs/payment";
import { faqs } from "../../utils/data";

const Faqs = () => {
  return (
    <div className="payment-screen-padding-x">
      <p className="try-text-header fw-bold text-inter faq-header">
        {t("faq_header")}
      </p>
      <div className="d-flex flex-column" style={{ gap: 30 }}>
        {faqs.map((item, index) => (
          <div key={index} className="d-flex" style={{ gap: 11 }}>
            <div>{Faq_icon()}</div>
            <div>
              <p className="faq-question text-inter fw-bold">{item.question}</p>
              <p className="faq-answer text-inter fw-regular m-0">
                {item.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
