import React, { useEffect, useRef, useState } from "react";
// import TransformationImage from "../../assets/images/payment/Transformation_11zon.png";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import Arrow_Svg from "../../assets/svgs/arrow.svg";
import { Level_Svg, Weight_Scale_Svg } from "../../assets/svgs/payment";
import {
  calculateDailyCaloryIntake,
  calculateDailyWaterIntake,
} from "../../modules/Calculator";
import { placeHolderImages, s3Images } from "../../utils/image";
import { isValidObject } from "../../utils/valid";
import ImageLoader from "../ImageLoader";

const actual_body_character = {
  skinny: placeHolderImages.payment_character[0],
  average: placeHolderImages.payment_character[1],
  overweight: placeHolderImages.payment_character[2],
};

const actual_body_character_src = {
  skinny: s3Images.payment_character[0],
  average: s3Images.payment_character[1],
  overweight: s3Images.payment_character[2],
};

const target_body_character = {
  "get shredded": placeHolderImages.payment_character[3],
  "muscle gain": placeHolderImages.payment_character[4],
  "weight loss": placeHolderImages.payment_character[5],
  cut: placeHolderImages.payment_character[3],
  bulk: placeHolderImages.payment_character[4],
  "extra bulk": placeHolderImages.payment_character[5],
};

const target_body_character_img = {
  "get shredded": s3Images.payment_character[3],
  "muscle gain": s3Images.payment_character[4],
  "weight loss": s3Images.payment_character[5],
  cut: s3Images.payment_character[3],
  bulk: s3Images.payment_character[4],
  "extra bulk": s3Images.payment_character[5],
};

const body_fat = {
  skinny: "5 - 15%",
  average: "15 - 25%",
  overweight: "25 - 30%",
};

const body_fat_target = {
  cut: "6 - 15%",
  bulk: "15 - 20%",
  "extra bulk": "20 - 30%",
};

const CaloryIntakeSlider = ({ sliderValue }) => {
  let thumbPosition = ((sliderValue - 1000) / (5000 - 1000)) * 100;

  React.useEffect(() => {
    const thumb = document.getElementById("calory-intake-thumb");
    thumb.style.transform = `translate(${thumbPosition}%, calc(-50% + 5px))`;
  }, [sliderValue]);

  return (
    <>
      <div className="calory-slider">
        <div className="calory-intake-thumb" id="calory-intake-thumb"></div>
        <div className="d-flex justify-content-between">
          <p className="calory-intake-min-max fw-bold">1000 kcal</p>
          <p className="calory-intake-min-max fw-bold">5000 kcal</p>
        </div>
      </div>
    </>
  );
};

const Transformation = () => {
  const [thumbPosition, setThumbPosition] = useState(0);
  const [currentBMI, setCurrentBMI] = useState(0);
  const [calorieIntake, setCalorieIntake] = useState(0);
  const [waterIntake, setWaterIntake] = useState(0);
  const [bmiAnimated, setBmiAnimated] = useState(false);
  const [calorieAnimated, setCalorieAnimated] = useState(false);
  const [waterAnimated, setWaterAnimated] = useState(false);
  const { detailState } = useSelector((state) => state);

  const bmiRef = useRef(null);
  const calorieRef = useRef(null);
  const waterRef = useRef(null);

  const animateValue = (start, end, duration, setValue) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setValue(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const animatePointValue = (start, end, duration, setValue) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setValue((start + progress * (end - start)).toFixed(1));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    const BMI = detailState?.details?.BMI || 0;

    let position = 0;

    if (BMI < 18.5) {
      position = 0;
    } else if (BMI >= 18.5 && BMI < 24.9) {
      position = 25;
    } else if (BMI >= 24.9 && BMI < 29.9) {
      position = 50;
    } else {
      position = 100;
    }

    setThumbPosition(position);
  }, [detailState.details.BMI]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const bmiObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !bmiAnimated) {
          animateValue(0, detailState.details.BMI, 1000, setCurrentBMI);
          setBmiAnimated(true);
          bmiObserver.unobserve(bmiRef.current);
        }
      });
    }, observerOptions);

    const calorieObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !calorieAnimated) {
          const targetCalorieIntake = calculateDailyCaloryIntake(
            detailState?.details?.currentWeight ||
              detailState?.details?.weight ||
              0,
            detailState?.details?.height || 0,
            detailState?.details?.age?.text || 0,
            detailState?.details?.sedentary?.text || 0,
            detailState?.details?.walkDaily?.text || 0
          );
          animateValue(0, targetCalorieIntake, 1000, setCalorieIntake);
          setCalorieAnimated(true);
          calorieObserver.unobserve(calorieRef.current);
        }
      });
    }, observerOptions);

    const waterObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !waterAnimated) {
          // const targetWaterIntake = calculateDailyWaterIntake(
          //   detailState?.details?.currentWeight ||
          //     detailState?.details?.weight ||
          //     "0 kg",
          //   detailState?.details?.walkDaily?.text || "less than 1 hour"
          // );
          const targetWaterIntake = calculateDailyWaterIntake(
            calculateDailyCaloryIntake(
              detailState?.details?.currentWeight ||
                detailState?.details?.weight ||
                0,
              detailState?.details?.height || 0,
              detailState?.details?.age?.text || 0,
              detailState?.details?.sedentary?.text || 0,
              detailState?.details?.walkDaily?.text || 0
            )
          );
          animatePointValue(0, targetWaterIntake, 1000, setWaterIntake);
          setWaterAnimated(true);
          waterObserver.unobserve(waterRef.current);
        }
      });
    }, observerOptions);

    if (bmiRef.current) bmiObserver.observe(bmiRef.current);
    if (calorieRef.current) calorieObserver.observe(calorieRef.current);
    if (waterRef.current) waterObserver.observe(waterRef.current);

    return () => {
      if (bmiRef.current) bmiObserver.unobserve(bmiRef.current);
      if (calorieRef.current) calorieObserver.unobserve(calorieRef.current);
      if (waterRef.current) waterObserver.unobserve(waterRef.current);
    };
  }, [detailState]);

  const GLASS_CAPACITY = 0.35;
  const TOTAL_GLASSES = 8;

  const filledGlasses = Math.min(
    Math.floor(waterIntake / GLASS_CAPACITY),
    TOTAL_GLASSES
  );

  const unfilledGlasses = TOTAL_GLASSES - filledGlasses;

  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        <div className="now-label text-inter fw-semibold text-capitalize">
          {t("Now")}
        </div>
        <div className="now-label goal-label text-inter fw-semibold text-capitalize">
          {t("After the program")}
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="d-flex flex-row" style={{ height: "250px" }}>
          <div className="w-50 d-flex justify-content-center align-items-end">
            <ImageLoader
              src={
                actual_body_character_src[
                  detailState?.details?.actualBodyType?.text?.toLowerCase() ||
                    "overweight"
                ]
              }
              placeHolderImage={
                actual_body_character[
                  detailState?.details?.actualBodyType?.text?.toLowerCase() ||
                    "overweight"
                ]
              }
              alt="character"
              style={{
                width: "100%",
                maxHeight: "100%",
                objectFit: "contain",
                position: "relative",
                zIndex: 2,
              }}
            />
          </div>
          <div className="w-50 d-flex justify-content-center align-items-end">
            <ImageLoader
              src={
                target_body_character_img[
                  detailState.details.primaryGoal?.text?.toLowerCase() || "cut"
                ]
              }
              placeHolderImage={
                target_body_character[
                  detailState.details.primaryGoal?.text?.toLowerCase() || "cut"
                ]
              }
              alt="character"
              style={{
                width: "100%",
                maxHeight: "100%",
                objectFit: "contain",
                position: "relative",
                zIndex: 2,
              }}
            />
          </div>
        </div>
        <div className="arrow-container animateArrow">
          <img src={Arrow_Svg} className="arrow" />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="body-fat-left d-block text-inter text-capitalize">
          <p className="m-0 body-fat fw-semibold">{t("Body fat")}</p>
          <p className="m-0 body-fat-value fw-regular">
            {
              body_fat[
                detailState?.details?.actualBodyType?.text?.toLowerCase() ||
                  "overweight"
              ]
            }
          </p>
          <hr className="body-type-hr" />
          <p className="m-0 body-fat fw-semibold">{t("Muscle Mass")}</p>
          <div className="d-flex flex-row gap-1 mt-1" style={{ width: "90%" }}>
            {[1, 0, 0, 0, 0].map((item, index) => (
              <div
                key={index}
                className={`flex-grow-1 muscle-mass-div ${
                  item ? "filled-muscle-mass-div" : ""
                }`}
              ></div>
            ))}
          </div>
        </div>
        <div className="body-fat-left d-block body-fat-right text-inter fw-semibold text-capitalize">
          <p className="m-0 body-fat fw-semibold">{t("Body fat")}</p>
          <p className="m-0 body-fat-value fw-regular">
            {
              body_fat_target[
                detailState.details.primaryGoal?.text?.toLowerCase() || "cut"
              ]
            }
          </p>
          <hr className="body-type-hr" />
          <p className="m-0 body-fat fw-semibold">{t("Muscle Mass")}</p>
          <div className="d-flex flex-row gap-1 mt-1" style={{ width: "90%" }}>
            {[1, 1, 1, 1, 1].map((item, index) => (
              <div
                key={index}
                className={`flex-grow-1 muscle-mass-div ${
                  item ? "filled-muscle-mass-div" : ""
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center my-4">
        <div className="personal-plan-container">
          <p className="payment-personal-plan-header fw-bold">
            <Trans
              i18nKey={"Your personal workout plan is ready!"}
              components={{ tag: <br /> }}
            />
          </p>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div className="payment-personal-plan-container">
              <p className="payment-personal-plan-parameter fw-regular m-0 text-end">
                {t("Target weight")}
              </p>
              <div className="d-flex flex-row justify-content-end gap-1 mt-2">
                <Weight_Scale_Svg />
                <p className="payment-personal-plan-value fw-bold m-0 text-end">
                  {detailState?.details?.targetWeight || "0 kg"}
                </p>
              </div>
            </div>
            <div className="line-between-div"></div>
            <div className="payment-personal-plan-container">
              <p className="payment-personal-plan-parameter fw-regular m-0 text-start">
                {t("Level")}
              </p>
              <div className="d-flex flex-row gap-1 mt-2">
                <Level_Svg />
                <p className="payment-personal-plan-value fw-bold m-0 text-start">
                  {t(
                    (isValidObject(detailState.details?.fitnessLevel)
                      ? detailState?.details?.fitnessLevel?.text
                      : detailState?.details?.fitnessLevel) || "Beginner"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="answer-summary-header fw-bold" ref={bmiRef}>
          <Trans
            i18nKey={"Personal summary based on your answers"}
            components={{ tag: <br /> }}
          />
        </p>
        <div
          className="d-flex flex-column align-items-start answer-summary-container"
          style={{ gap: 10 }}
        >
          <p className="current-bmi-text-payment fw-semibold m-0">
            {t("Current BMI")}
          </p>
          <p className="current-bmi-value-payment fw-bold m-0">
            {currentBMI || 0} BMI
          </p>
          <div className="w-100">
            <div className="payment-gradient-slider position-relative">
              <div className="slider-track"></div>
              <div
                className="bmi-payment-thumb position-absolute"
                style={{ left: `${thumbPosition}%` }}
                id="summary-thumb"
              ></div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="bmi-text fw-regular text-inter m-0 text-uppercase fw-bold payment-bmi-slider-text">
                {t("Normal")}
              </p>
              <p className="bmi-text fw-regular text-inter m-0 text-uppercase fw-bold payment-bmi-slider-text">
                {t("Obese")}
              </p>
            </div>
          </div>
          <p className="m-0 bmi-level-payment fw-bold">
            {detailState.details.BMI < 18.5
              ? t("Underweight")
              : detailState.details.BMI < 24.9
              ? t("Normal")
              : detailState.details.BMI < 29.9
              ? t("Overweight")
              : t("Underweight")}
          </p>
          <p className="bmi-helper-text m-0">{t("BMI_text")}</p>
        </div>
        <div
          className="answer-summary-container mt-4 position-relative"
          ref={calorieRef}
        >
          <div
            className="d-flex flex-row justify-content-between"
            style={{ gap: 8 }}
          >
            <div style={{ width: "80%", gap: 8 }} className="d-flex">
              <div className="d-flex align-items-center">
                <ImageLoader
                  className="recommendation-card-image w-100"
                  placeHolderImage={
                    placeHolderImages.payment_recommendations[2]
                  }
                  src={s3Images.payment_recommendations[2]}
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <p className="recommendation-header fw-bold m-0 text-start">
                  {t("Daily calorie intake")}
                </p>
                <p className="recommendation-value fw-bold m-0">
                  {calorieIntake} kcal
                </p>
              </div>
            </div>
            <div className="recommended-container">
              <p className="recommended-text m-0">{t("recommended")}</p>
            </div>
          </div>
          <CaloryIntakeSlider sliderValue={calorieIntake} />
        </div>
        <div
          className="answer-summary-container mt-4 position-relative"
          ref={waterRef}
        >
          <div
            className="d-flex flex-row justify-content-between"
            style={{ gap: 8 }}
          >
            <div style={{ width: "80%", gap: 8 }} className="d-flex">
              <div
                style={{ width: "20%" }}
                className="d-flex align-items-center"
              >
                <ImageLoader
                  className="recommendation-card-image w-100"
                  placeHolderImage={
                    placeHolderImages.payment_recommendations[3]
                  }
                  src={s3Images.payment_recommendations[3]}
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <p className="recommendation-header fw-bold m-0 text-start">
                  {t("Daily water intake")}
                </p>
                <p className="recommendation-value fw-bold m-0">
                  {waterIntake} l
                </p>
              </div>
            </div>
            <div className="recommended-container">
              <p className="recommended-text m-0">{t("recommended")}</p>
            </div>
          </div>
          <div>
            <div
              className="d-flex flex-row justify-content-between"
              style={{ marginTop: 20 }}
            >
              {Array(filledGlasses)
                .fill(0)
                .map((_, index) => (
                  <ImageLoader
                    key={index}
                    placeHolderImage={
                      placeHolderImages.payment_recommendations[1]
                    }
                    src={s3Images.payment_recommendations[1]}
                    className="fade-animation"
                    style={{
                      width: "11%",
                      maxHeight: "40px",
                      objectFit: "contain",
                    }}
                  />
                ))}
              {Array(unfilledGlasses)
                .fill(0)
                .map((_, index) => (
                  <ImageLoader
                    key={index + filledGlasses}
                    placeHolderImage={
                      placeHolderImages.payment_recommendations[0]
                    }
                    className="fade-animation"
                    src={s3Images.payment_recommendations[0]}
                    style={{
                      width: "11%",
                      maxHeight: "40px",
                      objectFit: "contain",
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-5">
        <p className="answer-summary-header fw-bold m-0 mb-4">
          <Trans
            i18nKey={"Personalized plan for You is ready!"}
            components={{ tag: <br /> }}
          />
        </p>
        {[
          {
            icon: placeHolderImages.payment_personalPlan[0],
            src: s3Images.payment_personalPlan[0],
            title: "Workout Duration",
            value: "30 minutes",
          },
          {
            icon: placeHolderImages.payment_personalPlan[1],
            src: s3Images.payment_personalPlan[1],
            title: "Fitness Level",
            value: detailState?.details?.fitnessLevel?.text || "N/A",
          },
          {
            icon: placeHolderImages.payment_personalPlan[2],
            src: s3Images.payment_personalPlan[2],
            title: "Place of Workout",
            value:
              detailState?.details?.workOutLocation?.text ||
              detailState?.details?.workoutLocation?.text ||
              "N/A",
          },
          {
            title: "Workout Frequency",
            value: "3 time per week",
            icon: placeHolderImages.payment_personalPlan[3],
            src: s3Images.payment_personalPlan[3],
          },
        ].map((item, index) => (
          <div
            className="personalized-plan-card"
            key={index}
            id={index === 3 ? "get-plan-scroll-new" : ""}
          >
            <div className="personalized-plan-icon-div">
              <ImageLoader
                src={item.src}
                placeHolderImage={item.icon}
                className="personalized-plan-icon"
              />
            </div>
            <div className="d-flex flex-column justify-content-between">
              <p className="personalized-plan-title fw-bold m-0">
                {t(item.title)}
              </p>
              <p className="personalized-plan-value fw-bold m-0">
                {t(item.value)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Transformation;
