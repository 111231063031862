import { t } from "i18next";
import moment from "moment";
import React from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Calendar_Icon } from "../../../assets/svgs/calendar";
import Button from "../../../components/Button";
import { Part_Four_Three } from "../../../constants/pageRouteName";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { nextPageRoute } from "../../../utils/navigationTransition";

const EventDate = () => {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const navigate = useNavigate();
  const dateRef = React.useRef(null);

  const dispatch = useDispatch();
  const { details } = useSelector((state) => state.detailState);

  const gotoNextPage = () => {
    selectedDate &&
      dispatch(
        setDetailsData({
          occasionDate: moment(selectedDate).isValid()
            ? moment(selectedDate).format("YYYY-MM-DD")
            : null,
        })
      );
    nextPageRoute(navigate, Part_Four_Three);
  };

  const handleCalendar = () => {
    dateRef.current.setOpen(true);
  };

  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-between pt-2 main-padding-b main-padding gap-3">
      <div>
        <h1 className="event-header my-0 text-inter fw-bold text-center">
          {t("ED_title")}
        </h1>
        <p className="text-center text-inter part-three-sub-text mt-3 mb-4">
          {t("ED_subtitle")}
        </p>
        <div className="position-relative">
          <div className="date-input p-0 w-100 login-input-div my-3">
            <DatePicker
              wrapperClassName="w-100"
              calendarClassName="text-inter"
              customInput={
                <p className="w-100 text-inter" style={{ color: "#B2B2B2" }}>
                  {selectedDate
                    ? moment(selectedDate).format("DD/MM/YYYY")
                    : t("Choose date")}
                </p>
              }
              ref={dateRef}
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="date-input w-100 m-0 border-0"
              placeholderText={t("Choose date")}
            />
            <div
              className="date-input-icon position-absolute"
              onClick={handleCalendar}
            >
              {Calendar_Icon()}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-2">
        <Button label={t("CONTINUE")} onClick={gotoNextPage} />
        <button
          onClick={gotoNextPage}
          className="m-0 p-0 mt-3 border-0 calendar-text text-inter fw-regular"
          style={{ outline: "none", backgroundColor: "transparent" }}
        >
          {t("SKIP THIS QUESTION")}
        </button>
      </div>
    </div>
  );
};

export default EventDate;
