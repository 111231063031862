import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logoPlaceholder from "../assets/images/blurred/Logo.png";
import { Menu_Icon } from "../assets/svgs/header";
import {
  Course_Page,
  Landing_Page,
  Login_Page,
} from "../constants/pageRouteName";
import { addS3Url } from "../modules/s3";
import ActionSheetCompo from "./ActionSheetCompo";
import ImageLoader from "./ImageLoader";

const Header = ({ showLoginBtn, showMenuBtn }) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const ref = React.useRef();

  const handleOpen = () => {
    ref.current.open();
  };

  const gotoLogin = () => {
    navigate(Login_Page);
  };

  const gotoHome = () => {
    isLoggedIn ? navigate(Course_Page) : navigate(Landing_Page);
  };

  return (
    <>
      <div
        className={`w-100 d-flex justify-content-between align-items-center ${
          isLoggedIn ? "py-2 main-padding" : "header-padding-y main-padding"
        } `}
        style={{ backgroundColor: showMenuBtn ? "#28292B" : "#1d1d1d" }}
      >
        <button
          className="d-flex button gap-2"
          style={{
            textDecoration: "none",
            backgroundColor: showMenuBtn ? "#28292B" : "#1d1d1d",
          }}
          onClick={gotoHome}
        >
          <ImageLoader
            placeHolderImage={logoPlaceholder}
            className="logo"
            src={addS3Url("/Logo.png")}
            alt="Logo"
          />
          <div>
            <p className="logo-text text-start text-inter fw-bolder">GAIN</p>
            <p className="logo-text text-start text-inter fw-bolder"> MUSCLE</p>
          </div>
        </button>
        {showLoginBtn && (
          <button
            className="bg-custom-black text-custom-white text-poppins fw-semibold login-btn"
            onClick={gotoLogin}
          >
            {t("LOGIN")}
          </button>
        )}
        {showMenuBtn && (
          <button
            className="bg-transparent text-custom-white text-poppins fw-semibold menu-btn"
            onClick={handleOpen}
          >
            {Menu_Icon()}
          </button>
        )}
      </div>
      {showMenuBtn && <ActionSheetCompo sheetRef={ref} />}
    </>
  );
};

export default Header;
