import React from "react";

import { t } from "i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import {
  Actual_Body,
  Privacy_Policy,
  Terms_Conditions,
} from "../../../constants/pageRouteName";
import {
  removeDetailsData,
  setDetailsData,
} from "../../../redux/slices/DetailsSlice";
import {
  pagePreloadImages,
  placeHolderImages,
  s3Images,
} from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";
import { preLoadImage } from "../../../utils/preLoadImage";

const Tile = ({ label, id, icon, onclick, placeHolderImage }) => {
  return (
    <button
      tabIndex={0}
      className="age-tile button-hover position-relative"
      onClick={() => onclick(id)}
    >
      <ImageLoader
        src={icon}
        placeHolderImage={placeHolderImage}
        alt="icon"
        width={"100%"}
        style={{ objectFit: "contain" }}
      />
      <div
        className="position-absolute bottom-0 start-0 d-flex justify-content-center align-items-center w-100 age-button-hover"
        style={{
          height: "37px",
          borderEndEndRadius: "10px",
          borderEndStartRadius: "10px",
          backgroundColor: "#747474",
        }}
      >
        <p className="age-text w-100 text-center m-0 fw-bold text-inter">
          {label}
        </p>
      </div>
    </button>
  );
};

const Items = [
  {
    id: 1,
    label: t("age_text", { range: "18-29" }),
    value: "Age 18-29",
    icon: s3Images.age[0],
    placeHolderImage: placeHolderImages.age[0],
  },
  {
    id: 2,
    label: t("age_text", { range: "30-39" }),
    value: "Age 30-39",
    icon: s3Images.age[1],
    placeHolderImage: placeHolderImages.age[1],
  },
  {
    id: 3,
    label: t("age_text", { range: "40-49" }),
    value: "Age 40-49",
    icon: s3Images.age[2],
    placeHolderImage: placeHolderImages.age[2],
  },
  {
    id: 4,
    label: t("age_text", { range: "50+" }),
    value: "Age 50+",
    icon: s3Images.age[3],
    placeHolderImage: placeHolderImages.age[3],
  },
];

const Age = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClick = (id) => {
    dispatch(
      setDetailsData({
        age: { text: Items[id - 1].value, id: id },
      })
    );
    // nextPageRoute(navigate, Part_Three_Thirteen);
    // nextPageRoute(navigate, Part_Two_One, dispatch, pagePreloadImages.agePage);
    nextPageRoute(navigate, Actual_Body, dispatch, pagePreloadImages.agePage);
  };

  React.useEffect(() => {
    dispatch(removeDetailsData());

    Object.keys(pagePreloadImages).forEach((key) => {
      preLoadImage(pagePreloadImages[key], dispatch);
    });
  }, []);

  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-between py-2 main-padding gap-4">
      <h1 className="age-header my-0 text-inter fw-bold text-center mb-3">
        {t("AG_title")}
      </h1>
      <div className="w-100 d-flex gap-2 flex-wrap justify-content-center">
        {Items.map((item, index) => (
          <Tile key={index} {...item} onclick={handleClick} />
        ))}
      </div>
      <div className="help-text-banner">
        <p className="text-inter text-center fw-bold help-text-banner-title">
          {t("AG_text_1")}
          <br />
          {t("AG_text_2")}
        </p>
        <p className="text-inter text-center fw-regular help-text-banner-subtitle m-0">
          {t("AG_text_3")}
        </p>
      </div>
      <p className="help-text help-text-landing-page text-center mt-1 pt-0 text-inter  mb-0">
        {t("AGE_LP_text")}{" "}
        <Link
          style={{ textWrap: "nowrap" }}
          className="help-text opacity-100 help-text-landing-page"
          to={Terms_Conditions}
        >
          {t("Terms of Use")}
        </Link>
        ,{" "}
        <Link
          style={{ textWrap: "nowrap" }}
          className="help-text opacity-100 help-text-landing-page"
          to={Privacy_Policy}
        >
          {t("Privacy Policy")}
        </Link>
        , and{" "}
        <Link
          style={{ textWrap: "nowrap" }}
          className="help-text opacity-100 help-text-landing-page"
          to={Terms_Conditions}
        >
          {t("Refund Policy")}
        </Link>
        .
      </p>
    </div>
  );
};

export default Age;
