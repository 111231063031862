import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Challenges_Svg,
  CurrentStatus_Svg,
  Diet_Svg,
  Goal_Svg,
  Motivation_Svg,
} from "../../assets/svgs/meal-plan";
import PreparingText from "../../components/PreparingText";
import { Meal_Details_Page } from "../../constants/pageRouteName";
import MobileView from "../../layouts/MobileView";
import { addS3Url } from "../../modules/s3";
import { isValidObject } from "../../utils/valid";

const dietData = [
  {
    image: addS3Url("/meal-plan/BreakFast.png"),
    title: t("MPP_Breakfast"),
    key: "Breakfast",
    content: "400-500 calories",
  },
  {
    image: addS3Url("/meal-plan/Lunch.png"),
    title: t("MPP_Lunch"),
    key: "Lunch",
    content: "450-550 calories",
  },
  {
    image: addS3Url("/meal-plan/Dinner.png"),
    title: t("MPP_Dinner"),
    key: "Dinner",
    content: "500-600 calories",
  },
  {
    image: addS3Url("/meal-plan/Snacks.png"),
    title: t("MPP_Snacks"),
    key: "Snacks",
    content: "150-200 calories",
  },
];

const Meals = () => {
  const [dietMeal, setDietMeal] = React.useState({});
  const [dietNeeds, setDietNeeds] = React.useState({});
  const mealPlan = useSelector((state) => state.mealState.details);
  // const mealPlan = mealPlan_data.mealState.details;

  React.useEffect(() => {
    setDietMeal(mealPlan?.DietMeal);
    setDietNeeds(mealPlan?.DietNeeds);
    // setDietMeal(mealPlanData?.DietMeal);
    // setDietNeeds(mealPlanData?.DietNeeds);
  }, [mealPlan]);

  return (
    <MobileView>
      <div
        className="text-center w-100 d-flex flex-column align-items-center justify-content-between"
        style={{ paddingTop: "36px" }}
      >
        <div className="d-flex flex-column align-items-center">
          <div className="text-center w-100">
            <img
              src={addS3Url("/Big_Logo.png")}
              alt="logo"
              className="mx-auto course-logo"
            />

            <h1 className="course-title fw-bold text-inter text-capitalize">
              {t("MPP_Title")}
            </h1>
          </div>
          {isValidObject(dietMeal) || isValidObject(dietNeeds) ? (
            <>
              <UnderStandNeeds dietNeeds={dietNeeds} />
              {dietMeal?.breakfast ||
              isValidObject(dietMeal?.breakfastDetail) ||
              isValidObject(dietMeal?.lunchDetail) ||
              isValidObject(dietMeal?.dinnerDetail) ||
              dietMeal?.lunch ||
              dietMeal?.snacks ||
              dietMeal?.dinner ? (
                <DietStrategy dietMeal={dietMeal} />
              ) : (
                <>
                  <p className="pt-4 m-0 fw-bold text-custom-white text-inter preparing-text">
                    {t("We Are Preparing Your Plan.")}
                  </p>
                  {dietMeal.length && (
                    <hr
                      className="w-100 mt-0"
                      style={{ color: "#C1C1C1", height: "1px" }}
                    />
                  )}
                </>
              )}
              {typeof dietMeal?.additionalTips === "string" ||
              dietMeal?.additionalTips?.length ? (
                <AdditionalTips dietMeal={dietMeal} />
              ) : (
                ""
              )}
              {!dietMeal?.additionalTips?.length && (
                <hr
                  style={{ color: "#C1C1C1", height: "1px" }}
                  className="w-100 mt-4"
                />
              )}
              <p
                className="fw-bold py-3 main-padding-auth text-inter text-custom-white"
                style={{ fontSize: "14px", lineHeight: "17px" }}
              >
                {t("MPP_Remember_text")}
              </p>
            </>
          ) : (
            <PreparingText />
          )}
        </div>
      </div>
    </MobileView>
  );
};

const UnderStandNeeds = ({ dietNeeds }) => {
  return (
    <div
      className="w-100 border-top border-bottom py-4 main-padding-auth d-flex flex-column gap-3 border-diet-plan px-4"
      style={{
        borderColor: "#C1C1C1",
        backgroundColor: "#292929",
      }}
    >
      <h4
        className="m-0 fw-semibold mb-2 text-custom-white text-inter text-capitalize"
        style={{ fontSize: "24px" }}
      >
        {t("MPP_Understand_Needs_text")}
      </h4>
      <div className="d-flex flex-column gap-3">
        <NeedItem
          content={`${dietNeeds?.primaryGoal}${dietNeeds.targetZones && ","}${
            dietNeeds?.targetZones && " " + dietNeeds.targetZones
          }.`}
          icon={Goal_Svg}
          title={`${t("MPP_Goal")}:`}
        />
        <NeedItem
          content={`${dietNeeds.height}, ${dietNeeds.weight}, desire to reach ${dietNeeds.desiredWeight}.`}
          icon={CurrentStatus_Svg}
          title={`${t("MPP_Current_status")}:`}
        />
        <NeedItem
          content={`${
            dietNeeds?.pushups.toLowerCase() == "fewer than 12"
              ? "I can do pushups " + dietNeeds.pushups.toLowerCase() + ", "
              : ""
          }${
            dietNeeds.sedentary.toLowerCase() === "yes"
              ? "have a sedentary lifestyle, "
              : ""
          }${
            dietNeeds.walkDaily.toLowerCase() === "less than 1 hour"
              ? " daily walking time " +
                dietNeeds.walkDaily.toLowerCase() +
                ", "
              : ""
          }${
            dietNeeds.feelBtwnMeal.toLowerCase() !== "i am always energized"
              ? dietNeeds.feelBtwnMeal.toLowerCase() + ", "
              : ""
          }${
            dietNeeds.sleep.toLowerCase() === "fewer than 5 hours"
              ? "getting sleep " + dietNeeds.sleep.toLowerCase()
              : ""
          }`}
          icon={Challenges_Svg}
          title={`${t("MPP_Challenges")}:`}
        />
        {dietNeeds?.motivation && (
          <NeedItem
            content={
              "Look and feel your best, buy new clothes, improve health."
            }
            icon={Motivation_Svg}
            title={`${t("MPP_Motivations")}:`}
          />
        )}
        {dietNeeds?.dietPreference && (
          <NeedItem
            content={dietNeeds?.dietPreference}
            icon={Diet_Svg}
            title={`${t("MPP_Diet_Preference")}:`}
          />
        )}
      </div>
    </div>
  );
};

const NeedItem = ({ content, icon, title }) => {
  return (
    <div className="d-flex gap-3">
      <div style={{ height: "24px", width: "24px" }}>{icon()}</div>

      <div>
        <p
          className="m-0 fw-semibold text-inter text-start text-custom-white text-capitalize"
          style={{ fontSize: "14px" }}
        >
          {title}
        </p>
        <p
          className="m-0 text-start text-inter"
          style={{ fontSize: "14px", color: "#B2B2B2", lineHeight: "17px" }}
        >
          {content}
        </p>
      </div>
    </div>
  );
};

const AdditionalTips = ({ dietMeal }) => {
  return (
    <div
      className="w-100 border-top-bottom py-4 main-padding-auth d-flex flex-column gap-3 mt-4 px-4"
      style={{
        borderColor: "#C1C1C1",
        backgroundColor: "#292929",
      }}
    >
      <h3
        className="m-0 fw-semibold mb-1 text-inter text-custom-white text-capitalize"
        style={{ fontSize: "24px" }}
      >
        {t("MPP_Tips_Title")}
      </h3>
      <div className="d-flex flex-column gap-3">
        {typeof dietMeal.additionalTips === "string" ? (
          <div>
            <p
              className="m-0 text-start text-inter"
              style={{ fontSize: "14px", color: "#B2B2B2" }}
            >
              {dietMeal.additionalTips}
            </p>
          </div>
        ) : (
          dietMeal.additionalTips?.map(
            (data, index) =>
              data && (
                <div key={index}>
                  <p
                    className="m-0 text-start  text-inter"
                    style={{
                      fontSize: "14px",
                      color: "#B2B2B2",
                      lineHeight: "17px",
                    }}
                  >
                    {data}
                  </p>
                </div>
              )
          )
        )}
      </div>
    </div>
  );
};

const DietStrategy = ({ dietMeal }) => {
  return (
    <div className="w-100 main-padding-auth d-flex flex-column gap-3 mt-4 px-4">
      <h3
        className="m-0 fw-semibold mb-1 text-inter text-custom-white text-capitalize"
        style={{ fontSize: "24px" }}
      >
        {t("MPP_Diet_Strategy")}
      </h3>
      <p
        className="text-start fw-regular text-inter m-0"
        style={{ fontSize: "14px", lineHeight: "17px", color: "#B2B2B2" }}
      >
        {t("MPP_Diet_Strategy_text")}
      </p>
      <div className="d-flex flex-column ">
        {dietData.map((data, index) =>
          data.key !== "Snacks" ? (
            dietMeal[data.key.toLowerCase()] ||
            isValidObject(dietMeal[data.key.toLowerCase() + "Detail"]) ? (
              <DietListItem
                dietMeal={dietMeal}
                key={index}
                item={data}
                showHr={index !== dietData.length - 1}
              />
            ) : (
              ""
            )
          ) : dietMeal.snacksTips || dietMeal[data.key.toLowerCase()] ? (
            <DietListItem
              dietMeal={dietMeal}
              key={index}
              item={data}
              showHr={index !== dietData.length - 1}
            />
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
};

const DietListItem = ({ item, showHr, dietMeal }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between ">
        <div className="d-flex gap-2 ">
          <img src={item.image} alt="" style={{ height: "43px" }} />
          <div className="d-flex flex-column justify-content-center">
            <p
              className="m-0 text-start fw-semibold text-inter text-custom-white text-capitalize"
              style={{ fontSize: "16px" }}
            >
              {item.title}
            </p>
            <p
              className="m-0 text-start text-inter fw-regular"
              style={{ color: "#B2B2B2", fontSize: "14px" }}
            >
              {dietMeal[item.key.toLowerCase()]
                ? dietMeal[item.key.toLowerCase()]
                : "- calories"}
            </p>
            {item.key === "Snacks" && dietMeal.snacksTips ? (
              <ul className="ps-3 mt-2 meal-list">
                {typeof dietMeal.snacksTips === "string" ? (
                  <li
                    className="text-start text-inter fw-regular"
                    style={{ fontSize: "14px", color: "#B2B2B2" }}
                  >
                    {dietMeal.snacksTips}
                  </li>
                ) : (
                  dietMeal.snacksTips?.map(
                    (list, index) =>
                      list && (
                        <li
                          key={index}
                          className="text-start text-inter fw-regular"
                          style={{
                            fontSize: "14px",
                            lineHeight: "17px",
                            color: "#B2B2B2",
                          }}
                        >
                          {list}
                        </li>
                      )
                  )
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>

        {item.key !== "Snacks" &&
        isValidObject(dietMeal[item.key.toLowerCase() + "Detail"]) ? (
          <div className="d-flex align-items-center ">
            <button
              className="fw-semibold py-2 text-capitalize"
              style={{
                backgroundColor: "#0045ff",
                color: "white",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontSize: "14px",
                lineHeight: "23px",
                borderRadius: "5px",
                border: "none",
                outline: "none",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(Meal_Details_Page, {
                  state: {
                    key: item.key,
                    details: dietMeal[item.key.toLowerCase() + "Detail"],
                  },
                });
              }}
            >
              {t("MPP_View_Btn_Text")}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      {showHr ? (
        item.key === "Dinner" && !dietMeal.snacksTips && !dietMeal.snacks ? (
          ""
        ) : (
          <div className="w-100 border-t my-3"></div>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default Meals;
