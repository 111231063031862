import React from "react";
import Tile from "../components/Tile";

const PartThreeLayout = ({
  title,
  Image,
  subText,
  items,
  handleSelect,
  selected,
  subTextClass,
}) => {
  return (
    <div className="flex-grow-1 main-padding pt-2 main-padding-b d-flex flex-column justify-content-between gap-4">
      <div>
        <h1 className="motivation-header my-0 text-inter fw-bold text-center">
          {title}
        </h1>
        {subText && (
          <p
            className={`mt-3 text-center text-inter part-three-sub-text ${
              subTextClass || ""
            }`}
          >
            {subText}
          </p>
        )}
      </div>
      <div className="">
        <Image />
      </div>
      <div className="d-flex flex-column gap-2">
        {items.map((item, index) => (
          <Tile
            selected={selected}
            {...item}
            key={index}
            handleClick={handleSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default PartThreeLayout;
