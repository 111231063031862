import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BeachIcon,
  BirthDayIcon,
  CompetitionIcon,
  ImportantDateIcon,
  NoIcon,
  ReunionIcon,
  SportsIcon,
  VacationIcon,
} from "../../../assets/svgs/partFour";
import Tile from "../../../components/Tile";
import { Part_Four_Two } from "../../../constants/pageRouteName";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { nextPageRoute } from "../../../utils/navigationTransition";

const Items = [
  {
    id: 1,
    text: t("Vacation"),
    value: "Vacation",
    icon: VacationIcon,
  },
  {
    id: 2,
    text: t("Competition"),
    value: "Competition",
    icon: CompetitionIcon,
  },
  {
    id: 3,
    text: t("Important Date"),
    value: "Important Date",
    icon: ImportantDateIcon,
  },
  {
    id: 4,
    text: t("Extreme Sports"),
    value: "Extreme Sports",
    icon: SportsIcon,
  },
  {
    id: 5,
    text: t("Birthday"),
    value: "Birthday",
    icon: BirthDayIcon,
  },
  {
    id: 6,
    text: t("Beach Trip"),
    value: "Beach Trip",
    icon: BeachIcon,
  },
  {
    id: 7,
    text: t("Reunion"),
    value: "Reunion",
    icon: ReunionIcon,
  },
  {
    id: 8,
    text: t("No"),
    value: "No",
    icon: NoIcon,
  },
];

const Event = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoNextPage = (id) => {
    setSelected(Items[id - 1]);
    dispatch(
      setDetailsData({
        occasion: { text: Items[id - 1].value, id: Items[id - 1].id },
      })
    );
    nextPageRoute(navigate, Part_Four_Two);
  };
  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-between pt-2 main-padding-b main-padding gap-3">
      <div className="w-100 d-flex justify-content-center">
        <h1
          className="event-header my-0 text-inter fw-bold text-center mb-3"
          style={{ maxWidth: "300px" }}
        >
          {t("EV_title")}
        </h1>
      </div>
      <div className="d-flex flex-column gap-2">
        {Items.map((item, index) => (
          <Tile
            key={index}
            {...item}
            handleClick={gotoNextPage}
            selected={selected?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default Event;
