import { t } from "i18next";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logoPlaceholder from "../../assets/images/blurred/Logo.png";
import { Email_Icon } from "../../assets/svgs/email";
import Button from "../../components/Button";
import ImageLoader from "../../components/ImageLoader";
import Loader from "../../components/Loader";
import { Otp_Page } from "../../constants/pageRouteName";
import { addS3Url } from "../../modules/s3";
import { supportHandler } from "../../modules/supportHandler";
import { failureToast, successToast } from "../../modules/toaster";
import ApiService from "../../service/api";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(true);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleEmail = (e) => setEmail(e.target.value.toLowerCase());
  const handleEmailFocus = () => setEmailFocus(true);

  const handleLogin = async () => {
    setLoading(true);
    await ApiService.loginUser({ email: email })
      .then((response) => {
        if (response.status === 200) {
          successToast(response.data?.message);
          localStorage.setItem("email", email);
          navigate(Otp_Page, { state: { email: email } });
        }
        setLoading(false);
      })
      .catch((error) => {
        failureToast(error?.request?.response);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (email.includes("@") && email.includes(".") && email !== "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, [email]);

  return (
    <div
      className="flex-grow-1 d-flex flex-column justify-content-center align-items-center main-padding"
      style={{ paddingBottom: "78px" }}
    >
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <ImageLoader
          placeHolderImage={logoPlaceholder}
          src={addS3Url("/Logo.png")}
          alt="logo"
          style={{ height: 100, width: 100 }}
          className="mb-1"
        />
        <h3
          style={{ fontSize: "24px", lineHeight: "29px" }}
          className="fw-bold text-inter text-custom-white mx-0 my-3"
        >
          {t("WELCOME")}
        </h3>
        <p
          className="fw-regular text-inter m-0"
          style={{ color: "#B2B2B2", lineHeight: "20px", fontSize: "16px" }}
        >
          {t("Don't have an account?")}
        </p>
        <Link
          to={"/"}
          className="button fw-bold text-inter"
          style={{ textDecoration: "underline", color: "#B2B2B2" }}
        >
          {t("Start the survey")}
        </Link>
        <div className="w-100 login-input-div mt-3">
          <div className="login-input-icon">{Email_Icon()}</div>
          <input
            onFocus={handleEmailFocus}
            className={`login-input w-100 ${
              emailFocus && emailError ? "border-red" : ""
            }`}
            value={email}
            onChange={handleEmail}
            type="email"
            placeholder={t("Email")}
          />
        </div>
        <div className="text-start w-100 my-2">
          <p
            className="p-0 m-0 text-inter"
            style={{ color: "red", fontSize: "12px" }}
          >
            {emailFocus && emailError && t("please enter valid email")}
          </p>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Button
            disabled={emailError}
            classNames={
              emailFocus && emailError
                ? "text-capitalize"
                : "mt-3 text-capitalize"
            }
            label={t("Login")}
            onClick={handleLogin}
          />
        )}
      </div>
      <div className="position-absolute text-center" style={{ bottom: "28px" }}>
        <p
          style={{ color: "#B2B2B2", fontSize: "16px", lineHeight: "20px" }}
          className="m-0 fw-regular text-inter"
        >
          {t("Need help?")}
        </p>
        <button
          onClick={supportHandler}
          className="button fw-bold text-inter"
          style={{ textDecoration: "underline", color: "#B2B2B2" }}
        >
          {t("Contact us")}
        </button>
      </div>
    </div>
  );
};

export default Login;
