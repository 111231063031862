import React from "react";
import ApiService from "../service/api";

import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Active_Exercise_Plan,
  Active_MealPlan_Icon,
  Exercise_Plan,
  MealPlan_Icon,
} from "../assets/svgs/bottom-tab";
import {
  Course_Page,
  Meal_Details_Page,
  Meal_Plan_Page,
} from "../constants/pageRouteName";
import { setMealPlanData } from "../redux/slices/MealPlanSlice";
import { isValidArray, isValidObject } from "../utils/valid";

const checkMealPlan = (mealPlan) => {
  return (
    mealPlan?.details &&
    (mealPlan?.details?.DietMeal.breakfast ||
      mealPlan?.details?.DietMeal.lunch ||
      mealPlan?.details?.DietMeal.dinner ||
      mealPlan?.details?.DietMeal.snacks ||
      isValidArray(mealPlan?.details?.DietMeal.snacksTips) ||
      isValidArray(mealPlan?.details?.DietMeal.additionalTips) ||
      isValidObject(mealPlan?.details?.DietMeal.breakfastDetail) ||
      isValidObject(mealPlan?.details?.DietMeal.lunchDetail) ||
      isValidObject(mealPlan?.details?.DietMeal.dinnerDetail))
  );
};

const BottomTabLayout = () => {
  const mealPlan = useSelector((state) => state.mealState);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!mealPlan.details) {
      ApiService.getMealPlanList()
        .then((res) => {
          if (typeof res.data === "object") {
            dispatch(setMealPlanData(res.data));
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const tabs = [
    {
      display: true,
      id: 1,
      name: t("Exercise Plan"),
      active: location.pathname.includes(Course_Page),
      activeIcon: Active_Exercise_Plan,
      icon: Exercise_Plan,
      isBorder: checkMealPlan(mealPlan),
      handleClick: () => {
        navigate(Course_Page);
      },
    },
    {
      display: checkMealPlan(mealPlan),
      id: 2,
      name: t("Meal Plan"),
      active: [Meal_Plan_Page, Meal_Details_Page].includes(location.pathname),
      activeIcon: Active_MealPlan_Icon,
      icon: MealPlan_Icon,
      handleClick: () => {
        navigate(Meal_Plan_Page);
      },
    },
  ];

  return (
    <div className="w-100 d-flex footer-container bottom-tab-div position-fixed bottom-0 left-0 py-2 ">
      {tabs.map(
        (tab, index) =>
          tab.display && (
            <button
              key={index}
              className="tabs"
              style={{
                borderRight: tab.isBorder ? "1px solid #8b8b8b" : "none",
              }}
              onClick={tab.handleClick}
            >
              <div style={{ width: "32px" }}>
                {tab.active ? tab.activeIcon() : tab.icon()}
              </div>
              <p
                className={`text-inter fw-bold m-0 mt-1 ${
                  tab.active ? "tab-active-text" : "tab-inactive-text"
                }`}
              >
                {tab.name}
              </p>
            </button>
          )
      )}
    </div>
  );
};

export default BottomTabLayout;
