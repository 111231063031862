import { t } from "i18next";
import arthur from "../assets/images/payment/arthur.png";
import emre from "../assets/images/payment/emre.png";
import lars from "../assets/images/payment/lars.png";
import mateus from "../assets/images/payment/matues.png";
import micheal from "../assets/images/payment/micheal.png";
import steven from "../assets/images/payment/steven.png";
import { addS3Url } from "../modules/s3";

export const features = [
  {
    title: t("feature1"),
  },
  { title: t("feature2") },
  { title: t("feature3") },
  {
    title: t("feature4"),
  },
  {
    title: t("feature5"),
  },
];

export const reviews = [
  {
    img: addS3Url("/payment/smiling-face-with-sunglasses.png"),
    name: "@atellb",
    title: t("review1"),
  },
  {
    img: addS3Url("/payment/smiling-face.png"),
    name: "@lkleir",
    title: t("review2"),
  },
  {
    img: addS3Url("/payment/thumbs-up.png"),
    name: "@anntp",
    title: t("review3"),
  },
];
export const newReviews = [
  {
    img: arthur,
    name: "Arthur Devereux",
    title: t("review4"),
  },
  {
    img: lars,
    name: "Lars Bergman",
    title: t("review5"),
  },
  {
    img: emre,
    name: "Emre Yilmaz",
    title: t("review6"),
  },
  {
    img: micheal,
    name: "Michael Hughes",
    title: t("review8"),
  },
  {
    img: steven,
    name: "Steven Harris",
    title: t("review9"),
  },
  {
    img: mateus,
    name: "Mateus Silva",
    title: t("review7"),
  },
];

export const courses = [
  {
    id: 1,
    title: "Bed Workout",
    subTitle: "Your ultimate bed workout experience",
  },
  {
    id: 2,
    title: "Home Workout",
    subTitle: "Home workouts for a healthier you",
  },
  {
    id: 3,
    title: "Stretching",
    subTitle: "Elevate your fitness with dynamic stretching",
  },
  {
    id: 4,
    title: "Bed Workout 2",
    subTitle: "Your ultimate bed workout experience",
  },
];

export const videos = [
  {
    id: 1,
    title: "Lessons Intro",
    subTitle: "Watch this first",
  },
  {
    id: 2,
    title: "Summus Brains Sit, Morbo Vel Maleficia Gorger",
    subTitle: "Mindless mortuis soulless creaturas",
  },
  {
    id: 3,
    title: "Zonbi Tattered For Solum",
    subTitle: "Qui animated corpse, cricket bat max brucks",
  },
  {
    id: 4,
    title: "Summus Brains Sit, Morbo Vel Maleficia Gorger",
    subTitle: "Mindless mortuis soullness creatures",
  },
  {
    id: 5,
    title: "Zonbi Tattered For Solum",
    subTitle: "Qui animated corpse, cricket bat max brucks",
  },
];

export const mealPlanData = {
  message: "Success",
  DietNeeds: {
    goal: "Weight Loss",
    bodyType: "Hero",
    currentWeight: "56 kg",
    BMI: "37.53",
    desiredWeight: "60 kg",
    motivatesToExercise: "Improving Health,Boosting Immune System",
    targetZones: "Legs,Belly,Arms,Pecs",
    previousPerfectWeight: "1-3 Years ago",
    interest: "Weight loss,Body sculpting,Hearth Health",
    fitnessLevel: "Beginner",
    pushups: "13-20",
    sedentary: "Yes",
    walkDaily: "Less than 1 hour",
    feelBtwnMeal: "I am tired after I eat",
    sleep: "Fewer than 5 hours",
    water: "I only drink tea and coffee",
    workoutLocation: "Home",
    height: "123 cm",
    age: "Age 40-49",
    event: "Important Date",
    occasionDate: "Invalid date",
  },
  DietMeal: {
    breakfast: "300-350 calories",
    lunch: "400-450 calories",
    dinner: "500-550 calories",
    snacks: "150-200 calories",
    snacksTips: [
      "1 cup chopped fruit (such as berries, bananas, and apples)",
      "1 cup Greek yogurt",
      "1/2 cup berries",
    ],
    breakfastDetail: {
      Veg: {
        Meal: "Oatmeal with Berries and Nuts",
        Kcal: "400",
        Carbs: "60g",
        Protein: "20g",
        Calories: "400",
        Fat: "10g",
        Ingredients: [
          "1/2 cup rolled oats",
          "1 cup berries (strawberries, blueberries, raspberries)",
          "1/4 cup chopped nuts (almonds, walnuts, pecans)",
          "200ml semi-skimmed milk or almond milk",
        ],
        Instructions: [
          "Combine oats, berries, and nuts in a bowl.",
          "Pour milk over the mixture and stir well.",
          "Microwave for 1-2 minutes, or until heated through.",
          "Let cool slightly before eating.",
        ],
      },
      "Non-Veg": {
        Meal: "Scrambled Eggs with Smoked Salmon and Spinach",
        Kcal: "450",
        Carbs: "10g",
        Protein: "35g",
        Calories: "450",
        Fat: "25g",
        Ingredients: [
          "3 eggs",
          "30g smoked salmon",
          "1 cup spinach",
          "1 tablespoon olive oil",
          "Salt and pepper to taste",
        ],
        Instructions: [
          "Heat the olive oil in a non-stick pan over medium heat.",
          "Whisk the eggs in a bowl and season with salt and pepper.",
          "Pour the eggs into the pan and cook, stirring occasionally, until set.",
          "Add the smoked salmon and spinach to the pan and cook for another minute, or until the spinach is wilted.",
          "Serve immediately.",
        ],
      },
    },
    lunchDetail: {
      Veg: {
        Meal: "Quinoa Salad with Grilled Vegetables",
        Kcal: "500",
        Carbs: "70g",
        Protein: "25g",
        Calories: "500",
        Fat: "15g",
        Ingredients: [
          "1 cup cooked quinoa",
          "1 cup grilled vegetables (zucchini, bell peppers, onions)",
          "1/2 cup chopped cucumber",
          "1/4 cup crumbled feta cheese",
          "2 tablespoons olive oil",
          "1 tablespoon lemon juice",
          "Salt and pepper to taste",
        ],
        Instructions: [
          "Combine all ingredients in a large bowl and mix well.",
          "Serve chilled.",
        ],
      },
      "Non-Veg": {
        Meal: "Chicken Breast with Brown Rice and Steamed Broccoli",
        Kcal: "550",
        Carbs: "60g",
        Protein: "45g",
        Calories: "550",
        Fat: "15g",
        Ingredients: [
          "150g grilled chicken breast",
          "1 cup cooked brown rice",
          "1 cup steamed broccoli",
          "1 tablespoon olive oil",
          "Salt and pepper to taste",
        ],
        Instructions: [
          "Season the chicken breast with salt and pepper and grill over medium heat until cooked through.",
          "Serve the chicken breast with brown rice and steamed broccoli.",
        ],
      },
    },
    Dinner: {
      Veg: {
        Meal: "Lentil Soup with Whole Wheat Bread",
        Kcal: "400",
        Carbs: "60g",
        Protein: "25g",
        Calories: "400",
        Fat: "10g",
        Ingredients: [
          "1 cup red lentils",
          "4 cups vegetable broth",
          "1 onion, chopped",
          "2 carrots, chopped",
          "2 celery stalks, chopped",
          "2 cloves garlic, minced",
          "1 teaspoon cumin powder",
          "1 teaspoon turmeric powder",
          "Salt and pepper to taste",
          "2 slices whole wheat bread",
        ],
        Instructions: [
          "Rinse the lentils in a colander.",
          "Heat the olive oil in a large pot over medium heat.",
          "Add the onion, carrots, celery, and garlic to the pot and cook until softened.",
          "Stir in the cumin and turmeric powder and cook for 1 minute more.",
          "Add the lentils and vegetable broth to the pot and bring to a boil.",
          "Reduce heat to low, cover, and simmer for 30 minutes, or until the lentils are tender.",
          "Season with salt and pepper to taste.",
          "Serve with whole wheat bread.",
        ],
      },
      "Non-Veg": {
        Meal: "Salmon with Roasted Sweet Potatoes and Asparagus",
        Kcal: "500",
        Carbs: "40g",
        Protein: "40g",
        Calories: "500",
        Fat: "20g",
        Ingredients: [
          "150g salmon fillet",
          "1 sweet potato, peeled and cubed",
          "1 bunch asparagus",
          "1 tablespoon olive oil",
          "Salt and pepper to taste",
        ],
        Instructions: [
          "Preheat oven to 400 degrees F (200 degrees C).",
          "Toss the sweet potato and asparagus with olive oil, salt, and pepper.",
          "Spread the vegetables in a single layer on a baking sheet.",
          "Place the salmon fillet on top of the vegetables.",
          "Bake for 15-20 minutes, or until the salmon is cooked through and the vegetables are tender.",
        ],
      },
    },
    dinnerDetail: {
      Veg: {
        Kcal: 2100,
        Meal: "Vegetable Stir-Fry with Brown Rice",
        Carbs: "70g",
        Protein: "20g",
        Calories: 500,
        Ingredients: [
          "1 cup chopped vegetables (such as broccoli, carrots, and onions)",
          "1 tablespoon olive oil",
          "1/2 cup cooked brown rice",
          "1/4 cup soy sauce",
        ],
        Instructions: [
          "Heat olive oil in a large skillet over medium heat.",
          "Add vegetables and cook until tender.",
          "Add brown rice and soy sauce and cook until heated through.",
          "Serve immediately.",
        ],
      },
      "Non-Veg": {
        Kcal: 2310,
        Meal: "Baked Salmon with Roasted Vegetables",
        Carbs: "60g",
        Protein: "35g",
        Calories: 550,
        Ingredients: [
          "1 salmon fillet",
          "1 tablespoon olive oil",
          "1/2 cup chopped vegetables (such as broccoli, carrots, and onions)",
          "1/4 cup lemon juice",
        ],
        Instructions: [
          "Preheat oven to 400 degrees Fahrenheit.",
          "Place salmon fillet on a baking sheet lined with parchment paper.",
          "Drizzle with olive oil and season with salt and pepper.",
          "Roast in oven for 15-20 minutes, or until cooked through.",
          "Serve with roasted vegetables and lemon juice.",
        ],
      },
    },
    additionalTips: [
      "Drink plenty of water throughout the day.",
      "Focus on compound exercises like squats, lunges, and deadlifts to build muscle and sculpt your butt.",
      "Don't be afraid to ask for help from a trainer or experienced gym-goer.",
      "Celebrate your progress and reward yourself for reaching your goals.",
      "Remember to listen to your body and rest when needed.",
    ],
  },
};

export const planSettings = {
  primaryGoal: "Muscle Gain",
  workoutLocation: "Home",
  weight: "78 kg",
  desiredWeight: "60 kg",
  diet: "Vegan",
  dietPreference: "Vegan",
};

export const try_and_get = [
  t("try_and_get_1"),
  t("try_and_get_2"),
  t("try_and_get_3"),
];

export const faqs = [
  {
    question: t("faq_q_1"),
    answer: t("faq_a_1"),
  },
  {
    question: t("faq_q_2"),
    answer: t("faq_a_2"),
  },
  {
    question: t("faq_q_3"),
    answer: t("faq_a_3"),
  },
];

export const mealPlan_data = {
  mealState: {
    details: {
      DietMeal: {
        breakfast: "450 calories",
        breakfastDetail: {
          item: "Oatmeal with fruits",
          calories: "200 calories",
          protein: "10g",
          carbs: "40g",
          fats: "5g",
        },
        lunch: "500 calories",
        lunchDetail: {
          item: "Grilled chicken with quinoa",
          calories: "300 calories",
          protein: "30g",
          carbs: "45g",
          fats: "10g",
        },
        dinner: "550 calories",
        dinnerDetail: {
          item: "Salmon with steamed veggies",
          calories: "400 calories",
          protein: "35g",
          carbs: "20g",
          fats: "20g",
        },
        snacks: "200 calories",
        snacksTips: [
          "Handful of almonds",
          "Greek yogurt with honey",
          "Apple slices with peanut butter",
        ],
        additionalTips: [
          "Drink plenty of water throughout the day.",
          "Incorporate a variety of colorful vegetables into your meals.",
          "Limit processed foods and added sugars.",
        ],
      },
      DietNeeds: {
        primaryGoal: "Lose weight",
        targetZones: "Abdomen and thighs",
        height: "170 cm",
        weight: "70 kg",
        desiredWeight: "65 kg",
        pushups: "More than 20",
        sedentary: "No",
        walkDaily: "1-2 hours",
        feelBtwnMeal: "Energized",
        sleep: "7-8 hours",
        motivation: "Look and feel your best, buy new clothes, improve health.",
        dietPreference: "Vegetarian",
      },
    },
  },
};
