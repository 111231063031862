export const Menu_Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="15.75"
    viewBox="0 0 27 15.75"
  >
    <g id="Menu" transform="translate(-4.5 -10.125)">
      <path
        id="Path_7"
        data-name="Path 7"
        d="M30.375,12.375H5.625A1.128,1.128,0,0,1,4.5,11.25h0a1.128,1.128,0,0,1,1.125-1.125h24.75A1.128,1.128,0,0,1,31.5,11.25h0A1.128,1.128,0,0,1,30.375,12.375Z"
        fill="#fff"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M30.375,19.125H5.625A1.128,1.128,0,0,1,4.5,18h0a1.128,1.128,0,0,1,1.125-1.125h24.75A1.128,1.128,0,0,1,31.5,18h0A1.128,1.128,0,0,1,30.375,19.125Z"
        fill="#fff"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M30.375,25.875H5.625A1.128,1.128,0,0,1,4.5,24.75h0a1.128,1.128,0,0,1,1.125-1.125h24.75A1.128,1.128,0,0,1,31.5,24.75h0A1.128,1.128,0,0,1,30.375,25.875Z"
        fill="#fff"
      />
    </g>
  </svg>
);
