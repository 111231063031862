import { createSlice } from "@reduxjs/toolkit";

const planSettingReducer = createSlice({
  name: "details",
  initialState: {
    details: {},
  },
  reducers: {
    setPlanDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});
const { setPlanDetails } = planSettingReducer.actions;

export const setPlanData = (data) => {
  return (dispatch, getState) => {
    let { details } = getState().planSettingState;
    let newData = { ...details, ...data };
    dispatch(setPlanDetails(newData));
  };
};

export default planSettingReducer.reducer;
