import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Otp_Page } from "../../constants/pageRouteName";
import { generateRandomCode } from "../../modules/randomCode";
import { failureToast, successToast } from "../../modules/toaster";
import ApiService from "../../service/api";

const PayPalElement = ({ email, plan }) => {
  const planIdRef = React.useRef(`${plan}`);
  const navigate = useNavigate();

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    "enable-funding": "",
    "disable-funding": "card",
    vault: "true",
  };

  const createSubscription = (data, actions) => {
    const planId = planIdRef.current;
    return actions.subscription
      .create({
        plan_id: planId,
      })
      .then((orderID) => {
        return orderID;
      });
  };

  React.useEffect(() => {
    planIdRef.current = `${plan}`;
  }, [plan]);

  const onApprove = async (data, actions) => {
    const subscriptionID = data.subscriptionID;
    const code = generateRandomCode();
    try {
      await ApiService.createSubscription({
        email: email.toLowerCase(),
        code: code,
        payment_type: "paypal",
        subscription_id: subscriptionID,
      })
        .then(async (response) => {
          if (response.status === 200) {
            await ApiService.loginUser({
              email: email.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  localStorage.setItem("email", email.toLowerCase());
                  successToast(res?.data?.message);
                  navigate(Otp_Page, {
                    state: {
                      email: email.toLowerCase(),
                      isNewRegistered: true,
                    },
                  });
                }
              })
              .catch((err) => {
                failureToast(err?.response?.data);
              });
          }
        })
        .catch((error) => {
          failureToast(
            error?.response?.data?.error ??
              error?.response?.data?.message ??
              error?.response?.data ??
              "Something went wrong"
          );
        });
    } catch (e) {
      console.log(e.err);
    }
  };

  const onError = (err) => {
    console.log("🚀  err:", err);
    failureToast("Payment rejected");
  };

  const onCancel = () => {
    failureToast("Payment rejected");
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div
        className="mt-3 mb-4 payment-screen-padding-x"
        style={{ height: "55px" }}
      >
        <PayPalButtons
          style={{ layout: "vertical", label: "buynow", height: 55 }}
          createSubscription={createSubscription}
          onApprove={onApprove}
          catchError={onError}
          onError={onError}
          onCancel={onCancel}
        />
      </div>
    </PayPalScriptProvider>
  );
};

export default React.memo(PayPalElement);
