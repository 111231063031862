import i18next, { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { BackArrow } from "../../assets/svgs/BackArrow";
import Button from "../../components/Button";

const Subscription = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`flex-grow-1 d-flex flex-column justify-content-between`}
        style={{
          overflow: "hidden",
        }}
      >
        <div
          className="text-center main-padding d-flex flex-column flex-grow-1"
          style={{
            paddingBottom: "18px",
          }}
        >
          <div className="d-flex justify-content-between py-4 align-items-center gap-2">
            <button
              onClick={() => navigate(-1)}
              className="button"
              style={{ width: "25px", height: "25px" }}
            >
              {BackArrow()}
            </button>
            <p
              className="fw-bold text-inter text-custom-white m-0 w-100 text-uppercase"
              style={{
                fontSize: ["pt", "en"].includes(i18next.language)
                  ? "21px"
                  : i18next.language === "es"
                  ? "20px"
                  : "24px",
                textAlign: "center",
              }}
            >
              {t("SBP_title")}
            </p>
          </div>
          <Button label={t("SBP_Active_Text")} classNames={"text-uppercase"} />
          <div
            className="flex-grow-1"
            style={{
              flex: "1",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{ fontSize: "16px", color: "#B2B2B2" }}
              className="text-inter"
            >
              <Trans
                i18nKey={"SBP_Cancel_Text"}
                components={{
                  line: <br />,
                  tag: (
                    <Link
                      to="mailto:support@gainmuscle.app?subject=Cancel%20Subscription"
                      className="fw-bold"
                      style={{ color: "#B2B2B2" }}
                    />
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
