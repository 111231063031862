import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const recurlyUrl = axios.create({
  baseURL: `${process.env.REACT_APP_RECURLY_URL}`,
});

export default class ApiService {
  static setUserDetails(data = {}) {
    return api.post(`${API_URL}//`, data);
  }
  static addTag(tagId = "", data = {}) {
    return api.post(
      `https://api.convertkit.com/v3/tags/${tagId}/subscribe`,
      data
    );
  }
  static removeTag(tagId = "", data = {}) {
    return api.post(
      `https://api.convertkit.com/v3/tags/${tagId}/unsubscribe`,
      data
    );
  }
  static addEmail(data = {}) {
    return api.post(`${API_URL}/add-email/`, data);
  }
  static getRecurlyToken(data = {}) {
    return recurlyUrl
      .post("token", data, {})
      .then(
        (response) => {
          if (response.status === 200) {
            if (response.data.error) {
              return response.data.error;
            }
            return response;
          }
        },
        (err) => {
          if (err.response.status === 401) {
          }
          return err.response;
        }
      )
      .catch((reason) => {
        return reason;
      });
  }
  static getRecurlyPrice() {
    return api.get(`${API_URL}/get-price`);
  }
  static createPaymentIntent(data = {}) {
    return api.post(`${API_URL}/create-payment-intent/`, data);
  }
  static saveStripeInfo(data = {}) {
    return api.post(`${API_URL}/save-stripe-info/`, data);
  }
  static loginUser(data = {}) {
    return api.post(`${API_URL}/login-with-otp/`, data);
  }
  static verifyOTP(data = {}) {
    return api.post(`${API_URL}/validate-otp/`, data);
  }
  static createSubscription(data = {}) {
    return api.post(`${API_URL}/create-subscription/`, data);
  }
  static subscribeByApplePay(data = {}) {
    return api.post(`${API_URL}/create-subscription/`, data);
  }
  static getCourseList() {
    const headers = {
      Authorization: `Token ${getToken()}`,
    };

    return api.get(`${API_URL}/courses/`, {
      headers: headers,
    });
  }
  static updateGoals(data = {}) {
    const headers = {
      Authorization: `Token ${getToken()}`,
    };

    return api.post(`${API_URL}/update-goals/`, data, {
      headers: headers,
    });
  }
  static getGoalsList() {
    const headers = {
      Authorization: `Token ${getToken()}`,
    };

    return api.get(`${API_URL}/get-goals/`, {
      headers: headers,
    });
  }
  static getMealPlanList() {
    const headers = {
      Authorization: `Token ${getToken()}`,
    };

    return api.get(`${API_URL}/get-meal-plan/`, {
      headers: headers,
    });
  }
  static updateMealPlanData(data = {}) {
    const headers = {
      Authorization: `Token ${getToken()}`,
    };

    return api.post(`${API_URL}/update-meal-plan/`, data, {
      headers: headers,
    });
  }
  static getCourseVideoList(id) {
    const headers = {
      Authorization: `Token ${getToken()}`,
    };

    return api.get(`${API_URL}/courses/${id}/videos/`, {
      headers: headers,
    });
  }
  static addViewVideo(data = {}) {
    const headers = {
      Authorization: `Token ${getToken()}`,
    };

    return api.post(`${API_URL}/add-view/`, data, {
      headers: headers,
    });
  }
}
function getToken() {
  const data = localStorage.getItem("token");
  if (data !== null && data !== undefined) {
    const access = JSON.parse(data);
    return access.token;
  } else {
    return null;
  }
}
