import React from "react";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import { Register_Page } from "../../../constants/pageRouteName";
import { placeHolderImages, s3Images } from "../../../utils/image";

const PersonalPlan = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(Register_Page);
    }, 6050);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="flex-grow-1 d-flex flex-column gap-3">
      <div className="main-padding d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-3">
          <h1 className="personal-plan-header my-0 text-inter fw-bold text-center">
            {t("1 MILLION")}
          </h1>
          <p className="text-inter text-center fw-regular help-text-banner-subtitle m-0">
            {t("UP_header")}
          </p>
        </div>
        <div className="banner">
          <p className="personal-plan-banner fw-regular text-inter text-center">
            {t("creating_plan")}
          </p>
          <div className="progress-bar w-100">
            <div className="progress-thumb"></div>
          </div>
        </div>
      </div>
      <div className="w-100 overflow-hidden position-relative">
        <ImageLoader
          placeHolderImage={placeHolderImages.personalPlan}
          src={s3Images.personalPlan}
          alt="Personal Plan"
          className="progress-img"
        />
      </div>
    </div>
  );
};

export default PersonalPlan;
