import { preLoadImage } from "./preLoadImage";
import { isValidArray } from "./valid";

export const nextPageRoute = async (
  routeFunc,
  route,
  dispatch = null,
  imagesToPreload = []
) => {
  const start = Date.now();

  if (isValidArray(imagesToPreload) && dispatch) {
    preLoadImage(imagesToPreload, dispatch);
  }

  const elapsed = Date.now() - start;
  const delay = Math.max(0, 250 - elapsed);
  setTimeout(() => {
    routeFunc(route);
  }, delay);
};
