import { createSlice } from "@reduxjs/toolkit";
import { s3Urls } from "../../utils/image";

const imageReducer = createSlice({
  name: "image",
  initialState: s3Urls
    .map((url) => ({ [url]: false }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  reducers: {
    setLoaded: (state, action) => {
      state = { ...state, [action.payload]: true };
      return state;
    },
  },
});
const { setLoaded } = imageReducer.actions;

export const setImageLoaded = (data) => {
  return (dispatch) => {
    dispatch(setLoaded(data));
  };
};

export default imageReducer.reducer;
