export const trTranslation = {
  START_NOW: "ŞİMDİ BAŞLA",
  "Privacy Policy": "Gizlilik Politikası",
  "Terms of Use": "Kullanım Şartları",
  "Refund Policy": "Politika de Reembolso",
  "Terms and Conditions": "Şartlar ve Koşullar",
  LOGIN: "GİRİŞ",
  "1 MILLION": "1 MİLYON",
  CONTINUE: "DEVAM ET",
  SAVE: "KAYDET",
  ft: "ft",
  lbs: "libras",
  kg: "kg",
  //footer
  copyright: "Telif Hakkı ® GainMuscle, 2024.",
  rights: "Tüm Hakları Saklıdır.",
  //landing page
  LP_header: "KASLARI 8 HAFTADA GELİŞTİRİN",
  LP_sub_header: "KİŞİYE ÖZEL BİR ANTRENMAN PLANINA SAHİP OLUN",
  LP_text_1: "'Şimdi Başla' düğmesine basarak,",
  LP_text_2: "okuduğunuzu, anladığınızı ve kabul ettiğinizi onaylarsınız",
  //users page
  UP_header: "KULLANICI BİZİ SEÇTİ",
  //actual body page
  AB_header: "VÜCUT <tag/>TİPİNİZİ SEÇİN",
  Skinny: "Zayıf",
  Average: "Ortalama",
  //goal page
  // GP_header: "HEDEFİNİZ NEDİR?",
  GP_header: "HEDEF<tag/> VÜCUT TİPİNİ SEÇİN",
  Cut: "Kesim",
  Bulk: "Hacim",
  "Extra Bulk": "Ekstra Hacim",
  "Get Shredded": "Fit Olmak",
  "Muscle Gain": "Kas Kazanımı",
  "Weight Loss": "Kilo Vermek",
  "GET SHREDDED": "Fit Olmak",
  "MUSCLE GAIN": "Kas Kazanımı",
  "WEIGHT LOSS": "Kilo Vermek",
  //body type page
  BT_header: "THE BODY YOU WANT",
  Athlete: "Athlete",
  Hero: "Hero",
  Bodybuilder: "Bodybuilder",
  //body type page
  BT_header: "İSTEDİĞİNİZ VÜCUT",
  Athlete: "Atlet",
  Hero: "Kahraman",
  Bodybuilder: "Vücut Geliştirici",
  //motivation page
  MT_header: "SİZİ EGZERSİZ YAPMAYA NE MOTİVE EDER?",
  "Improving Health": "Sağlığı İyileştirmek",
  "Boosting Immune System": "Bağışıklık Sistemini Güçlendirmek",
  "Looking Better": "Daha İyi Görünmek",
  "Building Strength": "Güçlenmek",
  "Boosting Libido": "Libidoyu Artırmak",
  //target-zones page
  TZ_header: "HEDEF BÖLGELERİNİ SEÇİN",
  ARMS: "kollar",
  BELLY: "karın",
  PECS: "göğüs",
  LEGS: "bacaklar",
  //perfect-weight page
  PW_header: "MÜKEMMEL KİLONUZDA NE ZAMAN OLDUNUZ?",
  "0-6 Months ago": "0-6 ay önce",
  "7-12 Months ago": "7-12 ay önce",
  "1-3 Years ago": "1-3 yıl önce",
  "More than 3 years ago": "3 yıldan fazla önce",
  Never: "Hiçbir zaman",
  //Interest page
  IT_header: "NEYLE İLGİLENİYORSUNUZ?",
  "Weight loss": "Kilo kaybı",
  "Body sculpting": "Vücut şekillendirme",
  "Hearth Health": "Kalp Sağlığı",
  Mobility: "Hareketlilik",
  Energy: "Enerji",
  Recovery: "İyileşme",
  //fitness level page
  FL_header: "FİTNESS SEVİYENİZ NEDİR?",
  Beginner: "Başlangıç",
  "I'm new to fitness": "Fitness'a yeniyim",
  Intermediate: "Orta seviye",
  "I workout from time to time": "Ara sıra antrenman yapıyorum",
  Advanced: "İleri seviye",
  "I exercise regularily": "Düzenli egzersiz yapıyorum",
  //push-up page
  PU_header: "KAÇ ŞINAV ÇEKEBİLİRSİNİZ?",
  "Fewer than 12": "12'den az",
  "13-20": "13-20",
  "More than 20": "20'den fazla",
  "I don't know": "Bilmiyorum",
  //life-style page
  LS_title_1: "SAHİP MİSİNİZ",
  LS_title_2: "SEDANTER BİR YAŞAM TARZINA?",
  Yes: "Evet",
  No: "Hayır",
  //walk page
  WL_title_1: "NE KADAR",
  WL_title_2: "GÜNDE NE KADAR YÜRÜYORSUNUZ?",
  "Less than 1 hour": "1 saatten az",
  "1-2 hours": "1-2 saat",
  "More than 2 hours": "2 saatten fazla",
  //Meal page
  ML_title_1: "ÖĞÜNLER ARASINDA",
  ML_title_2: "NASIL HİSSEDİYORSUNUZ?",
  ML_Subtitle:
    "Günün farklı saatlerinde enerjiniz nasıldır? En uygun seçenekleri seçin.",
  "I got sleepy when I'm hungry": "Açken uykum geliyor",
  "I am tired after I eat": "Yedikten sonra yoruluyorum",
  "I am always energized": "Her zaman enerjik",
  //Sleep page
  SL_title_1: "NE KADAR",
  SL_title_2: "UYUYORSUNUZ?",
  "Fewer than 5 hours": "5 saatten az",
  "Between 5 and 6 hours": "5 ila 6 saat arası",
  "Between 7 and 8 hours": "7 ila 8 saat arası",
  "Over 8 hours": "8 saatten fazla",
  //Water page
  WT_title: "SU TÜKETİMİNİZ NASIL?",
  WT_Subtitle_1: "Gün boyunca ne kadar su içiyorsunuz?",
  WT_Subtitle_2: "En uygun cevabı seçin.",
  "I only drink tea and coffee": "Sadece çay ve kahve içerim",
  "Fewer than 2 glasses of water": "2 bardaktan az su",
  "2-6 glasses of water": "2-6 bardak su",
  "7-10 glasses of water": "7-10 bardak su",
  "More than 10 glasses of water": "10 bardaktan fazla su",
  //workout location page
  WL_title: "ANTRENMAN YERİNİZİ SEÇİN",
  Home: "Ana Sayfa",
  Gym: "Spor salonu",
  HOME: "Ana Sayfa",
  GYM: "Spor salonu",
  //height page
  HT_title: "BOYUNUZ NE KADAR?",
  HT_text_1: "Vücut kitle indeksinizi hesaplama",
  HT_text_2:
    "VKİ, çeşitli sağlık sorunlarının gelişimi veya yaygınlığı için bir risk faktörü olarak ",
  HT_text_3: "yaygın olarak kullanılır.",
  HT_error_ft: "4ft ile 7ft 11in arasında bir değer girin",
  HT_error_cm: "122cm ile 242cm arasında bir değer girin",
  //current weight page
  CW_title_1: "ŞU ANKİ ",
  CW_title_2: "KİLONUZ NEDİR?",
  CW_text_1: "VKİ'niz {{bmi}}, bu obez olarak kabul {{bmiLevel}}.",
  CW_text_2: "Kilonuza odaklanmalısınız. Kişisel bir program hazırlayacağız.",
  Underweight: "Zayıf",
  Normal: "Normal",
  Obese: "Obez",
  Overweight: "Fazla kilolu",
  //target weight page
  TW_title_1: "HEDEF KİLONUZ ",
  TW_title_2: "NEDİR?",
  //age page
  AGE_LP_text:
    "Devam ederek, şartlarımızı okuduğunuzu, anladığınızı ve kabul ettiğinizi onaylıyor ve garanti ediyorsunuz",

  // AG_title: "KAÇ YAŞINDASINIZ?",
  AG_title: "MÜKEMMEL BEDENİNİZİ OLUŞTURUN",
  AG_text_1: "Yaşınızı sormamızın nedeni",
  AG_text_2: "kişisel planınız",
  AG_text_3:
    "Aynı VKİ'ye sahip genç insanlara göre yaşlı insanlar daha fazla vücut yağına sahip olma eğilimindedir.",
  age_text: "yaş {{range}}",
  //fitness summary page
  FS_title: "FİTNESS SEVİYENİZİN ÖZETİ",
  FS_bmi_text: "Vücut Kitle İndeksi (VKİ)",
  FS_normal_bmi_text: "NORMAL VKİ - {{bmi}}",
  FS_user_bmi_text: "SEN: {{bmi}}",
  FS_lifeStyle_text: "Yaşam Tarzı",
  FS_activity_text: "Aktivite",
  FS_exercise_text: "Egzersiz",
  FS_SEDENTARY: "SEDANTER",
  FS_ACTIVE: "AKTİF",
  FS_LOW: "DÜŞÜK",
  FS_HIGH: "YÜKSEK",
  "NO REGULAR WORKOUTS": "DÜZENLİ ANTRENMANLAR YOK",
  "REGULAR WORKOUTS": "DÜZENLİ ANTRENMANLAR",
  FS_text_1: "Sağlıksız bir VKİ'nin riskleri",
  FS_text_2:
    "Yüksek tansiyon, kalp hastalığı, felç, tip 2 diyabet, bazı kanser türleri, osteoartrit, sırt ağrısı, tüm nedenlerden ölüm.",
  //event page
  EV_title: "KAS KAZANMAK İSTEDİĞİNİZ ÖZEL BİR DURUM VAR MI?",
  Vacation: "Tatil",
  Competition: "Yarışma",
  "Important Date": "Önemli Tarih",
  "Extreme Sports": "Ekstrem Sporlar",
  Birthday: "Doğum Günü",
  "Beach Trip": "Plaj Gezisi",
  Reunion: "Buluşma",
  No: "Hayır",
  //event-date page
  ED_title: "ETKİNLİĞİNİZ NE ZAMAN?",
  ED_subtitle:
    "Bunu öğrendiğimizde, sizi forma sokmak için kişisel bir plan hazırlayabileceğiz. Verileriniz üçüncü taraflarla paylaşılmayacaktır.",
  "Choose date": "Tarih seçin",
  "SKIP THIS QUESTION": "BU SORUYU ATLA",
  //weight plan page
  WP_title_1: "İHTİYACINIZ OLAN TEK VE TEK PLAN",
  WP_title_2: "İÇİN {{text}} KİLO",
  WP_text_1: "Bize verdiğiniz bilgilere ",
  WP_text_2: "göre, hedef kilonuza ulaşacaksınız",
  WP_text_3: "Herkesi şaşırtmaya hazır olun",
  WP_text_4: "sizin {{desiredWeight}}!",
  WP_weight_status: "{{target}} TARİHİNE {{date}}",
  GAIN: "ALMAK",
  LOSE: "LOVERMEK",
  //personal-plan page
  creating_plan: "Kişisel planınızı oluşturuyor...",
  //register page
  RG_header: "Kişisel Planınız",
  RG_subheader:
    "E-posta adresinizi girin ve kişisel <tag>GainMuscle</tag> planınızı alın!",
  "Your Email": "E-posta adresiniz",
  email_error: "Geçerli bir e-posta adresi girin",
  "Well done!": "Aferin!",
  policy_text:
    "Gizliliğinize saygı duyuyoruz. E-postanız, reklam deneyimini daha alakalı hale getirebilir. <tag> Gizlilik Politikası </tag> .",
  //login page
  WELCOME: "HOŞGELDİNİZ",
  "Don't have an account?": "Bir hesabınız yok mu?",
  "Start the survey": "Anketi başlat",
  Email: "E-posta",
  "please enter valid email": "lütfen geçerli bir e-posta girin",
  Login: "giriş",
  "Need help?": "Yardıma mı ihtiyacınız var?",
  "Contact us": "bizimle iletişime geçin",
  //otp page
  OTP_header: "OTP girin",
  OTP_subheader:
    "Tek kullanımlık şifre e-posta adresinize <1>Email</1> gönderilmiştir: {{email}}",
  Submit: "Gönder",
  "Resend OTP": "OTP'yi tekrar gönder",
  //payment page
  "3 time per week": "Haftada 3 kez",
  "Target weight": "Hedef ağırlık",
  "30 minutes": "30 dakika",
  countdown_text: "Kişisel planınız önümüzdeki 15 dakika için rezerve edildi!",
  "get my plan": "PLANIMI AL",
  "TRY NOW": "ŞİMDİ DENEYİN",
  try_and_get_1:
    "Bir alışkanlık edinin ve doğru egzersiz tekniklerini geliştirin",
  try_and_get_2:
    "Aşırı vücut yağından kurtulun ve egzersiz yoğunluğunu artırın",
  try_and_get_3: "Hedefinize ulaşın ve hayatınızı sonsuza dek değiştirin",
  Now: "Şimdi",
  Goal: "Hedef",
  "After the program": "Programdan sonra",
  "Body fat": "Vücut yağı",
  "Muscle Mass": "Kas Kütlesi",
  "Your personal workout plan is ready!": "Kişisel antrenman planınız hazır!",
  "Current BMI": "Mevcut BMI",
  "Daily calorie intake": "Günlük kalori alımı",
  recommended: "önerilen",
  "Daily water intake": "Günlük su alımı",
  "Personalized plan for You is ready!":
    "Sizin için kişiselleştirilmiş plan hazır!",
  "Workout Duration": "Antrenman Süresi",
  "Fitness Level": "Fitness Seviyesi",
  "Place of Workout": "Antrenman Yeri",
  "Workout Frequency": "Antrenman Sıklığı",
  BMI_text:
    "Vücut kütle indeksi (BMI), boyunuzu ve kilonuzu kullanarak kilonuzun sağlıklı olup olmadığını belirleyen bir ölçümdür.",
  Level: "Seviye",
  "Personal summary based on your answers": "Cevaplarınıza dayalı kişisel özet",
  noticable_text:
    "Kullanıcıların %89'u GainMuscle planını kullanmanın sonuçlarının açıkça farkedilir olduğunu düşünüyor",
  image_warning:
    "*Görüntü kullanıcıyı temsil etmek amacıyla oluşturulmamıştır. Sonuçlar kişiye göre değişir ve garanti edilmez.",
  ready_text: "ANTRENMAN PLANINIZ HAZIR!",
  help_text: "Hedeflerinize ulaşmanıza yardımcı olmak için tasarlandı",
  try_header:
    "<tag>GainMuscle 7 GÜNÜ 1$ ÖDEME</tag> DENEYİN VE KAZANACAKSINIZ:",
  faq_header: "SSS",
  faq_q_1: "Planım nasıl oluşturulur?",
  faq_q_2: "Planıma nasıl erişebilirim?",
  faq_q_3: "Planımı oluşturduktan sonra değişiklik yapabilir miyim?",
  faq_a_1:
    "Planınız, sağladığınız yanıtlar ve gelişmiş algoritmalarımız kullanılarak benzersiz ihtiyaçlarınıza ve hedeflerinize özel olarak uyarlanır. Her program uzmanlar tarafından doğrulanarak sizin için etkili olacağı garanti edilir.",
  faq_a_2:
    "Her plan, beklentilerinizi karşılamak için beslenme uzmanları ve fitness uzmanlarından oluşan özel ekibimiz tarafından özelleştirilir. Planınıza e-posta yoluyla erişmeniz genellikle birkaç saat sürer.",
  faq_a_3:
    "Evet, planınızı oluşturduktan sonra bile üzerinde ayarlamalar yapabilirsiniz. Kondisyon seviyenizi, kişisel bilgilerinizi veya ölçüm birimlerinizi güncellemek için uygulamanın ayarlarına gitmeniz yeterlidir. Hedef kilo hedefinizi değiştirmek isterseniz ‘Kilo’ sekmesine gidin ve ekranın sağ üst köşesindeki ‘Hedef’ butonunu kullanın.",
  "Special Offer": "Özel Teklif",
  "7-Day Trial": "7 Günlük Deneme",
  "Total Today": "Bugün Toplam",
  "CARD NUMBER": "KART NUMARASI",
  "EXPIRES ON": "BİTİŞ TARİHİ",
  "Credit/Debit Card": "Kredi/Banka Kartı",
  "Process Card": "Kartı İşle",
  garrentee_text: "<tag>Güvenli</tag> Ödeme Garantisi",
  "WHAT YOU GET": "NE ELDE EDERSİNİZ",
  feature1: "1.000+ eğitmen onaylı egzersiz",
  feature2: "120+ lezzetli ve sağlıklı tarifler",
  feature3: "Adım adım video eğitimleriyle rehberli antrenmanlar",
  feature4:
    "İhtiyaçlarınıza ve fitness seviyenize göre kişiselleştirilmiş bir fitness planı",
  feature5:
    "Hepsi bir arada: en iyi sonuçlar için kardiyo, kuvvet ve toparlanma antrenmanlarının kombinasyonu",
  "USERS LOVE OUR PLANS": "KULLANICILAR PLANLARIMIZI SEVİYOR",
  review1:
    "Harika bir fitness uygulaması! Kullanımı kolay, beni motive ediyor ve ilerlememi sorunsuz bir şekilde takip ediyor.",
  review2:
    "Bu fitness uygulamasını seviyorum! Kolay takip, kişiselleştirilmiş planlar ve harika motivasyon. Şiddetle tavsiye ederim!",
  review3:
    "Bu fitness uygulaması bir oyun değiştirici! Kullanıcı dostu, beni motive ediyor ve kişiselleştirilmiş planlar hedeflerime ulaşmayı çocuk oyuncağı haline getiriyor. Çok etkilendim!",
  review4:
    "Bu, gerçekten bir rutine girmemi sağlayan ilk program çünkü başlamam için gerekli her şeyi sağladı, menü ve alışveriş listesi dahil.",
  review5:
    "Programı bir hafta denedim; sağlam özellikleri, iyi yapılandırılmış bir antrenman planı ve bir diyeti var. Diyet planı, bir alışveriş listesi ve adım adım talimatlarla birlikte geliyor.",
  review6:
    "Harika bir program! İyi çalışıyor ve düşünülerek tasarlanmış bir egzersiz seti içeriyor.",
  review7: "Şekle girmek için harika bir yol. Çok daha önce başlamalıydım!",
  review8:
    "Plan sağlam. Egzersizleri fitness seviyesine göre özelleştirmesini gerçekten takdir ediyorum ama çevrimdışı bir mod olursa daha iyi olurdu.",
  review9: "Bu muhteşem bir program! Sadece bir haftada 5 pound verdim.",
  "OUR USERS MEET THEIR GOALS": "KULLANICILARIMIZ HEDEFLERİNE ULAŞIYOR",
  "100% MONEY-BACK GUARANTEE": "%100 PARA İADE GARANTİSİ",
  garrentee_text2:
    "Planımızın sizin için işe yarayabileceğine ve 4 hafta içinde görünür sonuçlar alacağınıza inanıyoruz! Planımızı uyguladığınızı kanıtlayabilirseniz ve görünür sonuçlar alamazsanız paranızı geri vermeye bile hazırız. Tüm gereksinimleri anlamak için lütfen <tag>İade Politikamızı</tag> iki kez kontrol edin.",
  //course page
  CP_Title: "Tüm Kurslar",
  CP_NO_Courses: "Mevcut Kurs Yok",
  "Something went wrong.": "Bir şeyler yanlış gitti.",
  //meal plan page
  MPP_Title: "Kişisel Yemek Planı",
  "We Are Preparing Your Plan.": "Planınızı Hazırlıyoruz.",
  MPP_Remember_text:
    "Unutmayın, Hedeflerinize Ulaşmanın Anahtarı Tutarlılıktır. Motive Kalın, İlerlemenize Odaklanın ve Başarılarınızı Kutlayın!",
  MPP_Understand_Needs_text: "İhtiyaçlarınızı Anlamak",
  MPP_Goal: "Hedef",
  MPP_Current_status: "Mevcut Durum",
  MPP_Challenges: "Zorluklar",
  MPP_Motivations: "Motivasyonlar",
  MPP_Tips_Title: "Ekstra İpuçları",
  MPP_Diet_Preference: "Diyet Tercihi",
  MPP_Diet_Strategy: "Diyet Stratejisi",
  MPP_Diet_Strategy_text:
    "Egzersizlerinizi desteklemek ve kas yapmak için dengeli karbonhidratlar ve sağlıklı yağlar içeren yüksek proteinli bir diyete odaklanacağız.",
  MPP_View_Btn_Text: "Görüntüle",
  MPP_Breakfast: "Kahvaltı",
  MPP_Lunch: "Öğle Yemeği",
  MPP_Dinner: "Akşam Yemeği",
  MPP_Snacks: "Atıştırmalıklar",
  //meal details page
  "We Are Preparing Veg Meal Details.":
    "Vejetaryen Yemek Detaylarını Hazırlıyoruz.",
  "We Are Preparing Non-Veg Meal Details.":
    "Vejetaryen Olmayan Yemek Detaylarını Hazırlıyoruz.",
  MD_Remember_text:
    "Unutmayın, hedeflerinize ulaşmanın anahtarı tutarlılıktır. Motive kalın, ilerlemeye odaklanın ve başarılarınızı kutlayın!",
  MD_Nutritional_Info: "Besin bilgileri",
  "(per serving)": "(porsiyon başına)",
  Carbs: "Karbonhidratlar",
  Protein: "Protein",
  Fat: "Yağ",
  Ingredients: "Malzemeler",
  Instructions: "Talimatlar",
  //veg non veg
  "Vegan Diet": "Vegan Diyet",
  Vegan: "Vegan",
  "Non-vegan": "Vegan olmayan",
  VEGAN: "Vegan",
  "NON-VEGAN": "Vegan Olmayan",
  "Non-Vegan": "Vegan Olmayan",
  "Non-Vegan Diet": "Vegan Olmayan Diyet",
  VNP_Title: "Diyet <tag/> Tercihi",
  VNP_Banner_Veg:
    "diyetler bitki bazlı kaynaklardan bol miktarda lif, antioksidan ve mikro besin sağlar.",
  VNP_Banner_Non_Veg:
    "diyetler yüksek kaliteli protein ve gerekli amino asitleri sunar, kas onarımı ve büyümesini kolaylaştırır, bu da güç ve dayanıklılık antrenmanları için faydalı olabilir.",
  //settings page
  SP_toast: "Yeni Yemek Planı yakında güncellenecek.",
  SP_Title: "PLAN AYARLARI",
  SP_Helper_Text: "Kaydettikten sonra yeni bir plan oluşturulacak",
  SP_Button_Text: "Planı Güncelle",
  "Not Selected": "Seçilmedi",
  SP_Tile_1: "Hedefiniz",
  SP_Tile_2: "Hedef Bölgeler",
  SP_Tile_3: "Antrenman Yeri",
  SP_Tile_4: "Mevcut Ağırlık",
  SP_Tile_5: "Hedef Ağırlık",
  SP_Tile_6: "Diyet Tercihi",
  //subscription page
  SBP_title: "ABONELİĞİ YÖNET",
  SBP_Active_Text: "AKTİF",
  SBP_Cancel_Text:
    "İptal etmek istiyor musunuz? <line /> Dost canlısı destek ekibimize ulaşın <tag>burada</tag>",
  //bottom-tab
  "Exercise Plan": "Egzersiz Planı",
  "Meal Plan": "Yemek Planı",
  //action sheet
  "Edit Your Plan": "planınızı düzenleyin",
  Support: "destek",
  "Terms of Service": "hizmet şartlar",
  "Privacy Policy": "Gizlilik Politikası",
  "Manage Subscription": "aboneliği yönet",
  Logout: "çıkış",
};

export const privacy_policy_tr_Translation = {
  PP_title: "Gizlilik ve Politika",
  PP_commitment: "Gizliliğinize Bağlılığımız",
  PP_commitment_text:
    "WebApp kullanıcılarımızın gizliliğine saygı duyuyor ve koruyoruz. Bu Gizlilik Politikası, bilgilerinizi nasıl topladığımızı ve kullandığımızı açıklar ve WebApp'i kullandığınızda Hizmet Şartlarımızın bir parçasıdır. WebApp'imizi ve WebApp aracılığıyla sağlanan hizmeti (“Hizmet”) kullanarak, Gizlilik Politikamıza rıza göstermiş olursunuz. Kabul etmiyorsanız, lütfen WebApp'imizi kullanmayın.",
  PP_info_collect: "Hangi bilgileri topluyoruz?",
  PP_info_collect_text:
    "Satın alma işlemi gerçekleştirirseniz veya abonelik satın alırsanız, üçüncü taraf faturalama hizmeti sağlayıcılarımız adınız, kredi kartı numaranız ve fatura adresiniz gibi bilgileri toplayacaktır. Bize e-posta gönderirseniz, e-posta adresinizi yanıtlamak için kullanabiliriz. Mobil Cihazınız Tarafından Gönderilen Bilgiler. WebApp'imizi kullandığınızda, cihaz tanımlayıcısı, kullanıcı ayarları ve cihazınızın işletim sistemi gibi mobil cihazınızın gönderdiği belirli bilgileri toplarız ve Hizmetlerimizi kullanımınıza ilişkin bilgileri toplarız. Konum Bilgileri. WebApp'imizi kullandığınızda, IP adresinizi yaklaşık bir coğrafi konuma dönüştürerek veya konum hizmetlerini etkinleştirirseniz mobil cihazınızın GPS koordinatlarına veya kaba konumuna erişerek konumunuzu toplar ve saklarız. Konum bilgilerini Hizmetlerimizi sizin için iyileştirmek ve kişiselleştirmek için kullanabiliriz. Konum bilgilerini toplamamızı istemiyorsanız, bu özelliği mobil cihazınızda devre dışı bırakabilirsiniz.",
  PP_store_info: "Bilgilerinizi nerede saklıyoruz?",
  PP_store_info_text:
    "Bilgileriniz, kendi sunucularımızda veya üçüncü taraf bulut depolama sağlayıcılarına ait sunucularda saklanabilir. Üçüncü taraf depolama sağlayıcıları, bu Gizlilik Politikasında belirtilenler dışında bilgilerinizi kullanamaz.",
  PP_use_info: "Bilgilerinizi nasıl kullanıyoruz?",
  PP_use_info_text: "Sizden topladığımız bilgiler şu şekilde kullanılabilir:",
  PP_store_duration: "Bilgilerinizi ne kadar süre saklıyoruz?",
  PP_store_duration_text:
    "Bilgilerinizin bir kısmını süresiz olarak saklamayı amaçlıyoruz. Diğer web sitelerine bağlantılar hakkında ne yapmalı? Diğer web sitelerine bağlantılar veya uyumluluk sağlayabiliriz. Ancak, bu web siteleri tarafından kullanılan gizlilik uygulamalarından veya içeriklerinden sorumlu değiliz.",
  PP_protect_info: "Bilgilerinizi nasıl koruyoruz?",
  PP_protect_info_text:
    "Topladığımız bilgileri korumak ve güvence altına almak için uygun fiziksel, elektronik ve diğer prosedürleri kullanıyoruz. Ancak, internetin doğası gereği güvensiz bir ortam olduğunu ve bilgisayar korsanlarının sürekli olarak güvenlik önlemlerini aşmaya çalıştığını lütfen unutmayın. Bu nedenle, bilgilerinizin erişilmeyeceğini, ifşa edilmeyeceğini, değiştirilmeyeceğini veya yok edilmeyeceğini garanti edemeyiz ve bu riski kabul edersiniz.",
  PP_protect_self: "Bilgilerinizi nasıl koruyabilirsiniz?",
  PP_protect_self_text:
    "Kişisel bilgilerinizi başkalarıyla paylaşmamak veya çevrimiçi yayınlamamak gibi adımlar atarak kişisel bilgilerinizi güvende tutmanızı öneririz.",
  PP_disclose: "Herhangi bir bilgiyi dış taraflarla paylaşıyor muyuz?",
  PP_disclose_text:
    "Kişisel Tanımlanabilir Bilgilerinizi (PII) ticari veya pazarlama amaçları için toplamaz, satmaz, ticaretini yapmaz veya dış taraflara aktarmayız, burada belirtilenler hariç. Site aracılığıyla toplanan kişisel bilgiler (ve kişisel olmayan bilgiler), bizim adımıza hizmetler sunan şirketler ve kuruluşlarla paylaşılabilir (örneğin, veri depolama ve Web barındırma hizmetleri gibi veri yönetimi veya diğer destek hizmetleri sağlayan şirketler). Kişisel bilgilerinizi (ve kişisel olmayan bilgilerinizi) üçüncü taraflarla size uygun reklamlar sunmak ve ürünlerimizi pazarlamak amacıyla paylaşabiliriz, ancak kişisel bilgilerinizi satmayız. İşlem Bağlantılı Bilgiler. Bir birleşme, devralma veya varlık satışı gibi bir işlem sonucunda üçüncü bir taraf tarafından devralınırsak veya işimizi durdurursak veya iflas edersek, varlıklarımızın bir kısmı veya tamamı, PII'niz de dahil olmak üzere, işle bağlantılı olarak üçüncü bir tarafa ifşa edilebilir veya aktarılabilir. Bizim ve Başkalarının Korunması İçin Bilgi Açıklanması. Yasa ile uyum sağlamak ve uygulamak için hükümet ve kolluk kuvvetleri yetkilileri veya özel taraflarla işbirliği yapıyoruz. Size ilişkin herhangi bir bilgiyi, tek takdirimize bağlı olarak, şu amaçlarla hükümet veya kolluk kuvvetleri yetkililerine veya özel taraflara ifşa edebiliriz: (i) iddialara yanıt vermek, yasal süreçlere (mahkeme celbi dahil) yanıt vermek; (ii) mülkiyetimizi, haklarımızı ve güvenliğimizi ve üçüncü bir tarafın veya genel olarak kamunun mülkiyet, hak ve güvenliğini korumak; ve (iii) yasa dışı, etik olmayan veya yasal olarak dava edilebilir bir faaliyeti durdurmak.",
  PP_children: "Çocuklar İçin Uygun Değildir",
  PP_children_text:
    "WebApp'imiz ve Hizmetimiz, 16 yaşın altındaki çocuklar için uygun değildir. 16 yaşın altındaki çocuklardan bilerek veya özellikle bilgi toplamıyoruz.",
  PP_changes: "Gizlilik Politikamızdaki Değişiklikler",
  PP_changes_text:
    "Gizlilik Politikamızı değiştirmeye karar verirsek, bu sayfada değişiklikleri yayınlarız. Ayrıca, bir e-posta bildirimi göndermek zorunda olmamakla birlikte, bunu yapabiliriz. Bilgilerinizi nasıl koruduğumuz hakkında bilgi sahibi olmak için bu gizlilik politikasını sık sık gözden geçirmenizi öneririz.",
  PP_contact: "Bize Ulaşın",
  PP_contact_text:
    "Gizlilik Politikamız hakkında sorularınız varsa, lütfen bize şu adresten ulaşın",
};

export const terms_tr_Translation = {
  TC_title: "Şartlar ve Koşullar",
  main_desc:
    "Bu Son Kullanıcı Lisans Sözleşmesi, Sizinle Şirket arasında geçerli bir anlaşmadır. Lütfen bu EULA'yı dikkatlice okuyun. EULA'nın kuralları Size bağlayıcıdır. EULA kurallarına bağlı kalmayı kabul etmiyorsanız, lütfen WebApp'i kullanmayın. WebApp'i kullanmaya devam ederseniz, bu, EULA'yı okuduğunuz, anladığınız ve ona bağlı kalmayı kabul ettiğiniz anlamına gelir.",
  category_1_title: "TANIMLAR VE TERİMLER",
  category_1_desc:
    "EULA'nın kurallarını açıkça anlamak için, EULA'da kullanılan terimlerin anlamlarını öğrenmelisiniz. Bu EULA'da, büyük harfle yazıldığında, aşağıdaki terimler şu anlamlara gelir:",
  c_1_l_1:
    '"Hesap" - Platform hizmetleri kullanıcılarının, Platform Yöneticisi tarafından Platform Kullanım Koşulları veya benzeri bir belge kapsamında sağlanan kişisel hesabı anlamına gelir. Hesaba, bir Platform Kimliği (hesap adı) ve bir şifre ile erişilir.',
  c_1_l_2:
    '"Şirket", "Biz" - İrlanda\'da aşağıdaki adreste kurulan RBCOM anlamına gelir: 1 Raffles Place 40-02 One Raffles Place Tower 1',
  c_1_l_3:
    '"İçerik" - WebApp aracılığıyla Size sunulan, veri, metinler, görüntüler, videolar, sesler, bağlantılar, yazılım ve diğer her türlü bilgi dahil olmak üzere, her türlü içeriği ifade eder.',
  c_1_l_4:
    '"Cihaz" - Sahip olduğunuz veya yasal olarak yalnızca kişisel, ticari olmayan kullanımınız için kontrol ettiğiniz bir akıllı telefon, tablet veya benzeri bir mobil cihaz anlamına gelir.',
  c_1_l_5:
    "\"EULA\" - Sizinle Şirket arasında bağlayıcı bir anlaşma olan bu Son Kullanıcı Lisans Sözleşmesi anlamına gelir. EULA, WebApp'i kullanmanızla ilgili olarak Sizinle Bizim aramızdaki ilişkileri düzenleyen Gizlilik Politikası gibi diğer belgeleri de içerebilir. Bir belge EULA'nın bir parçası ise, bu açıkça belirtilmiştir.",
  c_1_l_6:
    '"Fikri Mülkiyet Hakları" - Herhangi bir patent, ticari marka, telif hakkı ve ticari sır yasaları kapsamındaki haklar ve dünya çapında tanınan diğer fikri mülkiyet veya mülkiyet hakları da dahil olmak üzere, manevi veya benzeri haklar dahil, topluca haklar anlamına gelir.',
  c_1_l_7:
    '"Abonelik" - WebApp\'i bir bütün olarak veya belirli ek İçeriği belirli bir ücret karşılığında kullandığınız süre anlamına gelir.',
  c_1_l_8:
    '"Kullanıcı tarafından oluşturulan İçerik" - Sosyal ağlar (örneğin, Instagram, Facebook) gibi çevrimiçi platformlarda kullanıcılar tarafından yayınlanan veri, metinler, görüntüler, videolar, sesler, bağlantılar dahil her türlü içeriği ifade eder.',
  c_1_l_9: '"Siz" - WebApp\'i kullanan birey anlamına gelir.',
  category_2_title: "EULA'NIN DEĞİŞİKLİKLERİ",
  c_2_l_1:
    "Bu EULA'da zaman zaman değişiklikler yapabiliriz. Web sayfasında bulunan bağlantıdan EULA'nın en son sürümünü düzenli olarak kontrol etmelisiniz.",
  c_2_l_2:
    "Güncellenmiş EULA'nın koşullarına katılmıyorsanız, güncellenmiş EULA'nın yürürlüğe girdiği tarihten itibaren WebApp'i kullanamazsınız. Bu durumda WebApp'i kullanmamalı ve Aboneliklerinizi yenilemeyi iptal etmelisiniz.",
  category_3_title: "CİHAZ",
  c_3_l_1:
    "Sahip olmadığınız bir Cihazda WebApp'i kullanmayı planlıyorsanız, bunun için sahibinin iznine sahip olmalısınız. Bu EULA'ya uyup uymamanızdan bağımsız olarak, WebApp'i kullanma sorumluluğu Size aittir.",
  c_3_l_2:
    "Cihaza sahip değilseniz, WebApp'i yalnızca Hesabınız aracılığıyla kullanabilirsiniz.",
  c_3_l_3:
    "Cihazı başka kişilerle birlikte kullanıyorsanız, Hesabınızdan WebApp'i kullanabilen kimse olmadığından emin olun. Hesabınızda yapılan tüm işlemler, bu işlemleri fiilen kimin yaptığına bakılmaksızın, sizin işlemleriniz olarak kabul edilir. Hesabınızda yapılan tüm işlemlerden tamamen siz sorumlusunuz.",
  c_3_l_4:
    "Cihazı satmaya veya başka bir şekilde elden çıkarmaya karar verirseniz, WebApp'i Cihazdan silmelisiniz.",
  category_4_title: "HUKUKİ YETERLİLİK VE YAŞ",
  c_4_l_1:
    "WebApp, 16 yaşından büyük kullanıcılar için tasarlanmıştır. WebApp'i kullanıyorsanız, en az 16 yaşında olduğunuzu ve bu Anlaşmaya taraf olma konusunda tam yasal kapasiteye sahip olduğunuzu doğruluyorsunuz.",
  c_4_l_2:
    "16 ile 18 yaşları arasındaysanız, ebeveyninizin, vasinizin veya diğer yasal yaşta bir temsilcinin bu EULA'yı Sizin adınıza kontrol etmesi ve kabul etmesi gerekir. WebApp'i kullanıyorsanız, ebeveyninizin, vasinizin veya diğer yasal yaşta bir temsilcinin bu EULA'nın koşullarını kabul ettiğini ve WebApp'i kullanmanıza izin verdiğini doğruluyorsunuz.",
  category_5_title: "FİKRİ MÜLKİYET",
  c_5_l_1:
    "Bu EULA'nın tüm şart ve koşullarına uymayı kabul etmeniz koşuluyla, WebApp'i yalnızca Kendi Cihazınızda erişmek, indirmek ve çalıştırmak için Size münhasır olmayan, kişisel, sınırlı, iptal edilebilir ve devredilemez bir lisans veriyoruz. Lütfen WebApp'in Size satılmadığını, lisanslandığını unutmayın.",
  c_5_l_2:
    "WebApp ile ilgili olarak Fikri Mülkiyet Haklarının tek sahibi Şirket veya Şirketin lisans verenidir. Şirket, WebApp üzerinde tüm hakları, mülkiyeti ve menfaati elinde tutar ve bu EULA'da Size açıkça verilmeyen tüm hakları saklı tutar.",
  c_5_l_3:
    "WebApp'i satamaz, yeniden satamaz, dağıtamaz, yeniden dağıtamaz, kiralayamaz, lisans veremez, devredemez, kopyalayamaz veya başka bir şekilde WebApp'e ilişkin herhangi bir hakkı üçüncü bir tarafa veremez veya devredemezsiniz.",
  c_5_l_4:
    "WebApp'i, aynı anda birden fazla kullanıcı tarafından indirilebilecek veya kullanılabilecek herhangi bir ağ üzerinden dağıtamaz veya kullanılabilir hale getiremezsiniz, bu durum Bizim tarafımızdan açıkça yetkilendirilmedikçe geçerlidir.",
  c_5_l_5:
    "WebApp'in düzgün çalışmasına müdahale etmek için herhangi bir otomatik veya manuel cihaz veya işlem kullanamazsınız. Ancak, WebApp'i Cihazınızdan silmek için herhangi bir cihaz veya işlem kullanabilirsiniz.",
  c_5_l_6:
    "WebApp'i veya herhangi bir parçasını derleme, tersine mühendislik yapma veya türev çalışmalar oluşturma işlemlerini gerçekleştiremezsiniz. WebApp'e yapılan tüm değişiklikler veya geliştirmeler Şirketin mülkiyetinde kalır.",
  c_5_l_7:
    "WebApp'i hukuka aykırı bir şekilde, hukuka aykırı bir amaçla veya bu EULA ile tutarsız bir şekilde kullanamazsınız veya WebApp'e kötü niyetli kodlar, virüsler veya zararlı veriler ekleyerek hileli veya kötü niyetli davranışlarda bulunamazsınız.",
  category_6_title: "ABONELİK VE DENEME SÜRESİ",
  c_6_l_1:
    "Bu WebApp bir bütün olarak veya belirli bir kısmı (ek İçerik) ücretli bir abonelik temelinde sağlanır. Ücret, Abonelik süresinin uzunluğuna bağlı olarak değişebilir.",
  c_6_l_2:
    "Abonelik süresi sonunda Abonelik aynı süre için seçilen paketin maliyeti üzerinden otomatik olarak yenilenecektir. Hesabınız, mevcut Aboneliğin sona ermesinden 24 saat önce yenilenmiş Abonelik için ücretlendirilecektir.",
  c_6_l_3:
    "Abonelik yenilemeyi, Abonelik sona ermeden en az 24 saat önce iptal edebilirsiniz: bu durumda, WebApp bir bütün olarak veya ek İçeriği Abonelik süresinin sonuna kadar kullanılabilir olacaktır. Abonelik yenilemeyi iptal etmek için Hesabınızın abonelik ayarlarını değiştirin. Abonelik süresinin sonunda WebApp bir bütün olarak veya ek İçeriği yalnızca yeni bir Abonelik satın alınırsa kullanılabilir olacaktır.",
  c_6_l_4:
    "Aboneliğiniz otomatik olarak durmaz. Yeni Abonelik maliyetiyle ücretlendirilmemek için otomatik yenilemeyi iptal etmelisiniz.",
  c_6_l_5:
    "WebApp, 7 günlük ücretsiz bir deneme süresi sağlar. Bu süre boyunca WebApp'i veya ek İçeriğini ücretsiz olarak kullanabilirsiniz. Lütfen deneme süresinin sonunda, en kısa süre için geçerli olan bir ücretli Aboneliğe otomatik olarak dönüşeceğini ve ücretsiz deneme süresinin sonundan en az 24 saat önce Abonelik iptal edilmezse Hesabınızın böyle bir Abonelik ücretiyle ücretlendirileceğini unutmayın.",
  category_7_title: "ÜCRETLER VE SATIN ALMA KOŞULLARI",
  c_7_l_1: "Tüm Aboneliklerin maliyetleri web sayfasında mevcuttur.",
  c_7_l_2:
    "Abonelik satın aldığınızda, Platform Yöneticisi Hesabınızı ücretlendirir ve parayı Bizim adımıza toplar. Ödeme, Platform Yöneticisi tarafından uygulanan bir ödeme işleme sistemi tarafından işlenir.",
  c_7_l_3:
    "Lütfen ödemeyi işlemediğimizi veya ödeme bilgileriniz, banka kartı verileriniz, adınız veya diğer ilgili bilgileriniz gibi herhangi bir bilginize erişimimizin olmadığını unutmayın.",
  c_7_l_4:
    "Ödeme işleme ile ilgili herhangi bir sorunuz varsa, lütfen Platform Yöneticisinin kullanıcı destek hizmetine sorununuzu iletin.",
  c_7_l_5:
    "Hesabınız, mevcut Aboneliğin sona ermesinden 24 saat önce yeni Abonelik maliyetiyle ücretlendirilir. Aboneliğin son 24 saati içinde otomatik yenilemeyi iptal ederseniz, bu iptal yeni Aboneliğin sonunda yürürlüğe girer.",
  c_7_l_6:
    "Lütfen son ödemenizin iadesini yaptığımızı lütfen unutmayın. Abonelik ücreti Hesabınızdan tahsil edildikten hemen sonra dijital içerik kullanımınıza sunulur.",
  category_8_title: "KULLANICI TARAFINDAN OLUŞTURULAN İÇERİK",
  c_8_l_1:
    "Diğer Instagram kullanıcılarının gönderilerine erişebileceğiniz için, potansiyel olarak itiraz edilebilir Kullanıcı tarafından oluşturulan İçeriğe maruz kalabilirsiniz. Lütfen Şirketin herhangi bir sosyal ağda yayınlanan Kullanıcı tarafından oluşturulan İçeriği kontrol etmediğini veya önceden kontrol etmediğini unutmayın.",
  c_8_l_2:
    "Şirketin Kullanıcı tarafından oluşturulan İçeriğe yönelik herhangi bir türde değişiklik veya sansürlemesi, insan hakları veya telif hakkı ihlali olarak kabul edilebilir ve Size yanlış bilgi verebilir.",
  c_8_l_3:
    "İtiraz edilebilir herhangi bir içeriğe maruz kalırsanız, ilgili sosyal ağın işlevselliğini kullanarak itiraz edilebilir Kullanıcı tarafından oluşturulan İçeriği takip edebilir ve silebilirsiniz.",
  c_8_l_4:
    "Şirket, Kullanıcı tarafından oluşturulan herhangi bir İçerikten hiçbir şekilde sorumlu tutulamaz.",
  category_9_title: "FERAGATNAME",
  c_9_l_1:
    "Geçerli yasanın izin verdiği en geniş ölçüde, Biz ve iştiraklerimiz, memurlarımız, çalışanlarımız, ajanlarımız, ortaklarımız ve lisans verenlerimiz, WebApp'imizdeki veya hizmetler tarafından oluşturulan bilgilere ilişkin olarak herhangi bir türde garanti vermez ve bu bilgilerin doğruluğu, yeterliliği veya eksiksizliği konusunda açık veya zımni herhangi bir garanti sağlamaz.",
  c_9_l_2:
    "WebApp'imizdeki İçerik, herhangi bir ticarete elverişlilik veya belirli bir amaca veya kullanıma uygunluk garantisi olmaksızın Size 'olduğu gibi' sunulmaktadır. WebApp'i kullanarak herhangi bir beklenen sonuca ulaşacağınızı garanti etmiyoruz.",
  c_9_l_3: "Şu konularda garanti vermiyoruz:",
  c_9_l_3a: "WebApp ihtiyaçlarınızı karşılayacak;",
  c_9_l_3b: "WebApp hatasız, güvenli veya kesintisiz olacak;",
  c_9_l_3c: "WebApp işlevselliği hataları düzeltilecektir;",
  c_9_l_3d: "WebApp'in kalitesi beklentilerinizi karşılayacaktır.",
  c_9_l_4:
    "Herhangi bir hata veya eksiklikten sorumlu değiliz. Size veya başka bir kişiye WebApp'in kullanımı nedeniyle doğrudan veya dolaylı, özel veya sonuçsal herhangi bir zarar için sorumlu değiliz, böyle bir zararın olasılığı konusunda bilgilendirilmiş olsanız bile. Bizden veya WebApp'imizden elde edilen sözlü veya yazılı hiçbir tavsiye veya bilgi, EULA'da açıkça belirtilmeyen herhangi bir garanti yaratmaz. Herhangi bir davanın nedeni ne olursa olsun, Bizim veya iştiraklerimizden herhangi birinin sorumluluğu, Sizin WebApp'e erişim için ödediğiniz ücretin ötesine geçemez. Ayrıca, Bizim kontrolümüz dışındaki durumlardan kaynaklanan gecikmeler veya başarısızlıklar için hiçbir sorumluluğumuz veya yükümlülüğümüz yoktur.",
  category_10_title: "HAKLARIN DEVRİ",
  c_10_l_1:
    "Bu EULA kapsamındaki hak ve yükümlülüklerimizi herhangi bir başka organizasyona veya bireye Sizin onayınız olmadan devredebiliriz. Bu durumda Size yazılı olarak bildireceğiz ve devrin EULA kapsamındaki haklarınızı etkilemeyeceğinden emin olacağız.",
  c_10_l_2:
    "EULA kapsamındaki haklarınızı veya yükümlülüklerinizi başka bir kişiye devretmek isterseniz, bunu yazılı olarak kabul etmemiz gerekir.",
  category_11_title: "HAKLARIN SONLANDIRILMASI",
  c_11_l_1:
    "Bu EULA'nın kurallarını ciddi bir şekilde ihlal ettiğinizde WebApp'i kullanma hakkınızı sona erdirebiliriz. Yaptığınız şeyi düzeltebiliyorsanız, bunu yapmanız için size makul bir fırsat vereceğiz.",
  c_11_l_2: "WebApp'i kullanma hakkınızı sona erdirirsek:",
  c_11_l_2a:
    "Bu EULA tarafından yetkilendirilen tüm faaliyetleri durdurmalısınız, WebApp'i kullanmanız da dahil.",
  c_11_l_2b:
    "WebApp'i tüm Cihazlardan silmeli veya kaldırmalı ve sahip olduğunuz tüm WebApp kopyalarını hemen imha etmeli ve bunu yaptığınızı bize onaylamalısınız.",
  category_12_title: "ÇEŞİTLİ",
  c_12_l_1:
    "Bu EULA'nın her bir paragrafı ayrı olarak işler. Herhangi bir mahkeme veya ilgili otorite herhangi birinin yasadışı olduğuna karar verirse, kalan paragraflar tam güç ve etkide kalacaktır.",
  c_12_l_2:
    "Bu EULA'yı uygulamayı geciktirsek bile, bunu daha sonra uygulayabiliriz. Bu EULA'nın şartlarına göre yapmanız gereken bir şeyi hemen yapmanızı talep etmezsek veya EULA'yı ihlal ettiğinizde Size karşı adım atmayı geciktirirsek, bu, o şeyleri yapmanız gerekmediği anlamına gelmez ve Size karşı daha sonra adım atmamızı engellemez.",
  c_12_l_3:
    "Bu EULA, Singapur yasalarına göre, yasanın çelişen hükümlerine bakılmaksızın, yönetilecek ve yorumlanacaktır.",
  c_12_l_4:
    "Sizinle Şirket arasında herhangi bir anlaşmazlık, tartışma veya farklılık oluşursa, bu dostane bir şekilde müzakere yoluyla çözülecektir. Bu tür bir anlaşmazlık, tartışma veya farklılık, 30 günlük bir süre içinde dostane bir şekilde çözülemezse, İrlanda Tahkim Derneği tarafından Uluslararası Hızlandırılmış Prosedürlerine göre çözülecektir.",
  last_text1: "Hakem sayısı bir olacaktır.",
  last_text2: "Tahkim yeri Singapur olacaktır.",
  last_text3:
    "Tahkim İngilizce olarak yapılacak ve karar İngilizce olarak verilecektir.",
  last_text4:
    "Her iki taraf (yani, Siz ve Şirket) kendi masraflarını karşılayacaktır, ancak taraflar tahkim mahkemesinin masraflarını eşit olarak paylaşacaktır.",
  support_message:
    "Destek. Web Uygulaması hakkında daha fazla bilgi almak veya kullanırken herhangi bir sorun yaşarsanız, lütfen bizimle iletişime geçin.",
};
