import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tile from "../../../components/Tile";
import { Part_Three_Three } from "../../../constants/pageRouteName";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { pagePreloadImages } from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const Items = [
  { text: t("Fewer than 12"), value: "Fewer than 12", id: 1 },
  { text: t("13-20"), value: "13-20", id: 2 },
  { text: t("More than 20"), value: "More than 20", id: 3 },
  { text: t("I don't know"), value: "I don't know", id: 4 },
];

const PushUp = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoNextPage = (id) => {
    dispatch(
      setDetailsData({
        pushups: { text: Items[id - 1].value, id: Items[id - 1].id },
      })
    );
    nextPageRoute(
      navigate,
      Part_Three_Three,
      dispatch,
      pagePreloadImages.pushUps
    );
  };

  return (
    <div className="flex-grow-1 pt-2 pb-3 main-padding d-flex flex-column justify-content-start">
      <div className="w-100 d-flex justify-content-center">
        <h1
          className="motivation-header mt-0 text-inter fw-bold text-center main-margin-b"
          style={{ maxWidth: "280px" }}
        >
          {t("PU_header")}
        </h1>
      </div>
      <div className="d-flex flex-column pt-2" style={{ gap: "10px" }}>
        {Items.map((item) => (
          <Tile
            {...item}
            key={item.id}
            handleClick={gotoNextPage}
            selected={selected}
          />
        ))}
      </div>
    </div>
  );
};

export default PushUp;
