export const BackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.008"
      height="27.008"
      viewBox="0 0 27.008 27.008"
    >
      <g id="Arrow_Left" transform="translate(-35.992 -191.746)">
        <path
          id="Path_4288"
          data-name="Path 4288"
          d="M15,1.5a13.507,13.507,0,0,1,5.257,25.946A13.507,13.507,0,0,1,9.747,2.562,13.42,13.42,0,0,1,15,1.5Zm0,24.553A11.049,11.049,0,1,0,3.955,15,11.061,11.061,0,0,0,15,26.053Z"
          transform="translate(34.492 190.246)"
          fill="#0045ff"
        />
        <path
          id="Path_4289"
          data-name="Path 4289"
          d="M16.638,22.776a1.224,1.224,0,0,1-.868-.36L10.86,17.506a1.228,1.228,0,0,1,0-1.736L15.77,10.86A1.228,1.228,0,0,1,17.506,12.6l-4.042,4.042,4.042,4.042a1.228,1.228,0,0,1-.868,2.1Z"
          transform="translate(32.857 188.611)"
          fill="#0045ff"
        />
        <path
          id="Path_4290"
          data-name="Path 4290"
          d="M21.549,18.955H11.728a1.228,1.228,0,1,1,0-2.455h9.821a1.228,1.228,0,1,1,0,2.455Z"
          transform="translate(32.857 187.521)"
          fill="#0045ff"
        />
      </g>
    </svg>
  );
};

export const RightArrow_Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.435"
    height="20"
    viewBox="0 0 11.435 20"
  >
    <path
      id="Icon_ion-ios-arrow-forward"
      data-name="Icon ion-ios-arrow-forward"
      d="M19.235,16.193,11.666,8.63a1.423,1.423,0,0,1,0-2.019,1.441,1.441,0,0,1,2.025,0l8.575,8.569a1.427,1.427,0,0,1,.042,1.971L13.7,25.779a1.429,1.429,0,0,1-2.025-2.019Z"
      transform="translate(-11.246 -6.196)"
      fill="white"
    />
  </svg>
);

export const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 20.678 23.632"
  >
    <path
      id="Icon_fa-solid-lock"
      data-name="Icon fa-solid-lock"
      d="M6.646,6.646V8.862h7.385V6.646a3.692,3.692,0,1,0-7.385,0ZM3.692,8.862V6.646a6.646,6.646,0,1,1,13.293,0V8.862h.738a2.957,2.957,0,0,1,2.954,2.954v8.862a2.957,2.957,0,0,1-2.954,2.954H2.954A2.957,2.957,0,0,1,0,20.678V11.816A2.957,2.957,0,0,1,2.954,8.862Z"
      fill="#6c6e79"
    />
  </svg>
);

export const CheckArrow = () => (
  <svg viewBox="0 0 24 24" height={"24"} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="#009B24"
        strokeWidth="1.5"
      ></circle>{" "}
      <path
        d="M8.5 12.5L10.5 14.5L15.5 9.5"
        stroke="#009B24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{" "}
    </g>
  </svg>
);

export const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.773"
    height="14.219"
    viewBox="0 0 17.773 14.219"
  >
    <path
      id="Icon_material-email"
      data-name="Icon material-email"
      d="M19,6H4.777A1.775,1.775,0,0,0,3.009,7.777L3,18.441a1.783,1.783,0,0,0,1.777,1.777H19a1.783,1.783,0,0,0,1.777-1.777V7.777A1.783,1.783,0,0,0,19,6Zm0,3.555L11.887,14,4.777,9.555V7.777l7.109,4.443L19,7.777Z"
      transform="translate(-3 -6)"
      fill="#6c6e79"
    />
  </svg>
);
