import i18next from "i18next";

export const Landing_Page = `/${i18next.language}`;
export const Login_Page = `/${i18next.language}/login`;
export const Register_Page = `/${i18next.language}/get-personal-plan`;
export const Payment_Page = `/${i18next.language}/payment`;
export const Otp_Page = `/${i18next.language}/otp`;
export const Privacy_Policy = `/${i18next.language}/privacy-policy`;
export const Terms_Conditions = `/${i18next.language}/terms-condition`;

export const Veg_Or_Non = `/${i18next.language}/meal-type`;

export const Course_Page = `/${i18next.language}/course`;
export const Course_Details_Page = `/${i18next.language}/course/:id`;
export const Meal_Plan_Page = `/${i18next.language}/meal-plan`;
export const Meal_Details_Page = `/${i18next.language}/meal-plan/details`;
export const Plan_Settings_Page = `/${i18next.language}/plan-settings`;
export const Plan_Goal_Page = `/${i18next.language}/plan-settings/goal`;
export const Plan_Zones_Page = `/${i18next.language}/plan-settings/target-zones`;
export const Plan_Location_Page = `/${i18next.language}/plan-settings/workout-location`;
export const Plan_Weight_Page = `/${i18next.language}/plan-settings/weight`;
export const Plan_Target_Weight_Page = `/${i18next.language}/plan-settings/target-weight`;
export const Manage_Subscription = `/${i18next.language}/manage-subscription`;

export const Part_One_One = `/${i18next.language}`;
export const Part_One_Two = `/${i18next.language}/users`;
export const Actual_Body = `/${i18next.language}/choose-body-type`;
export const Part_Two_One = `/${i18next.language}/goal`;
export const Part_Two_Two = `/${i18next.language}/body-type`;
export const Part_Two_Three = `/${i18next.language}/motivation`;
export const Part_Two_Four = `/${i18next.language}/target-zones`;
export const Part_Two_Five = `/${i18next.language}/perfect-weight`;
export const Part_Two_Six = `/${i18next.language}/interest`;

export const Part_Three_One = `/${i18next.language}/fitness-level`;
export const Part_Three_Two = `/${i18next.language}/push-up`;
export const Part_Three_Three = `/${i18next.language}/life-style`;
export const Part_Three_Four = `/${i18next.language}/walk`;
export const Part_Three_Five = `/${i18next.language}/meal`;
export const Part_Three_Six = `/${i18next.language}/sleep`;
export const Part_Three_Seven = `/${i18next.language}/water`;
export const Part_Three_Eight = `/${i18next.language}/workout-location`;
export const Part_Three_Nine = `/${i18next.language}/height`;
export const Part_Three_Ten = `/${i18next.language}/current-weight`;
export const Part_Three_Eleven = `/${i18next.language}/target-weight`;
export const Part_Three_Twelve = `/${i18next.language}/age`;
export const Part_Three_Thirteen = `/${i18next.language}/fitness-summary`;

export const Part_Four_One = `/${i18next.language}/events`;
export const Part_Four_Two = `/${i18next.language}/event-date`;
export const Part_Four_Three = `/${i18next.language}/plan`;
export const Part_Four_Four = `/${i18next.language}/personal-plan`;
