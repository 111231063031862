import ActionSheet from "actionsheet-react";
import React from "react";

import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Logout_Icon,
  Plan_Icon,
  Policy_Icon,
  SubSciption_Icon,
  Support_Icon,
} from "../assets/svgs/action-sheet";
import {
  Manage_Subscription,
  Plan_Settings_Page,
  Privacy_Policy,
  Terms_Conditions,
} from "../constants/pageRouteName";
import { supportHandler } from "../modules/supportHandler";
import { logoutState } from "../redux/slices/AuthSlice";

const ActionSheetCompo = ({ sheetRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actionData = [
    {
      id: 1,
      title: t("Edit Your Plan"),
      image: Plan_Icon,
      handleClick: () => {
        navigate(Plan_Settings_Page);
      },
    },
    {
      id: 2,
      title: t("Support"),
      image: Support_Icon,
      handleClick: () => {
        supportHandler();
      },
    },
    {
      id: 3,
      title: t("Terms of Service"),
      image: Policy_Icon,
      handleClick: () => {
        window.scrollTo(0, 0);
        navigate(Terms_Conditions);
      },
    },
    {
      id: 4,
      title: t("Privacy Policy"),
      image: Policy_Icon,
      handleClick: () => {
        window.scrollTo(0, 0);
        navigate(Privacy_Policy);
      },
    },
    {
      id: 5,
      title: t("Manage Subscription"),
      image: SubSciption_Icon,
      handleClick: () => {
        navigate(Manage_Subscription);
      },
    },
    {
      id: 6,
      title: t("Logout"),
      image: Logout_Icon,
      handleClick: () => {
        dispatch(logoutState());
      },
    },
  ];

  return (
    <ActionSheet
      ref={sheetRef}
      className="actionSheet"
      sheetStyle={{
        backgroundColor: "#000000",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        margin: "0 auto",
        right: 0,
      }}
      bgTransition="opacity 0.3s ease-in-out"
      sheetTransition="transform 0.3s ease-in-out"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "15px 15px",
        }}
      >
        <ListMenu arrayData={actionData} />
      </div>
    </ActionSheet>
  );
};

const ListMenu = ({ arrayData }) => {
  return (
    <>
      {arrayData.map((d) => (
        <button
          className="d-flex flex-row mt-2 mb-2 align-items-center button w-100"
          key={d?.id}
          onClick={d?.handleClick}
        >
          <div style={{ height: "34px", width: "34px" }} className="mx-3">
            {d.image()}
          </div>
          <p
            className="m-0 p-0 text-white text-capitalize"
            style={{ fontSize: "14px" }}
          >
            {d?.title}
          </p>
        </button>
      ))}
    </>
  );
};

export default ActionSheetCompo;
