import React from "react";
import PageView from "../layouts/PageView";
import CourseLayout from "../layouts/CourseLayout";

import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  Course_Page,
  Landing_Page,
  Login_Page,
  Manage_Subscription,
  Otp_Page,
  Plan_Goal_Page,
  Plan_Location_Page,
  Plan_Target_Weight_Page,
  Plan_Weight_Page,
  Plan_Zones_Page,
  Veg_Or_Non,
} from "../constants/pageRouteName";

const ProtectedRoute = ({ isProtected, children, ...props }) => {
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const location = useLocation();

  if (isProtected && !isLoggedIn) {
    return <Navigate to={Landing_Page} replace />;
  }

  if (!isProtected && isLoggedIn) {
    return <Navigate to={Course_Page} replace />;
  }

  if (
    isLoggedIn &&
    !location.state?.data &&
    [
      Plan_Goal_Page,
      Plan_Zones_Page,
      Plan_Location_Page,
      Plan_Weight_Page,
      Veg_Or_Non,
      Plan_Target_Weight_Page,
    ].includes(location.pathname)
  ) {
    return <Navigate to={Course_Page} replace />;
  }

  if (isLoggedIn) {
    if (
      [
        Plan_Goal_Page,
        Plan_Zones_Page,
        Plan_Location_Page,
        Plan_Weight_Page,
        Plan_Target_Weight_Page,
        Manage_Subscription,
        Veg_Or_Non,
      ].includes(location.pathname)
    ) {
      return (
        <PageView
          {...props}
          classNames={
            location.pathname.includes(Manage_Subscription)
              ? ""
              : "custom-plan-screen-padding-y"
          }
          showMenuBtn={
            location.pathname.includes(Manage_Subscription) ? true : false
          }
        >
          {children}
        </PageView>
      );
    }
    return <CourseLayout>{children}</CourseLayout>;
  }

  return <PageView {...props}>{children}</PageView>;
};

export default ProtectedRoute;
