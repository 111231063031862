export const esTranslation = {
  START_NOW: "EMPEZAR AHORA",
  "Privacy Policy": "Política de Privacidad",
  "Terms of Use": "Términos de Uso",
  "Refund Policy": "Política de Reembolso",
  "Terms and Conditions": "Términos y Condiciones",
  LOGIN: "INICIAR SESIÓN",
  "1 MILLION": "1 MILLÓN",
  CONTINUE: "CONTINUAR",
  SAVE: "GUARDAR",
  ft: "pies",
  lbs: "libras",
  kg: "kg",
  //footer
  copyright: "Derechos de Autor ® GainMuscle, 2024.",
  rights: "Todos los derechos reservados.",
  //landing page
  LP_header: "DESARROLLA MÚSCULOS EN 8 SEMANAS",
  LP_sub_header: "OBTÉN UN PLAN DE EJERCICIO PERSONALIZADO",
  LP_text_1: "Al presionar el botón 'Empezar Ahora', confirmas y garantizas",
  LP_text_2: "que has leído, comprendido y aceptado nuestra",
  //users page
  UP_header: "LOS USUARIOS NOS HAN ELEGIDO",
  //actual body page
  AB_header: "ELIGE TU <tag/>TIPO DE CUERPO",
  Skinny: "Delgado",
  Average: "Promedio",
  //goal page
  // GP_header: "WHAT'S YOUR GOAL?",
  GP_header: "ELIGE TU OBJETIVO <tag/> TIPO DE CUERPO",
  Cut: "Definido",
  Bulk: "Aumento de masa",
  "Extra Bulk": "Aumento de masa extra",
  "Get Shredded": "Definido",
  "Muscle Gain": "Ganancia de músculo",
  "Weight Loss": "Pérdida de peso",
  "GET SHREDDED": "Definido",
  "MUSCLE GAIN": "Ganancia de músculo",
  "WEIGHT LOSS": "Pérdida de peso",
  //body type page
  BT_header: "EL CUERPO QUE DESEAS",
  Athlete: "Atleta",
  Hero: "Héroe",
  Bodybuilder: "Culturista",
  //motivation page
  MT_header: "¿QUÉ TE MOTIVA A HACER EJERCICIO?",
  "Improving Health": "Mejorar la Salud",
  "Boosting Immune System": "Fortalecer el Sistema Inmunológico",
  "Looking Better": "Lucir Mejor",
  "Building Strength": "Construir Fuerza",
  "Boosting Libido": "Aumentar la Libido",
  //target-zones page
  TZ_header: "ELIGE TUS ZONAS OBJETIVO",
  ARMS: "BRAZOS",
  BELLY: "VIENTRE",
  PECS: "PECHOS",
  LEGS: "PIERNAS",
  //perfect-weight page
  PW_header: "¿CUÁNDO ESTUVISTE EN TU PESO PERFECTO?",
  "0-6 Months ago": "Hace 0-6 meses",
  "7-12 Months ago": "Hace 7-12 meses",
  "1-3 Years ago": "Hace 1-3 años",
  "More than 3 years ago": "Hace más de 3 años",
  Never: "Nunca",
  //Interest page
  IT_header: "¿EN QUÉ ESTÁS INTERESADO?",
  "Weight loss": "Pérdida de peso",
  "Body sculpting": "Esculpir el cuerpo",
  "Hearth Health": "Salud del corazón",
  Mobility: "Movilidad",
  Energy: "Energía",
  Recovery: "Recuperación",
  //fitness level page
  FL_header: "¿CUÁL ES TU NIVEL DE FITNESS?",
  Beginner: "Principiante",
  "I'm new to fitness": "Soy nuevo en el fitness",
  Intermediate: "Intermedio",
  "I workout from time to time": "Hago ejercicio de vez en cuando",
  Advanced: "Avanzado",
  "I exercise regularly": "Hago ejercicio regularmente", //push-up page
  //push-up page
  PU_header: "¿CUÁNTAS FLEXIONES PUEDES HACER?",
  "Fewer than 12": "Menos de 12",
  "13-20": "13-20",
  "More than 20": "Más de 20",
  "I don't know": "No lo sé",
  //life-style page
  LS_title_1: "¿TIENES",
  LS_title_2: "UN ESTILO DE VIDA SEDENTARIO?",
  Yes: "Sí",
  No: "No",
  //walk page
  WL_title_1: "¿CUÁNTO",
  WL_title_2: "CAMINAS DIARIAMENTE?",
  "Less than 1 hour": "Menos de 1 hora",
  "1-2 hours": "1-2 horas",
  "More than 2 hours": "Más de 2 horas",
  //Meal page
  ML_title_1: "¿CÓMO TE SIENTES",
  ML_title_2: "ENTRE COMIDAS?",
  ML_Subtitle:
    "¿Cómo es tu energía en diferentes momentos del día? Selecciona las opciones más adecuadas.",
  "I got sleepy when I'm hungry": "Me da sueño cuando tengo hambre",
  "I am tired after I eat": "Estoy cansado después de comer",
  "I am always energized": "Siempre estoy lleno de energía",
  //Sleep page
  SL_title_1: "¿CUÁNTO SUEÑO",
  SL_title_2: "DUERMES?",
  "Fewer than 5 hours": "Menos de 5 horas",
  "Between 5 and 6 hours": "Entre 5 y 6 horas",
  "Between 7 and 8 hours": "Entre 7 y 8 horas",
  "Over 8 hours": "Más de 8 horas",
  //Water page
  WT_title: "¿CÓMO ES TU CONSUMO DE AGUA?",
  WT_Subtitle_1: "¿Cuánta agua bebes durante el día?",
  WT_Subtitle_2: "Selecciona la respuesta más adecuada.",
  "I only drink tea and coffee": "Solo bebo té y café",
  "Fewer than 2 glasses of water": "Menos de 2 vasos de agua",
  "2-6 glasses of water": "2-6 vasos de agua",
  "7-10 glasses of water": "7-10 vasos de agua",
  "More than 10 glasses of water": "Más de 10 vasos de agua",
  //workout location page
  WL_title: "ELIGE TU UBICACIÓN DE EJERCICIO",
  Home: "Hogar",
  Gym: "Gimnasio",
  HOME: "Hogar",
  GYM: "Gimnasio",
  //height page
  HT_title: "¿CUÁNTO MIDES?",
  HT_text_1: "Calculando tu índice de masa corporal",
  HT_text_2:
    "El IMC se utiliza ampliamente como un factor de riesgo para el desarrollo o la prevalencia de",
  HT_text_3: "varios problemas de salud.",
  HT_error_ft: "Ingresa un valor de 4ft a 7ft 11in",
  HT_error_cm: "Ingresa un valor de 122cm a 242cm",
  //current weight page
  CW_title_1: "¿CUÁL ES TU",
  CW_title_2: "PESO ACTUAL?",
  CW_text_1: "Tu IMC es {{bmi}}, lo cual se considera {{bmiLevel}}.",
  CW_text_2:
    "Deberías intentar enfocarte en tu peso. Vamos a personalizar un programa para ti.",
  Underweight: "Bajo peso",
  Normal: "Normal",
  Obese: "Obeso",
  Overweight: "Sobrepeso",
  //target weight page
  TW_title_1: "¿CUÁL ES TU",
  TW_title_2: "PESO OBJETIVO?",
  //age page
  AGE_LP_text:
    "Al continuar, confirmas y garantizas que has leído, entendido y aceptado nuestra",
  AG_title: "CONSTRUYE TU CUERPO PERFECTO",
  AG_text_1: "Pedimos tu edad para crear",
  AG_text_2: "tu plan personal",
  AG_text_3:
    "Las personas mayores tienden a tener más grasa corporal que las personas más jóvenes con el mismo IMC.",
  age_text: "Edad {{range}}",
  //fitness summary page
  FS_title: "RESUMEN DE TU NIVEL DE FITNESS",
  FS_bmi_text: "Índice de Masa Corporal (IMC)",
  FS_normal_bmi_text: "IMC NORMAL - {{bmi}}",
  FS_user_bmi_text: "Tú: {{bmi}}",
  FS_lifeStyle_text: "Estilo de vida",
  FS_activity_text: "Actividad",
  FS_exercise_text: "Ejercicio",
  FS_SEDENTARY: "SEDENTARIO",
  FS_ACTIVE: "ACTIVO",
  FS_LOW: "BAJO",
  FS_HIGH: "ALTO",
  "NO REGULAR WORKOUTS": "SIN EJERCICIOS REGULARES",
  "REGULAR WORKOUTS": "EJERCICIOS REGULARES",
  FS_text_1: "Riesgos por un IMC poco saludable",
  FS_text_2:
    "Presión arterial alta, enfermedades cardíacas, accidentes cerebrovasculares, diabetes tipo 2, algunos tipos de cáncer, osteoartritis, dolor de espalda, mortalidad total.",
  //event page
  EV_title: "¿HAY UNA OCASIÓN ESPECIAL PARA LA QUE QUIERES GANAR MÚSCULO?",
  Vacation: "Vacaciones",
  Competition: "Competencia",
  "Important Date": "Fecha Importante",
  "Extreme Sports": "Deportes Extremos",
  Birthday: "Cumpleaños",
  "Beach Trip": "Viaje a la Playa",
  Reunion: "Reunión",
  No: "No",
  //event-date page
  ED_title: "¿CUÁNDO ES TU EVENTO?",
  ED_subtitle:
    "Una vez que sepamos esto, podremos crear un plan personalizado para ayudarte a ponerte en forma. Tus datos no se compartirán con terceros.",
  "Choose date": "Elegir fecha",
  "SKIP THIS QUESTION": "SALTAR ESTA PREGUNTA",
  //weight plan page
  WP_title_1: "EL ÚNICO PLAN QUE NECESITARÁS",
  WP_title_2: "PARA {{text}} PESO",
  WP_text_1: "Según la información que nos has proporcionado,",
  WP_text_2: "alcanzarás tu peso objetivo de",
  WP_text_3: "Prepárate para asombrar a todos",
  WP_text_4: "en tu {{desiredWeight}}!",
  WP_weight_status: "{{target}} PARA {{date}}",
  GAIN: "AUMENTAR",
  LOSE: "PERDER",
  //personal-plan page
  creating_plan: "Creando tu plan personalizado...",
  //register page
  RG_header: "Tu Plan Personal",
  RG_subheader:
    "Ingresa tu correo electrónico para obtener tu plan personal <tag>GainMuscle</tag>!",
  "Your Email": "Tu Correo Electrónico",
  email_error: "Ingresa una dirección de correo electrónico válida",
  "Well done!": "¡Bien hecho!",
  policy_text:
    "Respetamos tu privacidad. Tu correo electrónico puede hacer que la experiencia publicitaria sea más relevante. <tag> Política de Privacidad </tag>.",
  //login page
  WELCOME: "BIENVENIDO",
  "Don't have an account?": "¿No tienes una cuenta?",
  "Start the survey": "Iniciar la encuesta",
  Email: "Correo Electrónico",
  "please enter valid email": "por favor ingresa un correo electrónico válido",
  Login: "Iniciar Sesión",
  "Need help?": "¿Necesitas ayuda?",
  "Contact us": "Contáctanos",
  //otp page
  OTP_header: "Ingresa el OTP",
  OTP_subheader:
    "Se ha enviado una contraseña de un solo uso a tu <tag /> Correo Electrónico: {{email}}",
  Submit: "Enviar",
  "Resend OTP": "Reenviar OTP",
  //payment page
  "3 time per week": "3 veces por semana",
  "30 minutes": "30 minutos",
  "Target weight": "Peso objetivo",
  countdown_text:
    "¡Tu plan personalizado ha sido reservado por los próximos 15 minutos!",
  "get my plan": "OBTENER MI PLAN",
  "TRY NOW": "PRUEBA AHORA",
  Now: "Ahora",
  "After the program": "Después del programa",
  Goal: "Objetivo",
  "Body fat": "Grasa corporal",
  "Muscle Mass": "Masa muscular",
  "Daily calorie intake": "Ingesta diaria de calorías",
  recommended: "recomendado",
  "Daily water intake": "Ingesta diaria de agua",
  "Personalized plan for You is ready!":
    "¡Plan personalizado<tag/> para ti está listo!",
  "Workout Duration": "Duración del entrenamiento",
  "Fitness Level": "Nivel de condición física",
  "Place of Workout": "Lugar de entrenamiento",
  "Workout Frequency": "Frecuencia del entrenamiento",
  "Your personal workout plan is ready!":
    "¡Tu plan de entrenamiento personal está listo!",
  "Current BMI": "IMC Actual",
  BMI_text:
    "El índice de masa corporal (IMC) es una medida que utiliza tu altura y peso para determinar si tu peso es saludable.",
  Level: "Nivel",
  "Personal summary based on your answers":
    "Resumen personal<tag/> basado en tus respuestas",
  noticable_text:
    "El 89% de los usuarios encuentran los resultados de usar el plan de GainMuscle claramente notables.",
  image_warning:
    "*La imagen no está destinada a representar al usuario. Los resultados varían por persona y no están garantizados.",
  ready_text: "¡TU PLAN DE ENTRENAMIENTO ESTÁ LISTO!",
  help_text: "Está diseñado para ayudarte a alcanzar tus objetivos.",
  try_header: "PRUEBA <tag>GainMuscle 7 DÍAS POR $1</tag> Y LOGRARÁS:",
  try_and_get_1:
    "Crear un hábito y desarrollar las técnicas de ejercicio correctas.",
  try_and_get_2:
    "Perder grasa corporal excesiva y mejorar la intensidad del entrenamiento.",
  try_and_get_3: "Alcanzar tu objetivo y cambiar tu vida para siempre.",
  faq_header: "PREGUNTAS FRECUENTES",
  faq_q_1: "¿Cómo se crea mi plan?",
  faq_q_2: "¿Cómo accedo a mi plan?",
  faq_q_3: "¿Puedo hacer cambios en mi plan después de que se haya creado?",
  faq_a_1:
    "Tu plan está diseñado específicamente para tus necesidades y objetivos únicos utilizando las respuestas que has proporcionado y nuestros algoritmos avanzados. Cada programa es validado por expertos, asegurando que será efectivo para ti.",
  faq_a_2:
    "Cada plan es personalizado por nuestro equipo dedicado de nutricionistas y expertos en fitness para cumplir con tus expectativas. Típicamente toma unas horas recibir acceso a tu plan por correo electrónico.",
  faq_a_3:
    "Sí, puedes hacer ajustes a tu plan incluso después de que se haya creado. Simplemente ve a la configuración de la aplicación para actualizar tu nivel de condición física, detalles personales o unidades de medida. Si deseas cambiar tu objetivo de peso, dirígete a la pestaña ‘Peso’ y utiliza el botón ‘Objetivo’ en la esquina superior derecha de la pantalla.",
  "Special Offer": "Oferta Especial",
  "7-Day Trial": "Prueba de 7 Días",
  "Total Today": "Total Hoy",
  "CARD NUMBER": "NÚMERO DE TARJETA",
  "EXPIRES ON": "EXPIRA EL",
  "Credit/Debit Card": "Tarjeta de Crédito/Débito",
  "Process Card": "Procesar Tarjeta",
  garrentee_text: "Pago <tag>Seguro</tag> Garantizado",
  "WHAT YOU GET": "LO QUE OBTIENES",
  feature1: "Más de 1,000 ejercicios certificados por entrenadores",
  feature2: "Más de 120 recetas sabrosas y saludables",
  feature3: "Entrenamientos guiados con tutoriales en video paso a paso",
  feature4:
    "Un plan de fitness personalizado adaptado a tus necesidades y nivel de condición física",
  feature5:
    "Todo en uno: una combinación de entrenamientos de cardio, fuerza y recuperación para los mejores resultados",
  "USERS LOVE OUR PLANS": "A NUESTROS USUARIOS LES ENCANTAN NUESTROS PLANES",
  review1:
    "¡Aplicación de fitness fantástica! Fácil de usar, me mantiene motivado y rastrea mi progreso sin problemas.",
  review2:
    "¡Me encanta esta aplicación de fitness! Seguimiento fácil, planes personalizados y gran motivación. ¡La recomiendo altamente!",
  review3:
    "¡Esta aplicación de fitness es un cambio total! Es fácil de usar, me mantiene motivado y los planes personalizados hacen que alcanzar mis metas sea pan comido. ¡Estoy muy impresionado!",
  review4:
    "Este es el primer programa que realmente me metió en una rutina porque proporcionó todo lo que necesitaba para comenzar, incluyendo un menú y una lista de compras.",
  review5:
    "Probé el programa durante una semana; tiene características sólidas, un plan de entrenamiento bien estructurado y una dieta. El plan de dieta incluso viene con una lista de compras e instrucciones paso a paso.",
  review6:
    "¡Gran programa! Funciona bien e incluye un conjunto de ejercicios bien diseñado.",
  review7:
    "Es una forma fantástica de ponerse en forma. ¡Debería haber comenzado mucho antes!",
  review8:
    "El plan es sólido. Aprecio mucho cómo personaliza los entrenamientos según el nivel de condición física, pero sería aún mejor si hubiera un modo sin conexión.",
  review9:
    "¡Este es un programa increíble! Logré perder 5 libras en solo una semana.",
  "OUR USERS MEET THEIR GOALS": "NUESTROS USUARIOS ALCANZAN SUS OBJETIVOS",
  "100% MONEY-BACK GUARANTEE": "GARANTÍA DE DEVOLUCIÓN DEL DINERO DEL 100%",
  garrentee_text2:
    "Creemos que nuestro plan puede funcionar para ti y obtendrás resultados visibles en 4 semanas. ¡Incluso estamos dispuestos a devolverte tu dinero si no ves resultados visibles y puedes demostrar que seguiste nuestro plan! Por favor, verifica nuestra <tag>Política de Reembolso</tag> para entender todos los requisitos.",
  //course page
  CP_Title: "Todos los Cursos",
  CP_NO_Courses: "No Hay Cursos Disponibles",
  "Something went wrong.": "Algo salió mal.",
  //meal plan page
  MPP_Title: "Plan de Comidas Personalizado",
  "We Are Preparing Your Plan.": "Estamos Preparando Tu Plan.",
  MPP_Remember_text:
    "Recuerda, la consistencia es clave para alcanzar tus objetivos. Mantente motivado, concéntrate en el progreso y celebra tus éxitos a lo largo del camino.",
  MPP_Understand_Needs_text: "Entendiendo Tus Necesidades",
  MPP_Goal: "Objetivo",
  MPP_Current_status: "Estado Actual",
  MPP_Challenges: "Desafíos",
  MPP_Motivations: "Motivaciones",
  MPP_Diet_Preference: "Preferencia Dietética",
  MPP_Tips_Title: "Consejos Adicionales",
  MPP_Diet_Strategy: "Estrategia Dietética",
  MPP_Diet_Strategy_text:
    "Nos centraremos en una dieta alta en proteínas con carbohidratos balanceados y grasas saludables para alimentar tus entrenamientos y desarrollar músculo.",
  MPP_View_Btn_Text: "Ver",
  MPP_Breakfast: "Desayuno",
  MPP_Lunch: "Almuerzo",
  MPP_Dinner: "Cena",
  MPP_Snacks: "Meriendas",
  //meal details page
  "We Are Preparing Veg Meal Details.":
    "Estamos Preparando los Detalles de la Comida Vegetariana.",
  "We Are Preparing Non-Veg Meal Details.":
    "Estamos Preparando los Detalles de la Comida No Vegetariana.",
  MD_Remember_text:
    "Recuerda, la consistencia es clave para alcanzar tus objetivos. Mantente motivado, concéntrate en el progreso y celebra tus éxitos a lo largo del camino.",
  MD_Nutritional_Info: "Información Nutricional",
  "(per serving)": "(por porción)",
  Carbs: "Carbohidratos",
  Protein: "Proteína",
  Fat: "Grasa",
  Ingredients: "Ingredientes",
  Instructions: "Instrucciones",
  //veg non veg
  "Vegan Diet": "Dieta Vegana",
  Vegan: "Vegano",
  "Non-vegan": "No Vegano",
  VEGAN: "Vegano",
  "NON-VEGAN": "No Vegano",
  "Non-Vegan Diet": "Dieta No Vegana",
  VNP_Title: "PREFERENCIA <tag /> DIETÉTICA",
  VNP_Banner_Veg:
    "La dieta proporciona abundante fibra, antioxidantes y micronutrientes de fuentes vegetales.",
  VNP_Banner_Non_Veg:
    "Las dietas ofrecen proteínas de alta calidad y aminoácidos esenciales, facilitando la reparación y el crecimiento muscular, lo que puede ser beneficioso para el entrenamiento de fuerza y resistencia.",
  //settings page
  SP_toast: "El nuevo plan de comidas se actualizará pronto.",
  SP_Title: "CONFIGURACIÓN DEL PLAN",
  SP_Helper_Text: "Se creará un nuevo plan después de guardar",
  SP_Button_Text: "Actualizar Plan",
  "Not Selected": "No Seleccionado",
  SP_Tile_1: "Tu Objetivo",
  SP_Tile_2: "Zonas Objetivo",
  SP_Tile_3: "Ubicación del Entrenamiento",
  SP_Tile_4: "Peso Actual",
  SP_Tile_5: "Peso Objetivo",
  SP_Tile_6: "Preferencia Dietética",
  //subscription page
  SBP_title: "GESTIONAR SUSCRIPCIÓN",
  SBP_Active_Text: "ACTIVO",
  SBP_Cancel_Text:
    "¿Quieres cancelar? <line /> Contacta a nuestro amable equipo de soporte <tag>aquí</tag>",
  //bottom-tab
  "Exercise Plan": "Plan de Ejercicio",
  "Meal Plan": "Plan de Comidas",
  //action sheet
  "Edit Your Plan": "Editar Tu Plan",
  Support: "Soporte",
  "Terms of Service": "Términos de Servicio",
  "Privacy Policy": "Política de Privacidad",
  "Manage Subscription": "Gestionar Suscripción",
  Logout: "Cerrar Sesión",
};

export const privacy_policy_es_Translation = {
  PP_title: "Política de Privacidad",
  PP_commitment: "Nuestro Compromiso con Su Privacidad",
  PP_commitment_text:
    "Respetamos y protegemos la privacidad de nuestros usuarios de la WebApp. Esta Política de Privacidad explica cómo recopilamos y utilizamos su información y es parte de nuestros Términos de Uso cuando utiliza nuestra WebApp. Al utilizar nuestra WebApp y el servicio proporcionado a través de la WebApp (“Servicio”), usted consiente nuestra Política de Privacidad. Si no está de acuerdo, por favor no utilice nuestra WebApp.",
  PP_info_collect: "¿Qué información recopilamos?",
  PP_info_collect_text:
    "Si realiza compras o compra una suscripción, nuestros proveedores de servicios de facturación de terceros recopilarán información como su nombre completo, número de tarjeta de crédito y dirección de facturación. Si nos envía un correo electrónico, podemos usar su dirección de correo electrónico para responder a su mensaje. Información enviada por su dispositivo móvil. Recopilamos cierta información que su dispositivo móvil envía cuando utiliza nuestra WebApp, como un identificador de dispositivo, configuraciones de usuario y el sistema operativo de su dispositivo, así como información sobre su uso de nuestros Servicios. Información de ubicación. Cuando utiliza nuestra WebApp, podemos recopilar y almacenar información sobre su ubicación convirtiendo su dirección IP en una geolocalización aproximada o accediendo a las coordenadas GPS de su dispositivo móvil o ubicación aproximada si activa los servicios de ubicación en su dispositivo. Podemos utilizar la información de ubicación para mejorar y personalizar nuestros Servicios para usted. Si no desea que recopilemos información de ubicación, puede desactivar esa función en su dispositivo móvil.",
  PP_store_info: "¿Dónde almacenamos su información?",
  PP_store_info_text:
    "Su información puede almacenarse en nuestros propios servidores o en servidores propiedad de proveedores de almacenamiento en la nube de terceros. Los proveedores de almacenamiento de terceros no pueden utilizar su información, excepto como se establece en esta Política de Privacidad.",
  PP_use_info: "¿Cómo utilizamos su información?",
  PP_use_info_text:
    "La información que recopilamos de usted podría utilizarse:",
  PP_store_duration: "¿Cuánto tiempo almacenamos su información?",
  PP_store_duration_text:
    "Tenemos la intención de almacenar parte de su información indefinidamente. ¿Qué pasa con los enlaces a otros sitios web? Podemos proporcionar enlaces a o compatibilidad con otros sitios web. Sin embargo, no somos responsables de las prácticas de privacidad empleadas por esos sitios web ni de la información o contenido que contienen.",
  PP_protect_info: "¿Cómo protegemos su información?",
  PP_protect_info_text:
    "Utilizamos procedimientos físicos, electrónicos y otros apropiados para salvaguardar y asegurar la información que recopilamos. Sin embargo, tenga en cuenta que Internet es un entorno inherentemente inseguro y que los piratas informáticos están constantemente trabajando para eludir las medidas de seguridad. Por lo tanto, no podemos garantizar que su información no será accedida, divulgada, alterada o destruida, y usted acepta este riesgo.",
  PP_protect_self: "¿Cómo puede proteger su información?",
  PP_protect_self_text:
    "Le instamos a que tome medidas para mantener su información personal segura, no compartiéndola con otros o publicándola en línea.",
  PP_disclose: "¿Divulgamos alguna información a terceros?",
  PP_disclose_text:
    "No recopilamos, vendemos, intercambiamos ni transferimos de otra manera su Información Personal Identificable (PII) a terceros con fines comerciales o de marketing, excepto como se establece aquí. La información personal (y no personal) recopilada a través del Sitio puede compartirse con empresas y organizaciones que realizan servicios en nuestro nombre (por ejemplo, empresas que proporcionan gestión de datos u otros servicios de apoyo como almacenamiento de datos y servicios de alojamiento web). Podemos compartir su información personal (y no personal) con terceros para ofrecerle publicidad relevante y comercializar nuestros productos, pero no venderemos su información personal. Información divulgada en relación con transacciones comerciales. Si somos adquiridos por un tercero como resultado de una transacción como una fusión, adquisición o venta de activos o si nuestros activos son adquiridos por un tercero en caso de que cerremos nuestro negocio o entremos en bancarrota, algunos o todos nuestros activos, incluida su PII, pueden ser divulgados o transferidos a un adquirente externo en relación con la transacción. Información divulgada para nuestra protección y la protección de otros. Cooperamos con funcionarios gubernamentales y de aplicación de la ley o partes privadas para hacer cumplir y cumplir con la ley. Podemos divulgar cualquier información sobre usted a funcionarios gubernamentales o de aplicación de la ley o partes privadas según lo consideremos, a nuestra entera discreción, necesario o apropiado: (i) para responder a reclamaciones, procesos legales (incluidas citaciones); (ii) para proteger nuestra propiedad, derechos y seguridad y la propiedad, derechos y seguridad de un tercero o del público en general; y (iii) para detener cualquier actividad que consideremos ilegal, poco ética o legalmente procesable.",
  PP_children: "No destinado a niños",
  PP_children_text:
    "Nuestra WebApp y Servicio no están destinados a niños menores de 16 años. No recopilamos intencionalmente ni específicamente información de o sobre niños menores de 16 años.",
  PP_changes: "Cambios a nuestra Política de Privacidad",
  PP_changes_text:
    "Si decidimos cambiar nuestra Política de Privacidad, publicaremos esos cambios en esta página. También podemos, pero no estamos obligados a, enviarle un aviso por correo electrónico. Le recomendamos que revise esta política de privacidad con frecuencia para estar informado sobre cómo estamos protegiendo su información.",
  PP_contact: "Contáctenos",
  PP_contact_text:
    "Si tiene preguntas sobre nuestra Política de Privacidad, comuníquese con nosotros en",
};

export const terms_es_Translation = {
  TC_title: "Términos y Condiciones",
  main_desc:
    "Este Acuerdo de Licencia de Usuario Final es un acuerdo válido entre usted y la Empresa. Por favor, lea este EULA cuidadosamente. Las reglas del EULA son vinculantes para usted. Si no está de acuerdo en estar sujeto a las reglas del EULA, no utilice la WebApp. Si continúa utilizando la WebApp, esto significará que ha leído y entendido el EULA y acepta estar sujeto a él.",
  category_1_title: "DEFINICIONES Y TÉRMINOS",
  category_1_desc:
    "Para entender claramente las reglas del EULA, debe familiarizarse con el significado de los términos utilizados en el EULA. En este EULA, los siguientes términos, cuando están en mayúsculas, tendrán los siguientes significados:",
  c_1_l_1:
    "“Cuenta” – significa una cuenta personal de un usuario de los servicios de la Plataforma, que es proporcionada por el Administrador de la Plataforma bajo los Términos de Uso de la Plataforma o documento similar. La Cuenta se accede con un ID de Plataforma (nombre de cuenta) y una contraseña.",
  c_1_l_2:
    "“Empresa”, “Nosotros” – significa RBCOM, una empresa incorporada en Irlanda en la siguiente dirección: 1 Raffles Place 40-02 One Raffles Place Tower 1.",
  c_1_l_3:
    "“Contenido” – significa todo tipo de contenido, incluyendo, entre otros, datos, textos, imágenes, videos, sonidos, enlaces, software y otras piezas de información en cualquier forma, que se ponen a su disposición a través de la WebApp.",
  c_1_l_4:
    "“Dispositivo” – significa un teléfono inteligente, una tableta u otro dispositivo móvil similar que usted posea o controle legalmente exclusivamente para su uso personal no comercial.",
  c_1_l_5:
    "“EULA” – significa este Acuerdo de Licencia de Usuario Final, que es un acuerdo vinculante entre usted y la Empresa. El EULA puede incorporar otros documentos, como la Política de Privacidad que regulan las relaciones entre usted y nosotros con respecto al uso de la WebApp. Si un documento representa parte del EULA, tiene una clara indicación al respecto.",
  c_1_l_6:
    "“Derechos de Propiedad Intelectual” – significa, colectivamente, derechos bajo cualquier ley de patentes, marcas comerciales, derechos de autor y secretos comerciales, y cualquier otro derecho de propiedad intelectual o propietario reconocido en cualquier país o jurisdicción en todo el mundo, incluyendo, sin limitación, derechos morales o similares.",
  c_1_l_7:
    "“Suscripción” – significa un período durante el cual usted utiliza la WebApp en su totalidad o cierto contenido adicional por una tarifa.",
  c_1_l_8:
    "“Contenido generado por el usuario” – significa cualquier forma de contenido, incluyendo, entre otros, datos, textos, imágenes, videos, sonidos, enlaces, que han sido publicados por usuarios en plataformas en línea, por ejemplo, redes sociales (por ejemplo, Instagram, Facebook).",
  c_1_l_9: "“Usted” – significa un individuo que utiliza la WebApp.",
  category_2_title: "CAMBIOS DEL EULA",
  c_2_l_1:
    "Podemos introducir cambios a este EULA de vez en cuando. Debe revisar regularmente la última versión del EULA en el enlace disponible en la página web.",
  c_2_l_2:
    "Si no está de acuerdo con los términos del EULA actualizado, no se le permite usar la WebApp a partir del día en que el EULA actualizado entre en vigor. En este caso, no debe utilizar la WebApp y cancelar la renovación de sus Suscripciones.",
  category_3_title: "DISPOSITIVO",
  c_3_l_1:
    "Si tiene la intención de usar la WebApp en cualquier Dispositivo que no sea de su propiedad, debe tener el permiso del propietario para hacerlo. Usted será responsable de cumplir con este EULA, ya sea que posea o no el Dispositivo.",
  c_3_l_2:
    "Si no posee el Dispositivo, se le permite usar la WebApp solo a través de su Cuenta.",
  c_3_l_3:
    "Si usa el Dispositivo junto con otras personas, por favor, asegúrese de que nadie más que usted pueda usar la WebApp desde su Cuenta. Todas las acciones realizadas en su Cuenta se consideran sus acciones sin importar quién realice efectivamente tales acciones. Usted es completamente responsable de todas las acciones realizadas en su Cuenta.",
  c_3_l_4:
    "Si decide vender o de otro modo disponer del Dispositivo, debe eliminar la WebApp del Dispositivo.",
  category_4_title: "CAPACIDAD LEGAL Y EDAD",
  c_4_l_1:
    "La WebApp está diseñada para usuarios mayores de 16 años. Si utiliza la WebApp, confirma que tiene al menos 16 años y tiene plena capacidad legal para celebrar el Acuerdo.",
  c_4_l_2:
    "Si tiene entre 16 y 18 años, su padre, tutor u otro representante de edad legal debe revisar y aceptar este EULA en su nombre. Si utiliza la WebApp, confirma que su padre, tutor u otro representante de edad legal ha aceptado los términos de este EULA y le permite usar la WebApp.",
  category_5_title: "PROPIEDAD INTELECTUAL",
  c_5_l_1:
    "Por la presente le otorgamos una licencia no exclusiva, personal, limitada, revocable y no transferible para acceder, descargar y ejecutar la WebApp únicamente en su Dispositivo, siempre que acepte cumplir con todos los términos y condiciones de este EULA. Por favor, tenga en cuenta que la WebApp es licenciada, no vendida, a usted.",
  c_5_l_2:
    "La Empresa o el licenciante de la Empresa es el único titular de los Derechos de Propiedad Intelectual con respecto a la WebApp. La Empresa se reserva todos los derechos, títulos e intereses en la WebApp y se reserva todos los derechos no otorgados expresamente a usted en este EULA.",
  c_5_l_3:
    "No puede vender, revender, distribuir, redistribuir, alquilar, arrendar, sublicenciar, ceder, copiar, ni de ninguna otra manera transferir o otorgar derechos sobre la WebApp a terceros.",
  c_5_l_4:
    "No puede distribuir ni hacer que la WebApp esté disponible a través de ninguna red donde pueda ser descargada o utilizada por múltiples usuarios al mismo tiempo, excepto cuando sea expresamente autorizado por nosotros.",
  c_5_l_5:
    "No puede utilizar ningún dispositivo o proceso automático o manual para interferir con el correcto funcionamiento de la WebApp. Sin embargo, puede utilizar cualquier tipo de dispositivo o proceso para eliminar la WebApp de su Dispositivo.",
  c_5_l_6:
    "No puede descompilar, desensamblar, realizar ingeniería inversa ni crear obras derivadas de la WebApp o de ninguna parte de la misma. Todas las modificaciones o mejoras a la WebApp siguen siendo propiedad exclusiva de la Empresa.",
  c_5_l_7:
    "No puede utilizar la WebApp de ninguna manera ilegal, para ningún propósito ilegal, o de ninguna manera que sea inconsistente con este EULA, ni actuar de manera fraudulenta o maliciosa, por ejemplo, al hackear o insertar código malicioso, como virus, o datos dañinos, en la WebApp.",
  category_6_title: "SUSCRIPCIÓN Y PERÍODO DE PRUEBA",
  c_6_l_1:
    "Esta WebApp en su totalidad o alguna parte de ella (Contenido adicional) se proporciona en base a una suscripción paga. La tarifa puede variar según la duración del período de la Suscripción.",
  c_6_l_2:
    "La Suscripción se renovará automáticamente por el mismo período al final de la Suscripción al costo del paquete elegido. Su Cuenta será cargada por la Suscripción renovada dentro de las 24 horas previas al final de la Suscripción actual.",
  c_6_l_3:
    "Puede cancelar la renovación de la Suscripción al menos 24 horas antes del final de la Suscripción: en este caso, la WebApp en su totalidad o su Contenido adicional estará disponible para usted hasta el final de la Suscripción. Para cancelar la renovación automática de la Suscripción, cambie la configuración de suscripción de su Cuenta. Después del final de la Suscripción, la WebApp en su totalidad o su Contenido adicional solo estará disponible si se compra una nueva Suscripción.",
  c_6_l_4:
    "Su suscripción no se detendrá automáticamente. Debe cancelar la renovación automática de su suscripción para no ser cargado con el costo de la nueva Suscripción.",
  c_6_l_5:
    "La WebApp ofrece un período de prueba gratuito de 7 días. Puede usar la WebApp o su Contenido adicional de forma gratuita durante este período. Por favor, tenga en cuenta que al final del período de prueba se transformará automáticamente en una Suscripción paga por el período más corto disponible y su Cuenta será cargada con el costo de dicha Suscripción, a menos que cancele la Suscripción a través de la configuración de suscripción de su Cuenta al menos 24 horas antes del final del período de prueba gratuito.",

  category_7_title: "COSTOS Y TÉRMINOS DE COMPRA",
  c_7_l_1:
    "Los costos de todas las Suscripciones están disponibles en la página web.",
  c_7_l_2:
    "Cuando usted compra una Suscripción, el Administrador de la Plataforma carga su Cuenta y recoge el dinero en nuestro nombre. El pago es procesado por un sistema de procesamiento de pagos implementado por el Administrador de la Plataforma.",
  c_7_l_3:
    "Por favor, tenga en cuenta que nosotros no procesamos su pago ni tenemos acceso a su información de pago, como, entre otros, los datos de su tarjeta bancaria, su nombre u otra información relacionada.",
  c_7_l_4:
    "Si tiene alguna pregunta con respecto al procesamiento de pagos, por favor, envíe su problema al servicio de soporte al usuario del Administrador de la Plataforma.",
  c_7_l_5:
    "Su Cuenta será cargada con el costo de la nueva Suscripción dentro de las 24 horas antes del final de la Suscripción actual. Si cancela la renovación automática dentro de las últimas 24 horas de la Suscripción, esa cancelación tendrá efecto al final de la nueva Suscripción.",
  c_7_l_6:
    "Por favor, tenga en cuenta que nosotros hacemos el reembolso de su último pago. El contenido digital se pone a su disposición inmediatamente después de que se carga la tarifa de la Suscripción en su Cuenta.",
  category_8_title: "CONTENIDO GENERADO POR EL USUARIO",
  c_8_l_1:
    "En la medida en que pueda acceder a publicaciones de otros usuarios de Instagram, puede estar expuesto a contenido generado por el usuario que puede ser potencialmente objetable. Tenga en cuenta que la Empresa no controla ni revisa previamente ningún contenido generado por el usuario publicado en ninguna red social.",
  c_8_l_2:
    "Cualquier tipo de modificaciones o censura por parte de la Empresa con respecto al contenido generado por el usuario puede considerarse una violación de los derechos humanos o de los derechos de autor y puede potencialmente engañarlo.",
  c_8_l_3:
    "Si ha estado expuesto a algún tipo de contenido objetable, puede utilizar la funcionalidad de la respectiva red social para rastrear y eliminar el contenido generado por el usuario que considere objetable.",
  c_8_l_4:
    "Bajo ninguna circunstancia la Empresa será responsable de ningún contenido generado por el usuario.",
  category_9_title: "DESCARGO DE RESPONSABILIDAD",
  c_9_l_1:
    "En la máxima medida permitida por la ley aplicable, Nosotros y nuestros afiliados, funcionarios, empleados, agentes, socios y licenciantes declinamos expresamente todas las garantías de cualquier tipo, ya sean expresas o implícitas, en cuanto a la exactitud, adecuación o integridad de cualquiera de la información contenida en Nuestro WebApp o creada por los servicios.",
  c_9_l_2:
    "El contenido en Nuestro WebApp se proporciona a Usted en una base de 'tal cual', sin garantías sobre comerciabilidad o idoneidad para un propósito o uso particular. No garantizamos que Usted obtendrá algún resultado anticipado del uso del WebApp.",
  c_9_l_3: "No hacemos ninguna garantía de que:",
  c_9_l_3a: "el WebApp cumplirá con sus requisitos;",
  c_9_l_3b: "el WebApp estará libre de errores, seguro o ininterrumpido;",
  c_9_l_3c: "cualquier error en la funcionalidad del WebApp será corregido;",
  c_9_l_3d: "la calidad del WebApp cumplirá con sus expectativas.",
  c_9_l_4:
    "No tendremos ninguna responsabilidad por errores u omisiones. No somos responsables de ningún daño, ya sea directo o indirecto, especial o consecuente, sufrido por Usted o por cualquier otra persona debido al uso del WebApp, incluso si se le ha informado sobre la posibilidad de tales daños. Ningún consejo o información, ya sea oral o escrita, obtenida por Usted de Nosotros o de Nuestro WebApp creará ninguna garantía no expresamente declarada en el EULA. Bajo ninguna circunstancia nuestra responsabilidad o la de cualquiera de nuestros afiliados, conforme a cualquier causa de acción, ya sea en contrato, agravio u otro, superará la tarifa pagada por Usted para acceder al WebApp. Además, no tendremos responsabilidad por retrasos o fallas debido a circunstancias que escapan a nuestro control.",
  category_10_title: "TRANSFERENCIA DE DERECHOS",
  c_10_l_1:
    "Podemos transferir nuestros derechos y obligaciones bajo este EULA a cualquier otra organización o individuo sin su consentimiento. Siempre le informaremos por escrito si esto sucede y nos aseguraremos de que la transferencia no afecte sus derechos bajo el EULA.",
  c_10_l_2:
    "Solo puede transferir sus derechos u obligaciones bajo este EULA a otra persona si nosotros estamos de acuerdo por escrito.",
  category_11_title: "TERMINACIÓN",
  c_11_l_1:
    "Podemos finalizar sus derechos para usar el WebApp en cualquier momento contactándolo si ha violado las reglas de este EULA de manera grave. Si lo que ha hecho se puede corregir, le daremos una oportunidad razonable para hacerlo.",
  c_11_l_2: "Si finalizamos sus derechos para usar el WebApp:",
  c_11_l_2a:
    "Debe detener todas las actividades autorizadas por este EULA, incluyendo su uso del WebApp.",
  c_11_l_2b:
    "Debe eliminar o quitar el WebApp de todos los dispositivos y destruir inmediatamente todas las copias del WebApp que tenga y confirmarnos que ha hecho esto.",
  category_12_title: "DIVERSOS",
  c_12_l_1:
    "Cada uno de los párrafos de este EULA opera de forma independiente. Si algún tribunal o autoridad competente decide que alguno de ellos es ilegal, los párrafos restantes permanecerán en pleno vigor y efecto.",
  c_12_l_2:
    "Incluso si demoramos en hacer cumplir este EULA, aún podemos hacerlo más tarde. Si no insistimos de inmediato en que haga algo que se requiere que haga bajo los términos de este EULA, o si demoramos en tomar medidas contra usted en relación con su incumplimiento del EULA, eso no significará que no tiene que hacer esas cosas y no nos impedirá tomar medidas contra usted en una fecha posterior.",
  c_12_l_3:
    "Este EULA se regirá e interpretará de acuerdo con las leyes de Singapur, sin considerar sus disposiciones sobre conflictos de leyes.",
  c_12_l_4:
    "Si surge alguna disputa, controversia o diferencia entre usted y la Compañía, se resolverá amistosamente a través de negociaciones. En caso de que dicha disputa, controversia o diferencia no pueda resolverse amistosamente a través de negociaciones dentro de un período de treinta (30) días, se resolverá por la Asociación de Arbitraje de Irlanda de acuerdo con sus Procedimientos Internacionales Acelerados.",
  last_text1: "El número de árbitros será uno.",
  last_text2: "El lugar de arbitraje será Singapur.",
  last_text3: "El arbitraje se llevará a cabo y el laudo se emitirá en inglés.",
  last_text4:
    "Cada parte (es decir, usted y la Compañía) asumirá sus propios gastos, pero las partes compartirán igualmente los gastos del tribunal de arbitraje.",
  support_message:
    "Soporte. Si desea obtener más información sobre la WebApp o tiene problemas al usarla, comuníquese con nosotros en",
};
