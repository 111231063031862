import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import {
  Actual_Body,
  Course_Details_Page,
  Course_Page,
  Landing_Page,
  Login_Page,
  Manage_Subscription,
  Meal_Details_Page,
  Meal_Plan_Page,
  Otp_Page,
  Part_Four_Four,
  Part_Four_One,
  Part_Four_Three,
  Part_Four_Two,
  Part_Three_Eight,
  Part_Three_Eleven,
  Part_Three_Five,
  Part_Three_Four,
  Part_Three_Nine,
  Part_Three_One,
  Part_Three_Seven,
  Part_Three_Six,
  Part_Three_Ten,
  Part_Three_Thirteen,
  Part_Three_Three,
  Part_Three_Two,
  Part_Two_Five,
  Part_Two_Four,
  Part_Two_One,
  Part_Two_Six,
  Part_Two_Three,
  Payment_Page,
  Plan_Goal_Page,
  Plan_Location_Page,
  Plan_Settings_Page,
  Plan_Target_Weight_Page,
  Plan_Weight_Page,
  Plan_Zones_Page,
  Register_Page,
  Veg_Or_Non,
} from "../constants/pageRouteName";
import Login from "../pages/auth/Login";
import Otp from "../pages/auth/Otp";
import Register from "../pages/auth/Register";
import Course from "../pages/course/Course";
import CourseDetail from "../pages/course/CourseDetail";
import MealDetails from "../pages/meal/MealDetails";
import Meals from "../pages/meal/MealsPlan";
import VegNon from "../pages/meal/VegNon";
import Event from "../pages/register-flow/part-four/Event";
import EventDate from "../pages/register-flow/part-four/EventDate";
import PersonalPlan from "../pages/register-flow/part-four/PersonalPlan";
import WeightPlan from "../pages/register-flow/part-four/WeightPlan";
import Age from "../pages/register-flow/part-three/Age";
import CurrentWeight from "../pages/register-flow/part-three/CurrentWeight";
import FitnessLevel from "../pages/register-flow/part-three/FitnessLevel";
import FitnessSummary from "../pages/register-flow/part-three/FitnessSummary";
import Height from "../pages/register-flow/part-three/Height";
import LifeStyle from "../pages/register-flow/part-three/LifeStyle";
import Meal from "../pages/register-flow/part-three/Meal";
import PushUp from "../pages/register-flow/part-three/PushUp";
import Sleep from "../pages/register-flow/part-three/Sleep";
import TargetWeight from "../pages/register-flow/part-three/TargetWeight";
import Walk from "../pages/register-flow/part-three/Walk";
import Water from "../pages/register-flow/part-three/Water";
import WorkOutLocation from "../pages/register-flow/part-three/WorkOutLocation";
import ActualBody from "../pages/register-flow/part-two/ActualBody";
import Goal from "../pages/register-flow/part-two/Goal";
import Interest from "../pages/register-flow/part-two/Interest";
import Motivation from "../pages/register-flow/part-two/Motivation";
import PerfectWeight from "../pages/register-flow/part-two/PerfectWeight";
import TargetZones from "../pages/register-flow/part-two/TargetZones";
import PaymentNew from "../pages/register-flow/payment/PaymentNew";
import Plan from "../pages/settings/Plan";
import Subscription from "../pages/settings/Subscription";

export const routes = [
  // {
  //   id: 1,
  //   name: "StartHere",
  //   path: Landing_Page,
  //   component: <LandingPage />,
  //   isProtected: false,
  //   showHeader: true,
  //   showFooter: true,
  //   showLoginBtn: true,
  // },
  {
    id: 1,
    name: "age",
    path: Landing_Page,
    component: <Age />,
    isProtected: false,
    showHeader: true,
    showFooter: true,
    showLoginBtn: true,
  },
  {
    id: 2,
    name: "Actual_Body",
    path: Actual_Body,
    component: <ActualBody />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  // {
  //   id: 32,
  //   name: "StartHere",
  //   path: Start_Now_Page,
  //   component: <Landing_Page />,
  //   isProtected: false,
  //   showHeader: true,
  //   showFooter: true,
  //   showLoginBtn: true,
  // },
  // {
  //   id: 2,
  //   name: "Users",
  //   path: Part_One_Two,
  //   component: <Users />,
  //   isProtected: false,
  //   showHeader: true,
  //   showFooter: false,
  //   showLoginBtn: false,
  // },
  {
    id: 3,
    name: "Goal",
    path: Part_Two_One,
    component: <Goal />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  // {
  //   id: 4,
  //   name: "BodyType",
  //   path: Part_Two_Two,
  //   component: <Body />,
  //   isProtected: false,
  //   showHeader: true,
  //   showFooter: false,
  //   showLoginBtn: false,
  // },
  {
    id: 5,
    name: "Motivation",
    path: Part_Two_Three,
    component: <Motivation />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 6,
    name: "TargetZones",
    path: Part_Two_Four,
    component: <TargetZones />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 7,
    name: "PerfectWeight",
    path: Part_Two_Five,
    component: <PerfectWeight />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 8,
    name: "interest",
    path: Part_Two_Six,
    component: <Interest />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 9,
    name: "FitnessLevel",
    path: Part_Three_One,
    component: <FitnessLevel />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 10,
    name: "pushUp",
    path: Part_Three_Two,
    component: <PushUp />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 11,
    name: "lifestyle",
    path: Part_Three_Three,
    component: <LifeStyle />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 12,
    name: "waik",
    path: Part_Three_Four,
    component: <Walk />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 13,
    name: "meal",
    path: Part_Three_Five,
    component: <Meal />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 14,
    name: "sleep",
    path: Part_Three_Six,
    component: <Sleep />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 15,
    name: "water",
    path: Part_Three_Seven,
    component: <Water />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 16,
    name: "workoutLocation",
    path: Part_Three_Eight,
    component: <WorkOutLocation />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 17,
    name: "height",
    path: Part_Three_Nine,
    component: <Height />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 18,
    name: "currentWeight",
    path: Part_Three_Ten,
    component: <CurrentWeight />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 19,
    name: "targetWeight",
    path: Part_Three_Eleven,
    component: <TargetWeight />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  // {
  //   id: 20,
  //   name: "age",
  //   path: Part_Three_Twelve,
  //   component: <Age />,
  //   isProtected: false,
  //   showHeader: true,
  //   showFooter: false,
  //   showLoginBtn: false,
  // },
  {
    id: 21,
    name: "fitnessSummary",
    path: Part_Three_Thirteen,
    component: <FitnessSummary />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 22,
    name: "events",
    path: Part_Four_One,
    component: <Event />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 23,
    name: "eventDate",
    path: Part_Four_Two,
    component: <EventDate />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 24,
    name: "weightPlan",
    path: Part_Four_Three,
    component: <WeightPlan />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 25,
    name: "personalPlan",
    path: Part_Four_Four,
    component: <PersonalPlan />,
    isProtected: false,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
  },
  {
    id: 26,
    name: "payment",
    path: Payment_Page,
    component: (
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
        <Elements>
          <PaymentNew />
        </Elements>
      </RecurlyProvider>
    ),
    isProtected: false,
    showHeader: false,
    showFooter: true,
    showLoginBtn: false,
  },
  {
    id: 27,
    name: "course",
    path: Course_Page,
    component: <Course />,
    isProtected: true,
    showHeader: true,
    showFooter: true,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 28,
    name: "courseDetail",
    path: Course_Details_Page,
    component: <CourseDetail />,
    isProtected: true,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 29,
    name: "mealplan",
    path: Meal_Plan_Page,
    component: <Meals />,
    isProtected: true,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 30,
    name: "mealplanDetails",
    path: Meal_Details_Page,
    component: <MealDetails />,
    isProtected: true,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 31,
    name: "planSettings",
    path: Plan_Settings_Page,
    component: <Plan />,
    isProtected: true,
    showHeader: true,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 32,
    name: "settings-goal",
    path: Plan_Goal_Page,
    component: <Goal />,
    isProtected: true,
    showHeader: false,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 33,
    name: "settings-zones",
    path: Plan_Zones_Page,
    component: <TargetZones />,
    isProtected: true,
    showHeader: false,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 34,
    name: "settings-location",
    path: Plan_Location_Page,
    component: <WorkOutLocation />,
    isProtected: true,
    showHeader: false,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 35,
    name: "settings-current-weight",
    path: Plan_Weight_Page,
    component: <CurrentWeight />,
    isProtected: true,
    showHeader: false,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 36,
    name: "settings-target-weight",
    path: Plan_Target_Weight_Page,
    component: <TargetWeight />,
    isProtected: true,
    showHeader: false,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 37,
    name: "manage-subscription",
    path: Manage_Subscription,
    component: <Subscription />,
    isProtected: true,
    showHeader: true,
    showFooter: true,
    showLoginBtn: false,
    showMenuBtn: true,
  },
  {
    id: 28,
    name: "login",
    path: Login_Page,
    component: <Login />,
    isProtected: false,
    showHeader: false,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: false,
  },
  {
    id: 29,
    name: "personal-plan",
    path: Register_Page,
    component: <Register />,
    isProtected: false,
    showHeader: false,
    showFooter: true,
    showLoginBtn: false,
    showMenuBtn: false,
  },
  {
    id: 30,
    name: "veg-or-nonveg",
    path: Veg_Or_Non,
    component: <VegNon />,
    isProtected: true,
    showHeader: false,
    showFooter: false,
    showLoginBtn: false,
    showMenuBtn: false,
  },
  {
    id: 31,
    name: "otp",
    path: Otp_Page,
    component: <Otp />,
    isProtected: false,
    showHeader: false,
    showFooter: true,
    showLoginBtn: false,
    showMenuBtn: false,
  },
];
