import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Carousel from "react-simply-carousel";
import { Security_Svg, Star_Svg } from "../../assets/svgs/payment";
import { Terms_Conditions } from "../../constants/pageRouteName";
import { features, newReviews } from "../../utils/data";
import { placeHolderImages, s3Images } from "../../utils/image";
import ImageLoader from "../ImageLoader";
import Faqs from "./Faqs";

const RatingCarousel = () => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const carouselItems = newReviews.map((data, index) => (
    <div className="rating-card-payment" key={index}>
      <div className="rating-card-payment-background">
        <div className="rating-card-header">
          <div className="d-flex align-items-center gap-2">
            <ImageLoader src={data.img} className="rating-card-image" />
            <p className="m-0 rating-card-username fw-bold">{data.name}</p>
          </div>
          <div className="d-flex">
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <div
                  key={i}
                  className="me-2 d-flex justify-content-center align-items-start"
                  style={{ width: "14px", height: "14px", marginTop: "3px" }}
                >
                  {Star_Svg()}
                </div>
              ))}
          </div>
        </div>
        <p className="m-0 rating-card-body fw-regular carousel-item active">
          {data.title}
        </p>
      </div>
    </div>
  ));

  return (
    <div className="w-100 overflow-hidden">
      <Carousel
        swipeTreshold={60}
        infinite
        activeSlideIndex={activeSlide}
        autoplay
        autoplayDelay={2500}
        autoplayDirection="forward"
        backwardBtnProps={{ show: false }}
        forwardBtnProps={{ show: false }}
        onRequestChange={setActiveSlide}
        hideNavIfAllVisible
        dotsNav={{
          show: false,
        }}
        itemsToShow={2}
        speed={400}
        centerMode
      >
        {carouselItems}
      </Carousel>
      <div className="w-100 d-flex justify-content-center gap-1 mt-3 align-items-center">
        {newReviews.map((data, index) => (
          <div
            className={`${
              index === activeSlide
                ? "active-dot-rating"
                : "inactive-dot-rating"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

const GatewayFeatures = () => {
  return (
    <div className="">
      <p
        className="payment-screen-padding-x m-0 p-0 mt-3 fw-bold text-inter text-custom-white"
        style={{
          fontSize: "20px",
          textTransform: "uppercase",
        }}
      >
        <Trans
          i18nKey={"garrentee_text"}
          components={{ tag: <span style={{ color: "#0045FF" }} /> }}
        />
      </p>

      <div className="payment-screen-padding-x d-flex flex-row justify-content-center align-items-center mt-2">
        <div style={{ width: "60%" }}>{Security_Svg()}</div>
      </div>

      <div className="mt-5 payment-screen-padding-x">
        <h3
          className="mb-4 fw-bold text-inter text-center text-custom-white text-uppercase"
          style={{ fontSize: "20px" }}
        >
          {t("WHAT YOU GET")}
        </h3>
        {features.map((data, index) => (
          <div key={index} className="d-flex mt-3">
            <div
              className="me-2 d-flex justify-content-center align-items-start"
              style={{ width: "15px", height: "14px", marginTop: "3px" }}
            >
              {Star_Svg()}
            </div>
            <p
              className="m-0 p-0 text-custom-white text-inter fw-regular"
              style={{
                textAlign: "left",
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              {data?.title}
            </p>
          </div>
        ))}
      </div>

      <div className="margin-t-50 ">
        <RatingCarousel />
      </div>

      <Faqs />

      <div className="margin-t-50 mb-3 payment-screen-padding-x">
        <ImageLoader
          alt=""
          placeHolderImage={placeHolderImages.payment[3]}
          src={s3Images.payment[3]}
          style={{ height: "114px", width: "120px" }}
        />
        <h3
          className="text-inter fw-bold text-custom-white "
          style={{
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#FFFFFF",
          }}
        >
          {t("100% MONEY-BACK GUARANTEE")}
        </h3>
        <p
          className="mt-2 text-inter fw-medium"
          style={{ fontSize: "16px", lineHeight: "20px", color: "#8B8B8B" }}
        >
          <Trans
            i18nKey={"garrentee_text2"}
            components={{
              tag: (
                <Link
                  to={Terms_Conditions}
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#8B8B8B",
                    textDecoration: "underline",
                  }}
                />
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default GatewayFeatures;
