import i18next, { t } from "i18next";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GainGraph, Graph } from "../../../assets/svgs/partFour";
import Button from "../../../components/Button";
import { Part_Four_Four } from "../../../constants/pageRouteName";
import { pagePreloadImages } from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const Month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const WeightPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { details } = useSelector((state) => state.detailState);

  const gotoNextPage = () => {
    nextPageRoute(
      navigate,
      Part_Four_Four,
      dispatch,
      pagePreloadImages.weightPlan
    );
  };

  const getWeight = (weight) => {
    if (weight) {
      let newWeight = weight?.split(" ");
      return newWeight[0];
    }
    return 0;
  };

  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-between pt-2 main-padding-b main-padding gap-4">
      <div className="d-flex flex-column gap-3 flex-grow-1 justify-content-between">
        <div className="d-flex flex-column gap-3">
          <div className="w-100 d-flex justify-content-center">
            <h1
              className="age-header my-0 text-inter fw-bold text-center"
              style={{ maxWidth: "306px" }}
            >
              {t("WP_title_1", {
                text:
                  getWeight(details.targetWeight) > getWeight(details.weight)
                    ? t("GAIN")
                    : t("LOSE"),
              })}
              <br />
              {t("WP_title_2", {
                text:
                  getWeight(details.targetWeight) > getWeight(details.weight)
                    ? t("GAIN")
                    : t("LOSE"),
              })}
            </h1>
          </div>
          <p className="text-inter text-center fw-regular help-text-banner-subtitle m-0 weight-plan-sub-title-responsive">
            {t("WP_text_1")} {i18next.language !== "es" && <br />}
            {t("WP_text_2")}
          </p>
          <h1 className="age-header my-0 text-inter fw-bold text-center  text-uppercase">
            {t("WP_weight_status", {
              target: details.targetWeight,
              date: moment().add(3, "month").format("MMM DD, YYYY"),
            })}
          </h1>
          <p className="text-inter text-center fw-regular help-text-banner-subtitle m-0">
            {t("WP_text_3")} {i18next.language !== "es" && <br />}
            {t("WP_text_4", {
              desiredWeight: details.targetWeight,
            })}
          </p>
        </div>
        <div className="w-100 flex-grow-1 d-flex align-items-center">
          <div className="w-100 banner px-0 m-0">
            {getWeight(details.targetWeight) > getWeight(details.weight)
              ? GainGraph({
                  targetWeight: details.targetWeight,
                  weight: details.weight,
                  targetMonth: Month[moment().add(3, "month").get("month")],
                  month: Month[moment().get("month")],
                })
              : Graph({
                  targetWeight: details.weight,
                  weight: details.targetWeight,
                  targetMonth: Month[moment().get("month")],
                  month: Month[moment().add(3, "month").get("month")],
                })}
          </div>
        </div>
      </div>
      <Button label={t("CONTINUE")} onClick={gotoNextPage} />
    </div>
  );
};

export default WeightPlan;
