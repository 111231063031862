import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Part_Two_Three } from "../../../constants/pageRouteName";
import BigIconLayout from "../../../layouts/BigIconLayout";
import { updatePlanData } from "../../../modules/apiHelper";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { placeHolderImages, s3Images } from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

// const tile_Items = [
//   {
//     id: 1,
//     text: t("Get Shredded"),
//     value: "Get Shredded",
//     icon: s3Images.goalPage[0],
//     placeHolderImage: placeHolderImages.goalPage[0],
//   },
//   {
//     id: 2,
//     text: t("Muscle Gain"),
//     value: "Muscle Gain",
//     icon: s3Images.goalPage[1],
//     placeHolderImage: placeHolderImages.goalPage[1],
//   },
//   {
//     id: 3,
//     text: t("Weight Loss"),
//     value: "Weight Loss",
//     icon: s3Images.goalPage[2],
//     placeHolderImage: placeHolderImages.goalPage[2],
//   },
// ];

const tile_Items = [
  {
    id: 1,
    text: t("Cut"),
    value: "Cut",
    icon: s3Images.goalPage[2],
    placeHolderImage: placeHolderImages.goalPage[2],
  },
  {
    id: 2,
    text: t("Bulk"),
    value: "Bulk",
    icon: s3Images.goalPage[1],
    placeHolderImage: placeHolderImages.goalPage[1],
  },
  {
    id: 3,
    text: t("Extra Bulk"),
    value: "Extra Bulk",
    icon: s3Images.goalPage[0],
    placeHolderImage: placeHolderImages.goalPage[0],
  },
];

const Goal = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const gotoNextPage = (item) => {
    location.state?.isSettings
      ? updatePlanData(navigate, dispatch, { primaryGoal: item?.value })
      : // : nextPageRoute(
        //     navigate,
        //     Part_Two_Two,
        //     dispatch,
        //     pagePreloadImages.goalPage
        //   );
        nextPageRoute(navigate, Part_Two_Three);
  };

  const handleSelect = (item) => {
    dispatch(
      setDetailsData({ primaryGoal: { text: item.value, id: item.id } })
    );
    gotoNextPage(item);
  };

  React.useEffect(() => {
    if (location.state?.data) {
      const selectedItem = tile_Items.filter(
        (item) => item.value.toLowerCase() === location.state.data.toLowerCase()
      );
      if (selectedItem.length > 0) {
        setSelected(selectedItem[0]);
      }
    }
  }, []);

  return (
    <BigIconLayout
      titleClasses={"custom-goal-title"}
      title={<Trans i18nKey={"GP_header"} components={{ tag: <br /> }} />}
      items={tile_Items}
      handleSelect={handleSelect}
      selected={selected?.id}
    />
  );
};

export default Goal;
