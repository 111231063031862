import React from "react";
import Header from "../components/Header";
import BottomTabLayout from "./BottomTabLayout";
import { useLocation } from "react-router-dom";
import {
  Manage_Subscription,
  Plan_Settings_Page,
} from "../constants/pageRouteName";

const CourseLayout = ({ children }) => {
  const location = useLocation();

  if (location.pathname === Plan_Settings_Page) {
    return children;
  }

  if (location.pathname === Manage_Subscription) {
    return children;
  }

  return (
    <>
      <div
        className="position-relative scrollbar-hidden overflow-y-scroll w-100"
        style={{ height: "calc(100dvh - 60px)" }}
        id="scroll-div"
      >
        {location.pathname !== Plan_Settings_Page && (
          <Header showLoginBtn={false} showMenuBtn={true} />
        )}
        {children}
        {location.pathname !== Plan_Settings_Page && <BottomTabLayout />}
      </div>
    </>
  );
};

export default CourseLayout;
