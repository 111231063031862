import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Part_Two_One } from "../../../constants/pageRouteName";
import BigIconLayout from "../../../layouts/BigIconLayout";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import {
  pagePreloadImages,
  placeHolderImages,
  s3Images,
} from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const tile_Items = [
  {
    id: 1,
    text: t("Skinny"),
    value: "Skinny",
    placeHolderImage: placeHolderImages.actualBody[0],
    icon: s3Images.actualBody[0],
  },
  {
    id: 2,
    text: t("Average"),
    value: "Average",
    placeHolderImage: placeHolderImages.actualBody[1],
    icon: s3Images.actualBody[1],
  },
  {
    id: 3,
    text: t("Overweight"),
    value: "Overweight",
    placeHolderImage: placeHolderImages.actualBody[2],
    icon: s3Images.actualBody[2],
  },
];

const ActualBody = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const gotoNextPage = (item) => {
    // location.state?.isSettings
    //   ? updatePlanData(navigate, dispatch, { primaryGoal: item?.value })
    nextPageRoute(navigate, Part_Two_One, dispatch, pagePreloadImages.agePage);
  };

  const handleSelect = (item) => {
    dispatch(
      setDetailsData({ actualBodyType: { text: item.value, id: item.id } })
    );
    gotoNextPage(item);
  };

  return (
    <BigIconLayout
      titleClasses={"custom-goal-title"}
      title={<Trans i18nKey={"AB_header"} components={{ tag: <br /> }} />}
      items={tile_Items}
      handleSelect={handleSelect}
      selected={selected?.id}
    />
  );
};

export default ActualBody;
