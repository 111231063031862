// i18n.js
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {
  enTranslation,
  privacy_policy_en_Translation,
  terms_en_Translation,
} from "./i18n/translations/en";
import {
  esTranslation,
  privacy_policy_es_Translation,
  terms_es_Translation,
} from "./i18n/translations/es";
import {
  privacy_policy_pt_Translation,
  ptTranslation,
  terms_pt_Translation,
} from "./i18n/translations/pt";
import {
  privacy_policy_tr_Translation,
  terms_tr_Translation,
  trTranslation,
} from "./i18n/translations/tr";

const supported_languages = ["en", "tr", "pt", "es"];

const resources = {
  en: {
    translation: {
      ...enTranslation,
      ...terms_en_Translation,
      ...privacy_policy_en_Translation,
    },
  },
  tr: {
    translation: {
      ...trTranslation,
      ...terms_tr_Translation,
      ...privacy_policy_tr_Translation,
    },
  },
  pt: {
    translation: {
      ...ptTranslation,
      ...terms_pt_Translation,
      ...privacy_policy_pt_Translation,
    },
  },
  es: {
    translation: {
      ...esTranslation,
      ...terms_es_Translation,
      ...privacy_policy_es_Translation,
    },
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === "development",
    // debug: false,
    // supportedLngs: ["en", "tr", "pt"],
    supportedLngs: supported_languages,
    detection: {
      order: ["navigator"],
      convertDetectedLanguage: (props) => {
        const lang = props.split("-");
        const urlLang = window.location.pathname.split("/")[1];
        if (supported_languages.includes(urlLang)) {
          return urlLang;
        }
        return lang[0];
      },
    },
    resources,
  });

export default i18next;
