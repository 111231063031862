export const Plan_Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g id="_3" data-name="3" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="#0045ff"
      />
      <path
        id="Icon_fa-regular-circle-user"
        data-name="Icon fa-regular-circle-user"
        d="M12.7,12.487A4,4,0,0,0,9,10H7a4,4,0,0,0-3.7,2.487,6.5,6.5,0,1,1,9.406,0ZM11.45,13.509a6.508,6.508,0,0,1-6.9,0A2.5,2.5,0,0,1,7,11.5H9a2.5,2.5,0,0,1,2.453,2.009ZM8,16A8,8,0,1,0,0,8,8,8,0,0,0,8,16ZM8,7.5A1.25,1.25,0,1,1,9.25,6.25,1.25,1.25,0,0,1,8,7.5ZM5.25,6.25A2.75,2.75,0,1,0,8,3.5,2.75,2.75,0,0,0,5.25,6.25Z"
        transform="translate(45 195)"
        fill="#fff"
      />
    </g>
  </svg>
);
export const Support_Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g id="_1" data-name="1" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="#0045ff"
      />
      <path
        id="_1_1"
        data-name="1_1"
        d="M16.563,8.563a8,8,0,1,1-8-8A8,8,0,0,1,16.563,8.563ZM8.777,3.208a4.178,4.178,0,0,0-3.76,2.057.388.388,0,0,0,.088.524l1.119.849a.387.387,0,0,0,.538-.068c.576-.731.971-1.155,1.848-1.155.659,0,1.474.424,1.474,1.063,0,.483-.4.731-1.049,1.1C8.276,8,7.272,8.528,7.272,9.853v.129a.387.387,0,0,0,.387.387H9.466a.387.387,0,0,0,.387-.387V9.939c0-.918,2.683-.956,2.683-3.441C12.536,4.627,10.6,3.208,8.777,3.208Zm-.215,8a1.484,1.484,0,1,0,1.484,1.484A1.486,1.486,0,0,0,8.563,11.208Z"
        transform="translate(44.438 194.438)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const Policy_Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g id="_2" data-name="2" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="#0045ff"
      />
      <path
        id="_2_1"
        data-name="2_1"
        d="M8.563.563a8,8,0,1,0,8,8A8,8,0,0,0,8.563.563Zm0,3.548A1.355,1.355,0,1,1,7.208,5.466,1.355,1.355,0,0,1,8.563,4.111ZM10.369,12.3a.387.387,0,0,1-.387.387H7.143a.387.387,0,0,1-.387-.387V11.53a.387.387,0,0,1,.387-.387H7.53V9.079H7.143a.387.387,0,0,1-.387-.387V7.917a.387.387,0,0,1,.387-.387H9.208a.387.387,0,0,1,.387.387v3.226h.387a.387.387,0,0,1,.387.387Z"
        transform="translate(44.437 194.437)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const SubSciption_Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g id="_4" data-name="4" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="#0045ff"
      />
      <path
        id="_4_1"
        data-name="4_1"
        d="M18.051,11.848l-.323-.187a1.228,1.228,0,0,1,0-2.127l.323-.187a1.841,1.841,0,0,0,.674-2.517l-.614-1.064a1.844,1.844,0,0,0-2.517-.674l-.323.186a1.228,1.228,0,0,1-1.842-1.064V3.842A1.845,1.845,0,0,0,11.587,2H10.358A1.845,1.845,0,0,0,8.516,3.842v.373A1.228,1.228,0,0,1,6.674,5.279l-.323-.187a1.845,1.845,0,0,0-2.517.674L3.22,6.83a1.841,1.841,0,0,0,.674,2.517l.324.187a1.228,1.228,0,0,1,0,2.127l-.323.187a1.841,1.841,0,0,0-.674,2.517l.614,1.064a1.844,1.844,0,0,0,2.517.674l.323-.186A1.228,1.228,0,0,1,8.516,16.98v.373a1.845,1.845,0,0,0,1.842,1.842h1.228a1.845,1.845,0,0,0,1.842-1.842v-.373a1.228,1.228,0,0,1,1.842-1.064l.323.187a1.845,1.845,0,0,0,2.517-.674l.614-1.064A1.841,1.841,0,0,0,18.051,11.848Zm-7.078,1.82a3.07,3.07,0,1,1,3.07-3.07,3.074,3.074,0,0,1-3.07,3.07Z"
        transform="translate(42.028 192.403)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const Logout_Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g id="_5" data-name="5" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="red"
      />
      <g id="_5_1" data-name="5_1" transform="translate(39.525 190.525)">
        <path
          id="Path_4265"
          data-name="Path 4265"
          d="M18.465,7.741a.805.805,0,0,0-.521-.19.8.8,0,0,0-.8.87.8.8,0,0,0,.282.547A6.841,6.841,0,1,1,6.111,14.15,6.779,6.779,0,0,1,8.53,8.968a.8.8,0,0,0,.093-1.134.8.8,0,0,0-1.13-.093,8.377,8.377,0,0,0-2.993,6.4,8.475,8.475,0,1,0,13.965-6.4Z"
          transform="translate(0 -1.136)"
          fill="#fff"
        />
        <path
          id="Path_4266"
          data-name="Path 4266"
          d="M17.579,13.791a.818.818,0,0,0,.817-.817V5.317a.817.817,0,0,0-1.633,0v7.658A.818.818,0,0,0,17.579,13.791Z"
          transform="translate(-4.565)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
