import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import AuthSlice from "./slices/AuthSlice";
import DetailsSlice from "./slices/DetailsSlice";
import ImageSlice from "./slices/ImageSlice";
import MealPlanSlice from "./slices/MealPlanSlice";
import planSettingSlice from "./slices/PlanSettingSlice";

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

const detailPersistConfig = {
  key: "gainmuscledetails",
  storage,
};

const reducers = combineReducers({
  authState: AuthSlice,
  planSettingState: planSettingSlice,
  mealState: MealPlanSlice,
  imageState: ImageSlice,
  detailState: persistReducer(detailPersistConfig, DetailsSlice),
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
