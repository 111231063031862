import React from "react";

const Loader = ({ className }) => {
  return (
    <div
      className={
        "spinner-border mt-3 text-custom-primary " + className
      }
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default Loader;