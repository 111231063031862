import i18next, { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { Part_Three_Ten } from "../../../constants/pageRouteName";
import { convertToCm, convertToFtAndInch } from "../../../modules/Calculator";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { nextPageRoute } from "../../../utils/navigationTransition";

const Height = () => {
  const [activeMode, setActiveMode] = React.useState("cm");
  const [heightCM, setHeightCM] = React.useState("");
  const [heightFT, setHeightFT] = React.useState({ feet: "", inches: "" });
  const [error, setError] = React.useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cmRef = React.useRef(null);
  const ftRef = React.useRef(null);
  const inchRef = React.useRef(null);

  const handleInput = (e, valueType) => {
    if (activeMode === "cm") {
      if (e.target.value.length <= 3) {
        if (e.target.value === 0) {
          setHeightCM(null);
          setHeightFT(null);
        } else {
          setHeightCM(e.target.value);
          setHeightFT(convertToFtAndInch(e.target.value));
        }
      }
    } else {
      if (valueType === "ft") {
        if (e.target.value.length <= 1) {
          setHeightFT({ ...heightFT, feet: e.target.value });
          setHeightCM(convertToCm(e.target.value, heightFT.inches));

          if (e.target.value.length === 1) {
            inchRef.current.focus();
          }
        } else {
          inchRef.current.focus();
        }
      } else {
        if (Number(e.target.value) < 12) {
          setHeightFT({ ...heightFT, inches: e.target.value });
          setHeightCM(convertToCm(heightFT.feet, e.target.value));
        }
        if (e.target.value.length === 0) {
          ftRef.current.focus();
        }
      }
    }
  };

  const handleMode = (mode) => {
    setActiveMode(mode);
  };

  const gotoNextPage = () => {
    if (
      heightFT.feet >= 4 &&
      heightFT.feet <= 7 &&
      heightFT.inches >= 0 &&
      heightFT.inches <= 11
    ) {
      nextPageRoute(
        () => navigate(Part_Three_Ten, { state: { height: heightCM } }),
        Part_Three_Ten
      );
    } else {
      handleError("Enter a value from 4ft to 7ft 11in");
    }
  };

  const handleError = (errorMSG) => {
    setError(errorMSG);
  };

  React.useEffect(() => {
    if (activeMode === "cm") {
      heightCM &&
        dispatch(
          setDetailsData({
            height: heightCM + " cm",
          })
        );
    } else {
      (heightFT.feet || heightFT.inches) &&
        dispatch(
          setDetailsData({
            height:
              heightFT.feet.toString().replace(" ", "") +
              "ft " +
              heightFT.inches.toString().replace(" ", "") +
              "in",
          })
        );
    }
    if (
      (parseInt(heightFT.feet) >= 4 &&
        parseInt(heightFT.feet) <= 7 &&
        parseInt(heightFT.inches) >= 0 &&
        parseInt(heightFT.inches) <= 11) ||
      (heightCM >= 122 && heightCM < 243)
    ) {
      setError(null);
    } else {
      if (activeMode === "ft") {
        setError(t("HT_error_ft"));
      } else {
        setError(t("HT_error_cm"));
      }
    }
  }, [heightFT, activeMode]);

  const showValue = () => {
    if (activeMode === "cm") {
      return (
        <div className="w-100 d-flex justify-content-center align-items-end input-container">
          <div className="input-div">
            <input
              ref={cmRef}
              inputMode="numeric"
              placeholder="0"
              type="number"
              className="height-input fw-bold text-inter text-custom-white"
              value={heightCM}
              style={{
                minWidth: `${heightCM ? String(heightCM).length : 1}ch`,
                width: `${heightCM ? heightCM.length : 1}ch`,
              }}
              onChange={handleInput}
            />
          </div>
          <p className="unit-text fw-bold text-inter mb-4">cm</p>
        </div>
      );
    } else {
      return (
        <div className="w-100 d-flex justify-content-center align-content-center">
          <div className=" d-flex justify-content-center align-items-end">
            <input
              ref={ftRef}
              type="number"
              inputMode="numeric"
              placeholder="0"
              className="height-input fw-bold text-inter text-custom-white"
              value={heightFT.feet}
              onChange={(e) => handleInput(e, "ft")}
              style={{
                width: `${heightFT.feet ? String(heightFT.feet).length : 1}ch`,
              }}
            />
            <p className="unit-text fw-bold text-inter mb-4">{t("ft")}</p>
          </div>
          <div className=" d-flex justify-content-center align-items-end ms-1">
            <input
              ref={inchRef}
              placeholder="0"
              inputMode="numeric"
              step={"0.1"}
              type="number"
              className="height-input fw-bold text-inter text-custom-white"
              value={heightFT.inches}
              maxLength={2}
              max={11}
              onChange={(e) => handleInput(e, "in")}
              style={{
                width: `${
                  heightFT.inches ? String(heightFT.inches).length : 1
                }ch`,
              }}
            />
            <p className="unit-text fw-bold text-inter mb-4">in</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex-grow-1 d-flex flex-column align-content-center main-padding pt-2 pb-3 gap-4">
      <h1 className="height-header my-0 text-inter fw-bold text-center mb-4">
        {t("HT_title")}
      </h1>

      <div className="w-100 d-flex flex-column pt-3">
        <div className="height-button-group">
          <button
            onClick={() => {
              handleMode("cm");
            }}
            className={`height-unit-button text-inter text-custom-white fw-bold ${
              activeMode === "cm" ? "active-unit-button" : ""
            }`}
          >
            cm
          </button>
          <button
            onClick={() => {
              handleMode("ft");
            }}
            className={`height-unit-button text-inter text-custom-white fw-bold ${
              activeMode === "ft" ? "active-unit-button" : ""
            }`}
          >
            {t("ft")}
          </button>
        </div>
        <div className="w-100 d-flex justify-content-center">{showValue()}</div>
      </div>
      <div className="w-100">
        <div className="help-text-banner m-0">
          <p className="text-inter text-center fw-bold help-text-banner-title">
            {t("HT_text_1")}
          </p>
          <p className="text-inter height-banner-text-responsive text-center fw-regular help-text-banner-subtitle m-0">
            {t("HT_text_2")}
            {i18next.language !== "es" && <br />}
            {t("HT_text_3")}
          </p>
        </div>
        {error && <p className="my-2 text-danger text-center">{error}</p>}
        <Button
          label={t("CONTINUE")}
          classNames={!error ? "mt-4" : "m-0"}
          disabled={error}
          onClick={gotoNextPage}
        />
      </div>
    </div>
  );
};

export default Height;
