import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { Privacy_Policy, Terms_Conditions } from "../constants/pageRouteName";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import Terms from "../pages/legal/Terms";
import NotFound from "../pages/not-found/NotFound";
import { persistor, store } from "../redux/store";
import ProtectedRoute from "./ProtectedRoute";
import { routes } from "./routes";

const Router = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  element={
                    <ProtectedRoute {...route}>
                      {route.component}
                    </ProtectedRoute>
                  }
                />
              ))}
              <Route path={Privacy_Policy} element={<PrivacyPolicy />} />
              <Route path={Terms_Conditions} element={<Terms />} />
              <Route path={"*"} element={<NotFound />} />
            </Routes>
            <ToastContainer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  );
};

export default Router;
