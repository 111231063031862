export const convertToLBS = (kg) => {
  let weight = Number(kg);
  return (weight * 2.20462).toFixed(0);
};

export const convertToKG = (lbs) => {
  let weight = Number(lbs);
  return (weight / 2.20462).toFixed(0);
};

export const calculateBMI = (weight, height) => {
  let lbs = Number(weight);
  let inches = Number(height);
  return ((lbs / (inches * inches)) * 703).toFixed(2);
};

export const convertToCm = (feet, inches) => {
  let inch = Number(inches);
  let ft = Number(feet);
  const totalInches = Number(ft * 12 + inch);
  const cm = (totalInches * 2.54).toFixed(0);

  return Number(cm);
};

export const convertToFtAndInch = (cm) => {
  let cmValue = Number(cm);
  const totalInches = cmValue / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = (totalInches % 12).toFixed(0);
  return { feet: Number(feet), inches: Number(inches) };
};

export const calculateLBSwithPoint = (kg) => {
  let weight = Number(kg);
  return weight * 2.20462;
};

export const calculateInches = (values, type) => {
  if (type === "cm") {
    let cm = Number(values);
    const totalInches = cm / 2.54;

    return totalInches;
  } else {
    let ft = Number(values.feet);
    let inch = Number(values.inches);
    const totalInches = ft * 12 + inch;
    return totalInches;
  }
};

export const calculateDailyCaloryIntake = (
  weight,
  height,
  age,
  sedentary,
  walk
) => {
  if (!weight || !height || !age || !sedentary || !walk) {
    if (weight) {
      const convertedWeight = weight?.includes("lbs")
        ? convertToKG(Number(weight?.replace(" lbs", "")?.replace(" ", "")))
        : Number(weight?.replace(" kg", "")?.replace(" ", ""));

      return (convertedWeight * 66).toFixed(0);
    }
    return 1500;
  }

  const convertedWeight = (
    weight.includes("lbs")
      ? Number(convertToKG(Number(weight.replace(" lbs", "").replace(" ", ""))))
      : Number(weight.replace(" kg", "").replace(" ", ""))
  ).toFixed(0);
  // const convertedWeight = weight.includes("lbs")
  //   ? Number(weight.replace(" lbs", "").replace(" ", "")).toFixed(0)
  //   : Number(
  //       convertToLBS(Number(weight.replace(" kg", "").replace(" ", "")))
  //     ).toFixed(0);

  // const convertedHeight = height.includes("ft")
  //   ? convertToCm(
  //       Number(height.split("ft ")[0]),
  //       Number(height.split("ft ")[1].split("in")[0])
  //     )
  //   : Number(height.replace(" cm", "").replace(" ", ""));
  const convertedHeight = (
    height.includes("ft")
      ? calculateInches(
          {
            feet: Number(height.split("ft ")[0]),
            inches: Number(height.split("ft ")[1].split("in")[0]),
          },
          "ft"
        )
      : calculateInches(
          Number(height.replace(" cm", "").replace(" ", "")),
          "cm"
        )
  ).toFixed(0);

  const convertedAge = (
    age.includes("50+")
      ? Number(age.split("Age ")[1]?.replace("+", ""))
      : (Number(age.split("Age ")[1]?.split("-")[0]) +
          Number(age.split("Age ")[1]?.split("-")[1])) /
        2
  ).toFixed(0);

  // const BMR =
  //   10 * convertedWeight + 6.25 * convertedHeight - 5 * convertedAge + 5;
  const BMR =
    655 + 9.6 * convertedWeight + 1.8 * convertedHeight - 4.7 * convertedAge;

  // const BMR =
  //   66 +
  //   6.2 * convertedWeight +
  //   12.7 * convertedHeight -
  //   // 6.76 * convertedAge;
  //   4.7 * 21;

  let TDEE = 0;

  if (walk.toLowerCase() === "less than 1 hour") {
    TDEE = BMR * 1.375;
  } else if (walk.toLowerCase() === "1-2 hours") {
    TDEE = BMR * 1.55;
  } else if (walk.toLowerCase() === "more than 2 hours") {
    TDEE = BMR * 1.725;
  } else if (sedentary.toLowerCase() === "yes") {
    TDEE = BMR * 1.2;
  } else {
    TDEE = BMR * 1.55;
  }

  // return TDEE.toFixed(0);
  return (TDEE + TDEE * 0.15).toFixed(0);
};

// export const calculateDailyWaterIntake = (weight) => {
//   const convertedWeight = weight.includes("lbs")
//     ? Number(convertToKG(Number(weight.replace(" lbs", "").replace(" ", ""))))
//     : Number(weight.replace(" kg", "").replace(" ", ""));

//   let waterIntake = convertedWeight * 0.03;
//   console.log("🚀 ~ calculateDailyWaterIntake ~ waterIntake:", waterIntake);

//   return waterIntake.toFixed(2) = 0 ? 3.7 : waterIntake.toFixed(2);
// };
export const calculateDailyWaterIntake = (caloryIntake) => {
  // const convertedWeight = weight.includes("lbs")
  //   ? Number(convertToKG(Number(weight.replace(" lbs", "").replace(" ", ""))))
  //   : Number(weight.replace(" kg", "").replace(" ", ""));
  // let waterIntake = convertedWeight * 0.03;
  // console.log("🚀 ~ calculateDailyWaterIntake ~ waterIntake:", waterIntake);
  // return waterIntake.toFixed(2) = 0 ? 3.7 : waterIntake.toFixed(2);
  return ((caloryIntake * 1) / 1000).toFixed(2) === 0
    ? 3.7
    : ((caloryIntake * 1) / 1000).toFixed(2);
};
