import React from "react";
import { CheckedIcon } from "../assets/svgs/partTwo";

const CheckBoxTile = ({ icon, handleSelection, selected, text, id }) => {
  return (
    <button
      onClick={() => handleSelection(id, selected)}
      className={`w-100 d-flex justify-content-between align-items-center checkbox-tile px-3 ${
        selected ? "active-checkbox-tile" : ""
      }`}
    >
      <div className="d-flex justify-content-center align-items-center gap-2">
        {icon && <div>{icon()}</div>}
        <p className="m-0 text-white fw-bold checkbox-tile-text text-start">
          {text}
        </p>
      </div>
      <div
        className={`d-flex justify-content-center align-items-center me-1 ${
          selected ? "active-tile-checkbox" : "tile-checkbox"
        }`}
      >
        {selected && CheckedIcon()}
      </div>
    </button>
  );
};

export default React.memo(CheckBoxTile);
