import i18next, { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import MobileView from "../../layouts/MobileView";
import { addS3Url } from "../../modules/s3";
import { failureToast } from "../../modules/toaster";
import { checkLogin } from "../../redux/slices/AuthSlice";
import ApiService from "../../service/api";
import { isValidArray } from "../../utils/valid";

const Tile = ({ title, id, subtitle, isLine }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${i18next.language}/course/${id}`);
  };

  return (
    <>
      <button
        className={`course-tile w-100 py-3 ${isLine ? "course-border-t" : ""}`}
        onClick={handleClick}
      >
        <p className="course-name fw-bold text-inter m-0 text-start">{title}</p>
        <p className="course-subTitle fw-regular text-inter m-0 text-start">
          {subtitle}
        </p>
      </button>
    </>
  );
};

const Course = () => {
  const [courseList, setCourseList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

  const fetchCourses = async () => {
    await ApiService.getCourseList()
      .then((res) => {
        if (res.status === 200) {
          setCourseList([...res.data]);
        } else if (res.status === 202) {
          setError(res.data.message);
          setTimeout(() => {
            localStorage.removeItem("token");
            dispatch(checkLogin());
          }, 6000);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err?.request?.status === 401) {
          // dispatch(logoutState());
        } else {
          failureToast(t("Something went wrong."));
        }
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <>
      <MobileView>
        <div
          className="flex-grow-1 main-padding d-flex flex-column align-items-center"
          style={{ paddingTop: "36px" }}
        >
          <img
            src={addS3Url("/Big_Logo.png")}
            alt="logo"
            className="course-logo"
          />
          <h1 className="course-title fw-bold text-inter text-capitalize">
            {t("CP_Title")}
          </h1>
          <div className="course-container px-3 w-100">
            {error && <h6 className="mt-5 text-main px-4">{error}</h6>}
            {loading ? (
              <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : isValidArray(courseList) ? (
              courseList?.map((course, index) => (
                <Tile {...course} key={index} isLine={index !== 0} />
              ))
            ) : (
              <p
                className="text-inter text-center m-0 p-4 text-custom-white fw-bold"
                style={{ fontSize: "15px" }}
              >
                {t("CP_NO_Courses")}
              </p>
            )}
          </div>
        </div>
      </MobileView>
      <Footer />
    </>
  );
};

export default Course;
