import { useRecurly } from "@recurly/react-recurly";
import { t } from "i18next";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplePay_Svg } from "../../../assets/svgs/payment";
import Loader from "../../../components/Loader";
import GatewayFeatures from "../../../components/payment/GatewayFeatures";
import PaypalElement from "../../../components/payment/PaypalElement";
import TimerNotification from "../../../components/payment/TimerNotification";
import Transformation from "../../../components/payment/Transformation";
import TryGainmuscle from "../../../components/payment/TryGainmuscle";
import { Login_Page, Otp_Page } from "../../../constants/pageRouteName";
import { generateRandomCode } from "../../../modules/randomCode";
import { failureToast, successToast } from "../../../modules/toaster";
import ApiService from "../../../service/api";

const PaymentNew = () => {
  const recurly = useRecurly();
  const navigate = useNavigate();
  const location = useLocation();
  const risk = recurly.Risk();
  const email = new URLSearchParams(location.search).get("email");
  const currentYear = moment().year().toString().slice(2, 4);
  const stateEmail = email || location?.state?.email;
  const cardNumberRef = React.useRef();
  const cardMonthRef = React.useRef();
  const cardYearRef = React.useRef();
  const cardCvvRef = React.useRef();
  const emailRef = React.useRef();
  const formRef = React.useRef();
  const [isPaymentProcessing, setPaymentProcessing] = React.useState(false);
  const [cardNumberError, setCardNumberError] = React.useState(false);
  const [cardMonthError, setCardMonthError] = React.useState(false);
  const [loadingPayment, setLoadingPayment] = React.useState(false);
  const [googlePaymentProcessing, setGooglePaymentProcessing] =
    React.useState(false);
  const [currentPlan, setCurrentPlan] = React.useState("7-day");
  const [cardYearError, setCardYearError] = React.useState(false);
  const [appleAvailable, setAppleAvailable] = React.useState("");
  const [cardCvvError, setCardCvvError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [cardNumber, setCardNumber] = React.useState("");
  const [paypalPlan, setPaypalPlan] = React.useState("");
  const [isSupport, setIsSupport] = React.useState(true);
  const [gpayIsSupport, setGpayIsSupport] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [cardMonth, setCardMonth] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [unitPrice, setUnitPrice] = React.useState(0);
  const [lastName, setLastName] = React.useState("");
  const [cardYear, setCardYear] = React.useState("");
  const [cardCvv, setCardCvv] = React.useState("");
  const [tab, setTab] = React.useState(false);

  const nameString = stateEmail?.split("@")[0];
  const divideStringIntoHalf = () => {
    const length = nameString?.length;
    const middle = Math.floor(length / 2);

    const firstHalf = nameString?.slice(0, middle);
    const secondHalf = nameString?.slice(middle);
    setFirstName(firstHalf);
    setLastName(secondHalf);
  };

  React.useEffect(() => {
    !stateEmail && navigate(Login_Page);
    divideStringIntoHalf();
  }, [stateEmail]);

  React.useEffect(() => {
    const fetchPlans = async () => {
      await ApiService.getRecurlyPrice().then(async (response) => {
        if (response.status === 200) {
          const priceList = response?.data?.data?.plan_list;
          const currentUnitPrice =
            priceList?.length > 0 &&
            priceList?.find((plans) => plans.plan_code === currentPlan);
          setUnitPrice(currentUnitPrice?.unit_amount);
          setPaypalPlan(currentUnitPrice?.paypal_code);
          setCurrentPlan(currentUnitPrice?.plan_code);
        }
      });
    };
    fetchPlans();
  }, []);

  const handleCardNumberChange = (e) => {
    const inputVal = e.target.value;
    const inputCardNumber = e.target.value.replace(/\D/g, "");
    const formattedCardNumber = inputCardNumber
      .replace(/(\d{4})/g, "$1 ")
      .trim();
    setCardNumber(formattedCardNumber);
    const checkVal = inputVal.split(" ").join("");
    const length = checkVal.length;

    !inputVal || length < 15 || length > 16
      ? setCardNumberError(true)
      : setCardNumberError(false);

    if (length === 16) {
      cardMonthRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardMonthChange = (e) => {
    const length = e.target.value.length;
    setCardMonth(e.target.value);
    length < 2 || e.target.value > 12
      ? setCardMonthError(true)
      : setCardMonthError(false);
    if (length === 2 && e.target.value <= 12) {
      cardYearRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardYearChange = (e) => {
    const length = e.target.value.length;
    setCardYear(e.target.value);
    length < 2 || e.target.value < currentYear
      ? setCardYearError(true)
      : setCardYearError(false);
    if (length === 2 && e.target.value >= currentYear) {
      cardCvvRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardCvvChange = (e) => {
    const length = e.target.value.length;
    setCardCvv(e.target.value);
    length < 3 ? setCardCvvError(true) : setCardCvvError(false);
    if (length === 3) {
      setCardCvvError(false);
    }
  };

  const handleThreeDSPayment = async (formData) => {
    setLoading(true);
    try {
      await ApiService.createSubscription(formData)
        .then(async (response) => {
          if (response.status === 200) {
            await ApiService.loginUser({
              email: stateEmail.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  handleTags();
                  localStorage.setItem("email", stateEmail.toLowerCase());
                  successToast(res?.data?.message);
                  navigateToOtp();
                  setLoading(false);
                }
              })
              .catch((err) => {
                failureToast(err?.response?.data);
                setLoading(false);
              });
          }
        })
        .catch((error) => {
          failureToast(
            error?.response?.data?.error ??
              error?.response?.data?.message ??
              error?.response?.data ??
              "Something went wrong"
          );
          setLoading(false);
        });
    } catch (e) {
      console.log(e.err);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let showErr = false;
    if (!recurly && showErr) {
      return;
    }
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("number", cardNumber);
    formData.append("month", cardMonth);
    formData.append("year", cardYear);
    formData.append("cvv", cardCvv);
    formData.append("email", stateEmail);
    formData.append("three_d_secure_action_required", true);
    formData.append("key", process.env.REACT_APP_RECURLY_KEY);

    await ApiService.getRecurlyToken(formData)
      .then((response) => {
        if (
          !cardNumberError &&
          !cardYearError &&
          !cardMonthError &&
          !cardCvvError &&
          !response.message
        ) {
          setLoading(true);
          const code = generateRandomCode();
          const token = response?.data?.id;
          if (token) {
            try {
              ApiService.createSubscription({
                email: stateEmail.toLowerCase(),
                token: token,
                code: code,
                plan_code: currentPlan,
                payment_type: "recurly",
              })
                .then(async (response) => {
                  if (response.status === 201) {
                    setLoading(true);
                    const threeDSecure = risk.ThreeDSecure({
                      actionTokenId: response?.data?.action_token_id,
                    });
                    threeDSecure.on("error", (err) => {
                      setLoading(false);
                      console.log("threeDSecure err", err);
                      failureToast("Payment Rejected");
                    });

                    threeDSecure.on("token", (threeDsToken) => {
                      setLoading(true);
                      handleThreeDSPayment({
                        email: stateEmail.toLowerCase(),
                        token: token,
                        plan_code: currentPlan,
                        three_d_secure_action_result_token_id: threeDsToken?.id,
                        code: code,
                        accountCode: response?.data?.accountCode,
                        accountId: response?.data?.accountId,
                        payment_type: "recurly",
                      });
                    });

                    threeDSecure.attach(document.getElementById("3D-Secure"));
                  }
                  if (response.status === 200) {
                    await ApiService.loginUser({
                      email: stateEmail.toLowerCase(),
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          handleTags();
                          localStorage.setItem("email", stateEmail);
                          successToast(res?.data?.message);
                          navigateToOtp();
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        failureToast(err?.response?.data);
                        setLoading(false);
                      });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  failureToast(
                    error?.response?.data?.error ??
                      error?.response?.data?.message ??
                      error?.response?.data ??
                      "Something went wrong"
                  );
                  setLoading(false);
                });
            } catch (e) {
              console.log(e);
              setLoading(false);
              console.log(e.err);
            }
          }
        } else {
          if (
            response?.message ||
            (!cardNumberError &&
              !cardYearError &&
              !cardMonthError &&
              !cardCvvError)
          ) {
            failureToast("Card number cannot be a test card number");
          }
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  React.useEffect(() => {
    const applePayOptions = {
      requiredBillingAddressFields: ["all"],
      requiredShippingContactFields: ["phone", "email", "postalAddress"],
      countryCode: "US",
    };

    recurly.configure({
      applePay: applePayOptions,
    });
  }, [recurly]);

  React.useEffect(() => {
    if (!window.ApplePaySession) {
      if (appleAvailable !== "Not Available") {
        setIsSupport(false);
        setAppleAvailable("Not Available");
      }
      return;
    }
  }, [isPaymentProcessing]);

  const sendGoogleData = async (token) => {
    console.log("sendGoogleData", token);
    setGooglePaymentProcessing(true);
    const code = generateRandomCode();
    console.log("send google data token", token, code);

    try {
      console.log("subscription started", email, token, code, currentPlan);
      await ApiService.createSubscription({
        email: stateEmail.toLowerCase(),
        code: code,
        plan_code: currentPlan,
        payment_type: "recurly",
      })
        .then(async (response) => {
          console.log("createSubscription response", response);
          if (response.status === 200) {
            console.log("subscription status 200", response);
            await ApiService.loginUser({
              email: stateEmail.toLowerCase(),
            })
              .then((res) => {
                console.log("loginUser response", res);
                if (res.status === 200) {
                  console.log("user logged in status 200", res);
                  handleTags();
                  localStorage.setItem("email", stateEmail);
                  successToast(res?.data?.message);
                  navigateToOtp();
                  setGooglePaymentProcessing(false);
                }
                console.log("user logged in status another", res);
                failureToast(res?.data?.message);
              })
              .catch((err) => {
                console.log("user logged in error", err);
                failureToast(err?.response?.data);
                setGooglePaymentProcessing(false);
              });
          }
          console.log("subscription status ", response.status, response);
        })
        .catch((error) => {
          console.log("subscription error", error);
          failureToast(error?.response?.data?.message || error.message);
          setGooglePaymentProcessing(false);
        });
    } catch (e) {
      console.log("subscription started error", e);
      setGooglePaymentProcessing(false);
    }
    setGooglePaymentProcessing(false);
  };

  const sendData = async (token) => {
    setLoadingPayment(true);
    const code = generateRandomCode();

    try {
      await ApiService.createSubscription({
        email: stateEmail.toLowerCase(),
        token: token.id,
        code: code,
        plan_code: currentPlan,
        payment_type: "recurly",
      })
        .then(async (response) => {
          if (response.status === 200) {
            await ApiService.loginUser({
              email: stateEmail.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  handleTags();
                  localStorage.setItem("email", stateEmail);
                  successToast(res?.data?.message);
                  navigateToOtp();
                  setLoadingPayment(false);
                }
              })
              .catch((err) => {
                failureToast(err?.response?.data);
                setLoadingPayment(false);
              });
          }
        })
        .catch((error) => {
          failureToast(error?.response?.data);
          setLoadingPayment(false);
        });
    } catch (e) {
      setLoadingPayment(false);
    }
    setPaymentProcessing(false);
    setLoadingPayment(false);
  };

  const applePay = recurly.ApplePay({
    country: "US",
    currency: "USD",
    label: "GainMuscle.app",
    total: `${unitPrice}`,
  });

  const handleAppleClick = (event) => {
    event.preventDefault();
    setTab(false);
    if (!isSupport) {
      failureToast("Your device or browser is not compatible with Apple Pay.");
      return;
    } else if (isSupport && applePay && stateEmail) {
      applePay?.ready(function () {
        applePay?.begin();
      });

      applePay.on("error", function (err) {
        console.error("Apple Pay error", err);
      });

      applePay.on("token", async function (token) {
        await sendData(token);
      });
    }
  };

  async function onPaymentAuthorized(paymentData) {
    console.log("Google Pay succeeded.");
  }

  // const gpay = recurly.GooglePay({
  //   country: "SG",
  //   currency: "USD",
  //   total: `${unitPrice}`,
  //   environment: "PRODUCTION",
  //   buttonOptions: {
  //     buttonType: "plain",
  //     buttonSizeMode: "fill",
  //     buttonLocale: i18next.language,
  //     borderRadius: "5px",
  //     buttonColor: "white",
  //   },
  //   billingAddressRequired: false,
  //   googleMerchantId: process.env.REACT_APP_GPAY_MERCHANT_ID,
  //   callbacks: {
  //     onPaymentAuthorized,
  //   },
  // });

  // console.log("gpay", gpay);

  // React.useEffect(() => {
  //   gpay.on("ready", (googlePayButton) => {
  //     console.log("Google Pay ready");
  //     const container = document.getElementById("google-pay-button-container");
  //     container.style.height = "55px";
  //     container.innerHTML = "";
  //     container.appendChild(googlePayButton);
  //     console.log("Google Pay button added");
  //   });

  //   gpay.on("error", (err) => {
  //     const mapErrorToMsg = {
  //       "google-pay-config-missing": "Missing Internal Configuration",
  //       "google-pay-not-configured":
  //         "There is no gateway to support the Payment with Google",
  //       "google-pay-init-error": "Failed to load the Google Pay library",
  //       "google-pay-not-available":
  //         "Your device or browser is not compatible with Google Pay",
  //       "google-pay-payment-failure":
  //         "Failed to get your Google payment details",
  //       "api-error": "There was an error communicating with Recurly",
  //     };

  //     if (
  //       err.code !== "google-pay-init-error" &&
  //       err.code !== "google-pay-not-available"
  //     ) {
  //       const errorMsg = mapErrorToMsg[err.code] || err.message;
  //       failureToast(`${errorMsg}`);
  //     }
  //     console.log("Google Pay error", err);
  //   });

  //   gpay.on("token", async (token) => {
  //     console.log("Google Pay token", token);
  //     await sendGoogleData(token);
  //     console.log("Google Pay token sent", token);
  //   });

  //   gpay.on("paymentAuthorized", async (prop) => {
  //     console.log("Google Pay paymentAuthorized");
  //   });

  //   return () => {
  //     gpay.off("ready");
  //     gpay.off("paymentAuthorized");
  //     gpay.off("token");
  //     gpay.off("error");
  //   };
  // }, [gpay]);

  const handleOpen = (event) => {
    event.preventDefault();
    setTab(true);
  };

  React.useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const section = document.querySelector(hash);

      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const navigateToOtp = () => {
    console.log("navigateToOtp");
    navigate(Otp_Page, {
      state: {
        email: stateEmail.toLowerCase(),
        isNewRegistered: true,
      },
    });
    console.log("navigateToOtp done");
  };

  const handleTags = () => {};

  return (
    <>
      <div className="flex-grow-1 w-100">
        <TimerNotification />
        <div className="bg-custom-black">
          <div
            className="text-center d-flex flex-column pb-4 "
            style={{
              minHeight: "100dvh",
              paddingTop: "40px",
            }}
          >
            <div className="payment-screen-padding-x">
              <Transformation />
              <TryGainmuscle />
            </div>
            <form
              onSubmit={handleSubmit}
              ref={formRef}
              className="stripe-form "
            >
              <div
                className="text-start mt-3 payment-screen-padding-x"
                style={{ display: "none" }}
              >
                <p
                  className="m-0 text-14"
                  style={{ color: "#6C6E79", fontWeight: 600 }}
                >
                  EMAIL
                </p>
                <input
                  ref={emailRef}
                  className={` input-field ${
                    emailError ? "border-error" : "border-green"
                  } w-100 mt-1 p-2`}
                  id="email"
                  name="email"
                  type="text"
                  placeholder=""
                  required
                  defaultValue={stateEmail}
                  data-recurly={"email"}
                  style={{ textTransform: "lowercase" }}
                />
                {emailError ? (
                  <p className="small" style={{ color: "red" }}>
                    {t("email_error")}
                  </p>
                ) : null}
              </div>
              {tab && (
                <div className=" payment-screen-padding-x">
                  <div className={`mt-2 mb-3 card-container`}>
                    <div className="text-start">
                      <p className="m-0 text-14 card-label fw-semibold text-inter text-uppercase">
                        {t("CARD NUMBER")}
                      </p>
                      <input
                        ref={cardNumberRef}
                        className={`input-field ${
                          cardNumberError ? "border-error" : "border-green"
                        } w-100 mt-1 p-2 bg-white`}
                        id="cardNumber"
                        name="cardNumber"
                        type="text"
                        placeholder="XXXX XXXX XXXX XXXX"
                        required
                        value={cardNumber}
                        data-recurly={"number"}
                        onChange={(event) => handleCardNumberChange(event)}
                        inputMode="numeric"
                        autoComplete="off"
                        style={{ fontFamily: "Helvetica" }}
                      />
                    </div>

                    <div className="d-flex mt-4 justify-content-between">
                      <div
                        className="text-start"
                        style={{ minWidth: "33%", width: "55%" }}
                      >
                        <p className="m-0 text-14 card-label fw-semibold text-inter">
                          {t("EXPIRES ON")}
                        </p>

                        <div className="d-flex">
                          <input
                            ref={cardMonthRef}
                            className={`input-field text-center placeholder-center ${
                              cardMonthError ? "border-error" : "border-green"
                            } w-50 mt-1 p-2 bg-white me-1`}
                            id="cardMonth"
                            name="cardMonth"
                            type="text"
                            placeholder="MM"
                            required
                            value={cardMonth}
                            data-recurly={"month"}
                            onChange={(event) => handleCardMonthChange(event)}
                            inputMode="numeric"
                            autoComplete="off"
                            style={{ fontFamily: "Helvetica" }}
                            maxLength={2}
                          />
                          <input
                            ref={cardYearRef}
                            className={`input-field text-center placeholder-center ${
                              cardYearError ? "border-error" : "border-green"
                            } w-50 mt-1 p-2 bg-white ms-1`}
                            id="cardYear"
                            name="cardYear"
                            type="text"
                            placeholder="YY"
                            required
                            value={cardYear}
                            data-recurly={"year"}
                            onChange={(event) => handleCardYearChange(event)}
                            inputMode="numeric"
                            autoComplete="off"
                            style={{ fontFamily: "Helvetica" }}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="text-start" style={{ width: "30%" }}>
                        <p className="m-0 text-14 card-label fw-semibold text-inter">
                          CVC
                        </p>
                        <input
                          ref={cardCvvRef}
                          className={`input-field text-center placeholder-center ${
                            cardCvvError ? "border-error" : "border-green"
                          } w-100 mt-1 p-2 bg-white`}
                          id="cardYear"
                          name="cardYear"
                          type="text"
                          placeholder="XXX"
                          required
                          value={cardCvv}
                          data-recurly={"cvv"}
                          onChange={(event) => handleCardCvvChange(event)}
                          inputMode="numeric"
                          autoComplete="off"
                          style={{ fontFamily: "Helvetica" }}
                          maxLength={4}
                        />
                      </div>
                      <input
                        type="hidden"
                        name="recurly-token"
                        data-recurly="token"
                      />
                    </div>
                  </div>
                </div>
              )}
              {!tab ? (
                <div className="payment-screen-padding-x">
                  <button
                    className="w-100 main-button fw-bold fs-5 text-white mt-1 mb-4 text-inter"
                    style={{ height: "55px", backgroundColor: "#009B24" }}
                    onClick={handleOpen}
                  >
                    {t("Credit/Debit Card")}
                  </button>
                </div>
              ) : isLoading ? (
                <Loader className="align-self-center mt-1 mb-4" />
              ) : (
                <div className="payment-screen-padding-x">
                  <button
                    type="submit"
                    className="w-100 main-button fw-bold fs-5 text-white mt-1 mb-4 text-inter"
                    style={{ height: "55px", backgroundColor: "#009B24" }}
                  >
                    {t("Process Card")}
                  </button>
                </div>
              )}
              {loadingPayment ? (
                <Loader className="align-self-center mt-2 mb-3" />
              ) : (
                <div className="payment-screen-padding-x">
                  <button
                    id="apple-pay"
                    onClick={handleAppleClick}
                    className="w-100 main-button d-flex justify-content-center align-items-center fs-5 text-white mb-4 custom-payment-btn"
                  >
                    {ApplePay_Svg()}
                  </button>
                </div>
              )}

              {/* {googlePaymentProcessing ? (
                <Loader className="align-self-center mt-2 mb-3" />
              ) : (
                <div
                  id="google-pay-button-container"
                  className="custom-gpay-btn mb-4 payment-screen-padding-x"
                  style={{ borderRadius: "5px" }}
                ></div>
              )} */}
              <PaypalElement email={stateEmail} plan={paypalPlan} />
            </form>

            <div
              id="3D-Secure"
              className="threeDSecure"
              style={{
                maxWidth: "390px",
              }}
            ></div>
            <GatewayFeatures />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentNew;
