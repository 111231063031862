import React from "react";
import { useLocation } from "react-router-dom";

const Tile = ({ text, selected, id, handleClick, icon }) => {
  const location = useLocation();
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current?.blur();
  }, [location]);

  const handleButtonClick = () => {
    handleClick(id);
    // if (ref.current) {
    //   ref.current.blur();
    // }
  };

  return (
    <button
      tabIndex={0}
      ref={ref}
      className={`common-tile button-hover  ${
        selected === id ? "button-active" : ""
      }`}
      onClick={handleButtonClick}
    >
      <div>
        <p
          className={`common-tile-text fw-bold m-0 text-start ${
            icon ? "d-flex align-items-center gap-2" : ""
          }`}
        >
          {icon && <span>{icon()}</span>}
          <span>{text}</span>
        </p>
      </div>
    </button>
  );
};

export default Tile;
