import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckArrow, EmailIcon, LockIcon } from "../../assets/svgs/BackArrow";
import Button from "../../components/Button";
import { Payment_Page, Privacy_Policy } from "../../constants/pageRouteName";
import { failureToast } from "../../modules/toaster";
import ApiService from "../../service/api";

const Register = () => {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorFocus, setEmailErrorFocus] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { details } = useSelector((state) => state.detailState);
  const navigate = useNavigate();
  const emailRef = React.useRef();

  const validateEmail = (email) => {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return pattern.test(email);
  };

  const handleSubmit = async (e) => {
    if (!validateEmail(email)) {
      emailRef.current.focus();
      setEmailError(true);
      setEmailErrorFocus(true);
      setSuccess(false);
    } else {
      setSuccess(true);
      const data = {
        age: details.age?.text,
        occasion: details.occasion?.text,
        primaryGoal: details.primaryGoal?.text,
        bodyWant: details.bodyWant?.text,
        motivatesToExercise:
          typeof details.motivatesToExercise == "string"
            ? details.motivatesToExercise
            : details.motivatesToExercise?.map((item) => item.text).join(","),
        targetZones:
          typeof details.targetZones == "string"
            ? details.targetZones
            : details.targetZones?.map((item) => item.text).join(","),
        previousPerfectWeight: details.previousPerfectWeight?.text,
        interest:
          typeof details.interest == "string"
            ? details.interest
            : details.interest?.map((item) => item.text).join(","),
        fitnesslevel: details.fitnessLevel?.text,
        pushups: details.pushups?.text,
        sedentary: details.sedentary?.text,
        walkDaily: details.walkDaily?.text,
        feelBtwnMeal: details.feelBtwnMeal?.text,
        sleep: details.sleep?.text,
        water: details.water?.text,
        workoutLocation: details.workOutLocation?.text,
        desiredWeight: details.targetWeight,
        weight: details.weight,
        occasionDate: details.occasionDate,
        height: details.height,
      };

      const apiData = { ...data, email: email };
      await ApiService.addEmail(apiData)
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              navigate(Payment_Page, { state: { email } });
            }, 1000);
          }
        })
        .catch((err) => {
          failureToast(err?.response?.data);
        });
    }
  };

  React.useEffect(() => {
    if (validateEmail(email) && emailErrorFocus) {
      setEmailError(false);
    } else if (emailErrorFocus) {
      setEmailError(true);
      emailRef.current.focus();
    }
  }, [email]);

  return (
    <div className="d-flex flex-column justify-content-between">
      <div
        className="text-center main-padding d-flex flex-column"
        style={{
          paddingTop: "50px",
          height: "calc(100% - 50px)",
        }}
      >
        <div
          style={{ position: "relative" }}
          className="d-flex justify-content-center mb-3 gap-3 align-items-center"
        >
          <p className="plan-text-big text-inter fw-bold m-0 text-custom-white">
            {t("RG_header")}
          </p>
        </div>
        <p className="plan-text-big text-inter text-custom-white mb-0">
          <Trans
            i18nKey="RG_subheader"
            components={{ tag: <span style={{ color: "#2680EB" }} /> }}
          />
        </p>

        <div className="d-flex justify-content-center align-items-center flex-column mt-4">
          <label className="email-input-label text-uppercase">
            {t("Your Email")}
          </label>
          <div className="email-input-wrapper w-100">
            <div className="email-input-icon">
              <div
                style={{
                  width: "17px",
                  height: "14px",
                }}
              >
                {EmailIcon()}
              </div>
            </div>
            <input
              ref={emailRef}
              required
              className="w-100 input-field email-input text-inter text-custom-white register-input-padding"
              placeholder={t("Your Email")}
              onChange={(e) => {
                setSuccess(false);
                setEmail(e.target.value.toLowerCase());
              }}
              style={{
                textTransform: email.length > 0 && "lowercase",
              }}
            />
            {!emailError && (
              <div
                className={`position-absolute top-50 translate-middle-y  ${
                  success && "fade-in"
                }`}
                style={{ right: "10px", opacity: 0 }}
              >
                <CheckArrow />
              </div>
            )}
          </div>
          {emailError && (
            <>
              <p className="small align-self-start" style={{ color: "red" }}>
                {t("email_error")}
              </p>
            </>
          )}
          {!emailError && (
            <p
              className={`small m-0 text-inter align-self-start ${
                success && "fade-in"
              }`}
              style={{
                color: "#009B24",
                opacity: 0,
                fontWeight: "500",
                transition: "opacity 0.2s ease-in-out",
              }}
            >
              {t("Well done!")}
            </p>
          )}
          <div className="d-flex flex-row justify-content-around align-items-center gap-1 w-100 mt-4">
            <div
              style={{
                width: "40px",
              }}
            >
              {LockIcon()}
            </div>
            <p className="mb-0 text-inter text-custom-white text-start plan-privacy-text">
              <Trans
                i18nKey={"policy_text"}
                components={{
                  tag: (
                    <span
                      style={{ textDecoration: "underline" }}
                      onClick={() => navigate(Privacy_Policy)}
                    />
                  ),
                }}
              />
            </p>
          </div>
          <div className="mt-4 mb-1 w-100">
            <Button label={t("CONTINUE")} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
