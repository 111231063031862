import { t } from "i18next";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BackArrow } from "../../assets/svgs/BackArrow";
import PageView from "../../layouts/PageView";
import { TermsConditions } from "../../utils/legal";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <PageView>
      <div
        className="text-center d-flex flex-column pb-4"
        style={{ minHeight: "100dvh" }}
      >
        <div className="d-flex justify-content-center bottom-border align-items-center py-4">
          <div className="w-100 d-flex justify-content-center gap-2 align-items-center main-padding position-relative">
            <div className="position-absolute start-0 main-padding">
              <button
                onClick={() => navigate(-1)}
                className="button"
                style={{ width: "27px", height: "27px" }}
              >
                {BackArrow()}
              </button>
            </div>
            <div className="m-0 p-0">
              <h4 className=" text-inter fw-bold text-custom-white m-0 ">
                {t("TC_title")}
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{ textAlign: "left" }}
          className="d-flex flex-column main-padding mt-4"
        >
          <p className="text-inter text-custom-white">{t("main_desc")}</p>
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            1. {t("category_1_title")}
          </h3>
          <p className=" text-inter text-custom-white">
            {t("category_1_desc")}
          </p>
          {TermsConditions.listOne.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            2. {t("category_2_title")}
          </h3>
          {TermsConditions.listTwo.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            3. {t("category_3_title")}
          </h3>
          {TermsConditions.listThree.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            4. {t("category_4_title")}
          </h3>
          {TermsConditions.listFour.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            5. {t("category_5_title")}
          </h3>
          {TermsConditions.listFive.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            6. {t("category_6_title")}
          </h3>
          {TermsConditions.listSix.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            7. {t("category_7_title")}
          </h3>
          {TermsConditions.listSeven.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            8. {t("category_8_title")}
          </h3>
          {TermsConditions.listEight.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            9. {t("category_9_title")}
          </h3>
          {TermsConditions.listNine.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}
              {typeof d?.id === "number" ? "." : ")"} {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            10. {t("category_10_title")}
          </h3>
          {TermsConditions.listTen.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            11. {t("category_11_title")}
          </h3>
          <p className=" text-inter text-custom-white">1. {t("c_11_l_1")}</p>
          <p className=" text-inter text-custom-white">2. {t("c_11_l_2")}</p>
          <p className=" text-inter text-custom-white">a) {t("c_11_l_2a")}</p>
          <p className=" text-inter text-custom-white">b) {t("c_11_l_2b")}</p>
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            12. {t("category_12_title")}
          </h3>
          {TermsConditions.listTwelve.map((d) => (
            <p key={d.id} className=" text-inter text-custom-white">
              {d?.id}. {d?.data}
            </p>
          ))}
          <p className=" text-inter text-custom-white">{t("last_text1")}</p>
          <p className=" text-inter text-custom-white">{t("last_text2")}</p>
          <p className=" text-inter text-custom-white">{t("last_text3")}</p>
          <p className=" text-inter text-custom-white">{t("last_text4")}</p>
          <p className=" text-inter text-custom-white">
            5. {t("support_message")}{" "}
            <Link
              to="mailto:support@gainmuscle.app"
              className=" text-inter text-custom-white"
            >
              support@gainmuscle.app
            </Link>
          </p>
        </div>
      </div>
    </PageView>
  );
};

export default Terms;
