import React from "react";
import Button from "../../../components/Button";
import Slider from "../../../components/Slider";

import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import { Part_Three_Two } from "../../../constants/pageRouteName";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { placeHolderImages, s3Images } from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";
import { preLoadImage } from "../../../utils/preLoadImage";

const Items = [
  {
    id: 1,
    text: t("Beginner"),
    value: "Beginner",
    sub_text: t("I'm new to fitness"),
    icon: s3Images.fitnessLevel[0],
    placeHolderImage: placeHolderImages.fitnessLevel[0],
  },
  {
    id: 2,
    text: t("Intermediate"),
    value: "Intermediate",
    sub_text: t("I workout from time to time"),
    placeHolderImage: placeHolderImages.fitnessLevel[1],
    icon: s3Images.fitnessLevel[1],
  },
  {
    id: 3,
    text: t("Advanced"),
    value: "Advanced",
    sub_text: t("I exercise regularily"),
    placeHolderImage: placeHolderImages.fitnessLevel[2],
    icon: s3Images.fitnessLevel[2],
  },
];

const FitnessLevel = () => {
  const { details } = useSelector((state) => state.detailState);
  const [selected, setSelected] = React.useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (index) => {
    dispatch(
      setDetailsData({
        fitnessLevel: { text: Items[index].value, id: Items[index].id },
      })
    );
    setSelected(index);
  };

  const gotoNextPage = () => {
    !details.fitnessLevel &&
      dispatch(
        setDetailsData({
          fitnessLevel: { text: Items[selected].value, id: Items[selected].id },
        })
      );
    nextPageRoute(navigate, Part_Three_Two);
  };

  React.useEffect(() => {
    preLoadImage(s3Images.fitnessLevel.slice(0, 3), dispatch);
  }, []);

  return (
    <div className="flex-grow-1 pt-2 main-padding-b main-padding d-flex flex-column justify-content-between">
      <div className="flex-grow-1 d-flex flex-column justify-content-between">
        <div className="w-100 d-flex justify-content-center">
          <h1
            className="body-header mt-0 text-inter fw-bold text-center main-margin-b"
            style={{ maxWidth: "250px" }}
          >
            {t("FL_header")}
          </h1>
        </div>
        <div className="w-100 d-flex justify-content-center align-content-center flex-grow-1">
          <ImageLoader
            placeHolderImage={Items[selected].placeHolderImage}
            src={Items[selected].icon}
            alt="man_img"
            style={{
              width:
                Items[selected].id === 1
                  ? "60%"
                  : Items[selected].id === 2
                  ? "80%"
                  : "100%",
            }}
            className="object-fit-contain transition-smooth"
          />
        </div>
        <div className="w-100" style={{ marginBottom: "14px" }}>
          <div className=" mb-5 mt-1">
            <p className="body-text my-0 mb-2 text-inter fw-bold text-center ">
              {Items[selected].text}
            </p>
            <p className="fitness-level-sub-text m-0 text-center">
              {Items[selected].sub_text}
            </p>
          </div>
          <Slider active={selected} handleChange={handleSelect} />
        </div>
      </div>
      <Button label={t("CONTINUE")} onClick={gotoNextPage} />
    </div>
  );
};

export default FitnessLevel;
