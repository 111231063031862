import ApiService from "../service/api";

export const updatePlanData = (navigate, dispatch, data = {}) => {
  ApiService.updateGoals(data)
    .then((res) => navigate(-1))
    .catch((err) => {
      if (err?.request?.status === 401) {
        // dispatch(logoutState());
      } else {
        navigate(-1);
      }
      console.log(err);
    });
};
