import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BodyBuildingIcon,
  BodyStrengthIcon,
  HealthIcon,
  ImmuneIcon,
  LookIcon,
} from "../../../assets/svgs/partTwo";
import Button from "../../../components/Button";
import CheckBoxTile from "../../../components/CheckBoxTile";
import { Part_Two_Four } from "../../../constants/pageRouteName";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { pagePreloadImages } from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const Items = [
  {
    id: 1,
    text: t("Improving Health"),
    value: "Improving Health",
    icon: HealthIcon,
  },
  {
    id: 2,
    text: t("Boosting Immune System"),
    value: "Boosting Immune System",
    icon: ImmuneIcon,
  },
  {
    id: 3,
    text: t("Looking Better"),
    value: "Looking Better",
    icon: LookIcon,
  },
  {
    id: 4,
    text: t("Building Strength"),
    value: "Building Strength",
    icon: BodyBuildingIcon,
  },
  {
    id: 5,
    text: t("Boosting Libido"),
    value: "Boosting Libido",
    icon: BodyStrengthIcon,
  },
];

const Motivation = () => {
  const [selected, setSelected] = React.useState([]);
  const { details } = useSelector((state) => state.detailState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      dispatch(
        setDetailsData({
          motivatesToExercise: details.motivatesToExercise.filter(
            (item) => item.id !== Items[id - 1].id
          ),
        })
      );
      setSelected(selected.filter((item) => item !== id));
    } else {
      dispatch(
        setDetailsData({
          motivatesToExercise: [
            ...details.motivatesToExercise,
            { text: Items[id - 1].value, id: Items[id - 1].id },
          ],
        })
      );
      setSelected((pre) => [...pre, id]);
    }
  };

  React.useEffect(() => {
    dispatch(setDetailsData({ motivatesToExercise: [] }));
  }, []);

  const gotoNextPage = () => {
    nextPageRoute(
      navigate,
      Part_Two_Four,
      dispatch,
      pagePreloadImages.motivation
    );
  };

  return (
    <div className="flex-grow-1 pt-2 main-padding-b main-padding d-flex flex-column justify-content-between gap-2">
      <div className="d-flex flex-column align-items-center w-100">
        <h1
          className="motivation-header mt-0 text-inter fw-bold text-center main-margin-b"
          style={{ maxWidth: "300px" }}
        >
          {t("MT_header")}
        </h1>
        <div className="d-flex flex-column w-100" style={{ gap: "10px" }}>
          {Items.map((item) => (
            <CheckBoxTile
              {...item}
              key={item.id}
              handleSelection={handleSelection}
              selected={selected.find(
                (selectedItem) => selectedItem === item.id
              )}
            />
          ))}
        </div>
      </div>
      <Button
        disabled={!selected?.length}
        label={t("CONTINUE")}
        onClick={gotoNextPage}
      />
    </div>
  );
};

export default Motivation;
