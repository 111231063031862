import { toast } from "react-toastify";

export const successToast = (message) => {
  toast.success(message, {
    position: "bottom-center",
    autoClose: 1500,
    theme: "dark",
    progress: undefined,
  });
};

export const failureToast = (message) => {
  toast.error(message, {
    position: "bottom-center",
    autoClose: 1500,
    theme: "dark",
    progress: undefined,
  });
};
