import { createSlice } from "@reduxjs/toolkit";

const detailsReducer = createSlice({
  name: "details",
  initialState: {
    details: {},
  },
  reducers: {
    setDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});
const { setDetails } = detailsReducer.actions;

export const setDetailsData = (data) => {
  return (dispatch, getState) => {
    let { details } = getState().detailState;
    let newData = { ...details, ...data };
    dispatch(setDetails(newData));
  };
};

export const removeDetailsData = (data) => {
  return (dispatch, getState) => {
    dispatch(setDetails({}));
  };
};

export default detailsReducer.reducer;
