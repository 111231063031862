import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import { Part_Three_Seven } from "../../../constants/pageRouteName";
import PartThreeLayout from "../../../layouts/PartThreeLayout";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import {
  pagePreloadImages,
  placeHolderImages,
  s3Images,
} from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const items = [
  {
    id: 1,
    text: t("Fewer than 5 hours"),
    value: "Fewer than 5 hours",
  },
  {
    id: 2,
    text: t("Between 5 and 6 hours"),
    value: "Between 5 and 6 hours",
  },
  {
    id: 3,
    text: t("Between 7 and 8 hours"),
    value: "Between 7 and 8 hours",
  },
  {
    id: 4,
    text: t("Over 8 hours"),
    value: "Over 8 hours",
  },
];

const Image = () => {
  return (
    <ImageLoader
      placeHolderImage={placeHolderImages.sleep}
      src={s3Images.sleep}
      alt="Meal"
      className="w-100"
      style={{ maxHeight: "279px", objectFit: "contain" }}
    />
  );
};
const Sleep = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (id) => {
    dispatch(
      setDetailsData({
        sleep: { text: items[id - 1].value },
      })
    );
    nextPageRoute(
      navigate,
      Part_Three_Seven,
      dispatch,
      pagePreloadImages.sleep
    );
  };
  return (
    <PartThreeLayout
      title={
        <>
          {t("SL_title_1")}
          <br />
          {t("SL_title_2")}
        </>
      }
      handleSelect={handleSelect}
      items={items}
      selected={selected?.id}
      Image={Image}
    />
  );
};

export default Sleep;
