import { t } from "i18next";

export const TermsConditions = {
  listOne: [
    {
      id: 1,
      data: t("c_1_l_1"),
    },
    {
      id: 2,
      data: t("c_1_l_2"),
    },
    {
      id: 3,
      data: t("c_1_l_3"),
    },
    {
      id: 4,
      data: t("c_1_l_4"),
    },
    {
      id: 5,
      data: t("c_1_l_5"),
    },
    {
      id: 6,
      data: t("c_1_l_6"),
    },
    {
      id: 7,
      data: t("c_1_l_7"),
    },
    {
      id: 8,
      data: t("c_1_l_8"),
    },
    {
      id: 9,
      data: t("c_1_l_9"),
    },
  ],
  listTwo: [
    {
      id: 1,
      data: t("c_2_l_1"),
    },
    {
      id: 2,
      data: t("c_2_l_2"),
    },
  ],
  listThree: [
    {
      id: 1,
      data: t("c_3_l_1"),
    },
    {
      id: 2,
      data: t("c_3_l_2"),
    },
    {
      id: 3,
      data: t("c_3_l_3"),
    },
    {
      id: 4,
      data: t("c_3_l_4"),
    },
  ],
  listFour: [
    {
      id: 1,
      data: t("c_4_l_1"),
    },
    {
      id: 2,
      data: t("c_4_l_2"),
    },
  ],
  listFive: [
    {
      id: 1,
      data: t("c_5_l_1"),
    },
    {
      id: 2,
      data: t("c_5_l_2"),
    },
    {
      id: 3,
      data: t("c_5_l_3"),
    },
    {
      id: 4,
      data: t("c_5_l_4"),
    },
    {
      id: 5,
      data: t("c_5_l_5"),
    },
    {
      id: 6,
      data: t("c_5_l_6"),
    },
    {
      id: 7,
      data: t("c_5_l_7"),
    },
  ],
  listSix: [
    {
      id: 1,
      data: t("c_6_l_1"),
    },
    {
      id: 2,
      data: t("c_6_l_2"),
    },
    {
      id: 3,
      data: t("c_6_l_3"),
    },
    {
      id: 4,
      data: t("c_6_l_4"),
    },
    {
      id: 5,
      data: t("c_6_l_5"),
    },
  ],
  listSeven: [
    {
      id: 1,
      data: t("c_7_l_1"),
    },
    {
      id: 2,
      data: t("c_7_l_2"),
    },
    {
      id: 3,
      data: t("c_7_l_3"),
    },
    {
      id: 4,
      data: t("c_7_l_4"),
    },
    {
      id: 5,
      data: t("c_7_l_5"),
    },
    {
      id: 6,
      data: t("c_7_l_6"),
    },
  ],
  listEight: [
    {
      id: 1,
      data: t("c_8_l_1"),
    },
    {
      id: 2,
      data: t("c_8_l_2"),
    },
    {
      id: 3,
      data: t("c_8_l_3"),
    },
    {
      id: 4,
      data: t("c_8_l_4"),
    },
  ],
  listNine: [
    {
      id: 1,
      data: t("c_9_l_1"),
    },
    {
      id: 2,
      data: t("c_9_l_2"),
    },
    {
      id: 3,
      data: t("c_9_l_3"),
    },
    {
      id: "a",
      data: t("c_9_l_3a"),
    },
    {
      id: "b",
      data: t("c_9_l_3b"),
    },
    {
      id: "c",
      data: t("c_9_l_3c"),
    },
    {
      id: "d",
      data: t("c_9_l_3d"),
    },
    {
      id: 4,
      data: t("c_9_l_4"),
    },
  ],
  listTen: [
    {
      id: 1,
      data: t("c_10_l_1"),
    },
    {
      id: 2,
      data: t("c_10_l_2"),
    },
  ],
  listTwelve: [
    {
      id: 1,
      data: t("c_12_l_1"),
    },
    {
      id: 2,
      data: t("c_12_l_2"),
    },
    {
      id: 3,
      data: t("c_12_l_3"),
    },
    {
      id: 4,
      data: t("c_12_l_4"),
    },
  ],
};
