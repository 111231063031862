import React from "react";

const active_position = [0, "50%", "100%"];

const Slider = ({ active, handleChange }) => {
  return (
    <div className="w-100 px-4 my-3">
      <div className="w-100 position-relative d-flex align-items-center slider-container">
        <div className="w-100 slider-line">
          {active_position.map((position, index) => (
            <button
              onClick={() => handleChange(index)}
              key={index}
              className="slider-btn p-0"
              style={{
                left: position,
              }}
            ></button>
          ))}
          <div
            className="slider-active"
            style={{
              left: active_position[active],
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
