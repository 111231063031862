export const HealthIcon = () => (
  <svg
    id="_1"
    dataname="1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20.294"
    viewBox="0 0 24 20.294"
  >
    <path
      id="path564_-_Outline"
      dataname="path564 - Outline"
      d="M-339.474-238.867a2.2,2.2,0,0,1-1.427-.525c-.42-.356-.957-.793-1.55-1.277-2.289-1.865-5.138-4.187-5.92-5.515a7.436,7.436,0,0,1-.977-2.531q-.026-.147-.046-.3a.469.469,0,0,1,.4-.525.469.469,0,0,1,.525.4q.017.13.039.257a6.533,6.533,0,0,0,.861,2.218c.7,1.184,3.591,3.542,5.7,5.264.6.488,1.14.929,1.564,1.288a1.266,1.266,0,0,0,1.641,0c.347-.294.773-.642,1.244-1.026a.469.469,0,0,1,.659.067.469.469,0,0,1-.067.66c-.466.38-.887.725-1.23,1.015A2.2,2.2,0,0,1-339.474-238.867Z"
      transform="translate(349.397 255.724)"
      fill="#fff"
    />
    <path
      id="path568_-_Outline"
      dataname="path568 - Outline"
      d="M4.338-61.967a.467.467,0,0,1-.2-.044.469.469,0,0,1-.226-.623,6.393,6.393,0,0,0,.288-.723,5.79,5.79,0,0,0,.278-2.117A5,5,0,0,0-.228-70.052a5.692,5.692,0,0,0-3.623,1.517,1.088,1.088,0,0,1-1.42.013,5.909,5.909,0,0,0-3.619-1.53A4.886,4.886,0,0,0-12.5-68.2a.469.469,0,0,1-.66.065.469.469,0,0,1-.065-.66,5.842,5.842,0,0,1,4.337-2.2,6.837,6.837,0,0,1,4.227,1.754.145.145,0,0,0,.191,0A6.624,6.624,0,0,1-0.228-70.989a5.988,5.988,0,0,1,5.644,5.471,6.711,6.711,0,0,1-.323,2.453,7.424,7.424,0,0,1-.329.828A.469.469,0,0,1,4.338-61.967Z"
      transform="translate(14.49 70.989)"
      fill="#fff"
    />
    <path
      id="path572_-_Outline"
      dataname="path572 - Outline"
      transform="translate(0.578 4.506)"
      fill="#fff"
    />
    <path
      id="path576_-_Outline"
      dataname="path576 - Outline"
      d="M-325.329-173.652a6.089,6.089,0,0,1,6.06,6.1,6.089,6.089,0,0,1-6.06,6.1,6.089,6.089,0,0,1-6.06-6.1A6.089,6.089,0,0,1-325.329-173.652Zm0,11.272a5.151,5.151,0,0,0,5.122-5.167,5.151,5.151,0,0,0-5.122-5.167,5.151,5.151,0,0,0-5.122,5.167A5.151,5.151,0,0,0-325.329-162.379Z"
      transform="translate(343.269 181.736)"
      fill="#fff"
    />
    <path
      id="path580_-_Outline"
      dataname="path580 - Outline"
      d="M-154.542-61.6h1.125a1.042,1.042,0,0,1,1.038,1.043v.654h.644A1.042,1.042,0,0,1-150.7-58.86v1.136a1.042,1.042,0,0,1-1.038,1.043h-.644v.654a1.042,1.042,0,0,1-1.038,1.043h-1.125a1.042,1.042,0,0,1-1.038-1.043v-.654h-.644a1.042,1.042,0,0,1-1.038-1.043V-58.86a1.042,1.042,0,0,1,1.038-1.043h.644v-.654A1.042,1.042,0,0,1-154.542-61.6Zm2.808,3.981a.1.1,0,0,0,.1-.105V-58.86a.1.1,0,0,0-.1-.105h-1.113a.469.469,0,0,1-.469-.469v-1.123a.1.1,0,0,0-.1-.105h-1.125a.1.1,0,0,0-.1.105v1.123a.469.469,0,0,1-.469.469h-1.113a.1.1,0,0,0-.1.105v1.136a.1.1,0,0,0,.1.105h1.113a.469.469,0,0,1,.469.469v1.123a.1.1,0,0,0,.1.105h1.125a.1.1,0,0,0,.1-.105v-1.123a.469.469,0,0,1,.469-.469Z"
      transform="translate(171.919 72.482)"
      fill="#fff"
    />
  </svg>
);

export const ImmuneIcon = () => (
  <svg
    id="_2"
    dataname="2"
    xmlns="http://www.w3.org/2000/svg"
    width="19.5"
    height="25.638"
    viewBox="0 0 19.5 25.638"
  >
    <path
      id="path1644"
      d="M-25.663-12.839a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5"
      transform="translate(42.305 28.386)"
      fill="#fff"
    />
    <path
      id="path1648"
      d="M-495.136-125.11h.011a.5.5,0,0,1,.412.215c1.425,2.048,3.619,4.49,5.977,4.49a7.785,7.785,0,0,0,2.681-.469.5.5,0,0,1,.46.06.5.5,0,0,1,.214.411v6.81c0,5.66-3.214,10.828-8.6,13.825a2.376,2.376,0,0,1-1.154.3,2.375,2.375,0,0,1-1.154-.3c-5.383-3-8.6-8.165-8.6-13.825v-6.81a.5.5,0,0,1,.214-.411.5.5,0,0,1,.46-.06,7.786,7.786,0,0,0,2.681.469c2.358,0,4.552-2.442,5.977-4.49A.5.5,0,0,1-495.136-125.11Zm6.4,5.709c-2.56,0-4.831-2.251-6.395-4.355-1.564,2.1-3.835,4.355-6.395,4.355a8.848,8.848,0,0,1-2.352-.314v6.123c0,5.29,3.021,10.131,8.081,12.948a1.37,1.37,0,0,0,.665.169,1.37,1.37,0,0,0,.666-.169c5.06-2.817,8.081-7.658,8.081-12.948v-6.123A8.848,8.848,0,0,1-488.735-119.4Z"
      transform="translate(504.88 125.11)"
      fill="#fff"
    />
    <path
      id="path1652"
      d="M-235.91-85.068h3.1a.5.5,0,0,1,.5.5v2.2h2.2a.5.5,0,0,1,.5.5v3.1a.5.5,0,0,1-.5.5h-2.2v2.2a.5.5,0,0,1-.5.5h-3.1a.5.5,0,0,1-.5-.5v-2.2h-2.2a.5.5,0,0,1-.5-.5v-3.1a.5.5,0,0,1,.5-.5h2.2v-2.2A.5.5,0,0,1-235.91-85.068Zm2.6,1h-2.094v2.2a.5.5,0,0,1-.5.5h-2.2v2.094h2.2a.5.5,0,0,1,.5.5v2.2h2.094v-2.2a.5.5,0,0,1,.5-.5h2.2v-2.094h-2.2a.5.5,0,0,1-.5-.5Z"
      transform="translate(244.112 93.137)"
      fill="#fff"
    />
    <path
      id="path1656"
      d="M-12.832-134.347a.5.5,0,0,1-.418-.224.5.5,0,0,1,.14-.7,10.948,10.948,0,0,0,3.956-4.56.5.5,0,0,1,.664-.25.5.5,0,0,1,.25.664,11.95,11.95,0,0,1-4.314,4.981A.5.5,0,0,1-12.832-134.347Z"
      transform="translate(24.019 157.239)"
      fill="#fff"
    />
  </svg>
);

export const LookIcon = () => (
  <svg
    id="_3"
    dataname="3"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23.903"
    viewBox="0 0 24 23.903"
  >
    <g id="Group_36" dataname="Group 36" transform="translate(10.611 17.633)">
      <g id="Group_35" dataname="Group 35">
        <path
          id="Path_10"
          dataname="Path 10"
          d="M227.074,377.289a.406.406,0,0,0-.567.087l-.05.068a.406.406,0,0,0,.653.481l.051-.069A.406.406,0,0,0,227.074,377.289Z"
          transform="translate(-226.377 -377.21)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_38" dataname="Group 38" transform="translate(9.484 17.701)">
      <g id="Group_37" dataname="Group 37">
        <path
          id="Path_11"
          dataname="Path 11"
          d="M204.248,382.538l-1.044-1.9.974-1.327a.406.406,0,1,0-.654-.48l-1.128,1.536a.406.406,0,0,0-.029.436l1.169,2.124h0a.406.406,0,1,0,.711-.391Z"
          transform="translate(-202.318 -378.668)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_40" dataname="Group 40" transform="translate(12.543 17.708)">
      <g id="Group_39" dataname="Group 39">
        <path
          id="Path_12"
          dataname="Path 12"
          d="M269.485,380.5l-1.123-1.53a.406.406,0,0,0-.654.481l.97,1.321-1.036,1.885a.406.406,0,1,0,.711.391l1.161-2.112A.406.406,0,0,0,269.485,380.5Z"
          transform="translate(-267.591 -378.804)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_42" dataname="Group 42" transform="translate(8.702 12.98)">
      <g id="Group_41" dataname="Group 41">
        <path
          id="Path_13"
          dataname="Path 13"
          d="M192.029,277.985a.406.406,0,0,0-.419.025,6.465,6.465,0,0,0-.666.523,2.861,2.861,0,0,1-2.17.848.4.4,0,0,0-.074,0,4.119,4.119,0,0,1-2.033-1.03c-.126-.1-.255-.193-.384-.28a.406.406,0,0,0-.633.336V279.8a.406.406,0,0,0,.152.316.393.393,0,0,0,.035.025l2.877,1.923h0l.008.005a.406.406,0,0,0,.416.019l.037-.022,0,0,0,0,2.87-1.918.034-.024a.406.406,0,0,0,.156-.32v-1.451A.406.406,0,0,0,192.029,277.985Zm-.595,1.593-2.486,1.661-2.486-1.661v-.369a4.438,4.438,0,0,0,2.222.979h0a3.687,3.687,0,0,0,2.746-1v.394Z"
          transform="translate(-185.651 -277.939)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_44" dataname="Group 44" transform="translate(10.662 16.363)">
      <g id="Group_43" dataname="Group 43">
        <path
          id="Path_14"
          dataname="Path 14"
          d="M229.185,350.449a.406.406,0,0,0-.727-.173l-.933,1.27a.406.406,0,1,0,.654.48l.928-1.264a.405.405,0,0,0,.084-.247A.416.416,0,0,0,229.185,350.449Z"
          transform="translate(-227.446 -350.11)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_46" dataname="Group 46" transform="translate(5.833)">
      <g id="Group_45" dataname="Group 45" transform="translate(0 0)">
        <path
          id="Path_15"
          dataname="Path 15"
          d="M136.783,4.66a2.6,2.6,0,0,0-1.489-1.977,1.4,1.4,0,0,0-.119-.852,1.592,1.592,0,0,0-1.206-.784.4.4,0,0,0-.077-.007h-5.446a4.015,4.015,0,0,0-4.011,4.011V7.188a2.448,2.448,0,0,0,.534,1.529,1.975,1.975,0,0,1,.413,1.146.406.406,0,0,0,.811-.023V7.053a1.785,1.785,0,0,1,1.378-1.391h1.762a1.981,1.981,0,0,0,.606-.094c.335-.107.866-.305,1.253-.452a1.176,1.176,0,0,1,.807-.01l1.886.662a1.725,1.725,0,0,1,1.132,1.306v3.344a.405.405,0,0,0,.376.4h.031a.406.406,0,0,0,.4-.346,5.735,5.735,0,0,1,.686-1.895,1.818,1.818,0,0,0,.278-.962V4.711A.406.406,0,0,0,136.783,4.66Zm-.808,2.96a1.006,1.006,0,0,1-.148.521v-1.1a.4.4,0,0,0,0-.044,2.529,2.529,0,0,0-1.664-2l-.005,0-1.889-.663a1.989,1.989,0,0,0-1.364.017c-.542.206-.961.357-1.213.438a1.171,1.171,0,0,1-.358.055h-1.794a.413.413,0,0,0-.052,0,2.583,2.583,0,0,0-2.094,2.065.406.406,0,0,0-.01.088v.835a1.649,1.649,0,0,1-.135-.654V5.051a3.2,3.2,0,0,1,3.2-3.2h5.405a.867.867,0,0,1,.6.345.787.787,0,0,1-.018.579.406.406,0,0,0,.272.567,1.74,1.74,0,0,1,1.271,1.4V7.62Z"
          transform="translate(-124.434 -1.04)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_48" dataname="Group 48" transform="translate(6.779 3.188)">
      <g id="Group_47" dataname="Group 47">
        <path
          id="Path_16"
          dataname="Path 16"
          d="M155.072,71.82a2.529,2.529,0,0,0-1.664-2l-.005,0-1.889-.663a1.99,1.99,0,0,0-1.364.017c-.542.206-.961.357-1.213.438a1.171,1.171,0,0,1-.358.055h-1.794a.414.414,0,0,0-.052,0,2.583,2.583,0,0,0-2.094,2.065.407.407,0,0,0-.01.088v3.488s0,.006,0,.009c0,.121.088,2.983,2.066,4.291l.01.007.026.018c.113.076.226.159.337.246a5.005,5.005,0,0,0,2.518,1.2h0a2.246,2.246,0,0,0,.242.012,3.835,3.835,0,0,0,2.558-1.065,5.658,5.658,0,0,1,.582-.457l.03-.021.03-.021c.66-.443,1.8-1.586,2.042-4.264,0-.012,0-.024,0-.036V71.863A.435.435,0,0,0,155.072,71.82Zm-.809,3.4c-.221,2.417-1.251,3.354-1.684,3.645l-.046.032-.022.016a6.466,6.466,0,0,0-.666.523,2.862,2.862,0,0,1-2.173.847.407.407,0,0,0-.071,0,4.119,4.119,0,0,1-2.033-1.03c-.126-.1-.255-.193-.384-.28l-.01-.007-.031-.021c-1.6-1.056-1.7-3.527-1.7-3.63V71.871a1.785,1.785,0,0,1,1.377-1.391h1.762a1.981,1.981,0,0,0,.606-.094c.335-.107.866-.305,1.253-.452a1.176,1.176,0,0,1,.807-.01l1.886.662a1.725,1.725,0,0,1,1.132,1.306v3.326Z"
          transform="translate(-144.628 -69.046)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_50" dataname="Group 50" transform="translate(10.622 16.363)">
      <g id="Group_49" dataname="Group 49">
        <path
          id="Path_17"
          dataname="Path 17"
          d="M228.346,350.338a.406.406,0,0,0-.69-.059l-.972,1.324a.406.406,0,0,0,.654.481l.972-1.319a.42.42,0,0,0,.079-.246A.406.406,0,0,0,228.346,350.338Z"
          transform="translate(-226.606 -350.113)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_52" dataname="Group 52" transform="translate(11.597 22.995)">
      <g id="Group_51" dataname="Group 51">
        <path
          id="Path_18"
          dataname="Path 18"
          d="M248.2,491.727a.406.406,0,0,0-.567-.019l-.093.085a.406.406,0,0,0-.08.5l0,.005a.406.406,0,0,0,.3.2.41.41,0,0,0,.056,0,.406.406,0,0,0,.287-.119l.09-.09A.406.406,0,0,0,248.2,491.727Z"
          transform="translate(-247.406 -491.602)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_54" dataname="Group 54" transform="translate(11.499 22.995)">
      <g id="Group_53" dataname="Group 53">
        <path
          id="Path_19"
          dataname="Path 19"
          d="M246.073,491.794l-.093-.085a.406.406,0,0,0-.561.586l.09.09a.406.406,0,0,0,.287.119.4.4,0,0,0,.057,0,.406.406,0,0,0,.3-.207l0-.005A.406.406,0,0,0,246.073,491.794Z"
          transform="translate(-245.301 -491.603)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_56" dataname="Group 56" transform="translate(11.59 23.083)">
      <g id="Group_55" dataname="Group 55" transform="translate(0 0)">
        <path
          id="Path_20"
          dataname="Path 20"
          d="M247.94,493.582h0a.406.406,0,0,0-.554,0h0a.406.406,0,0,0-.013.588l0,0a.406.406,0,0,0,.573,0l0,0a.406.406,0,0,0-.013-.587Z"
          transform="translate(-247.252 -493.472)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_58" dataname="Group 58" transform="translate(11.602 16.357)">
      <g id="Group_57" dataname="Group 57">
        <path
          id="Path_21"
          dataname="Path 21"
          d="M248.041,350a.406.406,0,1,0,.283.387h0A.405.405,0,0,0,248.041,350Z"
          transform="translate(-247.512 -349.981)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_60" dataname="Group 60" transform="translate(11.594 16.356)">
      <g id="Group_59" dataname="Group 59">
        <path
          id="Path_22"
          dataname="Path 22"
          d="M248.079,350.13l0,0a.406.406,0,0,0-.732.241v.007a.407.407,0,0,0,.407.4.41.41,0,0,0,.226-.068.406.406,0,0,0,.1-.579Z"
          transform="translate(-247.343 -349.961)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_62" dataname="Group 62" transform="translate(11.594 14.43)">
      <g id="Group_61" dataname="Group 61">
        <path
          id="Path_23"
          dataname="Path 23"
          d="M252.577,309.707a.405.405,0,0,0-.243-.238l-1.556-.558-.039-.012a.405.405,0,0,0-.324.056l-2.883,1.926,0,0,.226.337-.231-.333a.406.406,0,0,0-.1.579l2.111,2.874a.406.406,0,0,0,.327.166h.027a.406.406,0,0,0,.329-.209l2.337-4.249A.406.406,0,0,0,252.577,309.707Zm-2.766,3.64-1.483-2.02,2.369-1.583.916.328Z"
          transform="translate(-247.344 -308.887)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_64" dataname="Group 64" transform="translate(7.147 14.43)">
      <g id="Group_63" dataname="Group 63">
        <path
          id="Path_24"
          dataname="Path 24"
          d="M157.715,311.157a.406.406,0,0,0-.174-.267l-2.892-1.933a.406.406,0,0,0-.314-.059.4.4,0,0,0-.049.014l-1.556.558a.406.406,0,0,0-.219.578l2.337,4.249a.406.406,0,0,0,.329.209h.026a.406.406,0,0,0,.327-.166l1.128-1.536,0,0,.98-1.335A.406.406,0,0,0,157.715,311.157ZM156,312.325l0,0-.749,1.02-1.8-3.275.916-.328,2.369,1.583Z"
          transform="translate(-152.461 -308.889)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_66" dataname="Group 66" transform="translate(11.6 14.988)">
      <g id="Group_65" dataname="Group 65">
        <path
          id="Path_25"
          dataname="Path 25"
          d="M259.846,327.977l-.523-3.161a2.8,2.8,0,0,0-1.807-2.172l-5.064-1.832a.406.406,0,0,0-.5.563l1.057,2.12-1.038.016a.406.406,0,0,0-.353.594l.426.813-4.355,3.984-.013.012-.09.09a.406.406,0,0,0-.069.482l0,.006a.406.406,0,0,0,.356.211h10.508a1.483,1.483,0,0,0,1.463-1.725Zm-.951.676a.67.67,0,0,1-.512.237H248.9l3.919-3.585a.406.406,0,0,0,.086-.488l-.265-.505,1.023-.016a.406.406,0,0,0,.357-.587l-.892-1.79,4.11,1.487a1.986,1.986,0,0,1,1.282,1.541l.523,3.161h0A.67.67,0,0,1,258.9,328.653Z"
          transform="translate(-247.466 -320.787)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_68" dataname="Group 68" transform="translate(11.594 23.087)">
      <g id="Group_67" dataname="Group 67">
        <path
          id="Path_26"
          dataname="Path 26"
          d="M248.03,493.665l0,0a.406.406,0,0,0-.56.015l0,0a.406.406,0,0,0,.288.692h.009a.406.406,0,0,0,.272-.707Z"
          transform="translate(-247.343 -493.555)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_70" dataname="Group 70" transform="translate(11.588 23.08)">
      <g id="Group_69" dataname="Group 69">
        <path
          id="Path_27"
          dataname="Path 27"
          d="M247.9,493.517a.406.406,0,0,0-.627.106l0,.005a.406.406,0,0,0,.064.477h0a.406.406,0,0,0,.565.018l0,0a.4.4,0,0,0,.09-.118A.41.41,0,0,0,247.9,493.517Z"
          transform="translate(-247.22 -493.413)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_72" dataname="Group 72" transform="translate(0 14.988)">
      <g id="Group_71" dataname="Group 71" transform="translate(0)">
        <path
          id="Path_28"
          dataname="Path 28"
          d="M12.286,329.007l0,0,0,0-.09-.09-.013-.012-4.355-3.984.426-.812a.406.406,0,0,0-.353-.594l-1.038-.016,1.057-2.12a.406.406,0,0,0-.5-.563L2.35,322.643a2.8,2.8,0,0,0-1.807,2.172L.02,327.976A1.483,1.483,0,0,0,1.483,329.7H12a.406.406,0,0,0,.286-.693Zm-10.8-.118a.671.671,0,0,1-.662-.781l.523-3.161a1.986,1.986,0,0,1,1.282-1.541l4.11-1.487-.892,1.789a.406.406,0,0,0,.357.587l1.023.016-.265.505a.406.406,0,0,0,.086.488l3.919,3.585Z"
          transform="translate(0 -320.786)"
          fill="#fff"
        />
      </g>
    </g>
    <g id="Group_74" dataname="Group 74" transform="translate(10.611 16.365)">
      <g id="Group_73" dataname="Group 73">
        <path
          id="Path_29"
          dataname="Path 29"
          d="M229.146,352a.406.406,0,0,0-.067-.339l-.987-1.345a.406.406,0,0,0-.667.022l-.969,1.315a.406.406,0,0,0-.007.473l.413.593-.44,2.772a.406.406,0,0,0,.045.259l.942,1.713a.406.406,0,0,0,.711,0l.949-1.726a.406.406,0,0,0,.05-.2h0a.416.416,0,0,0-.005-.064l-.438-2.759.408-.586A.41.41,0,0,0,229.146,352Zm-1.381,4.427-.52-.945.444-2.8a.406.406,0,0,0-.068-.3l-.34-.488.485-.658.488.664-.336.482a.406.406,0,0,0-.068.3l.442,2.783Z"
          transform="translate(-226.376 -350.155)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const CheckedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.465"
    height="8.55"
    viewBox="0 0 11.465 8.55"
  >
    <path
      id="Icon_awesome-check"
      data-name="Icon awesome-check"
      d="M3.894,12.959.168,9.233a.573.573,0,0,1,0-.811l.811-.811a.573.573,0,0,1,.811,0l2.51,2.51L9.676,4.745a.573.573,0,0,1,.811,0l.811.811a.573.573,0,0,1,0,.811L4.7,12.959A.573.573,0,0,1,3.894,12.959Z"
      transform="translate(0 -4.577)"
      fill="#0045ff"
    />
  </svg>
);

export const BodyStrengthIcon = () => (
  <svg
    id="_5"
    data-name="5"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24.006"
    viewBox="0 0 24 24.006"
  >
    <path
      id="Path_41"
      data-name="Path 41"
      d="M46.923,24.788H40.059a1.932,1.932,0,0,0-1.935,1.7A1.886,1.886,0,0,0,40,28.56H42.37l-4.083,4.082A8.764,8.764,0,1,0,33.58,48.794c.231,0,.464-.009.7-.028a8.761,8.761,0,0,0,6.678-13.457l4.083-4.083v2.31a1.932,1.932,0,0,0,1.7,1.935A1.887,1.887,0,0,0,48.809,33.6V26.674a1.888,1.888,0,0,0-1.886-1.886Zm.815,8.807a.815.815,0,0,1-.9.811.853.853,0,0,1-.733-.868v-3.6a.536.536,0,0,0-.916-.379l-5.308,5.31a.536.536,0,0,0-.057.691,7.607,7.607,0,0,1,1.417,5.056,7.7,7.7,0,1,1-8.258-8.258q.3-.022.593-.022a7.591,7.591,0,0,1,4.462,1.439.536.536,0,0,0,.691-.057l5.31-5.308a.536.536,0,0,0-.38-.915H40a.815.815,0,0,1-.811-.9.853.853,0,0,1,.869-.733h6.864a.815.815,0,0,1,.815.815Z"
      transform="translate(-24.809 -24.788)"
      fill="#fff"
    />
    <path
      id="Path_42"
      data-name="Path 42"
      d="M39.676,54.751a6.067,6.067,0,1,0,6.067,6.067A6.067,6.067,0,0,0,39.676,54.751Zm0,11.062a5,5,0,1,1,5-5A5,5,0,0,1,39.676,65.813Z"
      transform="translate(-30.915 -45.579)"
      fill="#fff"
    />
  </svg>
);

export const BodyBuildingIcon = () => (
  <svg
    id="_4"
    data-name="4"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="22.838"
    viewBox="0 0 24 22.838"
  >
    <path
      id="Path_31"
      data-name="Path 31"
      d="M187.262,38.051c-.772-1.929-3.889-2.151-4.649-2.175a9.244,9.244,0,0,0-3.518-2.25l-.333-1.331a.387.387,0,0,0-.751.188l.387,1.548a.387.387,0,0,0,.292.284,8.079,8.079,0,0,1,3.488,2.218.386.386,0,0,0,.273.113c.95,0,3.538.307,4.092,1.692a.387.387,0,1,0,.719-.287Z"
      transform="translate(-163.289 -32.002)"
      fill="#fff"
    />
    <path
      id="Path_32"
      data-name="Path 32"
      d="M31.3,36.532a8.333,8.333,0,0,1,3.3-2.221.387.387,0,0,0,.282-.282l.387-1.548a.387.387,0,0,0-.751-.188l-.335,1.339a9.55,9.55,0,0,0-3.323,2.242c-.782.024-4.068.241-4.843,2.176a.387.387,0,0,0,.719.287c.554-1.385,3.282-1.691,4.286-1.692a.386.386,0,0,0,.273-.113Z"
      transform="translate(-25.998 -32.001)"
      fill="#fff"
    />
    <path
      id="Path_33"
      data-name="Path 33"
      d="M72.307,122.048l-1.435,1.435H65.509a1.24,1.24,0,0,1-1.122-.714l-.724-2.491a.387.387,0,0,0-.744,0l-.724,2.491a1.24,1.24,0,0,1-1.122.714H56.1l-1.435-1.435a.387.387,0,0,0-.547.547l1.548,1.548a.387.387,0,0,0,.274.113h5.136a2.016,2.016,0,0,0,1.84-1.2.422.422,0,0,0,.018-.049l.36-1.24.36,1.24a.4.4,0,0,0,.018.049,2.015,2.015,0,0,0,1.84,1.2h5.523a.387.387,0,0,0,.274-.113l1.548-1.548a.387.387,0,0,0-.547-.547Z"
      transform="translate(-51.291 -111.483)"
      fill="#fff"
    />
    <path
      id="Path_34"
      data-name="Path 34"
      d="M64.689,169.668a.387.387,0,0,0-.273-.5c-.357-.089-1.432-.457-1.683-.959a.387.387,0,0,0-.731.214,17.064,17.064,0,0,0,1.467,5.345l.854,2.422v1.1a.387.387,0,0,0,.774,0v-1.161a.384.384,0,0,0-.022-.129l-.887-2.516a.384.384,0,0,0-.028-.061,13.8,13.8,0,0,1-1.234-4.021,5.238,5.238,0,0,0,.944.415,4.5,4.5,0,0,0,.917,2.672.387.387,0,1,0,.619-.464A3.219,3.219,0,0,1,64.689,169.668Z"
      transform="translate(-58.516 -154.836)"
      fill="#fff"
    />
    <path
      id="Path_35"
      data-name="Path 35"
      d="M205.605,168.016a.387.387,0,0,0-.466.2c-.208.416-1.325.809-2.052.955a.387.387,0,0,0-.291.5,3.235,3.235,0,0,1-.717,2.355.387.387,0,0,0,.62.464,4.527,4.527,0,0,0,.919-2.65,6.461,6.461,0,0,0,1.276-.465,16.378,16.378,0,0,1-1.375,4.047c-.007.012-.013.024-.018.037l-1.08,2.516a.389.389,0,0,0-.031.153v1.161a.387.387,0,0,0,.774,0v-1.082l1.041-2.425a20.05,20.05,0,0,0,1.664-5.334A.387.387,0,0,0,205.605,168.016Z"
      transform="translate(-184.97 -154.836)"
      fill="#fff"
    />
    <path
      id="Path_36"
      data-name="Path 36"
      d="M113.871,179.1a.387.387,0,0,0,.387-.387V177.09l1.587-.317h1.51a.387.387,0,1,0,0-.774h-1.548a.393.393,0,0,0-.076.008l-1.859.372-1.859-.372a.393.393,0,0,0-.076-.008h-1.548a.387.387,0,1,0,0,.774h1.51l1.587.317v1.618A.387.387,0,0,0,113.871,179.1Z"
      transform="translate(-101.871 -162.064)"
      fill="#fff"
    />
    <path
      id="Path_37"
      data-name="Path 37"
      d="M114.129,212h-1.935a.394.394,0,0,0-.076.008l-1.859.372-1.859-.372a.394.394,0,0,0-.076-.008h-1.935a.387.387,0,1,0,0,.774h1.9l1.587.317v2.392a.387.387,0,0,0,.774,0V213.09l1.587-.317h1.9a.387.387,0,0,0,0-.774Z"
      transform="translate(-98.258 -194.58)"
      fill="#fff"
    />
    <path
      id="Path_38"
      data-name="Path 38"
      d="M162.007,85.234a.387.387,0,0,0,.379.311.375.375,0,0,0,.076-.008l3.871-.774a.387.387,0,0,0-.152-.759l-3.871.774A.387.387,0,0,0,162.007,85.234Z"
      transform="translate(-148.838 -78.965)"
      fill="#fff"
    />
    <path
      id="Path_39"
      data-name="Path 39"
      d="M86.462,84a.387.387,0,0,0-.152.759l3.871.774a.375.375,0,0,0,.076.008.387.387,0,0,0,.076-.767Z"
      transform="translate(-80.193 -78.963)"
      fill="#fff"
    />
    <path
      id="Path_40"
      data-name="Path 40"
      d="M127.7,36a.691.691,0,0,0,1.233,0l1.665-3.3a.387.387,0,1,0-.691-.349l-1.59,3.156-1.59-3.156a.387.387,0,1,0-.691.349Z"
      transform="translate(-116.322 -32.12)"
      fill="#fff"
    />
    <circle
      id="Ellipse_6"
      data-name="Ellipse 6"
      cx="0.427"
      cy="0.427"
      r="0.427"
      transform="translate(11.947 21.334)"
      fill="#fff"
    />
  </svg>
);
