import React from "react";
import PageView from "../../layouts/PageView";

import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { BackArrow } from "../../assets/svgs/BackArrow";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <PageView>
      <div
        className="text-center d-flex flex-column pb-4"
        style={{ minHeight: "100dvh" }}
      >
        <div className="d-flex justify-content-center bottom-border py-4">
          <div className="w-100 d-flex justify-content-center gap-2 align-items-center position-relative">
            <div className="position-absolute start-0 main-padding">
              <button
                onClick={() => navigate(-1)}
                className="button"
                style={{ width: "27px", height: "27px" }}
              >
                {BackArrow()}
              </button>
            </div>
            <h4 className="m-0 p-0 fw-bold text-inter text-custom-white">
              {t("PP_title")}
            </h4>
          </div>
        </div>
        <div
          style={{ textAlign: "left" }}
          className="d-flex flex-column main-padding mt-4"
        >
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_commitment")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_commitment_text")}
          </p>
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_info_collect")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_info_collect_text")}
          </p>
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_store_info")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_store_info_text")}
          </p>
          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_use_info")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_use_info_text")}
          </p>

          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_store_duration")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_store_duration_text")}
          </p>

          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_protect_info")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_protect_info_text")}
          </p>

          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_protect_self")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_protect_self_text")}
          </p>

          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_disclose")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_disclose_text")}
          </p>

          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_children")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_children_text")}
          </p>

          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_changes")}
          </h3>
          <p className="text-inter text-custom-white">{t("PP_changes_text")}</p>

          <h3 className="mt-2 fw-semibold text-inter text-custom-white">
            {t("PP_contact")}
          </h3>
          <p className="text-inter text-custom-white">
            {t("PP_contact_text")}{" "}
            <Link to="mailto:support@gainmuscle.app">
              support@gainmuscle.app
            </Link>
          </p>
        </div>
      </div>
    </PageView>
  );
};

export default PrivacyPolicy;
