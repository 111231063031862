export const ptTranslation = {
  START_NOW: "COMECE AGORA",
  "Privacy Policy": "Política de Privacidade",
  "Terms of Use": "Termos de Uso",
  "Refund Policy": "Política de Reembolso",
  "Terms and Conditions": "Termos e Condições",
  LOGIN: "ENTRAR",
  "1 MILLION": "1 MILHÃO",
  CONTINUE: "CONTINUAR",
  SAVE: "SALVAR",
  ft: "pés",
  lbs: "libras",
  kg: "kg",
  //footer
  copyright: "Direitos Autorais ® GainMuscle, 2024.",
  rights: "Todos os direitos reservados.",
  //landing page
  LP_header: "CONSTRUA MÚSCULOS EM 8 SEMANAS",
  LP_sub_header: "OBTENHA UM PLANO DE TREINO PERSONALIZADO",
  LP_text_1: "Ao pressionar o botão 'Comece Agora', você confirma e garante",
  LP_text_2: "que leu, entendeu e concordou com nossos",
  //users page
  UP_header: "DE USUÁRIOS NOS ESCOLHERAM",
  //actual body page
  AB_header: "ESCOLHA SEU <tag/>TIPO DE CORPO",
  Skinny: "Magro",
  Average: "Médio",
  //goal page
  // GP_header: "QUAL É O SEU OBJETIVO?",
  GP_header: "ESCOLHA O <tag/>TIPO DE CORPO ALVO",
  Cut: "Definição",
  Bulk: "Volume",
  "Extra Bulk": "Volume Extra",
  "Get Shredded": "Definir",
  "Muscle Gain": "Ganho de Músculo",
  "Weight Loss": "Perda de Peso",
  "GET SHREDDED": "Definir",
  "MUSCLE GAIN": "Ganho de Músculo",
  "WEIGHT LOSS": "Perda de Peso",
  //body type page
  BT_header: "O CORPO QUE VOCÊ QUER",
  Athlete: "Atleta",
  Hero: "Herói",
  Bodybuilder: "Fisiculturista",
  //motivation page
  MT_header: "O QUE TE MOTIVA A EXERCITAR-SE?",
  "Improving Health": "Melhorar a Saúde",
  "Boosting Immune System": "Reforçar o Sistema Imunológico",
  "Looking Better": "Melhorar a Aparência",
  "Building Strength": "Construir Força",
  "Boosting Libido": "Aumentar a Libido",
  //target-zones page
  TZ_header: "ESCOLHA SUAS ZONAS ALVO",
  ARMS: "braços",
  BELLY: "barriga",
  PECS: "peitoral",
  LEGS: "pernas",
  //perfect-weight page
  PW_header: "QUANDO VOCÊ ESTEVE NO SEU PESO PERFEITO?",
  "0-6 Months ago": "0-6 meses atrás",
  "7-12 Months ago": "7-12 meses atrás",
  "1-3 Years ago": "1-3 anos atrás",
  "More than 3 years ago": "Mais de 3 anos atrás",
  Never: "Nunca",
  //Interest page
  IT_header: "EM QUE VOCÊ ESTÁ INTERESSADO?",
  "Weight loss": "Perda de Peso",
  "Body sculpting": "Esculpir o Corpo",
  "Hearth Health": "Saúde do Coração",
  Mobility: "Mobilidade",
  Energy: "Energia",
  Recovery: "Recuperação",
  //fitness level page
  FL_header: "QUAL É O SEU NÍVEL DE CONDICIONAMENTO FÍSICO?",
  Beginner: "Iniciante",
  "I'm new to fitness": "Sou novo na academia",
  Intermediate: "Intermediário",
  "I workout from time to time": "De vez em quando eu malho",
  Advanced: "Avançado",
  "I exercise regularily": "Eu me exercito regularmente",
  //push-up page
  PU_header: "QUANTAS FLEXÕES VOCÊ CONSEGUE FAZER?",
  "Fewer than 12": "Menos de 12",
  "13-20": "13-20",
  "More than 20": "Mais de 20",
  "I don't know": "Eu não sei",
  //life-style page
  LS_title_1: "VOCÊ TEM",
  LS_title_2: "UM ESTILO DE VIDA SEDENTÁRIO?",
  Yes: "Sim",
  No: "Não",
  //walk page
  WL_title_1: "QUANTO",
  WL_title_2: "VOCÊ CAMINHA DIARIAMENTE?",
  "Less than 1 hour": "Menos de 1 hora",
  "1-2 hours": "1-2 horas",
  "More than 2 hours": "Mais de 2 horas",
  //Meal page
  ML_title_1: "COMO VOCÊ SE SENTE",
  ML_title_2: "ENTRE AS REFEIÇÕES?",
  ML_Subtitle:
    "Como está sua energia em diferentes momentos do dia? Selecione as opções mais apropriadas.",
  "I got sleepy when I'm hungry": "Fico sonolento quando estou com fome",
  "I am tired after I eat": "Fico cansado depois de comer",
  "I am always energized": "Estou sempre energizado",
  //Sleep page
  SL_title_1: "QUANTO VOCÊ",
  SL_title_2: "DORME?",
  "Fewer than 5 hours": "Menos de 5 horas",
  "Between 5 and 6 hours": "Entre 5 e 6 horas",
  "Between 7 and 8 hours": "Entre 7 e 8 horas",
  "Over 8 hours": "Mais de 8 horas",
  //Water page
  WT_title: "COMO É SEU CONSUMO DE ÁGUA?",
  WT_Subtitle_1: "Quanto de água você bebe ao longo do dia?",
  WT_Subtitle_2: "Selecione a resposta mais apropriada.",
  "I only drink tea and coffee": "Só bebo chá e café",
  "Fewer than 2 glasses of water": "Menos de 2 copos de água",
  "2-6 glasses of water": "2-6 copos de água",
  "7-10 glasses of water": "7-10 copos de água",
  "More than 10 glasses of water": "Mais de 10 copos de água",
  //workout location page
  WL_title: "ESCOLHA SEU LOCAL DE TREINO",
  Home: "Início",
  Gym: "Academia",
  HOME: "Início",
  GYM: "Academia",
  //height page
  HT_title: "QUAL É SUA ALTURA?",
  HT_text_1: "Calculando seu índice de massa corporal",
  HT_text_2:
    "O IMC é amplamente utilizado como fator de risco para o desenvolvimento ou prevalência",
  HT_text_3: "de vários problemas de saúde.",
  HT_error_ft: "Insira um valor de 4ft a 7ft 11in",
  HT_error_cm: "Insira um valor de 122cm a 242cm",
  //current weight page
  CW_title_1: "QUAL É O SEU",
  CW_title_2: "PESO ATUAL?",
  CW_text_1: "Seu IMC é {{bmi}}, o que é considerado {{bmiLevel}}.",
  CW_text_2:
    "Você deve tentar focar no seu peso. Iremos criar um programa personalizado.",
  Underweight: "Abaixo do peso",
  Normal: "Normal",
  Obese: "Obeso",
  Overweight: "Acima do peso",
  //target weight page
  TW_title_1: "QUAL É SEU ",
  TW_title_2: "PESO ALVO?",
  //age page
  AGE_LP_text:
    "Ao prosseguir, você confirma e garante que leu, entendeu e concordou com nossos",

  // AG_title: "QUANTOS ANOS VOCÊ TEM?",
  AG_title: "CONSTRUA SEU CORPO PERFEITO",
  AG_text_1: "Perguntamos sua idade para criar",
  AG_text_2: "seu plano pessoal",
  AG_text_3:
    "Pessoas mais velhas tendem a ter mais gordura corporal do que pessoas mais jovens com o mesmo IMC.",
  age_text: "idade {{range}}",
  //fitness summary page
  FS_title: "RESUMO DO SEU NÍVEL DE CONDICIONAMENTO FÍSICO",
  FS_bmi_text: "Índice de Massa Corporal (IMC)",
  FS_normal_bmi_text: "IMC NORMAL - {{bmi}}",
  FS_user_bmi_text: "VOCÊ: {{bmi}}",
  FS_lifeStyle_text: "Estilo de Vida",
  FS_activity_text: "Atividade",
  FS_exercise_text: "Exercício",
  FS_SEDENTARY: "SEDENTÁRIO",
  FS_ACTIVE: "ATIVO",
  FS_LOW: "BAIXO",
  FS_HIGH: "ALTO",
  "NO REGULAR WORKOUTS": "SEM TREINOS REGULARES",
  "REGULAR WORKOUTS": "TREINOS REGULARES",
  FS_text_1: "Riscos de um IMC não saudável",
  FS_text_2:
    "Pressão alta, doenças cardíacas, derrame, diabetes tipo 2, alguns tipos de câncer, osteoartrite, dor nas costas, mortalidade geral.",
  //event page
  EV_title: "HÁ UMA OCASIÃO ESPECIAL PARA A QUAL VOCÊ QUER GANHAR MÚSCULOS?",
  Vacation: "Férias",
  Competition: "Competição",
  "Important Date": "Data Importante",
  "Extreme Sports": "Esportes Radicais",
  Birthday: "Aniversário",
  "Beach Trip": "Viagem à Praia",
  Reunion: "Reunião",
  No: "Não",
  //event-date page
  ED_title: "QUANDO É SEU EVENTO?",
  ED_subtitle:
    "Uma vez que soubermos disso, poderemos criar um plano personalizado para ajudá-lo a entrar em forma. Seus dados não serão compartilhados com terceiros.",
  "Choose date": "Escolher data",
  "SKIP THIS QUESTION": "PULAR ESTA PERGUNTA",
  //weight plan page
  WP_title_1: "O ÚNICO PLANO QUE VOCÊ JÁ PRECISARÁ ",
  WP_title_2: "PARA {{text}} PESO",
  WP_text_1: "De acordo com as informações que ",
  WP_text_2: "que você nos forneceu, você atingirá seu peso ideal de",
  WP_text_3: "Prepare-se para impressionar a todos",
  WP_text_4: "no seu {{desiredWeight}}!",
  WP_weight_status: "{{target}} até {{date}}",
  GAIN: "GANHAR",
  LOSE: "PERDER",
  //personal-plan page
  creating_plan: "Criando seu plano personalizado...",
  //register page
  RG_header: "Seu Plano Pessoal",
  RG_subheader:
    "Insira seu e-mail para receber seu plano pessoal <tag>GainMuscle</tag>!",
  "Your Email": "Seu email",
  email_error: "Insira um endereço de email válido",
  "Well done!": "Muito bem!",
  policy_text:
    "Respeitamos sua privacidade. Seu email pode tornar a experiência de publicidade mais relevante. <tag> Política de Privacidade </tag> .",
  //login page
  WELCOME: "BEM-VINDO",
  "Don't have an account?": "Não tem uma conta?",
  "Start the survey": "Iniciar a pesquisa",
  Email: "Email",
  "please enter valid email": "por favor insira um e-mail válido",
  Login: "Entrar",
  "Need help?": "Precisa de ajuda?",
  "Contact us": "entre em contato conosco",
  //otp page
  OTP_header: "Insira OTP",
  OTP_subheader:
    "Uma senha de uso único foi enviada para seu <1>Email</1>: {{email}}",
  Submit: "Enviar",
  "Resend OTP": "Reenviar OTP",
  //payment page
  "3 time per week": "3 vezes por semana",
  "Target weight": "Peso alvo",
  "30 minutes": "30 minutos",
  countdown_text:
    "Seu plano personalizado foi reservado pelos próximos 15 minutos!",
  "get my plan": "OBTER MEU PLANO",
  "TRY NOW": "TENTE AGORA",
  try_and_get_1:
    "Crie um hábito e desenvolva as técnicas corretas de exercício",
  try_and_get_2:
    "Perca gordura corporal excessiva e melhore a intensidade do treino",
  try_and_get_3: "Alcance seu objetivo e mude sua vida para sempre",
  Now: "Agora",
  Goal: "Objetivo",
  "After the program": "Após o programa",
  "Body fat": "Gordura corporal",
  "Muscle Mass": "Massa Muscular",
  "Your personal workout plan is ready!":
    "Seu plano de treino personalizado está pronto!",
  "Daily calorie intake": "Ingestão diária de calorias",
  recommended: "recomendado",
  "Daily water intake": "Ingestão diária de água",
  Level: "Nível",
  "Personal summary based on your answers":
    "Resumo pessoal com base nas suas respostas",
  "Current BMI": "BMI Atual",
  "Personalized plan for You is ready!":
    "O plano personalizado para você está pronto!",
  "Workout Duration": "Duração do Treino",
  "Fitness Level": "Nível de Aptidão",
  "Place of Workout": "Local de Treino",
  "Workout Frequency": "Frequência de Treino",
  BMI_text:
    "O índice de massa corporal (IMC) é uma medida que utiliza sua altura e peso para determinar se seu peso é saudável.",
  noticable_text:
    "89% dos usuários acham os resultados do plano GainMuscle claramente perceptíveis",
  image_warning:
    "*A imagem não se destina a representar o usuário. Os resultados variam por pessoa e não são garantidos.",
  ready_text: "SEU PLANO DE TREINO ESTÁ PRONTO!",
  help_text: "Foi projetado para ajudá-lo a atingir seus objetivos",
  try_header: "EXPERIMENTE <tag>GainMuscle 7 DIAS POR US$ 1</tag> E VOCÊ IRÁ:",
  faq_header: "Perguntas frequentes",
  faq_q_1: "Como meu plano é criado?",
  faq_q_2: "Como faço para acessar meu plano?",
  faq_q_3: "Posso fazer alterações no meu plano depois de criado?",
  faq_a_1:
    "Seu plano é adaptado especificamente às suas necessidades e objetivos exclusivos, usando as respostas que você forneceu e nossos algoritmos avançados. Cada programa é validado por especialistas, garantindo que será eficaz para você.",
  faq_a_2:
    "Cada plano é personalizado por nossa equipe dedicada de nutricionistas e especialistas em fitness para atender às suas expectativas. Normalmente leva algumas horas para você receber acesso ao seu plano por e-mail.",
  faq_a_3:
    "Sim, você pode fazer ajustes em seu plano mesmo depois de criado. Basta acessar as configurações do aplicativo para atualizar seu nível de condicionamento físico, dados pessoais ou unidades de medida. Se você deseja alterar sua meta de peso alvo, vá até a guia ‘Peso’ e use o botão ‘Meta’ no canto superior direito da tela.",
  "Special Offer": "Oferta Especial",
  "7-Day Trial": "Teste de 7 Dias",
  "Total Today": "Total Hoje",
  "CARD NUMBER": "NÚMERO DO CARTÃO",
  "EXPIRES ON": "VENCE EM",
  "Credit/Debit Card": "Cartão de Crédito/Débito",
  "Process Card": "Processar Cartão",
  garrentee_text: "Compra <tag>Segura</tag> Garantida",
  "WHAT YOU GET": "O QUE VOCÊ RECEBE",
  feature1: "Mais de 1.000 exercícios certificados por treinadores",
  feature2: "Mais de 120 receitas saborosas e boas para você",
  feature3: "Treinos guiados com tutoriais em vídeo passo a passo",
  feature4:
    "Um plano de fitness personalizado adaptado às suas necessidades e nível de condicionamento físico",
  feature5:
    "Tudo em um: uma combinação de treinos de cardio, força e recuperação para os melhores resultados",
  "USERS LOVE OUR PLANS": "OS USUÁRIOS ADORAM NOSSOS PLANOS",
  review1:
    "Aplicativo de fitness fantástico! Fácil de usar, me mantém motivado e acompanha meu progresso sem problemas.",
  review2:
    "Adoro este aplicativo de fitness! Fácil acompanhamento, planos personalizados e grande motivação. Altamente recomendado!",
  review3:
    "Este aplicativo de fitness é revolucionário! É fácil de usar, me mantém motivado e os planos personalizados tornam o alcance dos meus objetivos uma moleza. Altamente impressionado!",
  review4:
    "Este é o primeiro programa que realmente me colocou em uma rotina, pois forneceu tudo o que eu precisava para começar, incluindo um menu e uma lista de compras.",
  review5:
    "Eu experimentei o programa por uma semana; ele tem recursos sólidos, um plano de treino bem estruturado e uma dieta. O plano de dieta ainda vem com uma lista de compras e instruções passo a passo.",
  review6:
    "Ótimo programa! Funciona bem e inclui um conjunto de exercícios bem projetado.",
  review7:
    "É uma maneira fantástica de entrar em forma. Eu deveria ter começado muito antes!",
  review8:
    "O plano é sólido. Eu realmente aprecio como personaliza os treinos com base no nível de condicionamento físico, mas seria ainda melhor se houvesse um modo offline.",
  review9:
    "Este é um programa incrível! Consegui perder 5 libras em apenas uma semana.",
  "OUR USERS MEET THEIR GOALS": "NOSSOS USUÁRIOS ATINGEM SEUS OBJETIVOS",
  "100% MONEY-BACK GUARANTEE": "GARANTIA DE DEVOLUÇÃO DE DINHEIRO DE 100%",
  garrentee_text2:
    "Acreditamos que nosso plano pode funcionar para você e você obterá resultados visíveis em 4 semanas! Estamos até dispostos a devolver seu dinheiro se você não vir resultados visíveis e puder provar que seguiu nosso plano. Verifique nossa <tag>Política de Reembolso</tag> para entender todos os requisitos.",
  //course page
  CP_Title: "Todos os Cursos",
  CP_NO_Courses: "Nenhum Curso Disponível",
  "Something went wrong.": "Algo deu errado.",
  //meal plan page
  MPP_Title: "Plano de Refeição Pessoal",
  "We Are Preparing Your Plan.": "Estamos Preparando Seu Plano.",
  MPP_Remember_text:
    "Lembre-se, a Consistência é a Chave Para Alcançar Seus Objetivos. Mantenha-se Motivado, Foco no Progresso e Celebre Suas Conquistas Ao Longo do Caminho!",
  MPP_Understand_Needs_text: "Compreendendo Suas Necessidades",
  MPP_Goal: "Objetivo",
  MPP_Current_status: "Status Atual",
  MPP_Challenges: "Desafios",
  MPP_Motivations: "Motivações",
  MPP_Diet_Preference: "Preferência Alimentar",
  MPP_Diet_Strategy: "Estratégia de Dieta",
  MPP_Diet_Strategy_text:
    "Focaremos em uma dieta rica em proteínas com carboidratos equilibrados e gorduras saudáveis para alimentar seus treinos e construir músculos.",
  MPP_Tips_Title: "Dicas Adicionais",
  MPP_View_Btn_Text: "Ver",
  MPP_Breakfast: "Café da Manhã",
  MPP_Lunch: "Almoço",
  MPP_Dinner: "Jantar",
  MPP_Snacks: "Lanches",
  //meal details page
  "We Are Preparing Veg Meal Details.":
    "Estamos Preparando Detalhes da Refeição Vegana.",
  "We Are Preparing Non-Veg Meal Details.":
    "Estamos Preparando Detalhes da Refeição Não-Vegana.",
  MD_Remember_text:
    "Lembre-se, a consistência é a chave para alcançar seus objetivos. Mantenha-se motivado, foque no progresso e celebre suas conquistas ao longo do caminho!",
  MD_Nutritional_Info: "Informações nutricionais",
  "(per serving)": "(por porção)",
  Carbs: "Carboidratos",
  Protein: "Proteína",
  Fat: "Gordura",
  Ingredients: "Ingredientes",
  Instructions: "Instruções",
  //veg non veg
  "Vegan Diet": "Dieta Vegana",
  Vegan: "Vegano",
  "Non-vegan": "Não vegano",
  "Non-Vegan": "Não Vegano",
  VEGAN: "Vegano",
  "NON-VEGAN": "Não Vegano",
  "Non-Vegan Diet": "Dieta Não-Vegana",
  VNP_Title: "Preferência <tag/> Alimentar",
  VNP_Banner_Veg:
    "dietas fornecem fibras, antioxidantes e micronutrientes amplamente de fontes vegetais.",
  VNP_Banner_Non_Veg:
    "dietas oferecem proteínas de alta qualidade e aminoácidos essenciais, facilitando a reparação e crescimento muscular, o que pode ser benéfico para o treino de força e resistência.",
  //settings page
  SP_toast: "Novo Plano de Refeições será atualizado em breve.",
  SP_Title: "CONFIGURAÇÃO DO PLANO",
  SP_Helper_Text: "Um novo plano será criado após salvar",
  SP_Button_Text: "Atualizar Plano",
  "Not Selected": "Não Selecionado",
  SP_Tile_1: "Seu Objetivo",
  SP_Tile_2: "Zonas Alvo",
  SP_Tile_3: "Local de Treino",
  SP_Tile_4: "Peso Atual",
  SP_Tile_5: "Peso Alvo",
  SP_Tile_6: "Preferência Alimentar",
  //subscription page
  SBP_title: "gerenciar assinatura",
  SBP_Active_Text: "ATIVO",
  SBP_Cancel_Text:
    "Você quer cancelar? <line /> Entre em contato com nossa equipe de suporte amigável <tag>aqui</tag>",
  //bottom-tab
  "Exercise Plan": "Plano de Exercícios",
  "Meal Plan": "Plano de Refeição",
  //action sheet
  "Edit Your Plan": "edite seu plano",
  Support: "suporte",
  "Terms of Service": "termos de serviço",
  "Privacy Policy": "Política de Privacidade",
  "Manage Subscription": "gerenciar assinatura",
  Logout: "sair",
};

export const privacy_policy_pt_Translation = {
  PP_title: "Privacidade e Política",
  PP_commitment: "Nosso Compromisso com a Sua Privacidade",
  PP_commitment_text:
    "Respeitamos e protegemos a privacidade dos usuários do nosso WebApp. Esta Política de Privacidade explica como coletamos e usamos suas informações e faz parte dos nossos Termos de Uso quando você utiliza nosso WebApp. Ao usar nosso WebApp e o serviço fornecido através do WebApp (“Serviço”), você consente com nossa Política de Privacidade. Se você não concorda, por favor, não use nosso WebApp.",
  PP_info_collect: "Que informações coletamos?",
  PP_info_collect_text:
    "Se você fizer compras ou adquirir uma assinatura, nossos provedores de serviços de faturamento de terceiros coletarão informações como seu nome completo, número do cartão de crédito e endereço de cobrança. Se você nos enviar um e-mail, podemos usar seu endereço de e-mail para responder ao seu e-mail. Informações Enviadas pelo Seu Dispositivo Móvel. Coletamos certas informações que seu dispositivo móvel envia quando você usa nosso WebApp, como um identificador de dispositivo, configurações do usuário e o sistema operacional do seu dispositivo, bem como informações sobre o uso de nossos Serviços. Informações de Localização. Quando você usa nosso WebApp, podemos coletar e armazenar informações sobre sua localização convertendo seu endereço IP em uma localização aproximada ou acessando as coordenadas de GPS ou localização aproximada do seu dispositivo móvel, se você ativar os serviços de localização no seu dispositivo. Podemos usar informações de localização para melhorar e personalizar nossos Serviços para você. Se você não quiser que coletemos informações de localização, você pode desativar esse recurso no seu dispositivo móvel.",
  PP_store_info: "Onde armazenamos suas informações?",
  PP_store_info_text:
    "Suas informações podem ser armazenadas em nossos próprios servidores ou em servidores de provedores de armazenamento em nuvem de terceiros. Provedores de armazenamento de terceiros não podem usar suas informações, exceto conforme previsto nesta Política de Privacidade.",
  PP_use_info: "Como usamos suas informações?",
  PP_use_info_text: "As informações que coletamos de você podem ser usadas:",
  PP_store_duration: "Por quanto tempo armazenamos suas informações?",
  PP_store_duration_text:
    "Pretendemos armazenar algumas de suas informações indefinidamente. E quanto aos links para outros sites? Podemos fornecer links para ou compatibilidade com outros sites. No entanto, não somos responsáveis pelas práticas de privacidade empregadas por esses sites ou pelas informações ou conteúdos que eles contêm.",
  PP_protect_info: "Como protegemos suas informações?",
  PP_protect_info_text:
    "Usamos procedimentos físicos, eletrônicos e outros apropriados para proteger e garantir as informações que coletamos. No entanto, esteja ciente de que a Internet é um ambiente inerentemente inseguro e os hackers estão constantemente tentando vencer as medidas de segurança. Assim, não podemos garantir que suas informações não serão acessadas, divulgadas, alteradas ou destruídas, e você aceita esse risco.",
  PP_protect_self: "Como você pode proteger suas informações?",
  PP_protect_self_text:
    "Incentivamos você a tomar medidas para manter suas informações pessoais seguras, não as compartilhando com outros ou postando online.",
  PP_disclose: "Divulgamos alguma informação para terceiros?",
  PP_disclose_text:
    "Não coletamos, vendemos, trocamos ou de outra forma transferimos para terceiros suas Informações Pessoais Identificáveis (PII) para fins comerciais ou de marketing, exceto conforme declarado aqui. Informações pessoais (e não pessoais) coletadas através do Site podem ser compartilhadas com empresas e organizações que prestam serviços em nosso nome (por exemplo, empresas que fornecem serviços de gerenciamento de dados ou outros serviços de suporte para nós, como armazenamento de dados e serviços de hospedagem na Web). Podemos compartilhar suas informações pessoais (e não pessoais) com terceiros para fornecer publicidade relevante e promover nossos produtos, mas não venderemos suas informações pessoais. Informações Divulgadas em Conexão com Transações Comerciais. Se formos adquiridos por um terceiro como resultado de uma transação, como uma fusão, aquisição ou venda de ativos, ou se nossos ativos forem adquiridos por um terceiro no caso de encerrarmos nossos negócios ou entrarmos em falência, alguns ou todos os nossos ativos, incluindo suas PII, podem ser divulgados ou transferidos para um adquirente terceiro em conexão com a transação. Informações Divulgadas para Nossa Proteção e a Proteção de Outros. Cooperamos com autoridades governamentais e de aplicação da lei ou partes privadas para fazer cumprir e cumprir a lei. Podemos divulgar qualquer informação sobre você para autoridades governamentais ou de aplicação da lei ou partes privadas conforme acreditarmos, a nosso exclusivo critério, ser necessário ou apropriado: (i) para responder a reivindicações, processos legais (incluindo intimações); (ii) para proteger nossa propriedade, direitos e segurança e a propriedade, direitos e segurança de um terceiro ou do público em geral; e (iii) para parar qualquer atividade que consideremos ilegal, antiética ou passível de ação legal.",
  PP_children: "Não Destinado a Crianças",
  PP_children_text:
    "Nosso WebApp e Serviço não são destinados a crianças com menos de 16 anos. Não coletamos intencionalmente ou especificamente informações de ou sobre crianças com menos de 16 anos.",
  PP_changes: "Alterações em nossa Política de Privacidade",
  PP_changes_text:
    "Se decidirmos alterar nossa Política de Privacidade, postaremos essas alterações nesta página. Também podemos, mas não somos obrigados a, enviar um aviso por e-mail. Recomendamos que você revise esta política de privacidade com frequência para se manter informado sobre como estamos protegendo suas informações.",
  PP_contact: "Entre em Contato Conosco",
  PP_contact_text:
    "Se você tiver dúvidas sobre nossa Política de Privacidade, entre em contato conosco em",
};

export const terms_pt_Translation = {
  TC_title: "Termos e Condições",
  main_desc:
    "This End User License Agreement é um acordo válido entre Você e a Empresa. Por favor, leia este EULA com atenção. As regras do EULA são obrigatórias para Você. Se Você não concorda em se submeter às regras do EULA, por favor, não use o WebApp. Se Você continuar a usar o WebApp, isso significará que Você leu e entendeu o EULA e concorda em se submeter a ele.",
  category_1_title: "DEFINIÇÕES E TERMOS",
  category_1_desc:
    "Para entender claramente as regras do EULA, Você deve se familiarizar com o significado dos termos usados no EULA. Neste EULA, os seguintes termos, quando em maiúsculas, terão os seguintes significados:",
  c_1_l_1:
    "“Conta” – significa uma conta pessoal de um usuário dos serviços da Plataforma, que é fornecida pelo Administrador da Plataforma nos termos de uso da Plataforma ou em documento semelhante. A Conta é acessada com um ID de Plataforma (nome da conta) e uma senha.",
  c_1_l_2:
    "“Empresa”, “Nós” – significa RBCOM, uma empresa incorporada na Irlanda, no seguinte endereço: 1 Raffles Place 40-02 One Raffles Place Tower 1",
  c_1_l_3:
    "“Conteúdo” – significa todos os tipos de conteúdo, incluindo, entre outros, dados, textos, imagens, vídeos, sons, links, software e outras informações em qualquer forma, que são disponibilizadas para Você através do WebApp.",
  c_1_l_4:
    "“Dispositivo” – significa um smartphone, tablet ou outro dispositivo móvel semelhante que Você possua ou controle legalmente exclusivamente para seu uso pessoal e não comercial.",
  c_1_l_5:
    "“EULA” – significa este Acordo de Licença de Usuário Final, que é um acordo vinculante entre Você e a Empresa. O EULA pode incorporar outros documentos, como a Política de Privacidade, que regulam as relações entre Você e Nós em relação ao uso do WebApp. Se um documento faz parte do EULA, há uma indicação clara disso.",
  c_1_l_6:
    "“Direitos de Propriedade Intelectual” – significa, coletivamente, direitos sob qualquer lei de patentes, marcas registradas, direitos autorais e segredos comerciais, e quaisquer outros direitos de propriedade intelectual ou direitos de propriedade reconhecidos em qualquer país ou jurisdição em todo o mundo, incluindo, sem limitação, direitos morais ou semelhantes.",
  c_1_l_7:
    "“Assinatura” – significa um período durante o qual Você usa o WebApp como um todo ou certo Conteúdo adicional mediante uma taxa.",
  c_1_l_8:
    "“Conteúdo Gerado pelo Usuário” – significa qualquer forma de Conteúdo, incluindo, entre outros, dados, textos, imagens, vídeos, sons, links, que foram postados por usuários em plataformas online, por exemplo, redes sociais (por exemplo, Instagram, Facebook).",
  c_1_l_9: "“Você” – significa um indivíduo que utiliza o WebApp.",
  category_2_title: "ALTERAÇÕES NO EULA",
  c_2_l_1:
    "Podemos introduzir alterações neste EULA de tempos em tempos. Você deve verificar regularmente a versão mais recente do EULA no link disponível na página da web.",
  c_2_l_2:
    "Se Você não concorda com os termos do EULA atualizado, Você não está autorizado a usar o WebApp a partir do dia em que o EULA atualizado entrar em vigor. Nesse caso, Você não deve usar o WebApp e cancelar a renovação de suas Assinaturas.",
  category_3_title: "DISPOSITIVO",
  c_3_l_1:
    "Se Você pretende usar o WebApp em qualquer Dispositivo que não seja de sua propriedade, deve ter a permissão do proprietário para fazê-lo. Você será responsável pelo cumprimento deste EULA, independentemente de ser ou não o proprietário do Dispositivo.",
  c_3_l_2:
    "Se Você não é o proprietário do Dispositivo, pode usar o WebApp apenas através de sua Conta.",
  C_3_l_3:
    "Se Você usa o Dispositivo junto com outras pessoas, por favor, certifique-se de que ninguém, exceto Você, possa usar o WebApp a partir de sua Conta. Todas as ações realizadas em sua Conta são consideradas suas ações, independentemente de quem realmente realiza essas ações. Você é totalmente responsável por todas as ações realizadas em sua Conta.",
  c_3_l_4:
    "Se Você decidir vender ou de outra forma se desfazer do Dispositivo, deve excluir o WebApp do Dispositivo.",
  category_4_title: "CAPACIDADE LEGAL E IDADE ",
  c_4_l_1:
    "O WebApp é destinado a usuários com mais de 16 anos. Se Você usar o WebApp, confirma que tem pelo menos 16 anos e tem plena capacidade legal para celebrar o Acordo.",
  c_4_l_2:
    "Se Você tiver entre 16 e 18 anos, seu pai, mãe, guardião ou outro representante legal deve verificar e concordar com este EULA em seu nome. Se Você usar o WebApp, confirma que seu pai, mãe, guardião ou outro representante legal concordou com os termos deste EULA e permite que Você use o WebApp.",
  category_5_title: "PROPRIEDADE INTELECTUAL",
  c_5_l_1:
    "Nós concedemos a Você uma licença não exclusiva, pessoal, limitada, revogável e intransferível para acessar, baixar e executar o WebApp apenas em seu Dispositivo, desde que Você concorde em cumprir todos os termos e condições deste EULA. Por favor, considere que o WebApp é licenciado, não vendido, para Você.",
  c_5_l_2:
    "A Empresa ou o licenciante da Empresa é o único titular dos Direitos de Propriedade Intelectual em relação ao WebApp. A Empresa retém todos os direitos, títulos e interesses no WebApp e reserva todos os direitos não expressamente concedidos a Você neste EULA.",
  c_5_l_3:
    "Você não pode vender, revender, distribuir, redistribuir, alugar, arrendar, sublicenciar, ceder, copiar ou de qualquer outra forma transferir ou conceder quaisquer direitos ao WebApp a qualquer terceiro.",
  c_5_l_4:
    "Você não pode distribuir ou tornar o WebApp disponível em qualquer rede onde possa ser baixado ou usado por vários usuários ao mesmo tempo, exceto quando expressamente autorizado por Nós.",
  c_5_l_5:
    "Você não pode usar qualquer dispositivo ou processo automático ou manual para interferir com o funcionamento adequado do WebApp. No entanto, Você pode usar qualquer tipo de dispositivo ou processo para excluir o WebApp de seu Dispositivo.",
  c_5_l_6:
    "Você não pode descompilar, desmontar, realizar engenharia reversa ou criar trabalhos derivados do WebApp ou de qualquer parte dele. Todas as modificações ou melhorias no WebApp permanecem como propriedade exclusiva da Empresa.",
  c_5_l_7:
    "Você não pode usar o WebApp de forma ilegal, para qualquer finalidade ilegal, ou de qualquer maneira inconsistente com este EULA, ou agir de forma fraudulenta ou maliciosa, por exemplo, hackeando ou inserindo código malicioso, como vírus, ou dados prejudiciais no WebApp.",
  category_6_title: "ASSINATURA E PERÍODO DE TESTE ",
  c_6_l_1:
    "Este WebApp como um todo ou uma parte dele (Conteúdo adicional) é fornecido com base em assinatura paga. A taxa pode variar dependendo do período de duração da Assinatura.",
  c_6_l_2:
    "A Assinatura será renovada automaticamente pelo mesmo período no final da Assinatura ao custo do pacote escolhido. Sua Conta será cobrada pela Assinatura renovada dentro de 24 horas antes do final da Assinatura atual.",
  c_6_l_3:
    "Você pode cancelar a renovação da Assinatura pelo menos 24 horas antes do final da Assinatura: neste caso, o WebApp como um todo ou seu Conteúdo adicional estará disponível para Você até o final da Assinatura. Para cancelar a renovação automática da Assinatura, altere as configurações de assinatura de sua Conta. Após o término da Assinatura, o WebApp como um todo ou seu Conteúdo adicional estará disponível apenas se uma nova Assinatura for adquirida.",
  c_6_l_4:
    "Sua assinatura não será interrompida automaticamente. Você deve cancelar a renovação automática de sua assinatura para não ser cobrado pelo custo da nova Assinatura.",
  c_6_l_5:
    "O WebApp oferece um período de teste gratuito de 7 dias. Você pode usar o WebApp ou seu Conteúdo adicional gratuitamente durante este período. Por favor, considere que no final do período de teste, ele se transformará automaticamente em uma Assinatura paga pelo período mais curto disponível e sua Conta será cobrada pelo custo dessa Assinatura, a menos que Você cancele a Assinatura através das configurações de assinatura de sua Conta pelo menos 24 horas antes do final do período de teste gratuito.",
  category_7_title: "TAXAS E TERMOS DE COMPRA ",
  c_7_l_1:
    "Os custos de todas as Assinaturas estão disponíveis na página da web.",
  c_7_l_2:
    "Quando Você compra uma Assinatura, o Administrador da Plataforma cobra de sua Conta e recolhe o dinheiro em Nosso nome. O pagamento é processado por um sistema de processamento de pagamento implementado pelo Administrador da Plataforma.",
  c_7_l_3:
    "Por favor, note que Nós não processamos seu pagamento nem temos acesso às suas informações de pagamento, como, entre outros, seus dados de cartão bancário, seu nome ou qualquer outra informação relacionada.",
  c_7_l_4:
    "Se Você tiver alguma dúvida em relação ao processamento de pagamento, por favor, envie seu problema ao serviço de suporte ao usuário do Administrador da Plataforma.",
  c_7_l_5:
    "Sua Conta será cobrada pelo custo da nova Assinatura dentro de 24 horas antes do final da Assinatura atual. Se Você cancelar a renovação automática nas últimas 24 horas da Assinatura, essa cancelamento entrará em vigor no final da nova Assinatura.",
  c_7_l_6:
    "Por favor, observe que efetuamos o reembolso do seu último pagamento. O conteúdo digital é disponibilizado para você imediatamente após a cobrança da taxa de assinatura em sua conta.",
  category_8_title: "CONTEÚDO GERADO PELO USUÁRIO",
  c_8_l_1:
    "No que diz respeito ao acesso a postagens de outros usuários do Instagram, Você pode estar exposto a Conteúdos Gerados por Usuários potencialmente questionáveis. Por favor, leve em consideração que a Empresa não controla nem verifica previamente qualquer Conteúdo Gerado por Usuários postado em qualquer rede social.",
  c_8_l_2:
    "Qualquer tipo de modificação ou censura pela Empresa em relação ao Conteúdo Gerado por Usuários pode ser considerada uma violação dos direitos humanos ou de direitos autorais e pode potencialmente induzir Você a erro.",
  c_8_l_3:
    "Se Você foi exposto a qualquer tipo de conteúdo questionável, pode usar a funcionalidade da respectiva rede social para rastrear e excluir o Conteúdo Gerado por Usuários questionável.",
  c_8_l_4:
    "Em nenhuma circunstância a Empresa será responsável por qualquer Conteúdo Gerado por Usuários.",
  category_9_title: "ISENÇÃO DE RESPONSABILIDADE",
  c_9_l_1:
    "Na máxima extensão permitida pela lei aplicável, Nós e nossos afiliados, diretores, funcionários, agentes, parceiros e licenciadores renunciam expressamente a todas as garantias de qualquer tipo, expressas ou implícitas, quanto à precisão, adequação ou integridade de qualquer informação contida em nosso WebApp ou criada pelos serviços.",
  c_9_l_2:
    "O Conteúdo em Nosso WebApp é fornecido a Você 'como está', sem quaisquer garantias quanto à comercialização ou adequação a uma finalidade específica ou uso. Nós não garantimos que Você atingirá qualquer resultado esperado com o uso do WebApp.",
  c_9_l_3: "Nós não oferecemos garantia de que:",
  c_9_l_3a: "o WebApp atenderá às suas necessidades;",
  c_9_l_3b: "o WebApp estará livre de erros, seguro ou ininterrupto;",
  c_9_l_3c: "quaisquer erros de funcionalidade do WebApp serão corrigidos;",
  c_9_l_3d: "a qualidade do WebApp atenderá às suas expectativas.",
  c_9_l_4:
    "Não teremos responsabilidade por quaisquer erros ou omissões. Não somos responsáveis por quaisquer danos, sejam diretos ou indiretos, especiais ou consequenciais, sofridos por Você ou por qualquer outra pessoa pelo uso do WebApp, mesmo que Você tenha sido avisado da possibilidade de tais danos. Nenhum conselho ou informação, seja oral ou escrita, obtida por Você de Nós ou de Nosso WebApp criará qualquer garantia não expressamente declarada no EULA. Sob nenhuma circunstância nossa responsabilidade ou de qualquer de nossos afiliados, de acordo com qualquer causa de ação, seja em contrato, delito ou outro, excederá a taxa paga por Você pelo acesso ao WebApp. Além disso, não teremos responsabilidade por atrasos ou falhas devido a circunstâncias além de nosso controle.",
  category_10_title: "TRANSFERÊNCIA DE DIREITOS",
  c_10_l_1:
    "Podemos transferir nossos direitos e obrigações sob este EULA para qualquer outra organização ou indivíduo sem o seu consentimento. Sempre informaremos Você por escrito se isso acontecer e garantiremos que a transferência não afetará seus direitos sob o EULA.",
  c_10_l_2:
    "Você só pode transferir seus direitos ou suas obrigações sob este EULA para outra pessoa se Nós concordarmos por escrito.",
  category_11_title: "ENCERRAMENTO DOS DIREITOS",
  c_11_l_1:
    "Podemos encerrar seus direitos de uso do WebApp a qualquer momento, entrando em contato com Você, se Você tiver violado as regras deste EULA de maneira séria. Se o que Você fez puder ser corrigido, daremos a Você uma oportunidade razoável para fazê-lo.",
  c_11_l_2: "Se encerrarmos seus direitos de uso do WebApp:",
  c_11_l_2a:
    "Você deve interromper todas as atividades autorizadas por este EULA, incluindo o uso do WebApp.",
  c_11_l_2b:
    "Você deve excluir ou remover o WebApp de todos os Dispositivos e destruir imediatamente todas as cópias do WebApp que Você possui e confirmar para Nós que fez isso.",
  category_12_title: "DIVERSOS",
  c_12_l_1:
    "Cada um dos parágrafos deste EULA opera separadamente. Se qualquer tribunal ou autoridade relevante decidir que algum deles é ilegal, os parágrafos restantes permanecerão em pleno vigor e efeito.",
  c_12_l_2:
    "Mesmo que demoremos a aplicar este EULA, ainda poderemos aplicá-lo posteriormente. Se não insistirmos imediatamente que Você faça algo que seja obrigado a fazer sob os termos deste EULA, ou se demorarmos a tomar medidas contra Você em relação à violação do EULA, isso não significará que Você não precise fazer essas coisas e não nos impedirá de tomar medidas contra Você em uma data posterior.",
  c_12_l_3:
    "Este EULA será regido e interpretado de acordo com as leis de Singapura, sem consideração às suas disposições de conflito de leis.",
  c_12_l_4:
    "Se surgir qualquer disputa, controvérsia ou diferença entre Você e a Empresa, ela deverá ser resolvida amigavelmente por meio de negociações. No caso de tal disputa, controvérsia ou diferença não puder ser resolvida amigavelmente através de negociações dentro de um período de trinta (30) dias, deverá ser resolvida pela Associação de Arbitragem da Irlanda de acordo com seus Procedimentos Expeditos Internacionais.",
  last_text1: "O número de árbitros será um.",
  last_text2: "O local da arbitragem será Cingapura.",
  last_text3:
    "A arbitragem será realizada e o prêmio será concedido em inglês.",
  last_text4:
    "Cada parte (ou seja, Você e a Empresa) arcará com suas próprias despesas, mas as partes compartilharão igualmente as despesas do tribunal arbitral.",
  support_message:
    "Suporte. Se você quiser saber mais sobre o aplicativo da Web ou tiver algum problema ao usá-lo, por favor, entre em contato conosco em",
};
