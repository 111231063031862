import React, { Fragment } from "react";
import "react-circular-progressbar/dist/styles.css";
import PreparingText from "../../components/PreparingText";
import MobileView from "../../layouts/MobileView";

import { t } from "i18next";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackArrowWhite,
  Calory_Icon,
  Time_Icon,
} from "../../assets/svgs/meal-plan";
import { addS3Url } from "../../modules/s3";
import { isValidObject } from "../../utils/valid";

const contants = {
  protein: 4,
  carbs: 4,
  fat: 9,
};

const Images = {
  Lunch: addS3Url("/meal-plan/Sandwich.jpeg"),
  Dinner: addS3Url("/meal-plan/Chiken.jpeg"),
};

const calculate = (key, value, calories) => {
  value = Number(value?.replace("g", ""));
  const percent = (value * contants[key] * 100) / calories;

  return percent.toFixed(0);
};

const MealSection = ({ data }) => {
  return (
    <div className="w-100 pb-2">
      <img
        src={data.image}
        alt="meal"
        className="w-100"
        style={{ maxHeight: "250px", objectFit: "cover" }}
      />
      <div className="py-3">
        <p
          style={{ fontSize: "20px", lineHeight: "24px" }}
          className="fw-bold m-0 main-padding text-inter text-custom-white"
        >
          {data.Meal}
        </p>
        <div
          className="d-flex gap-3 main-padding mt-1 border-b pb-3 mb-3"
          style={{ color: "#6C6E79", fontSize: "14px", lineHeight: "15px" }}
        >
          <div className="d-flex gap-1 align-items-center gap-2">
            <div style={{ width: "14px" }}>{Time_Icon()}</div>
            <p
              className="m-0 text-inter"
              style={{ fontSize: "14px", lineHeight: "17px" }}
            >
              15 min
            </p>
          </div>
          <div className="d-flex gap-1 align-items-center gap-2">
            <div style={{ width: "14px" }}>{Calory_Icon()}</div>
            <p
              className="m-0 text-inter"
              style={{ fontSize: "14px", lineHeight: "17px" }}
            >
              {data.Calories || "-"} cal
            </p>
          </div>
        </div>
        {data.Calories && (
          <>
            <p
              style={{ fontSize: "14px" }}
              className="fw-semibold text-center m-0 main-padding text-inter text-custom-white"
            >
              {t("MD_Nutritional_Info")}
              {/* Nutritional information */}
            </p>
            <p
              style={{ color: "#6C6E79", fontSize: "14px" }}
              className="text-center m-0 main-padding mb-3"
            >
              {t("(per serving)")}
            </p>
            <div className="d-flex pt-2 gap-4 px-5 justify-content-center">
              {[
                {
                  title: t("Carbs"),
                  percent: data.Carbs
                    ? calculate("carbs", data.Carbs, data.Calories)
                    : null,
                },
                {
                  title: t("Protein"),
                  percent: data.Protein
                    ? calculate("protein", data.Protein, data.Calories)
                    : null,
                },
                data.Fat && {
                  title: t("Fat"),
                  percent: data.Fat
                    ? calculate("fat", data.Fat, data.Calories)
                    : null,
                },
              ].map(
                (data, index) =>
                  data?.percent && (
                    <div
                      className="d-flex flex-column align-items-center gap-1 "
                      key={index}
                      style={{ maxWidth: "82px" }}
                    >
                      <CircularProgressbarWithChildren
                        strokeWidth={7}
                        value={Number(data.percent)}
                        key={index}
                        styles={{
                          path: {
                            stroke: "#0045FF",
                            width: "100px",
                          },
                          trail: {
                            stroke: "#D6D6D6",
                          },
                        }}
                        className="custom-progress-bar"
                      >
                        <div className="fw-semibold">
                          <span
                            className="text-inter text-custom-white"
                            style={{ fontSize: "30px" }}
                          >
                            {data.percent}
                          </span>
                          <span
                            className="text-inter text-custom-white"
                            style={{ fontSize: "10px" }}
                          >
                            %
                          </span>
                        </div>
                      </CircularProgressbarWithChildren>
                      <p
                        className="fw-semibold m-0 text-inter text-custom-white"
                        style={{ fontSize: "14px" }}
                      >
                        {data.title}
                      </p>
                    </div>
                  )
              )}
            </div>
          </>
        )}
        {(data.Calories || data.Protein || data.Carbs) && (
          <div className="my-3 border-b">
            {[
              { Calories: data.Calories ? data.Calories + "cal" : null },
              { Protein: data.Protein },
              { Carbs: data.Carbs },
            ].map(
              (d, index) =>
                d[Object.keys(d)[0]] && (
                  <Fragment key={index}>
                    <div
                      key={index}
                      className="d-flex gap-2 align-content-center justify-content-between main-padding border-t"
                      style={{ paddingTop: "18px", paddingBottom: "18px" }}
                    >
                      <p
                        style={{ fontSize: "14px" }}
                        className="fw-semibold m-0 text-inter text-custom-white"
                      >
                        {Object.keys(d)[0]}
                      </p>
                      <p
                        style={{ fontSize: "14px", color: "#B2B2B2" }}
                        className="fw-semibold m-0 text-inter"
                      >
                        {Object.values(d)[0]}
                      </p>
                    </div>
                  </Fragment>
                )
            )}
          </div>
        )}
        {data.Ingredients && (
          <div className="main-padding d-flex flex-column gap-2">
            <p
              style={{ fontSize: "14px" }}
              className="fw-semibold m-0 text-inter text-custom-white"
            >
              {t("Ingredients")}:
            </p>
            <ul className="ul-meal-details d-flex flex-column gap-1 border-1 border-start border-diet-plan">
              {typeof data.Ingredients === "string" ? (
                <li
                  className="text-inter"
                  style={{ fontSize: "14px", fontWeight: "normal" }}
                >
                  {data.Ingredients}
                </li>
              ) : (
                data?.Ingredients?.map(
                  (ingredient, index) =>
                    ingredient && (
                      <li
                        className="text-inter"
                        key={index}
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "#B2B2B2",
                        }}
                      >
                        {ingredient}
                      </li>
                    )
                )
              )}
            </ul>
          </div>
        )}
        {data?.Instructions && (
          <div className="main-padding d-flex flex-column gap-2">
            <p
              style={{ fontSize: "14px" }}
              className="fw-semibold m-0 text-inter text-custom-white"
            >
              {t("Instructions")}:
            </p>
            <ol className="ol-meal-details fw-semibold ps-3 d-flex flex-column gap-1">
              {typeof data.Instructions === "string" ? (
                <li
                  className="text-inter"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#B2B2B2",
                  }}
                >
                  {data.Instructions}
                </li>
              ) : (
                data.Instructions?.map(
                  (instruction, index) =>
                    instruction && (
                      <li
                        className="text-inter"
                        key={index}
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "#B2B2B2",
                        }}
                      >
                        {instruction}
                      </li>
                    )
                )
              )}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

const MealDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    document.getElementById("scroll-div").scrollTo(0, 0);
  }, []);

  return (
    <>
      <MobileView>
        <div className="flex-grow-1 position-relative">
          <div
            className="position-absolute d-flex justify-content-center "
            style={{ width: "100%", top: "30px" }}
          >
            <div style={{ width: "100%" }} className="main-padding">
              <button
                onClick={() => navigate(-1)}
                className="button"
                style={{ width: "27px", height: "27px" }}
              >
                {BackArrowWhite()}
              </button>
            </div>
          </div>
          {location.state ? (
            <>
              {isValidObject(location.state.details["Veg"]) ? (
                <MealSection
                  data={{
                    ...location.state.details.Veg,
                    image:
                      location.state.key === "Breakfast"
                        ? addS3Url("/meal-plan/meal1.png")
                        : Images[location.state.key],
                  }}
                />
              ) : (
                <>
                  <PreparingText customText="We Are Preparing Veg Meal Details." />
                </>
              )}
              {isValidObject(location.state.details["Non-Veg"]) ? (
                <MealSection
                  data={{
                    ...location.state.details["Non-Veg"],
                    image:
                      location.state.key === "Breakfast"
                        ? addS3Url("/meal-plan/meal2.png")
                        : Images[location.state.key],
                  }}
                />
              ) : (
                <>
                  <PreparingText customText="We Are Preparing Non-Veg Meal Details." />
                </>
              )}
            </>
          ) : (
            <>
              <PreparingText customText="We Are Preparing Your Meal Details." />
            </>
          )}

          <div className="main-padding py-2">
            <p
              className="text-center fw-semibold text-inter text-custom-white"
              style={{ fontSize: "16px" }}
            >
              Remember, consistency is key to achieving your goals. Stay
              motivated, focus on progress, and celebrate your successes along
              the way!
            </p>
          </div>
        </div>
      </MobileView>
    </>
  );
};

export default MealDetails;
