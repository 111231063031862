import React from "react";
import ImageLoader from "../components/ImageLoader";

const Tile = ({ text, setSelect, isSelected, ...props }) => {
  return (
    <button
      tabIndex={0}
      className={`button-hover bg-secondary-black p-0 w-100 d-flex align-items-center justify-content-between goal-tile ${
        isSelected ? "button-active" : ""
      }`}
      style={{ marginTop: "10px" }}
      onClick={setSelect}
    >
      <h6 className="ms-3 text-custom-white text-item text-inter fw-bold mb-0 text-start">
        {text}
      </h6>
      <ImageLoader
        src={props.icon}
        {...props}
        alt="icon"
        height={"100%"}
        className="goal-tile-image"
      />
    </button>
  );
};

const BigIconLayout = ({
  title,
  items,
  handleSelect,
  selected,
  titleClasses,
}) => {
  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-between pt-2 main-padding-b main-padding">
      <h1
        className={`goal-header mt-0 main-margin-b text-inter fw-bold text-center ${
          titleClasses || ""
        }`}
      >
        {title}
      </h1>
      <div className="w-100">
        {items.map((item) => (
          <Tile
            key={item.id}
            isSelected={selected === item.id}
            setSelect={() => handleSelect(item)}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default BigIconLayout;
