import i18next, { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import { Part_Three_Four } from "../../../constants/pageRouteName";
import PartThreeLayout from "../../../layouts/PartThreeLayout";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import {
  pagePreloadImages,
  placeHolderImages,
  s3Images,
} from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const items = [
  {
    id: 1,
    text: t("Yes"),
    value: "Yes",
  },
  {
    id: 2,
    text: t("No"),
    value: "No",
  },
];

const Image = () => {
  return (
    <ImageLoader
      placeHolderImage={placeHolderImages.lifeStyle}
      src={s3Images.lifeStyle}
      alt="lifestyle"
      className="w-100 lifestyle-img"
    />
  );
};

const LifeStyle = () => {
  const [selected, setSelected] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelect = (id) => {
    dispatch(
      setDetailsData({
        sedentary: { text: items[id - 1].value },
      })
    );
    nextPageRoute(
      navigate,
      Part_Three_Four,
      dispatch,
      pagePreloadImages.lifeStyle
    );
  };

  return (
    <PartThreeLayout
      handleSelect={handleSelect}
      title={
        <>
          {t("LS_title_1")}
          {i18next.language === "es" ? "" : <br />} {t("LS_title_2")}
        </>
      }
      Image={Image}
      selected={selected?.id}
      items={items}
    />
  );
};

export default LifeStyle;
