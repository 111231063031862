import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileView from "./MobileView";

const PageView = ({
  classNames,
  showHeader,
  showFooter,
  showLoginBtn,
  showMenuBtn,
  children,
}) => {
  return (
    <div
      className={`flex-grow-1 d-flex flex-column justify-content-between w-100 ${classNames ? classNames : ""}`}
      style={{ minHeight: "100dvh" }}
    >
      {showHeader && (
        <Header showLoginBtn={showLoginBtn} showMenuBtn={showMenuBtn} />
      )}
      <MobileView>{children}</MobileView>
      {showFooter && <Footer />}
    </div>
  );
};

export default PageView;
