import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tile from "../../../components/Tile";
import { Part_Two_Six } from "../../../constants/pageRouteName";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { nextPageRoute } from "../../../utils/navigationTransition";

const Items = [
  { text: t("0-6 Months ago"), value: "0-6 Months ago", id: 1 },
  { text: t("7-12 Months ago"), value: "7-12 Months ago", id: 2 },
  { text: t("1-3 Years ago"), value: "1-3 Years ago", id: 3 },
  { text: t("More than 3 years ago"), value: "More than 3 years ago", id: 4 },
  { text: t("Never"), value: "Never", id: 5 },
];

const PerfectWeight = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoNextPage = (id) => {
    dispatch(
      setDetailsData({
        previousPerfectWeight: {
          text: Items[id - 1].value,
          id: Items[id - 1].id,
        },
      })
    );
    nextPageRoute(navigate, Part_Two_Six);
  };

  return (
    <div className="flex-grow-1 pt-2 pb-3 main-padding d-flex flex-column justify-content-start">
      <div className="w-100 d-flex justify-content-center">
        <h1
          className="motivation-header mt-0 text-inter fw-bold text-center main-margin-b"
          style={{ maxWidth: "320px" }}
        >
          {t("PW_header")}
        </h1>
      </div>
      <div className="d-flex flex-column gap-2">
        {Items.map((item) => (
          <Tile
            {...item}
            key={item.id}
            handleClick={gotoNextPage}
            selected={selected}
          />
        ))}
      </div>
    </div>
  );
};

export default PerfectWeight;
