import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import CheckBoxTile from "../../../components/CheckBoxTile";
import { Part_Three_One } from "../../../constants/pageRouteName";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { pagePreloadImages } from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const Items = [
  {
    id: 1,
    text: t("Weight loss"),
    value: "Weight loss",
  },
  {
    id: 2,
    text: t("Body sculpting"),
    value: "Body sculpting",
  },
  {
    id: 3,
    text: t("Hearth Health"),
    value: "Hearth Health",
  },
  {
    id: 4,
    text: t("Mobility"),
    value: "Mobility",
  },
  {
    id: 5,
    text: t("Energy"),
    value: "Energy",
  },
  {
    id: 6,
    text: t("Recovery"),
    value: "Recovery",
  },
];

const Interest = () => {
  const [selected, setSelected] = React.useState([]);
  const { details } = useSelector((state) => state.detailState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      dispatch(
        setDetailsData({
          interest: details.interest.filter(
            (item) => item.id !== Items[id - 1].id
          ),
        })
      );
      setSelected(selected.filter((item) => item !== id));
    } else {
      if (details.interest) {
        dispatch(
          setDetailsData({
            interest: [
              ...details.interest,
              { text: Items[id - 1].value, id: Items[id - 1].id },
            ],
          })
        );
      } else {
        dispatch(
          setDetailsData({
            interest: [{ text: Items[id - 1].value, id: Items[id - 1].id }],
          })
        );
      }
      setSelected((pre) => [...pre, id]);
    }
  };

  React.useEffect(() => {
    dispatch(setDetailsData({ interest: [] }));
  }, []);

  const gotoNextPage = () => {
    nextPageRoute(
      navigate,
      Part_Three_One,
      dispatch,
      pagePreloadImages.interest
    );
  };

  return (
    <div className="flex-grow-1 pt-2 main-padding-b main-padding d-flex flex-column justify-content-between gap-2">
      <div>
        <div className="w-100 d-flex justify-content-center">
          <h1
            className="motivation-header mt-0 text-inter fw-bold text-center main-margin-b"
            style={{ maxWidth: "200px" }}
          >
            {t("IT_header")}
          </h1>
        </div>
        <div className="d-flex flex-column gap-2">
          {Items.map((item) => (
            <CheckBoxTile
              {...item}
              key={item.id}
              handleSelection={handleSelection}
              selected={selected.find(
                (selectedItem) => selectedItem === item.id
              )}
            />
          ))}
        </div>
      </div>
      <Button
        disabled={!selected?.length}
        label={t("CONTINUE")}
        onClick={gotoNextPage}
      />
    </div>
  );
};

export default Interest;
