import React from "react";

const ImageLoader = ({ placeHolderImage, src, ...props }) => {
  const [imageSrc, setImageSrc] = React.useState(placeHolderImage || src);

  React.useEffect(() => {
    if (imageSrc !== (placeHolderImage || src))
      setImageSrc(placeHolderImage || src);
    if (placeHolderImage) {
      const img = new Image();
      img.src = src;
      img.fetchpriority = "high";
      img.onload = () => {
        setImageSrc(src);
      };
    }
  }, [src, placeHolderImage]);

  return <img src={imageSrc} fetchpriority="high" loading="eager" {...props} />;
};

export default ImageLoader;
