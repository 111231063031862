import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatePlanData } from "../../modules/apiHelper";
import { addS3Url } from "../../modules/s3";
import { setDetailsData } from "../../redux/slices/DetailsSlice";
import { setPlanData } from "../../redux/slices/PlanSettingSlice";

const bannerData = {
  Vegan: t("VNP_Banner_Veg"),
  "Non-vegan": t("VNP_Banner_Non_Veg"),
};

const VegNon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Items = [
    {
      label: t("Vegan Diet"),
      img: addS3Url("/veg-nonVeg/veg.png"),
      handleClick: () => {
        dispatch(setDetailsData({ diet: "Vegan" }));
        updatePlanData(navigate, dispatch, { dietPreference: "Vegan" });
      },
    },
    {
      label: t("Non-Vegan Diet"),
      img: addS3Url("/veg-nonVeg/non-veg.png"),
      handleClick: () => {
        dispatch(setPlanData({ diet: "Non-Vegan" }));
        updatePlanData(navigate, dispatch, { dietPreference: "Non-Vegan" });
      },
    },
  ];

  return (
    <div className="flex-grow-1 main-padding d-flex flex-column justify-content-between pt-5">
      <h1
        className="text-inter fw-bold text-center text-uppercase"
        style={{ fontSize: "24px", lineHeight: "29px", color: "#FFFFFF" }}
      >
        <Trans i18nKey={"VNP_Title"} components={{ tag: <br /> }} />
      </h1>
      <div className="d-flex justify-content-center gap-2">
        {Items.map((item, index) => (
          <div
            onClick={item.handleClick}
            key={index}
            className="w-100 d-flex flex-column align-items-center gap-4"
          >
            <img
              src={item.img}
              alt="Veg"
              className="w-100 px-2"
              style={{ maxHeight: "153px", objectFit: "contain" }}
            />
            <p
              className="text-inter fw-bold text-center m-0"
              style={{ color: "#FFFFFF", fontSize: "19px", lineHeight: "24px" }}
            >
              {item.label}
            </p>
          </div>
        ))}
      </div>
      <div className="banner">
        {Object.keys(bannerData).map((key, index) => (
          <p
            key={index}
            className=" text-start m-0 text-inter mb-2"
            style={{ color: "#B2B2B2", fontSize: "14px", lineHeight: "17px" }}
          >
            <span className="fw-bold">{t(key)}</span> {bannerData[key]}
          </p>
        ))}
      </div>
    </div>
  );
};

export default VegNon;
