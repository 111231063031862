import { CurrentWeight } from "../../pages/register-flow/part-three/CurrentWeight";

export const VacationIcon = () => (
  <svg
    id="_1"
    data-name="1"
    xmlns="http://www.w3.org/2000/svg"
    width="25.817"
    height="24.424"
    viewBox="0 0 25.817 24.424"
  >
    <path
      id="Path_203"
      data-name="Path 203"
      d="M24.083,60.095a5.029,5.029,0,1,0-7.111,7.111l.456.456,7.111-7.111Z"
      transform="translate(-14.717 -56.363)"
      fill="#fff594"
    />
    <g id="Group_294" data-name="Group 294" transform="translate(0 1.478)">
      <path
        id="Path_204"
        data-name="Path 204"
        d="M10.069,44.852a5.9,5.9,0,1,0-8.341,8.341l.535.535L10.6,45.386Zm-6.122,3.57-2.4.02A4.336,4.336,0,0,1,2.2,46.671Zm-.73-2.869a4.337,4.337,0,0,1,1.732-.812l.031,2.575Zm-1.6,4.4,2.3-.019L2.308,51.543a4.333,4.333,0,0,1-.7-1.589Zm4.88-2.595L6.46,44.672a4.338,4.338,0,0,1,1.959.759Z"
        transform="translate(0 -43.124)"
        fill="#fed200"
      />
    </g>
    <g id="Group_295" data-name="Group 295" transform="translate(3.083 7.637)">
      <path
        id="Path_205"
        data-name="Path 205"
        d="M63.015,165.268a10.736,10.736,0,0,0,8.868,16.787l1.055-8.2-1.055-8.582H63.015Z"
        transform="translate(-61.147 -165.268)"
        fill="#9f5f4e"
      />
      <path
        id="Path_206"
        data-name="Path 206"
        d="M282.915,165.269h-8.868v16.787a10.736,10.736,0,0,0,8.868-16.787Z"
        transform="translate(-263.312 -165.269)"
        fill="#714437"
      />
    </g>
    <g id="Group_296" data-name="Group 296" transform="translate(4.674 6.904)">
      <path
        id="Path_207"
        data-name="Path 207"
        d="M94.222,150.736a1.524,1.524,0,1,0,0,3.048h2.06L97.4,155.8l1.114-2.013h3.333l.7-1.371-.7-1.677H94.222Z"
        transform="translate(-92.698 -150.736)"
        fill="#f7ecda"
      />
      <path
        id="Path_208"
        data-name="Path 208"
        d="M281.621,150.737h-7.574v3.048h2.28l.873-.395.667.395h3.754a1.524,1.524,0,1,0,0-3.048Z"
        transform="translate(-264.903 -150.737)"
        fill="#ecd6b2"
      />
    </g>
    <g id="Group_298" data-name="Group 298" transform="translate(7.921 18.077)">
      <g id="Group_297" data-name="Group 297">
        <path
          id="Path_209"
          data-name="Path 209"
          d="M157.078,372.318h1.512v1.513h-1.512Z"
          transform="translate(-157.078 -372.318)"
          fill="#c8885e"
        />
      </g>
    </g>
    <g id="Group_303" data-name="Group 303" transform="translate(9.433 16.564)">
      <g id="Group_300" data-name="Group 300">
        <g id="Group_299" data-name="Group 299">
          <path
            id="Path_210"
            data-name="Path 210"
            d="M187.068,342.3h1.512v1.512h-1.512Z"
            transform="translate(-187.068 -342.302)"
            fill="#c8885e"
          />
        </g>
      </g>
      <g
        id="Group_302"
        data-name="Group 302"
        transform="translate(1.513 1.514)"
      >
        <g id="Group_301" data-name="Group 301">
          <path
            id="Path_211"
            data-name="Path 211"
            d="M217.067,372.318h1.512v1.513h-1.512Z"
            transform="translate(-217.067 -372.318)"
            fill="#c8885e"
          />
        </g>
      </g>
    </g>
    <path
      id="Path_212"
      data-name="Path 212"
      d="M322.508,13.815l-1.912,1.57-1.486,8.382h1.536l1.341-7.568,1.062-.872h5.787V13.815Z"
      transform="translate(-303.019 -13.815)"
      fill="#fd445f"
    />
    <path
      id="Path_213"
      data-name="Path 213"
      d="M350.488,13.815l-1.912,1.57,1.392.814,1.062-.872Z"
      transform="translate(-330.999 -13.815)"
      fill="#e1105a"
    />
  </svg>
);

export const CompetitionIcon = () => (
  <svg
    id="_2"
    data-name="2"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23.388"
    viewBox="0 0 24 23.388"
  >
    <g id="Group_133" data-name="Group 133" transform="translate(0 4.932)">
      <path
        id="Path_82"
        data-name="Path 82"
        d="M184.84,185.581h-6.346a.483.483,0,0,1-.483-.483V170.332a.483.483,0,0,1,.483-.483h6.346a.483.483,0,0,1,.483.483V185.1A.483.483,0,0,1,184.84,185.581Z"
        transform="translate(-169.667 -167.125)"
        fill="#fb4a59"
      />
      <path
        id="Path_83"
        data-name="Path 83"
        d="M286.452,170.334V185.1a.483.483,0,0,1-.483.483h-1.855a.483.483,0,0,0,.483-.483V170.334a.483.483,0,0,0-.483-.483h1.855A.483.483,0,0,1,286.452,170.334Z"
        transform="translate(-270.796 -167.127)"
        fill="#f82f40"
      />
      <g id="Group_132" data-name="Group 132" transform="translate(0 0)">
        <g
          id="Group_127"
          data-name="Group 127"
          transform="translate(15.656 3.061)"
        >
          <path
            id="Path_84"
            data-name="Path 84"
            d="M341.85,377.181h-7.378a.483.483,0,0,1-.483-.483v-5.344a.483.483,0,0,1,.483-.483h7.378a.483.483,0,0,1,.483.483V376.7A.483.483,0,0,1,341.85,377.181Z"
            transform="translate(-333.989 -361.787)"
            fill="#fddd45"
          />
          <path
            id="Path_85"
            data-name="Path 85"
            d="M464.456,371.353V376.7a.483.483,0,0,1-.483.483h-1.855a.483.483,0,0,0,.483-.483v-5.344a.483.483,0,0,0-.483-.483h1.855A.483.483,0,0,1,464.456,371.353Z"
            transform="translate(-456.112 -361.785)"
            fill="#f8c52d"
          />
          <g id="Group_125" data-name="Group 125" transform="translate(1.4)">
            <circle
              id="Ellipse_12"
              data-name="Ellipse 12"
              cx="3.139"
              cy="3.139"
              r="3.139"
              transform="translate(0 0)"
              fill="#fc9744"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M413.189,180.192a3.141,3.141,0,0,1-4.158,2.969h.011a2.969,2.969,0,0,0,0-5.938h-.013a3.141,3.141,0,0,1,4.16,2.969Z"
              transform="translate(-406.911 -177.053)"
              fill="#fb8627"
            />
          </g>
          <g
            id="Group_126"
            data-name="Group 126"
            transform="translate(2.556 1.157)"
          >
            <circle
              id="Ellipse_13"
              data-name="Ellipse 13"
              cx="1.982"
              cy="1.982"
              r="1.982"
              transform="translate(0 0)"
              fill="#fddd45"
            />
            <path
              id="Path_87"
              data-name="Path 87"
              d="M416.83,203.709a1.982,1.982,0,0,1-2.767,1.82,1.868,1.868,0,0,0,0-3.641,1.983,1.983,0,0,1,2.767,1.821Z"
              transform="translate(-412.866 -201.727)"
              fill="#f8c52d"
            />
          </g>
          <path
            id="Path_88"
            data-name="Path 88"
            d="M410.474,228.345a.361.361,0,0,1-.256-.106l-.353-.353a.362.362,0,0,1,.512-.512l.1.1.641-.641a.362.362,0,1,1,.512.512l-.9.9A.362.362,0,0,1,410.474,228.345Z"
            transform="translate(-406.208 -224.396)"
            fill="#fb8627"
          />
        </g>
        <g id="Group_131" data-name="Group 131">
          <path
            id="Path_89"
            data-name="Path 89"
            d="M7.861,300.384H.483A.483.483,0,0,1,0,299.9v-9.121a.483.483,0,0,1,.483-.483H7.861a.483.483,0,0,1,.483.483V299.9A.483.483,0,0,1,7.861,300.384Z"
            transform="translate(0 -281.928)"
            fill="#31a7fb"
          />
          <path
            id="Path_90"
            data-name="Path 90"
            d="M130.471,290.783v9.12a.483.483,0,0,1-.483.483h-1.855a.483.483,0,0,0,.483-.483v-9.12a.483.483,0,0,0-.483-.483h1.855A.483.483,0,0,1,130.471,290.783Z"
            transform="translate(-122.127 -281.93)"
            fill="#1c96f9"
          />
          <g id="Group_130" data-name="Group 130" transform="translate(0.614)">
            <g id="Group_128" data-name="Group 128">
              <circle
                id="Ellipse_14"
                data-name="Ellipse 14"
                cx="3.139"
                cy="3.139"
                r="3.139"
                transform="translate(0 0)"
                fill="#fc9744"
              />
              <path
                id="Path_91"
                data-name="Path 91"
                d="M62.428,114.882a3.141,3.141,0,0,1-4.158,2.969h.011a2.969,2.969,0,1,0,0-5.938h-.013a3.141,3.141,0,0,1,4.16,2.969Z"
                transform="translate(-56.15 -111.743)"
                fill="#fb8627"
              />
            </g>
            <g
              id="Group_129"
              data-name="Group 129"
              transform="translate(1.157 1.157)"
            >
              <circle
                id="Ellipse_15"
                data-name="Ellipse 15"
                cx="1.982"
                cy="1.982"
                r="1.982"
                transform="translate(0 0)"
                fill="#fddd45"
              />
              <path
                id="Path_92"
                data-name="Path 92"
                d="M66.068,138.4a1.982,1.982,0,0,1-2.767,1.82,1.868,1.868,0,0,0,0-3.641,1.983,1.983,0,0,1,2.767,1.821Z"
                transform="translate(-62.104 -136.417)"
                fill="#f8c52d"
              />
            </g>
            <path
              id="Path_93"
              data-name="Path 93"
              d="M59.712,163.035a.361.361,0,0,1-.256-.106l-.353-.353a.362.362,0,1,1,.512-.512l.1.1.641-.641a.362.362,0,1,1,.512.512l-.9.9A.361.361,0,0,1,59.712,163.035Z"
              transform="translate(-56.846 -159.086)"
              fill="#fb8627"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_138" data-name="Group 138" transform="translate(7.332)">
      <ellipse
        id="Ellipse_16"
        data-name="Ellipse 16"
        cx="0.944"
        cy="0.964"
        rx="0.944"
        ry="0.964"
        transform="translate(3.724 4.256)"
        fill="#fc9744"
      />
      <path
        id="Path_94"
        data-name="Path 94"
        d="M247.538,98.291a.954.954,0,0,1-.945.964.924.924,0,0,1-.463-.124.933.933,0,0,0,0-1.681.924.924,0,0,1,.463-.124A.955.955,0,0,1,247.538,98.291Z"
        transform="translate(-241.925 -93.071)"
        fill="#fb8627"
      />
      <g id="Group_136" data-name="Group 136" transform="translate(0 0)">
        <g id="Group_135" data-name="Group 135" transform="translate(0 0.121)">
          <g
            id="Group_134"
            data-name="Group 134"
            transform="translate(5.685 0)"
          >
            <path
              id="Path_95"
              data-name="Path 95"
              d="M279.084,13.026h-1.033a.362.362,0,0,1,0-.724h1.033a1.533,1.533,0,0,0,1.532-1.532V9.952a.121.121,0,0,0-.121-.121h-2.069a.362.362,0,0,1,0-.724H280.5a.846.846,0,0,1,.845.845v.818A2.259,2.259,0,0,1,279.084,13.026Z"
              transform="translate(-277.689 -9.107)"
              fill="#fb8627"
            />
          </g>
          <path
            id="Path_96"
            data-name="Path 96"
            d="M159.7,13.025h-1.033a2.259,2.259,0,0,1-2.256-2.256V9.951a.846.846,0,0,1,.845-.845h2.069a.362.362,0,0,1,0,.724h-2.069a.121.121,0,0,0-.121.121v.818a1.533,1.533,0,0,0,1.532,1.532H159.7a.362.362,0,1,1,0,.724Z"
            transform="translate(-156.413 -9.106)"
            fill="#fb8627"
          />
        </g>
        <path
          id="Path_97"
          data-name="Path 97"
          d="M206.472,11.089a2.436,2.436,0,0,1-2.436-2.436V7.013a.483.483,0,0,1,.483-.483h3.906a.483.483,0,0,1,.483.483v1.64A2.436,2.436,0,0,1,206.472,11.089Z"
          transform="translate(-201.804 -6.53)"
          fill="#fddd45"
        />
        <path
          id="Path_98"
          data-name="Path 98"
          d="M245.053,7.009v1.64A2.438,2.438,0,0,1,241.959,11a2.437,2.437,0,0,0,1.778-2.346V7.009a.483.483,0,0,0-.483-.483h1.316A.483.483,0,0,1,245.053,7.009Z"
          transform="translate(-237.949 -6.526)"
          fill="#f8c52d"
        />
      </g>
      <g
        id="Group_137"
        data-name="Group 137"
        transform="translate(2.592 6.184)"
      >
        <path
          id="Path_99"
          data-name="Path 99"
          d="M215.628,139.934a.241.241,0,0,0,.2-.372,2.436,2.436,0,0,0-4.073,0,.241.241,0,0,0,.2.372Z"
          transform="translate(-211.719 -138.463)"
          fill="#fddd45"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M238.715,139.932h-.767a2.354,2.354,0,0,0-2.006-1.283,2.437,2.437,0,0,1,2.977.911A.241.241,0,0,1,238.715,139.932Z"
          transform="translate(-234.807 -138.461)"
          fill="#f8c52d"
        />
      </g>
    </g>
  </svg>
);

export const ImportantDateIcon = () => (
  <svg id="_3" data-name="3" width="24" height="22.922" viewBox="0 0 24 22.922">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.463"
        y1="0.914"
        x2="0.463"
        y2="-0.169"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ffcf95" />
        <stop offset="0.427" stopColor="#ffc954" />
        <stop offset="1" stopColor="#ffc200" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="1.217"
        y1="1.457"
        x2="-0.633"
        y2="1.457"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ffc200" stopOpacity="0" />
        <stop offset="0.203" stopColor="#fb0" stopOpacity="0.204" />
        <stop offset="0.499" stopColor="#ffa700" stopOpacity="0.498" />
        <stop offset="0.852" stopColor="#f80" stopOpacity="0.851" />
        <stop offset="1" stopColor="#ff7800" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.518"
        y1="-0.407"
        x2="0.353"
        y2="1.223"
        href="#linear-gradient-2"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="-0.462"
        y1="-1.03"
        x2="0.591"
        y2="-0.306"
        href="#linear-gradient-2"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="-0.151"
        y1="-0.116"
        x2="0.585"
        y2="-0.571"
        href="#linear-gradient-2"
      />
      <linearGradient
        id="linear-gradient-6"
        x1="0.61"
        y1="2.287"
        x2="0.415"
        y2="1.017"
        href="#linear-gradient-2"
      />
      <linearGradient
        id="linear-gradient-7"
        x1="1.34"
        y1="1.58"
        x2="-0.083"
        y2="0.368"
        href="#linear-gradient-2"
      />
    </defs>
    <path
      id="Path_101"
      data-name="Path 101"
      d="M12.883,12.05l3.174,6.432,7.1,1.031a.985.985,0,0,1,.546,1.68L18.565,26.2l1.213,7.07a.985.985,0,0,1-1.429,1.038L12,30.97,5.651,34.307a.985.985,0,0,1-1.429-1.038L5.435,26.2.3,21.193a.985.985,0,0,1,.546-1.68l7.1-1.031,3.174-6.432A.985.985,0,0,1,12.883,12.05Z"
      transform="translate(0 -11.501)"
      fill="url(#linear-gradient)"
    />
    <path
      id="Path_102"
      data-name="Path 102"
      d="M260.058,18.482l-3.174-6.432A.973.973,0,0,0,256,11.5V23.679Z"
      transform="translate(-244.001 -11.501)"
      fill="url(#linear-gradient-2)"
    />
    <path
      id="Path_103"
      data-name="Path 103"
      d="M262.565,203.023l5.136-5.007a.972.972,0,0,0,.253-1L256,200.5Z"
      transform="translate(-244 -188.324)"
      fill="url(#linear-gradient-3)"
    />
    <path
      id="Path_104"
      data-name="Path 104"
      d="M263.389,281.841,256,271.289v7.291l6.349,3.338A.973.973,0,0,0,263.389,281.841Z"
      transform="translate(-244 -259.111)"
      fill="url(#linear-gradient-4)"
    />
    <path
      id="Path_105"
      data-name="Path 105"
      d="M97.55,271.289l-6.565,2.521-1.212,7.07a.973.973,0,0,0,.389.961Z"
      transform="translate(-85.55 -259.111)"
      fill="url(#linear-gradient-5)"
    />
    <path
      id="Path_106"
      data-name="Path 106"
      d="M.968,162.144l11.955,3.481-4.058-5.2-7.1,1.032a.973.973,0,0,0-.8.684Z"
      transform="translate(-0.923 -153.448)"
      fill="url(#linear-gradient-6)"
    />
    <path
      id="Path_107"
      data-name="Path 107"
      d="M5.44,203.024.3,198.017a.972.972,0,0,1-.253-1L12.006,200.5Z"
      transform="translate(-0.006 -188.325)"
      fill="url(#linear-gradient-7)"
    />
  </svg>
);

export const SportsIcon = () => (
  <svg
    id="_4"
    data-name="4"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20.69"
    viewBox="0 0 24 20.69"
  >
    <path
      id="Path_108"
      data-name="Path 108"
      d="M206.069,98.759l-1.726,1.726a1.655,1.655,0,0,1-2.341,0l-2.14-2.14-3.724,3.724-1.887-1.887L192,97.931l3.724-3.724-2.14-2.14a1.655,1.655,0,0,1,0-2.341L195.31,88l1.655,1.655L195.724,90.9l1.655,1.655,3.761,3.761.377.377,1.655,1.655,1.241-1.241Z"
      transform="translate(-183.31 -86.345)"
      fill="#f35244"
    />
    <path
      id="Path_109"
      data-name="Path 109"
      d="M247.338,251.156l-1.726,1.726a1.655,1.655,0,0,1-2.341,0l-2.14-2.14-3.724,3.724-1.887-1.887a25.671,25.671,0,0,0,6.889-3.869l.377.377,1.655,1.655,1.241-1.241Z"
      transform="translate(-224.579 -238.742)"
      fill="#e64c3f"
    />
    <path
      id="Path_110"
      data-name="Path 110"
      d="M433.655,227.724l1.241-1.241v-1.655l-.828-.828-.828.828V224h-.828L432,226.069Z"
      transform="translate(-410.897 -215.31)"
      fill="#fec9a3"
    />
    <path
      id="Path_111"
      data-name="Path 111"
      d="M325.379,82.69v.621H320V82.69a2.69,2.69,0,1,1,5.379,0Z"
      transform="translate(-304.69 -78.759)"
      fill="#34507b"
    />
    <path
      id="Path_112"
      data-name="Path 112"
      d="M344.051,105.837v.621H339.69a10.535,10.535,0,0,0,3.951-2.048A2.674,2.674,0,0,1,344.051,105.837Z"
      transform="translate(-323.361 -101.906)"
      fill="#2f486e"
    />
    <path
      id="Path_113"
      data-name="Path 113"
      d="M317.379,144v2.9a1.241,1.241,0,0,1-.785,1.154,1.228,1.228,0,0,1-.456.087H314.9l-.828.414L312,146.483l.828-.828V144Z"
      transform="translate(-297.103 -139.448)"
      fill="#fec9a3"
    />
    <path
      id="Path_114"
      data-name="Path 114"
      d="M328,144h3.724v.828H328Z"
      transform="translate(-312.276 -139.448)"
      fill="#ffb531"
    />
    <path
      id="Path_115"
      data-name="Path 115"
      d="M378.069,144.414v.828a.414.414,0,0,1-.414.414h-.828a.828.828,0,0,1,0-1.655h.828A.414.414,0,0,1,378.069,144.414Z"
      transform="translate(-357.793 -139.448)"
      fill="#f98500"
    />
    <path
      id="Path_116"
      data-name="Path 116"
      d="M24.539,192h3.185v1.655H25.241l-1-.668a.539.539,0,0,1,.3-.988Z"
      transform="translate(-24 -184.966)"
      fill="#bfbfbd"
    />
    <path
      id="Path_117"
      data-name="Path 117"
      d="M49.655,232.69l1.655-1.655,2.069,2.069,2.069-2.069-1.241-1.241H49.413A1.413,1.413,0,0,1,48,228.38V224h2.483v2.9h4.966l4.138,4.138-4.38,4.38a2,2,0,0,1-2.826,0Z"
      transform="translate(-46.759 -215.31)"
      fill="#34507b"
    />
    <path
      id="Path_118"
      data-name="Path 118"
      d="M36.138,337.241,34.9,336l-2.725,2.725a.585.585,0,0,0,.414,1h.1a.585.585,0,0,0,.262-.062l1.532-.766Z"
      transform="translate(-31.586 -321.517)"
      fill="#bfbfbd"
    />
    <path
      id="Path_119"
      data-name="Path 119"
      d="M256,65.241,257.241,64H258.9l.828.828-.828.828h.828v.828l-2.069.414Z"
      transform="translate(-244 -63.586)"
      fill="#fec9a3"
    />
    <path
      id="Path_120"
      data-name="Path 120"
      d="M144.414,280h2.069l-1.241,2.9H144Z"
      transform="translate(-137.793 -268.414)"
      fill="#353f52"
    />
    <path
      id="Path_121"
      data-name="Path 121"
      d="M176.828,360h4.138l-2.069,2.069-2.9-1.241Z"
      transform="translate(-168.138 -344.276)"
      fill="#353f52"
    />
    <path
      id="Path_122"
      data-name="Path 122"
      d="M190.1,168.707l-5.379,5.379-2.967-2.967a1.657,1.657,0,0,1,0-2.341l3.038-3.038a1.655,1.655,0,0,1,2.341,0Z"
      transform="translate(-173.138 -159.604)"
      fill="#ffb531"
    />
    <path
      id="Path_123"
      data-name="Path 123"
      d="M0,0H2.341V1.756H0Z"
      transform="translate(11.583 14.482) rotate(-45)"
      fill="#353f52"
    />
    <path
      id="Path_124"
      data-name="Path 124"
      d="M224,213.379l.414-2.9L226.9,208h2.483l1.241,1.241-5.379,5.379Z"
      transform="translate(-213.655 -200.138)"
      fill="#f07800"
    />
    <path
      id="Path_125"
      data-name="Path 125"
      d="M186.481,165.3v2.158L184,169.945h-2.483l-.136-.136a1.645,1.645,0,0,1,.478-1.035l3.038-3.038A1.654,1.654,0,0,1,186.481,165.3Z"
      transform="translate(-173.24 -159.6)"
      fill="#f98500"
    />
    <g id="Group_139" data-name="Group 139" transform="translate(1.655)">
      <path
        id="Path_126"
        data-name="Path 126"
        d="M56,56h.828v4.966H56Z"
        transform="translate(-56 -56)"
        fill="#e0e0de"
      />
      <path
        id="Path_127"
        data-name="Path 127"
        d="M88,88h.828v3.724H88Z"
        transform="translate(-86.345 -86.345)"
        fill="#e0e0de"
      />
      <path
        id="Path_128"
        data-name="Path 128"
        d="M152,144h.828v2.9H152Z"
        transform="translate(-147.034 -139.448)"
        fill="#e0e0de"
      />
      <path
        id="Path_129"
        data-name="Path 129"
        d="M152,112h.828v.828H152Z"
        transform="translate(-147.034 -109.103)"
        fill="#e0e0de"
      />
      <path
        id="Path_130"
        data-name="Path 130"
        d="M456,120h.828v2.9H456Z"
        transform="translate(-435.31 -116.69)"
        fill="#e0e0de"
      />
    </g>
    <path
      id="Path_131"
      data-name="Path 131"
      d="M324.18,148.051a1.228,1.228,0,0,1-.456.087h-.536l-2.87-3.724L320,144h1.062l.312.414.6.8.309-.8.16-.414h.89l-.169.414-.622,1.526Z"
      transform="translate(-304.69 -139.448)"
      fill="#353f52"
    />
    <path
      id="Path_132"
      data-name="Path 132"
      d="M321.062,144l.312.414h-1.055L320,144Z"
      transform="translate(-304.69 -139.448)"
      fill="#313b4c"
    />
    <path
      id="Path_133"
      data-name="Path 133"
      d="M365.28,144l-.169.414h-.881l.16-.414Z"
      transform="translate(-346.632 -139.448)"
      fill="#313b4c"
    />
    <path
      id="Path_134"
      data-name="Path 134"
      d="M385.285,148.852v.828a.414.414,0,0,1-.414.414h-.828a.825.825,0,0,1-.434-.123q.837-.6,1.638-1.291A.415.415,0,0,1,385.285,148.852Z"
      transform="translate(-365.009 -143.886)"
      fill="#f77c00"
    />
  </svg>
);

export const BirthDayIcon = () => (
  <svg
    id="_5"
    data-name="5"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26.783"
    viewBox="0 0 24 26.783"
  >
    <g id="Group_151" data-name="Group 151" transform="translate(0 3.694)">
      <g
        id="Group_145"
        data-name="Group 145"
        transform="translate(0.105 9.522)"
      >
        <g id="Group_144" data-name="Group 144">
          <g id="Group_141" data-name="Group 141">
            <g id="Group_140" data-name="Group 140">
              <path
                id="Path_135"
                data-name="Path 135"
                d="M29.817,30.45l-.025,8.636c0,.535-.893.969-1.855.818L7.276,36.652a2,2,0,0,1-.654-.22.751.751,0,0,1-.44-.616l.025-8.63a.755.755,0,0,0,.44.61,1.862,1.862,0,0,0,.654.22l20.667,3.252c.962.151,1.849-.283,1.849-.818Z"
                transform="translate(-6.182 -27.186)"
                fill="#ffd06c"
              />
            </g>
          </g>
          <g
            id="Group_143"
            data-name="Group 143"
            transform="translate(0.014 4.402)"
          >
            <g id="Group_142" data-name="Group 142">
              <path
                id="Path_136"
                data-name="Path 136"
                d="M29.823,38.339l-.006,2.587c0,.535-.893.969-1.855.818L7.3,38.491a2,2,0,0,1-.654-.22.751.751,0,0,1-.44-.616l.006-2.581a.755.755,0,0,0,.44.61,1.862,1.862,0,0,0,.654.22l20.667,3.252c.962.151,1.849-.283,1.849-.818Z"
                transform="translate(-6.207 -35.074)"
                fill="#febc1f"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_147"
        data-name="Group 147"
        transform="translate(0.345 9.996)"
      >
        <g id="Group_146" data-name="Group 146">
          <path
            id="Path_137"
            data-name="Path 137"
            d="M30.006,30.827l-.011,4.419c-.175.088-.427-.011-.422-.2a.552.552,0,0,1,.093-.23.748.748,0,0,0-.005-.673,1.815,1.815,0,0,1-.788.159.63.63,0,0,1-.564-.493c-.016-.131.022-.3-.077-.383a.258.258,0,0,0-.164-.066,1.8,1.8,0,0,0-1.084.148c-.5.241-.871.7-1.358.953a2.5,2.5,0,0,1-3.148-1.221,1.606,1.606,0,0,0-.416-.63,1.243,1.243,0,0,0-.794-.2c-1.09,0-2.278.2-3.181-.416a3.8,3.8,0,0,0-.564-.383,2.214,2.214,0,0,0-1.517.1,1.647,1.647,0,0,1-1.489-.093c-.12-.1-.208-.23-.334-.318a.878.878,0,0,0-.876-.027,1.8,1.8,0,0,0-.635.646,2.976,2.976,0,0,0-.422,1.188c-.005.027-.005.06-.011.088a.008.008,0,0,0,0,.011c-.011.3-.005.591,0,.882q.008.682.033,1.363a5.125,5.125,0,0,0,.1,1,4.3,4.3,0,0,0,1.506,2.354c.509.323,1.188.438,1.528.936.181.271.077.444-.23.542a24.777,24.777,0,0,1-2.732.8,3.041,3.041,0,0,1-3.094-1.549,2.534,2.534,0,0,0-.268-.476c-.164-.189-.609-.442-.526-.788.132-.682,1.971-.506,2.376-.69.011-.005.016-.005.022-.016.339-.175.274-.63.318-1.018l.246-2.283V34.26a.664.664,0,0,0-.58-.745,2.181,2.181,0,0,1-1.2-.438,3.578,3.578,0,0,1-.9-1.905.85.85,0,0,0-.186-.394c-.318-.285-.827.049-1.243-.033-.493-.093-.673-.7-.717-1.194a6.2,6.2,0,0,1-.038-1.517,1.322,1.322,0,0,0,.192.137,1.9,1.9,0,0,0,.652.219L28.16,31.643c.958.153,1.845-.285,1.845-.816Z"
            transform="translate(-6.611 -28.035)"
            fill="#8a4852"
          />
        </g>
      </g>
      <g id="Group_149" data-name="Group 149" transform="translate(0 0.001)">
        <g id="Group_148" data-name="Group 148">
          <path
            id="Path_138"
            data-name="Path 138"
            d="M29.686,26.558a.77.77,0,0,1-.293.481c-.171.094-.442,0-.437-.194a.558.558,0,0,1,.094-.232.732.732,0,0,0-.006-.669,1.862,1.862,0,0,1-.785.155.619.619,0,0,1-.564-.492c-.017-.133.022-.293-.077-.382a.261.261,0,0,0-.166-.066,1.785,1.785,0,0,0-1.084.149c-.5.238-.868.7-1.36.951a2.493,2.493,0,0,1-3.147-1.222,1.7,1.7,0,0,0-.415-.63,1.271,1.271,0,0,0-.8-.194c-1.089,0-2.278.194-3.18-.42a4.208,4.208,0,0,0-.564-.382,2.221,2.221,0,0,0-1.515.105,1.628,1.628,0,0,1-1.488-.094c-.122-.1-.21-.227-.337-.315a.866.866,0,0,0-.874-.028,1.742,1.742,0,0,0-.636.647,2.924,2.924,0,0,0-.42,1.183c-.006.028-.006.061-.011.089a.008.008,0,0,0,0,.011c-.011.3-.006.592,0,.885q.008.68.033,1.36a5.468,5.468,0,0,0,.094,1,4.151,4.151,0,0,0,.465,1.134,3.251,3.251,0,0,0,1.045,1.222c.509.326,1.189.437,1.526.935.181.272.083.442-.232.542a26.188,26.188,0,0,1-2.732.807,3.034,3.034,0,0,1-3.091-1.548,2.358,2.358,0,0,0-.271-.476c-.165-.2-.609-.442-.525-.791.13-.674,1.983-.512,2.378-.691.365-.166.293-.636.337-1.034.083-.758.166-1.521.249-2.284v-.007a.664.664,0,0,0-.582-.745,2.189,2.189,0,0,1-1.206-.442,3.532,3.532,0,0,1-.9-1.9.815.815,0,0,0-.188-.393c-.315-.288-.824.05-1.244-.033-.492-.094-.669-.7-.713-1.194s-.216-1.565.149-2.007c.011-.011.017-.022.028-.033a.312.312,0,0,1,.055-.072q5.259-5.566,15.545-9c.714-.242,1.872-.1,2.168.514l5.663,11.929a.476.476,0,0,1,.05.166,1.272,1.272,0,0,1,.194.586,7.4,7.4,0,0,1-.238,3.13Z"
            transform="translate(-5.994 -10.122)"
            fill="#aa6256"
          />
        </g>
      </g>
      <g id="Group_150" data-name="Group 150" transform="translate(0.132)">
        <path
          id="Path_139"
          data-name="Path 139"
          d="M24.121,10.745l5.667,11.933c.3.635-.7,1.217-1.8,1.046L7.325,20.473a1.9,1.9,0,0,1-.655-.218c-.447-.258-.587-.671-.26-1.017q5.258-5.568,15.547-9.007c.744-.249,1.886-.074,2.164.515Z"
          transform="translate(-6.231 -10.12)"
          fill="#bf7d5a"
        />
      </g>
    </g>
    <g id="Group_188" data-name="Group 188" transform="translate(5.275)">
      <g id="Group_173" data-name="Group 173" transform="translate(0 4.955)">
        <g id="Group_172" data-name="Group 172">
          <g id="Group_152" data-name="Group 152">
            <path
              id="Path_140"
              data-name="Path 140"
              d="M25.606,13.168a3.291,3.291,0,0,1,1.718,3.714c-.124,1.378-.84,1.985-1.983,1.892a1.856,1.856,0,0,1-2.4.658c-1.167,1.132-2.214,1.022-3.172,0-1.923.714-3.22.479-3.461-1.361-1.076-.291-1.052-1.1-.427-2.18-.427-.914-.136-1.614,1.038-2.053a1.093,1.093,0,0,1,1-.75,1.615,1.615,0,0,1,1.5-.6l4.441-.1a2.114,2.114,0,0,1,1.742.784Z"
              transform="translate(-15.448 -12.381)"
              fill="#f3f1fc"
            />
          </g>
          <g
            id="Group_171"
            data-name="Group 171"
            transform="translate(0.434 0.297)"
          >
            <g
              id="Group_154"
              data-name="Group 154"
              transform="translate(6.483 0.72)"
            >
              <g id="Group_153" data-name="Group 153">
                <path
                  id="Path_141"
                  data-name="Path 141"
                  d="M27.844,14.2c3.551.513,3.584,2.587,3.639,2.642a2.3,2.3,0,0,1,.04.486,2.469,2.469,0,0,1-.038.481,5.161,5.161,0,0,1-.664,1.766,7.27,7.27,0,0,0,.454-1.79c.19-2.075-1.448-2.948-3.43-3.585Z"
                  transform="translate(-27.844 -14.205)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_156"
              data-name="Group 156"
              transform="translate(5.531 1.129)"
            >
              <g id="Group_155" data-name="Group 155">
                <path
                  id="Path_142"
                  data-name="Path 142"
                  d="M27.667,20.562c1.561-2.663,1.281-3.978-1.528-5.624,2.021.742,3.308,2.139,2.487,4.117a5.549,5.549,0,0,1-.959,1.507Z"
                  transform="translate(-26.139 -14.938)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_158"
              data-name="Group 158"
              transform="translate(3.887 1.129)"
            >
              <g id="Group_157" data-name="Group 157">
                <path
                  id="Path_143"
                  data-name="Path 143"
                  d="M23.192,20.562c2.71-1.193,2.819-4.7.9-5.624C26.389,15.553,26.209,19.827,23.192,20.562Z"
                  transform="translate(-23.192 -14.938)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_160"
              data-name="Group 160"
              transform="translate(0.427 0.72)"
            >
              <g id="Group_159" data-name="Group 159">
                <path
                  id="Path_144"
                  data-name="Path 144"
                  d="M16.99,18.876A7.973,7.973,0,0,0,22.008,14.2,6.147,6.147,0,0,1,16.99,18.876Z"
                  transform="translate(-16.99 -14.204)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_162"
              data-name="Group 162"
              transform="translate(0 0.795)"
            >
              <g id="Group_161" data-name="Group 161">
                <path
                  id="Path_145"
                  data-name="Path 145"
                  d="M16.225,16.756a1.355,1.355,0,0,1,.281-.288,1.925,1.925,0,0,1,1.149-.413h-.011a7.035,7.035,0,0,0,3.564-1.715,4.916,4.916,0,0,1-3.542,1.941,2.112,2.112,0,0,0-1.441.475Z"
                  transform="translate(-16.225 -14.339)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_164"
              data-name="Group 164"
              transform="translate(1.038 0.541)"
            >
              <g id="Group_163" data-name="Group 163">
                <path
                  id="Path_146"
                  data-name="Path 146"
                  d="M18.086,14.5a1.527,1.527,0,0,1,1.34-.148l-.019-.005a3.081,3.081,0,0,0,2.156-.464,2.19,2.19,0,0,1-2.2.712,2.055,2.055,0,0,0-1.272-.1Z"
                  transform="translate(-18.086 -13.884)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_166"
              data-name="Group 166"
              transform="translate(2.042 0.308)"
            >
              <g id="Group_165" data-name="Group 165">
                <path
                  id="Path_147"
                  data-name="Path 147"
                  d="M19.885,13.566c.8-.086,1.7,0,2.512-.1A2.989,2.989,0,0,1,19.885,13.566Z"
                  transform="translate(-19.885 -13.465)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_168"
              data-name="Group 168"
              transform="translate(7.51 0.444)"
            >
              <g id="Group_167" data-name="Group 167">
                <path
                  id="Path_148"
                  data-name="Path 148"
                  d="M33.671,16.757a5,5,0,0,0-3.987-3.037A3.8,3.8,0,0,1,33.671,16.757Z"
                  transform="translate(-29.684 -13.709)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
            <g
              id="Group_170"
              data-name="Group 170"
              transform="translate(7.337)"
            >
              <g id="Group_169" data-name="Group 169">
                <path
                  id="Path_149"
                  data-name="Path 149"
                  d="M31.762,13.4a14.785,14.785,0,0,0-2.388-.306,1.8,1.8,0,0,1,.605-.168A2.553,2.553,0,0,1,31.762,13.4Z"
                  transform="translate(-29.374 -12.914)"
                  fill="#e9e7fc"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Group_185" data-name="Group 185" transform="translate(2.96 2.407)">
        <g id="Group_184" data-name="Group 184">
          <g id="Group_183" data-name="Group 183">
            <g id="Group_174" data-name="Group 174">
              <circle
                id="Ellipse_17"
                data-name="Ellipse 17"
                cx="2.748"
                cy="2.748"
                r="2.748"
                transform="translate(0 0.544) rotate(-5.682)"
                fill="#f45170"
              />
            </g>
            <g
              id="Group_175"
              data-name="Group 175"
              transform="translate(1.03 0.407)"
            >
              <path
                id="Path_150"
                data-name="Path 150"
                d="M27.322,11.15a2.752,2.752,0,0,1-4.48,2.135s0,0-.007,0a2.867,2.867,0,0,1-.236-.222,2.747,2.747,0,0,0,2.852-4.515c.1.035.208.08.308.125a.012.012,0,0,0,.007,0,2.746,2.746,0,0,1,1.556,2.478Z"
                transform="translate(-22.599 -8.544)"
                fill="#e93565"
              />
            </g>
            <g
              id="Group_182"
              data-name="Group 182"
              transform="translate(2.516 0.724)"
            >
              <g
                id="Group_177"
                data-name="Group 177"
                transform="translate(0 0.003)"
              >
                <g id="Group_176" data-name="Group 176">
                  <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M25.768,9.68a.7.7,0,0,1-.36-.082c-.326-.188-.062-.507.388-.48a.707.707,0,0,1,.3.081C26.4,9.375,26.187,9.674,25.768,9.68Z"
                    transform="translate(-25.263 -9.116)"
                    fill="#474746"
                  />
                </g>
              </g>
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(0.001)"
              >
                <g id="Group_178" data-name="Group 178">
                  <path
                    id="Path_152"
                    data-name="Path 152"
                    d="M26.107,9.589s-.007,0-.01.007a.371.371,0,0,1-.073.035.759.759,0,0,1-.568-.014c-.223-.074-.267-.312-.052-.419a.013.013,0,0,1,.01,0,.729.729,0,0,1,.416-.076.059.059,0,0,1,.024,0,.619.619,0,0,1,.239.076A.208.208,0,0,1,26.107,9.589Z"
                    transform="translate(-25.263 -9.111)"
                    fill="#4c5c75"
                  />
                </g>
              </g>
              <g
                id="Group_181"
                data-name="Group 181"
                transform="translate(0 0.002)"
              >
                <g id="Group_180" data-name="Group 180">
                  <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M26.225,9.465c-.108-.287-.836-.29-.948,0-.117-.28.478-.462.818-.265C26.22,9.27,26.264,9.372,26.225,9.465Z"
                    transform="translate(-25.262 -9.115)"
                    fill="#e93565"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Group_186" data-name="Group 186" transform="translate(5.016 0)">
        <path
          id="Path_154"
          data-name="Path 154"
          d="M25.742,7.109l-.062.035a.049.049,0,0,1-.021.007.769.769,0,0,1-.343.045,3.923,3.923,0,0,1-.873-2.381,2.382,2.382,0,0,1,.405-1.147l0-.007a.36.36,0,1,1,.6.4.121.121,0,0,1-.017.028,1.731,1.731,0,0,0-.367.8,3.657,3.657,0,0,0,.676,2.225Z"
          transform="translate(-24.438 -3.5)"
          fill="#78e75a"
        />
      </g>
      <g id="Group_187" data-name="Group 187" transform="translate(5.016)">
        <path
          id="Path_155"
          data-name="Path 155"
          d="M25.538,7.182a.624.624,0,0,1-.222.014,3.923,3.923,0,0,1-.873-2.381,2.382,2.382,0,0,1,.405-1.147l0-.007a.36.36,0,0,1,.416-.142.346.346,0,0,0-.187.142l0,.007a2.381,2.381,0,0,0-.405,1.147,3.885,3.885,0,0,0,.866,2.367Z"
          transform="translate(-24.437 -3.5)"
          fill="#5ec363"
        />
      </g>
    </g>
    <g
      id="Group_227"
      data-name="Group 227"
      transform="translate(16.146 10.647)"
    >
      <g
        id="Group_205"
        data-name="Group 205"
        transform="translate(0.148 0.105)"
      >
        <g
          id="Group_190"
          data-name="Group 190"
          transform="translate(3.687 4.99)"
        >
          <g id="Group_189" data-name="Group 189">
            <path
              id="Path_156"
              data-name="Path 156"
              d="M42.987,31.937l-1,.634a.121.121,0,0,1-.13-.2l1-.634A.121.121,0,0,1,42.987,31.937Z"
              transform="translate(-41.803 -31.712)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_192"
          data-name="Group 192"
          transform="translate(3.08 3.122)"
        >
          <g id="Group_191" data-name="Group 191">
            <path
              id="Path_157"
              data-name="Path 157"
              d="M41.374,28.533l-.425.983a.121.121,0,0,1-.223-.1l.425-.983A.121.121,0,0,1,41.374,28.533Z"
              transform="translate(-40.715 -28.363)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_194"
          data-name="Group 194"
          transform="translate(1.767 4.089)"
        >
          <g id="Group_193" data-name="Group 193">
            <path
              id="Path_158"
              data-name="Path 158"
              d="M38.6,30.2l.2,1.087a.121.121,0,1,1-.238.044l-.2-1.087A.121.121,0,1,1,38.6,30.2Z"
              transform="translate(-38.362 -30.097)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g id="Group_196" data-name="Group 196" transform="translate(0 2.831)">
          <g id="Group_195" data-name="Group 195">
            <path
              id="Path_159"
              data-name="Path 159"
              d="M35.317,27.843a.121.121,0,0,1,.12.108l.118,1.115a.121.121,0,0,1-.241.026L35.2,27.977a.121.121,0,0,1,.108-.133Z"
              transform="translate(-35.196 -27.843)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_198"
          data-name="Group 198"
          transform="translate(1.384 2.25)"
        >
          <g id="Group_197" data-name="Group 197">
            <path
              id="Path_160"
              data-name="Path 160"
              d="M38.469,26.8a.121.121,0,0,1,.075.217l-.672.524a.121.121,0,0,1-.149-.191l.672-.524A.121.121,0,0,1,38.469,26.8Z"
              transform="translate(-37.675 -26.802)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_200"
          data-name="Group 200"
          transform="translate(2.874 0.752)"
        >
          <g id="Group_199" data-name="Group 199">
            <path
              id="Path_161"
              data-name="Path 161"
              d="M40.468,24.117a.121.121,0,0,1,.118.1l.207.942a.121.121,0,1,1-.237.052l-.207-.942a.121.121,0,0,1,.092-.144l.026,0Z"
              transform="translate(-40.347 -24.117)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g id="Group_202" data-name="Group 202" transform="translate(1.04)">
          <g id="Group_201" data-name="Group 201">
            <path
              id="Path_162"
              data-name="Path 162"
              d="M38.145,23l-.913.427a.121.121,0,1,1-.1-.22l.913-.427A.121.121,0,0,1,38.145,23Z"
              transform="translate(-37.059 -22.769)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_204"
          data-name="Group 204"
          transform="translate(0.152 1.173)"
        >
          <g id="Group_203" data-name="Group 203">
            <path
              id="Path_163"
              data-name="Path 163"
              d="M35.912,24.872a.122.122,0,0,1,.11.172l-.321.7a.121.121,0,0,1-.221-.1l.321-.7a.122.122,0,0,1,.11-.071Z"
              transform="translate(-35.468 -24.872)"
              fill="#aa6256"
            />
          </g>
        </g>
      </g>
      <g id="Group_226" data-name="Group 226">
        <g
          id="Group_207"
          data-name="Group 207"
          transform="translate(3.687 4.99)"
        >
          <g id="Group_206" data-name="Group 206">
            <path
              id="Path_164"
              data-name="Path 164"
              d="M42.722,31.748l-1,.634a.121.121,0,0,1-.13-.2l1-.634A.121.121,0,0,1,42.722,31.748Z"
              transform="translate(-41.538 -31.523)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_209"
          data-name="Group 209"
          transform="translate(3.081 3.121)"
        >
          <g id="Group_208" data-name="Group 208">
            <path
              id="Path_165"
              data-name="Path 165"
              d="M41.11,28.344l-.425.983a.121.121,0,0,1-.223-.1l.425-.983A.121.121,0,0,1,41.11,28.344Z"
              transform="translate(-40.452 -28.174)"
              fill="#57b3fe"
            />
          </g>
        </g>
        <g
          id="Group_211"
          data-name="Group 211"
          transform="translate(1.767 4.089)"
        >
          <g id="Group_210" data-name="Group 210">
            <path
              id="Path_166"
              data-name="Path 166"
              d="M38.338,30.008l.2,1.087a.121.121,0,1,1-.238.044l-.2-1.087a.121.121,0,1,1,.238-.044Z"
              transform="translate(-38.097 -29.908)"
              fill="#4c5c75"
            />
          </g>
        </g>
        <g id="Group_213" data-name="Group 213" transform="translate(0 2.831)">
          <g id="Group_212" data-name="Group 212">
            <path
              id="Path_167"
              data-name="Path 167"
              d="M35.052,27.654a.121.121,0,0,1,.12.108l.118,1.115a.121.121,0,0,1-.241.026l-.118-1.115a.121.121,0,0,1,.108-.133Z"
              transform="translate(-34.93 -27.654)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_215"
          data-name="Group 215"
          transform="translate(1.384 2.25)"
        >
          <g id="Group_214" data-name="Group 214">
            <path
              id="Path_168"
              data-name="Path 168"
              d="M38.2,26.613a.121.121,0,0,1,.075.217l-.672.524a.121.121,0,1,1-.149-.191l.672-.524a.121.121,0,0,1,.075-.026Z"
              transform="translate(-37.41 -26.613)"
              fill="#78e75a"
            />
          </g>
        </g>
        <g
          id="Group_217"
          data-name="Group 217"
          transform="translate(2.874 0.752)"
        >
          <g id="Group_216" data-name="Group 216">
            <path
              id="Path_169"
              data-name="Path 169"
              d="M40.2,23.928a.121.121,0,0,1,.118.1l.207.942a.121.121,0,1,1-.237.052l-.207-.942a.121.121,0,0,1,.092-.144Z"
              transform="translate(-40.082 -23.928)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_219" data-name="Group 219" transform="translate(1.04)">
          <g id="Group_218" data-name="Group 218">
            <path
              id="Path_170"
              data-name="Path 170"
              d="M37.879,22.813l-.913.427a.121.121,0,1,1-.1-.22l.913-.427A.121.121,0,0,1,37.879,22.813Z"
              transform="translate(-36.794 -22.58)"
              fill="#57b3fe"
            />
          </g>
        </g>
        <g
          id="Group_221"
          data-name="Group 221"
          transform="translate(0.152 1.173)"
        >
          <g id="Group_220" data-name="Group 220">
            <path
              id="Path_171"
              data-name="Path 171"
              d="M35.646,24.683a.122.122,0,0,1,.11.172l-.321.7a.121.121,0,0,1-.221-.1l.321-.7A.122.122,0,0,1,35.646,24.683Z"
              transform="translate(-35.203 -24.683)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_223"
          data-name="Group 223"
          transform="translate(4.792 2.497)"
        >
          <g id="Group_222" data-name="Group 222">
            <path
              id="Path_172"
              data-name="Path 172"
              d="M43.64,27.055a.121.121,0,0,0-.082.21l.815.753a.121.121,0,1,0,.164-.178l-.815-.753A.121.121,0,0,0,43.64,27.055Z"
              transform="translate(-43.519 -27.055)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_225"
          data-name="Group 225"
          transform="translate(4.792 2.368)"
        >
          <g id="Group_224" data-name="Group 224">
            <path
              id="Path_173"
              data-name="Path 173"
              d="M43.64,26.825a.121.121,0,0,0-.082.21l.815.753a.121.121,0,1,0,.164-.178l-.815-.753A.121.121,0,0,0,43.64,26.825Z"
              transform="translate(-43.519 -26.825)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_250" data-name="Group 250" transform="translate(1.932 10.887)">
      <g
        id="Group_236"
        data-name="Group 236"
        transform="translate(0.148 0.105)"
      >
        <g
          id="Group_229"
          data-name="Group 229"
          transform="translate(1.23 2.249)"
        >
          <g id="Group_228" data-name="Group 228">
            <path
              id="Path_174"
              data-name="Path 174"
              d="M12.72,27.231a.121.121,0,0,1,.075.217l-.672.524a.121.121,0,0,1-.149-.191l.672-.524A.121.121,0,0,1,12.72,27.231Z"
              transform="translate(-11.926 -27.231)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_231"
          data-name="Group 231"
          transform="translate(2.721 0.751)"
        >
          <g id="Group_230" data-name="Group 230">
            <path
              id="Path_175"
              data-name="Path 175"
              d="M14.719,24.546a.121.121,0,0,1,.118.1l.207.942a.121.121,0,1,1-.237.052l-.207-.942a.121.121,0,0,1,.092-.144l.026,0Z"
              transform="translate(-14.598 -24.546)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g id="Group_233" data-name="Group 233" transform="translate(0.886)">
          <g id="Group_232" data-name="Group 232">
            <path
              id="Path_176"
              data-name="Path 176"
              d="M12.344,23.2a.121.121,0,0,1,.051.231l-.913.427a.121.121,0,1,1-.1-.22l.913-.427A.121.121,0,0,1,12.344,23.2Z"
              transform="translate(-11.31 -23.201)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g id="Group_235" data-name="Group 235" transform="translate(0 1.172)">
          <g id="Group_234" data-name="Group 234">
            <path
              id="Path_177"
              data-name="Path 177"
              d="M10.164,25.3a.122.122,0,0,1,.11.172l-.321.7a.121.121,0,0,1-.221-.1l.321-.7A.122.122,0,0,1,10.164,25.3Z"
              transform="translate(-9.722 -25.301)"
              fill="#aa6256"
            />
          </g>
        </g>
      </g>
      <g id="Group_249" data-name="Group 249">
        <g
          id="Group_238"
          data-name="Group 238"
          transform="translate(1.23 2.249)"
        >
          <g id="Group_237" data-name="Group 237">
            <path
              id="Path_178"
              data-name="Path 178"
              d="M12.455,27.042a.121.121,0,0,1,.075.217l-.672.524a.121.121,0,1,1-.149-.191l.672-.524A.121.121,0,0,1,12.455,27.042Z"
              transform="translate(-11.661 -27.042)"
              fill="#78e75a"
            />
          </g>
        </g>
        <g
          id="Group_240"
          data-name="Group 240"
          transform="translate(2.721 0.751)"
        >
          <g id="Group_239" data-name="Group 239">
            <path
              id="Path_179"
              data-name="Path 179"
              d="M14.454,24.357a.121.121,0,0,1,.118.1l.207.942a.121.121,0,1,1-.237.052l-.207-.942a.121.121,0,0,1,.092-.144l.026,0Z"
              transform="translate(-14.333 -24.357)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_242" data-name="Group 242" transform="translate(0.886)">
          <g id="Group_241" data-name="Group 241">
            <path
              id="Path_180"
              data-name="Path 180"
              d="M12.079,23.012a.121.121,0,0,1,.051.231l-.913.427a.121.121,0,1,1-.1-.22l.913-.427A.121.121,0,0,1,12.079,23.012Z"
              transform="translate(-11.045 -23.012)"
              fill="#57b3fe"
            />
          </g>
        </g>
        <g id="Group_244" data-name="Group 244" transform="translate(0 1.172)">
          <g id="Group_243" data-name="Group 243">
            <path
              id="Path_181"
              data-name="Path 181"
              d="M9.9,25.112a.122.122,0,0,1,.11.172l-.321.7a.121.121,0,0,1-.221-.1l.321-.7a.122.122,0,0,1,.11-.071Z"
              transform="translate(-9.457 -25.112)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_246"
          data-name="Group 246"
          transform="translate(4.639 2.495)"
        >
          <g id="Group_245" data-name="Group 245">
            <path
              id="Path_182"
              data-name="Path 182"
              d="M17.891,27.484a.121.121,0,0,0-.082.21l.815.753a.121.121,0,1,0,.164-.178l-.815-.753a.121.121,0,0,0-.082-.032Z"
              transform="translate(-17.77 -27.484)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_248"
          data-name="Group 248"
          transform="translate(4.639 2.367)"
        >
          <g id="Group_247" data-name="Group 247">
            <path
              id="Path_183"
              data-name="Path 183"
              d="M17.891,27.254a.121.121,0,0,0-.082.21l.815.753a.121.121,0,1,0,.164-.178l-.815-.753a.121.121,0,0,0-.082-.032Z"
              transform="translate(-17.77 -27.254)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_269" data-name="Group 269" transform="translate(15.87 4.142)">
      <g id="Group_259" data-name="Group 259" transform="translate(0 0.105)">
        <g
          id="Group_252"
          data-name="Group 252"
          transform="translate(1.509 3.154)"
        >
          <g id="Group_251" data-name="Group 251">
            <path
              id="Path_184"
              data-name="Path 184"
              d="M37.189,16.983l.672.524a.121.121,0,0,0,.149-.191l-.672-.524A.121.121,0,0,0,37.189,16.983Z"
              transform="translate(-37.141 -16.764)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_254"
          data-name="Group 254"
          transform="translate(3.076 4.52)"
        >
          <g id="Group_253" data-name="Group 253">
            <path
              id="Path_185"
              data-name="Path 185"
              d="M40.158,19.306l-.207.942a.121.121,0,0,0,.237.052l.207-.942A.121.121,0,0,0,40.158,19.306Z"
              transform="translate(-39.948 -19.211)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g id="Group_256" data-name="Group 256">
          <g id="Group_255" data-name="Group 255">
            <path
              id="Path_186"
              data-name="Path 186"
              d="M34.506,11.344l.913.427a.121.121,0,0,0,.1-.22l-.913-.427A.121.121,0,0,0,34.506,11.344Z"
              transform="translate(-34.436 -11.111)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_258"
          data-name="Group 258"
          transform="translate(1.479 1.173)"
        >
          <g id="Group_257" data-name="Group 257">
            <path
              id="Path_187"
              data-name="Path 187"
              d="M37.208,13.214a.122.122,0,0,0-.11.172l.321.7a.121.121,0,0,0,.221-.1l-.321-.7A.122.122,0,0,0,37.208,13.214Z"
              transform="translate(-37.087 -13.214)"
              fill="#aa6256"
            />
          </g>
        </g>
      </g>
      <g id="Group_268" data-name="Group 268" transform="translate(0.148)">
        <g
          id="Group_261"
          data-name="Group 261"
          transform="translate(1.509 3.154)"
        >
          <g id="Group_260" data-name="Group 260">
            <path
              id="Path_188"
              data-name="Path 188"
              d="M37.455,16.794l.672.524a.121.121,0,0,0,.149-.191L37.6,16.6A.121.121,0,0,0,37.455,16.794Z"
              transform="translate(-37.406 -16.575)"
              fill="#78e75a"
            />
          </g>
        </g>
        <g
          id="Group_263"
          data-name="Group 263"
          transform="translate(3.076 4.519)"
        >
          <g id="Group_262" data-name="Group 262">
            <path
              id="Path_189"
              data-name="Path 189"
              d="M40.424,19.117l-.207.942a.121.121,0,0,0,.237.052l.207-.942a.121.121,0,1,0-.237-.052Z"
              transform="translate(-40.213 -19.021)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_265" data-name="Group 265">
          <g id="Group_264" data-name="Group 264">
            <path
              id="Path_190"
              data-name="Path 190"
              d="M34.772,11.155l.913.427a.121.121,0,0,0,.1-.22l-.913-.427a.121.121,0,0,0-.1.22Z"
              transform="translate(-34.701 -10.922)"
              fill="#57b3fe"
            />
          </g>
        </g>
        <g
          id="Group_267"
          data-name="Group 267"
          transform="translate(1.479 1.173)"
        >
          <g id="Group_266" data-name="Group 266">
            <path
              id="Path_191"
              data-name="Path 191"
              d="M37.473,13.025a.122.122,0,0,0-.11.172l.321.7a.121.121,0,0,0,.221-.1l-.321-.7a.122.122,0,0,0-.11-.071Z"
              transform="translate(-37.352 -13.025)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_292" data-name="Group 292" transform="translate(8.391 12.613)">
      <g
        id="Group_278"
        data-name="Group 278"
        transform="translate(0.148 0.105)"
      >
        <g
          id="Group_271"
          data-name="Group 271"
          transform="translate(4.276 2.169)"
        >
          <g id="Group_270" data-name="Group 270">
            <path
              id="Path_192"
              data-name="Path 192"
              d="M29.754,30.18a.121.121,0,0,1,.075.217l-.672.524a.121.121,0,0,1-.149-.191l.672-.524A.121.121,0,0,1,29.754,30.18Z"
              transform="translate(-28.96 -30.18)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_273"
          data-name="Group 273"
          transform="translate(2.72 0.879)"
        >
          <g id="Group_272" data-name="Group 272">
            <path
              id="Path_193"
              data-name="Path 193"
              d="M26.413,27.964l.207.942a.121.121,0,1,1-.237.052l-.207-.942a.121.121,0,0,1,.237-.052Z"
              transform="translate(-26.172 -27.869)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g id="Group_275" data-name="Group 275" transform="translate(0.2)">
          <g id="Group_274" data-name="Group 274">
            <path
              id="Path_194"
              data-name="Path 194"
              d="M22.741,26.526l-.913.427a.121.121,0,0,1-.1-.22l.913-.427A.121.121,0,0,1,22.741,26.526Z"
              transform="translate(-21.655 -26.293)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g id="Group_277" data-name="Group 277" transform="translate(0 1.3)">
          <g id="Group_276" data-name="Group 276">
            <path
              id="Path_195"
              data-name="Path 195"
              d="M21.85,28.794l-.321.7a.121.121,0,0,1-.221-.1l.321-.7A.121.121,0,0,1,21.85,28.794Z"
              transform="translate(-21.297 -28.622)"
              fill="#aa6256"
            />
          </g>
        </g>
      </g>
      <g id="Group_291" data-name="Group 291">
        <g
          id="Group_280"
          data-name="Group 280"
          transform="translate(4.276 2.169)"
        >
          <g id="Group_279" data-name="Group 279">
            <path
              id="Path_196"
              data-name="Path 196"
              d="M29.489,29.991a.121.121,0,0,1,.075.217l-.672.524a.121.121,0,0,1-.149-.191l.672-.524A.121.121,0,0,1,29.489,29.991Z"
              transform="translate(-28.695 -29.991)"
              fill="#78e75a"
            />
          </g>
        </g>
        <g
          id="Group_282"
          data-name="Group 282"
          transform="translate(2.721 0.879)"
        >
          <g id="Group_281" data-name="Group 281">
            <path
              id="Path_197"
              data-name="Path 197"
              d="M26.148,27.774l.207.942a.121.121,0,0,1-.237.052l-.207-.942a.121.121,0,1,1,.237-.052Z"
              transform="translate(-25.908 -27.679)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_284" data-name="Group 284" transform="translate(0.2)">
          <g id="Group_283" data-name="Group 283">
            <path
              id="Path_198"
              data-name="Path 198"
              d="M22.476,26.337l-.913.427a.121.121,0,0,1-.1-.22l.913-.427A.121.121,0,0,1,22.476,26.337Z"
              transform="translate(-21.39 -26.104)"
              fill="#57b3fe"
            />
          </g>
        </g>
        <g id="Group_286" data-name="Group 286" transform="translate(0 1.3)">
          <g id="Group_285" data-name="Group 285">
            <path
              id="Path_199"
              data-name="Path 199"
              d="M21.584,28.605l-.321.7a.121.121,0,0,1-.221-.1l.321-.7a.121.121,0,0,1,.221.1Z"
              transform="translate(-21.032 -28.433)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_288"
          data-name="Group 288"
          transform="translate(4.973 0.615)"
        >
          <g id="Group_287" data-name="Group 287">
            <path
              id="Path_200"
              data-name="Path 200"
              d="M29.985,27.418l.815.753a.121.121,0,1,0,.164-.178l-.815-.753a.121.121,0,0,0-.165.178Z"
              transform="translate(-29.944 -27.206)"
              fill="#aa6256"
            />
          </g>
        </g>
        <g
          id="Group_290"
          data-name="Group 290"
          transform="translate(4.973 0.486)"
        >
          <g id="Group_289" data-name="Group 289">
            <path
              id="Path_201"
              data-name="Path 201"
              d="M29.985,27.188l.815.753a.121.121,0,1,0,.164-.178l-.815-.753a.121.121,0,0,0-.165.178Z"
              transform="translate(-29.944 -26.976)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_293" data-name="Group 293" transform="translate(3.425 23.969)">
      <path
        id="Path_202"
        data-name="Path 202"
        d="M13.192,46.509c.17.057.332.137.505.181.635.16,1.257-.178,2.1.364.478.307.338.7-.037.776a1.626,1.626,0,0,1-.478-.009,3.536,3.536,0,0,0-1.434.19A1.841,1.841,0,0,1,12.6,47.88a.746.746,0,1,1,.588-1.371Z"
        transform="translate(-12.132 -46.455)"
        fill="#8a4852"
      />
    </g>
  </svg>
);

export const BeachIcon = () => (
  <svg
    id="_6"
    data-name="6"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_114" data-name="Group 114" transform="translate(1.351 0.333)">
      <path
        id="Path_48"
        data-name="Path 48"
        d="M181.353,194.984a9.957,9.957,0,0,1,.142,4.61l.1.176c1.051-1.086,2.194-3.013.093-5.131l-.057-.019-.281.091v.273Z"
        transform="translate(-174.203 -185.83)"
        fill="#00f4a8"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        d="M192.742,172.432a10.32,10.32,0,0,1,3.911,3.525l.2.148c.035-1.74-.534-4.29-4.189-4.244l-.078.061-.049.242Z"
        transform="translate(-184.859 -164.137)"
        fill="#00f4a8"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M108.831,7.107l-.058.279s2.589,3.595,2.225,6.732l.076.089.306-.173.051-.088c1.888-4.253-.594-6.078-2.6-6.839Z"
        transform="translate(-105.025 -7.107)"
        fill="#00f4a8"
      />
      <path
        id="Path_51"
        data-name="Path 51"
        d="M28.817,87.647a9.788,9.788,0,0,1,4.334,1.922l.3.266.5-.155.083-.09c-1.456-3-3.662-2.782-5.214-2.1Z"
        transform="translate(-28.817 -83.312)"
        fill="#00f4a8"
      />
      <path
        id="Path_52"
        data-name="Path 52"
        d="M31.137,166.166c-1.4.225-1.973,2.507-1.973,2.507l.082.128a7.986,7.986,0,0,1,3.924-2.129l.054-.094-.027-.505A8.3,8.3,0,0,0,31.137,166.166Z"
        transform="translate(-29.148 -158.596)"
        fill="#00f4a8"
      />
    </g>
    <path
      id="Path_53"
      data-name="Path 53"
      d="M3.866,96.225a.259.259,0,0,1-.161-.1,7.4,7.4,0,0,0-1.117.613.263.263,0,0,0,.151.482A8.774,8.774,0,0,1,7.832,98.6h.111l.505-.157a8.852,8.852,0,0,0-4.582-2.218Z"
      transform="translate(-2.354 -91.623)"
      fill="#00e49d"
    />
    <path
      id="Path_54"
      data-name="Path 54"
      d="M83.273,7.323A11.247,11.247,0,0,0,80.908.333,9.161,9.161,0,0,0,79.867.01a.314.314,0,0,0-.3.523,10.245,10.245,0,0,1,2.969,6.141l.058.1.557.656.137-.077Z"
      transform="translate(-75.751 0)"
      fill="#00e49d"
    />
    <path
      id="Path_55"
      data-name="Path 55"
      d="M187.018,134.551s-1.7-1.939-3.594-1.83a4.774,4.774,0,0,0-2.808,1.261l.479.324.129.018a9.633,9.633,0,0,1,5.795.5v-.274Z"
      transform="translate(-172.15 -126.495)"
      fill="#00f4a8"
    />
    <path
      id="Path_56"
      data-name="Path 56"
      d="M171.807,118.832c2.831-2.339,5.084-.644,6.278.759l.07.027a.29.29,0,0,0,.343-.44c-1.087-1.5-3.78-4.225-7.254-.772l.068.091Z"
      transform="translate(-163.216 -111.262)"
      fill="#00e49d"
    />
    <path
      id="Path_57"
      data-name="Path 57"
      d="M190.151,179.266l-.108.53.03.1a8.625,8.625,0,0,1,3.856,4.069.263.263,0,0,0,.5-.085,7.727,7.727,0,0,0,.049-.83A9.219,9.219,0,0,0,190.151,179.266Z"
      transform="translate(-181.135 -170.863)"
      fill="#00e49d"
    />
    <path
      id="Path_58"
      data-name="Path 58"
      d="M21.753,154.6c.446-1.334,1.507-3.1,3.958-2.609l-.034-.629-.058-.091c-2.975-.715-3.986,1.809-4.309,3.148a.237.237,0,0,0,.4.225Z"
      transform="translate(-20.304 -144.058)"
      fill="#00e49d"
    />
    <path
      id="Path_59"
      data-name="Path 59"
      d="M168.84,201.839a7.815,7.815,0,0,0-.295-4.952l-.461.15-.121.1a7.805,7.805,0,0,1,.023,4.984.237.237,0,0,0,.381.25c.146-.125.306-.273.468-.44A.245.245,0,0,1,168.84,201.839Z"
      transform="translate(-160.09 -187.658)"
      fill="#00e49d"
    />
    <path
      id="Path_60"
      data-name="Path 60"
      d="M309.411,373.9a1.837,1.837,0,0,1-2.235-.288.506.506,0,0,0-.719,0,1.837,1.837,0,0,1-2.606,0,.506.506,0,0,0-.719,0,1.837,1.837,0,0,1-2.606,0,.506.506,0,0,0-.681-.035v.387l1.877,3.356h7.787l.281-.652v-2.553A.25.25,0,0,0,309.411,373.9Z"
      transform="translate(-285.791 -355.953)"
      fill="#60b9fe"
    />
    <path
      id="Path_61"
      data-name="Path 61"
      d="M269.072,375.945a1.831,1.831,0,0,1-1.3.542c-.057,0-.114,0-.17-.008v.1l2.329,2.919.221.16h1.632a13.5,13.5,0,0,0-2.671-3.743Q269.091,375.926,269.072,375.945Z"
      transform="translate(-255.055 -358.289)"
      fill="#1ca8ff"
    />
    <path
      id="Path_62"
      data-name="Path 62"
      d="M359.2,439.518a1.831,1.831,0,0,1-1.3-.542.506.506,0,0,0-.719,0,1.837,1.837,0,0,1-2.606,0,.506.506,0,0,0-.719,0,1.835,1.835,0,0,1-.792.47l.777,2.622.35.189h5.68a.643.643,0,0,0,.643-.643v-2.645A1.831,1.831,0,0,1,359.2,439.518Z"
      transform="translate(-336.509 -418.256)"
      fill="#8bcaff"
    />
    <path
      id="Path_63"
      data-name="Path 63"
      d="M316.015,443.907a13.454,13.454,0,0,0-.942-2.449,1.836,1.836,0,0,1-1.893-.445.5.5,0,0,0-.058-.05v.07l1.293,3.068.2.191h1.5Z"
      transform="translate(-298.444 -420.293)"
      fill="#60b9fe"
    />
    <path
      id="Path_64"
      data-name="Path 64"
      d="M401.689,218.664a2.324,2.324,0,0,0,0,4.241,2.233,2.233,0,0,0,0-4.241Z"
      transform="translate(-381.551 -208.414)"
      fill="#ffc365"
    />
    <path
      id="Path_65"
      data-name="Path 65"
      d="M368.7,218.51a2.233,2.233,0,0,1,1.424-2.081,2.233,2.233,0,1,0,0,4.162A2.233,2.233,0,0,1,368.7,218.51Z"
      transform="translate(-349.88 -206.139)"
      fill="#ffa90f"
    />
    <g
      id="Group_115"
      data-name="Group 115"
      transform="translate(22.631 12.008)"
    >
      <path
        id="Path_66"
        data-name="Path 66"
        d="M483.748,256.9h-.592a.362.362,0,0,1,0-.724h.592a.362.362,0,0,1,0,.724Z"
        transform="translate(-482.794 -256.178)"
        fill="#ffc365"
      />
    </g>
    <g
      id="Group_116"
      data-name="Group 116"
      transform="translate(14.933 12.008)"
    >
      <path
        id="Path_67"
        data-name="Path 67"
        d="M319.518,256.9h-.592a.362.362,0,0,1,0-.724h.592a.362.362,0,0,1,0,.724Z"
        transform="translate(-318.564 -256.178)"
        fill="#ffc365"
      />
    </g>
    <g id="Group_117" data-name="Group 117" transform="translate(21.59 9.077)">
      <path
        id="Path_68"
        data-name="Path 68"
        d="M460.7,194.689a.362.362,0,0,1,0-.512l.418-.418a.362.362,0,0,1,.512.512l-.418.418A.362.362,0,0,1,460.7,194.689Z"
        transform="translate(-460.591 -193.653)"
        fill="#ffc365"
      />
    </g>
    <g
      id="Group_118"
      data-name="Group 118"
      transform="translate(16.147 14.521)"
    >
      <path
        id="Path_69"
        data-name="Path 69"
        d="M344.569,310.818a.362.362,0,0,1,0-.512l.418-.418a.362.362,0,1,1,.512.512l-.418.418A.362.362,0,0,1,344.569,310.818Z"
        transform="translate(-344.463 -309.782)"
        fill="#ffc365"
      />
    </g>
    <g id="Group_119" data-name="Group 119" transform="translate(19.078 7.863)">
      <path
        id="Path_70"
        data-name="Path 70"
        d="M407.351,169.069a.362.362,0,0,1-.362-.362v-.592a.362.362,0,0,1,.724,0v.592A.362.362,0,0,1,407.351,169.069Z"
        transform="translate(-406.989 -167.753)"
        fill="#ffc365"
      />
    </g>
    <g
      id="Group_120"
      data-name="Group 120"
      transform="translate(19.078 15.562)"
    >
      <path
        id="Path_71"
        data-name="Path 71"
        d="M407.351,333.3a.362.362,0,0,1-.362-.362v-.592a.362.362,0,0,1,.724,0v.592A.362.362,0,0,1,407.351,333.3Z"
        transform="translate(-406.989 -331.984)"
        fill="#ffc365"
      />
    </g>
    <g id="Group_121" data-name="Group 121" transform="translate(16.147 9.077)">
      <path
        id="Path_72"
        data-name="Path 72"
        d="M344.987,194.689l-.418-.418a.362.362,0,0,1,.512-.512l.418.418a.362.362,0,0,1-.512.512Z"
        transform="translate(-344.463 -193.653)"
        fill="#ffc365"
      />
    </g>
    <g id="Group_122" data-name="Group 122" transform="translate(21.59 14.521)">
      <path
        id="Path_73"
        data-name="Path 73"
        d="M461.115,310.817l-.418-.418a.362.362,0,1,1,.512-.512l.418.418a.362.362,0,0,1-.512.512Z"
        transform="translate(-460.591 -309.781)"
        fill="#ffc365"
      />
    </g>
    <path
      id="Path_74"
      data-name="Path 74"
      d="M36.554,324l-.843.476-1.372-.1-.206-.3a12.027,12.027,0,0,0-1.448.28v7.93a.581.581,0,0,0,.349.531H47.326A12.071,12.071,0,0,0,36.554,324Z"
      transform="translate(-31.152 -308.811)"
      fill="#ffdda8"
    />
    <g id="Group_123" data-name="Group 123" transform="translate(0 15.514)">
      <path
        id="Path_75"
        data-name="Path 75"
        d="M1.629,338.911v-7.955a11.974,11.974,0,0,0-1.2.365.651.651,0,0,0-.429.612v2.435l.293.245.032,3.01-.325.52v.649a.65.65,0,0,0,.65.65H1.978A.581.581,0,0,1,1.629,338.911Z"
        transform="translate(0 -330.956)"
        fill="#ffc365"
      />
    </g>
    <path
      id="Path_76"
      data-name="Path 76"
      d="M6.989,184.061l-.215.044-.209.816L3.49,190.848.252,195.385,0,196.056v2.078a27.424,27.424,0,0,0,7.47-13.346v-.154Z"
      transform="translate(0 -175.433)"
      fill="#dd8858"
    />
    <g id="Group_124" data-name="Group 124" transform="translate(0 8.63)">
      <path
        id="Path_77"
        data-name="Path 77"
        d="M6.98,184.1l-.722.147-.151.131A25.048,25.048,0,0,1,0,194.4v1.7C4.719,190.864,6.4,186.3,6.98,184.1Z"
        transform="translate(0 -184.099)"
        fill="#cb6c35"
      />
    </g>
    <path
      id="Path_78"
      data-name="Path 78"
      d="M167.2,154.481a1.2,1.2,0,0,0,0,2.148,1.171,1.171,0,0,0,0-2.148Z"
      transform="translate(-158.723 -147.24)"
      fill="#938493"
    />
    <path
      id="Path_79"
      data-name="Path 79"
      d="M148.844,153.944a1.171,1.171,0,0,1,.62-1.434,1.183,1.183,0,0,0-.121-.048,1.168,1.168,0,0,0-.955.1l-.077.084-.415,1.308v.084a1.17,1.17,0,0,0,1.568.6A1.168,1.168,0,0,1,148.844,153.944Z"
      transform="translate(-140.962 -145.258)"
      fill="#7b6c79"
    />
    <path
      id="Path_80"
      data-name="Path 80"
      d="M131.514,142.418a1.229,1.229,0,0,0,0,2.171,1.171,1.171,0,0,0,0-2.171Z"
      transform="translate(-124.726 -135.742)"
      fill="#938493"
    />
    <path
      id="Path_81"
      data-name="Path 81"
      d="M111.483,141.383a1.168,1.168,0,0,1,.616-.69,1.19,1.19,0,0,0-.125-.05,1.171,1.171,0,1,0,.125,2.172A1.171,1.171,0,0,1,111.483,141.383Z"
      transform="translate(-105.254 -133.992)"
      fill="#7b6c79"
    />
  </svg>
);

export const ReunionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23.994"
    viewBox="0 0 24 23.994"
  >
    <g id="_7" data-name="7" transform="translate(0 0)">
      <path
        id="Path_214"
        data-name="Path 214"
        d="M0,164.885l5.748-17.068L17.1,159.126Z"
        transform="translate(0 -140.891)"
        fill="#ffea84"
      />
      <g id="Group_304" data-name="Group 304" transform="translate(0 5.393)">
        <path
          id="Path_215"
          data-name="Path 215"
          d="M0,279.763,17.1,274l-5.681-5.66Z"
          transform="translate(0 -261.163)"
          fill="#ffdc40"
        />
        <path
          id="Path_216"
          data-name="Path 216"
          d="M129.5,128.141a5.816,5.816,0,0,1-2.086-.449,15.844,15.844,0,0,1-7.92-7.92c-.673-1.721-.586-3.126.246-3.958s2.237-.919,3.958-.246a15.844,15.844,0,0,1,7.92,7.92c.673,1.721.586,3.126-.246,3.958h0A2.553,2.553,0,0,1,129.5,128.141Z"
          transform="translate(-113.468 -115.12)"
          fill="#ffdc40"
        />
      </g>
      <g id="Group_305" data-name="Group 305" transform="translate(6.99 6.803)">
        <path
          id="Path_217"
          data-name="Path 217"
          d="M203.317,193.74h0l-5.65,5.65h0a14.38,14.38,0,0,0,4.683,3.237,5.817,5.817,0,0,0,2.086.449,2.553,2.553,0,0,0,1.872-.694h0c.832-.832.919-2.237.246-3.958A14.378,14.378,0,0,0,203.317,193.74Z"
          transform="translate(-195.391 -191.464)"
          fill="#ffab15"
        />
        <path
          id="Path_218"
          data-name="Path 218"
          d="M150.274,145.187a1.212,1.212,0,0,0-.877.282c-.4.4-.373,1.314.07,2.447a14.465,14.465,0,0,0,7.121,7.121c1.133.443,2.048.47,2.447.07s.373-1.314-.07-2.447a14.466,14.466,0,0,0-7.121-7.121A4.479,4.479,0,0,0,150.274,145.187Z"
          transform="translate(-149.116 -145.187)"
          fill="#ffab15"
        />
      </g>
      <path
        id="Path_219"
        data-name="Path 219"
        d="M225.514,219.209a12.959,12.959,0,0,0-2.921-4.2h0l-3.656,3.656h0a12.958,12.958,0,0,0,4.2,2.921c1.133.443,2.048.47,2.447.07S225.957,220.342,225.514,219.209Z"
        transform="translate(-208.674 -204.933)"
        fill="#ff9400"
      />
      <path
        id="Path_220"
        data-name="Path 220"
        d="M249.6,12.4l-1-1a3.341,3.341,0,0,1,2.559-.96,1.923,1.923,0,0,0,2.154-2.154,3.3,3.3,0,0,1,3.518-3.518,2,2,0,0,0,1.607-.547,2,2,0,0,0,.547-1.607,3.341,3.341,0,0,1,.959-2.558l1,1a2,2,0,0,0-.547,1.607,3.3,3.3,0,0,1-3.518,3.518,1.923,1.923,0,0,0-2.154,2.154,3.3,3.3,0,0,1-3.518,3.518A2,2,0,0,0,249.6,12.4Z"
        transform="translate(-236.946 -0.062)"
        fill="#063e8b"
      />
      <path
        id="Path_221"
        data-name="Path 221"
        d="M187.434,12.051a1.923,1.923,0,0,1,1.385-2.714,3.3,3.3,0,0,0,2.261-4.432,2,2,0,0,1,.025-1.7l-1.256-.641a3.342,3.342,0,0,0-.123,2.73A2,2,0,0,1,189.7,7a2,2,0,0,1-1.36,1.016,3.3,3.3,0,0,0-2.261,4.432,2,2,0,0,1-.025,1.7,2.058,2.058,0,0,1-.178.29c.277.373.581.744.909,1.109a3.266,3.266,0,0,0,.526-.758A3.342,3.342,0,0,0,187.434,12.051Z"
        transform="translate(-177.132 -2.451)"
        fill="#86d6f2"
      />
      <path
        id="Path_222"
        data-name="Path 222"
        d="M264.508,269.35a3.3,3.3,0,0,0,4.958-.407,2,2,0,0,1,1.457-.871l-.116-1.405A3.341,3.341,0,0,0,268.421,268a1.923,1.923,0,0,1-3.036.249,3.3,3.3,0,0,0-4.958.407,2.033,2.033,0,0,1-1.36.861,11.876,11.876,0,0,0,1.488.927,4.818,4.818,0,0,0,.917-.842,1.923,1.923,0,0,1,3.036-.249Z"
        transform="translate(-246.923 -254.169)"
        fill="#f4717a"
      />
      <circle
        id="Ellipse_18"
        data-name="Ellipse 18"
        cx="0.94"
        cy="0.94"
        r="0.94"
        transform="translate(8.456 2.253)"
        fill="#063e8b"
      />
      <circle
        id="Ellipse_19"
        data-name="Ellipse 19"
        cx="0.94"
        cy="0.94"
        r="0.94"
        transform="translate(21.364 16.84)"
        fill="#86d6f2"
      />
      <g
        id="Group_306"
        data-name="Group 306"
        transform="translate(17.339 1.313)"
      >
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="0.94"
          cy="0.94"
          r="0.94"
          transform="translate(2.115 8.226)"
          fill="#f4717a"
        />
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="0.94"
          cy="0.94"
          r="0.94"
          transform="translate(0 0)"
          fill="#f4717a"
        />
      </g>
    </g>
  </svg>
);

export const NoIcon = () => (
  <svg
    id="_8"
    data-name="8"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_223"
      data-name="Path 223"
      d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM4.385,16.728q-.181-.291-.34-.6a8.9,8.9,0,0,1-1-3.85A8.967,8.967,0,0,1,16.133,4.043q.306.159.6.341a.161.161,0,0,1,.03.25L4.635,16.758a.161.161,0,0,1-.251-.03ZM12,20.964a8.921,8.921,0,0,1-4.131-1.007c-.2-.106-.4-.22-.6-.34a.161.161,0,0,1-.03-.251L19.366,7.241a.161.161,0,0,1,.251.03c.121.194.234.394.34.6A8.966,8.966,0,0,1,12,20.964Z"
      fill="#e94444"
    />
    <path
      id="Path_224"
      data-name="Path 224"
      d="M106.571,45.99a12,12,0,0,1-14.55,19.059,12,12,0,1,0,14.55-19.059Z"
      transform="translate(-87.708 -43.834)"
      fill="#d83131"
    />
  </svg>
);

export const Graph = ({ targetWeight, weight, targetMonth, month }) => (
  <svg
    id="_1"
    data-name="1"
    width="100%"
    viewBox="-0.324 -3.894 358.324 245.961"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsbx="https://boxy-svg.com"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.825"
        x2="0"
        y2="0.814"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#20ff00" />
        <stop offset="0.502" stopColor="#ffda00" />
        <stop offset="1" stopColor="red" />
      </linearGradient>
      <style bxfonts="Inter">
        {`@import url(https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900&display=swap);`}
      </style>
    </defs>
    <path
      id="Path_4130"
      data-name="Path 4130"
      d="M5127.786,514.657h-357.9V336.089s81.167,0,144.134,63.95,98.389,104.306,213.769,103.8C5128,509.246,5127.786,514.657,5127.786,514.657Z"
      transform="translate(-4769.883 -290.516)"
      opacity="0.11"
      fill="url(#linear-gradient)"
    />
    <path
      id="Path_4130-2"
      data-name="Path 4130"
      d="M5163.856,504.714l.041-4.157c-116.236-1.143-151.485-38.4-200.277-89.966-5.352-5.657-10.887-11.507-16.694-17.479A183.073,183.073,0,0,0,4923.9,373.1a192.661,192.661,0,0,0-23.928-14.928,213.838,213.838,0,0,0-45.633-17.579c-6.343-1.656-12.827-3.052-19.271-4.147-5.156-.877-10.3-1.565-15.287-2.046a135.891,135.891,0,0,0-13.8-.749l.066,4.156c.05,0,5.116-.067,13.457.742a219.717,219.717,0,0,1,33.915,6.1c24.842,6.5,60.713,20.693,90.529,51.362,5.786,5.952,11.312,11.792,16.655,17.439,12.141,12.833,23.611,24.954,35.721,35.828a235.923,235.923,0,0,0,18.927,15.41,185.932,185.932,0,0,0,21.1,13.339c15.891,8.6,33.227,14.889,53,19.235a301.642,301.642,0,0,0,34.026,5.332C5135.83,503.858,5149.454,504.572,5163.856,504.714Z"
      transform="translate(-4805.976 -290.104)"
      fill="url(#linear-gradient)"
    />
    <g id="Group_2143" data-name="Group 2143" transform="translate(27.18 0)">
      <g
        id="Ellipse_10"
        data-name="Ellipse 10"
        transform="translate(25.419 45.93)"
        fill="red"
        stroke="#fff"
        strokeWidth="2"
      >
        <circle cx="12.67" cy="12.67" r="12.67" stroke="none" />
        <circle cx="12.67" cy="12.67" r="11.67" fill="none" />
      </g>
      <line
        id="Line_16"
        data-name="Line 16"
        y2="151.309"
        transform="translate(37.665 72.736)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
        strokeDasharray="2 5"
        opacity="0.5"
      />
      <g id="Group_101" data-name="Group 101" transform="translate(0)">
        <g id="Group_2146" data-name="Group 2146" transform="translate(0)">
          <rect
            id="Rectangle_26"
            data-name="Rectangle 26"
            width="76.197"
            height="35.922"
            rx="5"
            fill="#8b8b8b"
          />
        </g>
        <path
          id="Polygon_1"
          data-name="Polygon 1"
          d="M4.9,0,9.8,5.443H0Z"
          transform="translate(43.541 41.364) rotate(180)"
          fill="#8b8b8b"
        />
      </g>
    </g>
    <g
      id="Group_2144"
      data-name="Group 2144"
      transform="translate(280.664 193.222)"
    >
      <line
        id="Line_16-2"
        data-name="Line 16"
        y2="10.545"
        transform="translate(11.976 20.278)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
        strokeDasharray="2 5"
        opacity="0.5"
      />
      <g
        id="Ellipse_10-2"
        data-name="Ellipse 10"
        transform="translate(0)"
        fill="#36fc00"
        stroke="#fff"
        strokeWidth="2"
      >
        <circle cx="12.67" cy="12.67" r="12.67" stroke="none" />
        <circle cx="12.67" cy="12.67" r="11.67" fill="none" />
      </g>
    </g>
    <g
      id="Group_2147"
      data-name="Group 2147"
      transform="translate(254.684 144.775)"
    >
      <g id="Group_2146-2" data-name="Group 2146" transform="translate(0 0)">
        <rect
          id="Rectangle_26-2"
          data-name="Rectangle 26"
          width="76.197"
          height="35.922"
          rx="5"
          transform="translate(0 0)"
          fill="#8b8b8b"
        />
      </g>
      <path
        id="Polygon_1-2"
        data-name="Polygon 1"
        d="M5.443,0l5.443,4.354H0Z"
        transform="translate(44.63 40.276) rotate(180)"
        fill="#8b8b8b"
      />
    </g>
    <text
      style={{
        fill: "white",
        fontFamily: "Inter",
        fontSize: "16px",
        whiteSpace: "pre",
      }}
      x="49.868"
      y="239.917"
    >
      {targetMonth}
    </text>
    <text
      style={{
        fill: "white",
        fontFamily: "Inter",
        fontSize: "16px",
        whiteSpace: "pre",
      }}
      x="279.74"
      y="240.116"
    >
      {month}
    </text>
    <text
      style={{
        fill: "white",
        fontFamily: "Inter",
        fontSize: "18px",
        whiteSpace: "pre",
        textAnchor: "middle",
      }}
      x="64.881"
      y="24.49"
    >
      {targetWeight}
    </text>
    <text
      style={{
        fill: "white",
        fontFamily: "Inter",
        fontSize: "18px",
        whiteSpace: "pre",
        textAnchor: "middle",
      }}
      x="293.186"
      y="169.347"
    >
      {weight}
    </text>
  </svg>
);


export const GainGraph = ({ targetWeight, weight, targetMonth, month }) => (
  <svg
  style={{transform:"rotateY(180deg)"}}
    id="_1"
    data-name="1"
    width="100%"
    viewBox="-0.324 -3.894 358.324 245.961"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsbx="https://boxy-svg.com"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.825"
        x2="0"
        y2="0.814"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="red" />
        <stop offset="0.502" stopColor="#ffda00" />
        <stop offset="1" stopColor="#20ff00" />
      </linearGradient>
      <style bxfonts="Inter">
        {`@import url(https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900&display=swap);`}
      </style>
    </defs>
    <path
      id="Path_4130"
      data-name="Path 4130"
      d="M5127.786,514.657h-357.9V336.089s81.167,0,144.134,63.95,98.389,104.306,213.769,103.8C5128,509.246,5127.786,514.657,5127.786,514.657Z"
      transform="translate(-4769.883 -290.516)"
      opacity="0.11"
      fill="url(#linear-gradient)"
    />
    <path
      id="Path_4130-2"
      data-name="Path 4130"
      d="M5163.856,504.714l.041-4.157c-116.236-1.143-151.485-38.4-200.277-89.966-5.352-5.657-10.887-11.507-16.694-17.479A183.073,183.073,0,0,0,4923.9,373.1a192.661,192.661,0,0,0-23.928-14.928,213.838,213.838,0,0,0-45.633-17.579c-6.343-1.656-12.827-3.052-19.271-4.147-5.156-.877-10.3-1.565-15.287-2.046a135.891,135.891,0,0,0-13.8-.749l.066,4.156c.05,0,5.116-.067,13.457.742a219.717,219.717,0,0,1,33.915,6.1c24.842,6.5,60.713,20.693,90.529,51.362,5.786,5.952,11.312,11.792,16.655,17.439,12.141,12.833,23.611,24.954,35.721,35.828a235.923,235.923,0,0,0,18.927,15.41,185.932,185.932,0,0,0,21.1,13.339c15.891,8.6,33.227,14.889,53,19.235a301.642,301.642,0,0,0,34.026,5.332C5135.83,503.858,5149.454,504.572,5163.856,504.714Z"
      transform="translate(-4805.976 -290.104)"
      fill="url(#linear-gradient)"
    />
    <g id="Group_2143" data-name="Group 2143" transform="translate(27.18 0)">
      <g
        id="Ellipse_10"
        data-name="Ellipse 10"
        transform="translate(25.419 45.93)"
        fill="#36fc00"
        stroke="#fff"
        strokeWidth="2"
      >
        <circle cx="12.67" cy="12.67" r="12.67" stroke="none" />
        <circle cx="12.67" cy="12.67" r="11.67" fill="none" />
      </g>
      <line
        id="Line_16"
        data-name="Line 16"
        y2="151.309"
        transform="translate(37.665 72.736)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
        strokeDasharray="2 5"
        opacity="0.5"
      />
      <g id="Group_101" data-name="Group 101" transform="translate(0)">
        <g id="Group_2146" data-name="Group 2146" transform="translate(0)">
          <rect
            id="Rectangle_26"
            data-name="Rectangle 26"
            width="76.197"
            height="35.922"
            rx="5"
            fill="#8b8b8b"
          />
        </g>
        <path
          id="Polygon_1"
          data-name="Polygon 1"
          d="M4.9,0,9.8,5.443H0Z"
          transform="translate(43.541 41.364) rotate(180)"
          fill="#8b8b8b"
        />
      </g>
    </g>
    <g
      id="Group_2144"
      data-name="Group 2144"
      transform="translate(280.664 193.222)"
    >
      <line
        id="Line_16-2"
        data-name="Line 16"
        y2="10.545"
        transform="translate(11.976 20.278)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
        strokeDasharray="2 5"
        opacity="0.5"
      />
      <g
        id="Ellipse_10-2"
        data-name="Ellipse 10"
        transform="translate(0)"
        fill="red"
        stroke="#fff"
        strokeWidth="2"
      >
        <circle cx="12.67" cy="12.67" r="12.67" stroke="none" />
        <circle cx="12.67" cy="12.67" r="11.67" fill="none" />
      </g>
    </g>
    <g
      id="Group_2147"
      data-name="Group 2147"
      transform="translate(254.684 144.775)"
    >
      <g id="Group_2146-2" data-name="Group 2146" transform="translate(0 0)">
        <rect
          id="Rectangle_26-2"
          data-name="Rectangle 26"
          width="76.197"
          height="35.922"
          rx="5"
          transform="translate(0 0)"
          fill="#8b8b8b"
        />
      </g>
      <path
        id="Polygon_1-2"
        data-name="Polygon 1"
        d="M5.443,0l5.443,4.354H0Z"
        transform="translate(44.63 40.276) rotate(180)"
        fill="#8b8b8b"
      />
    </g>
    <text
      style={{
        transform:"rotateY(180deg)",
        fill: "white",
        fontFamily: "Inter",
        fontSize: "16px",
        whiteSpace: "pre",
      }}
      x="-79.868"
      y="239.917"
    >
      {targetMonth}
    </text>
    <text
      style={{
        transform:"rotateY(180deg)",
        fill: "white",
        fontFamily: "Inter",
        fontSize: "16px",
        whiteSpace: "pre",
      }}
      x="-307.74"
      y="240.116"
    >
      {month}
    </text>
    <text
      style={{
        transform:"rotateY(180deg)",
        fill: "white",
        fontFamily: "Inter",
        fontSize: "18px",
        whiteSpace: "pre",
        textAnchor: "middle",
      }}
      x="-64.881"
      y="24.49"
    >
      {targetWeight}
    </text>
    <text
      style={{
        transform:"rotateY(180deg)",
        fill: "white",
        fontFamily: "Inter",
        fontSize: "18px",
        whiteSpace: "pre",
        textAnchor: "middle",
      }}
      x="-293.186"
      y="169.347"
    >
      {weight}
    </text>
  </svg>
);
