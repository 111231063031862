export const Arrow_Svg = () => (
  <svg
    className="arrow"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="294.832"
    height="141.198"
    viewBox="0 0 294.832 141.198"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="0.087"
        x2="0.5"
        y2="0.941"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2680eb" stopOpacity="0" />
        <stop offset="0.473" stopColor="#2680eb" />
        <stop offset="1" stopColor="#2680eb" stopOpacity="0" />
      </linearGradient>
    </defs>
    <g id="arrows" transform="translate(-58 -247.4)">
      <g id="arrows-2" data-name="arrows">
        <path
          id="arrow"
          d="M0,15.772,12.622,3.15l70.6,70.6-70.6,70.6L0,131.726,57.976,73.749Z"
          transform="translate(143 244.25)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
        <path
          id="arrow-2"
          data-name="arrow"
          d="M0,15.772,12.622,3.15l70.6,70.6-70.6,70.6L0,131.726,57.976,73.749Z"
          transform="translate(184.611 244.25)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
      </g>
      <g id="arrows-3" data-name="arrows" transform="translate(85)">
        <path
          id="arrow-3"
          data-name="arrow"
          d="M0,15.772,12.622,3.15l70.6,70.6-70.6,70.6L0,131.726,57.976,73.749Z"
          transform="translate(143 244.25)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
        <path
          id="arrow-4"
          data-name="arrow"
          d="M0,15.772,12.622,3.15l70.6,70.6-70.6,70.6L0,131.726,57.976,73.749Z"
          transform="translate(184.611 244.25)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
      </g>
      <g id="arrows-4" data-name="arrows" transform="translate(-85)">
        <path
          id="arrow-5"
          data-name="arrow"
          d="M0,15.772,12.622,3.15l70.6,70.6-70.6,70.6L0,131.726,57.976,73.749Z"
          transform="translate(143 244.25)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
        <path
          id="arrow-6"
          data-name="arrow"
          d="M0,15.772,12.622,3.15l70.6,70.6-70.6,70.6L0,131.726,57.976,73.749Z"
          transform="translate(184.611 244.25)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
      </g>
    </g>
  </svg>
);

export const Discount_Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.1 48.606">
    <path
      id="Path_4264"
      data-name="Path 4264"
      d="M-165.76-18.332v-8.279h25.038v8.279ZM-101-33.756a16.6,16.6,0,0,1-2.736,9.481h0L-118.289-2.308h-10.549l10.407-15.223a16.612,16.612,0,0,1-11.845-4.64,15.562,15.562,0,0,1-4.571-11.584A15.518,15.518,0,0,1-130.14-45.44a16.821,16.821,0,0,1,12.185-4.54q7.545,0,12.252,4.507A15.491,15.491,0,0,1-101-33.756Zm-24.7,0a7.186,7.186,0,0,0,2.237,5.542,7.807,7.807,0,0,0,5.508,2.07,7.865,7.865,0,0,0,5.608-2.07,7.242,7.242,0,0,0,2.2-5.542,7.135,7.135,0,0,0-2.2-5.508,7.94,7.94,0,0,0-5.608-2.036,7.807,7.807,0,0,0-5.508,2.07A7.105,7.105,0,0,0-125.7-33.756ZM-78.429-1.374A16.191,16.191,0,0,1-92.149-8.017q-4.974-6.643-4.974-17.66t4.974-17.66A16.191,16.191,0,0,1-78.429-49.98q8.813,0,13.754,6.643t4.941,17.66q0,11.017-4.941,17.66T-78.429-1.374Zm-7.077-12.919a7.8,7.8,0,0,0,7.077,3.906,7.86,7.86,0,0,0,7.111-3.939q2.437-3.939,2.437-11.35,0-7.478-2.437-11.417a7.86,7.86,0,0,0-7.111-3.939,7.784,7.784,0,0,0-7.077,3.939q-2.4,3.939-2.4,11.417T-85.506-14.293Zm49.074-16.825a10.931,10.931,0,0,1-8.012,3.171,10.982,10.982,0,0,1-8.045-3.2,10.575,10.575,0,0,1-3.238-7.812,10.575,10.575,0,0,1,3.238-7.812,10.982,10.982,0,0,1,8.045-3.2,10.931,10.931,0,0,1,8.012,3.171,10.6,10.6,0,0,1,3.2,7.845A10.6,10.6,0,0,1-36.432-31.118ZM-50.988-9.386l30.446-33.851,4.073,2.27L-46.981-7.049Zm3.505-26.54a3.912,3.912,0,0,0,2.971,1.235,4.02,4.02,0,0,0,3-1.235,4.189,4.189,0,0,0,1.2-3.038,4.076,4.076,0,0,0-1.2-3,4.076,4.076,0,0,0-3-1.2,3.966,3.966,0,0,0-2.971,1.2,4.138,4.138,0,0,0-1.168,3A4.253,4.253,0,0,0-47.482-35.926ZM-13.865-4.712a10.87,10.87,0,0,1-8.012,3.2,10.87,10.87,0,0,1-8.012-3.2,10.63,10.63,0,0,1-3.2-7.812,10.63,10.63,0,0,1,3.2-7.812,10.871,10.871,0,0,1,8.012-3.2,10.87,10.87,0,0,1,8.012,3.2,10.63,10.63,0,0,1,3.2,7.812A10.63,10.63,0,0,1-13.865-4.712ZM-24.848-9.486a3.912,3.912,0,0,0,2.971,1.235,3.862,3.862,0,0,0,2.971-1.269,4.287,4.287,0,0,0,1.168-3,4.253,4.253,0,0,0-1.168-3.038A3.912,3.912,0,0,0-21.877-16.8a3.912,3.912,0,0,0-2.971,1.235,4.253,4.253,0,0,0-1.168,3.038A4.253,4.253,0,0,0-24.848-9.486Z"
      transform="translate(165.76 49.98)"
      fill="#fff"
    />
  </svg>
);

export const ApplePay_Svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="33.5"
    viewBox="0 0 58.474 24"
  >
    <path
      id="Icon_awesome-apple-pay"
      data-name="Icon awesome-apple-pay"
      d="M10.681,11.856A3.587,3.587,0,0,1,7.8,13.217a4.05,4.05,0,0,1,1.032-2.978,4.3,4.3,0,0,1,2.86-1.471,4.347,4.347,0,0,1-1.014,3.088m1,1.571c-1.59-.091-2.951.9-3.7.9-.767,0-1.919-.859-3.179-.831A4.7,4.7,0,0,0,.814,15.921C-.9,18.872.367,23.23,2.029,25.633c.813,1.188,1.781,2.494,3.06,2.448C6.3,28.035,6.78,27.3,8.242,27.3s1.9.786,3.179.767c1.325-.027,2.156-1.188,2.969-2.375a10.327,10.327,0,0,0,1.325-2.732,4.316,4.316,0,0,1-2.585-3.919,4.395,4.395,0,0,1,2.092-3.682,4.515,4.515,0,0,0-3.545-1.928m9.172-3.307V27.926h2.768V21.841h3.828a5.65,5.65,0,0,0,5.947-5.874,5.585,5.585,0,0,0-5.856-5.847H20.849Zm2.768,2.33h3.188c2.4,0,3.773,1.279,3.773,3.526s-1.37,3.545-3.782,3.545H23.617ZM38.436,28.063a4.561,4.561,0,0,0,4.075-2.275h.055v2.138h2.558V19.064c0-2.567-2.056-4.23-5.217-4.23-2.933,0-5.107,1.681-5.189,3.983h2.494A2.432,2.432,0,0,1,39.824,17c1.69,0,2.64.786,2.64,2.238v.987l-3.453.21c-3.207.192-4.942,1.507-4.942,3.791C34.078,26.528,35.868,28.063,38.436,28.063Zm.749-2.11c-1.471,0-2.412-.713-2.412-1.791,0-1.124.9-1.772,2.631-1.873l3.07-.192v1a3,3,0,0,1-3.289,2.85Zm9.364,6.815c2.7,0,3.965-1.032,5.07-4.148L58.47,15.008H55.657L52.4,25.523h-.055L49.1,15.008H46.21l4.678,12.964-.256.786a2.2,2.2,0,0,1-2.33,1.855c-.219,0-.64-.027-.813-.046V32.7A9.576,9.576,0,0,0,48.549,32.768Z"
      transform="translate(0.004 -8.768)"
    />
  </svg>
);

export const Gpay_Svg = () => (
  <svg
    width="58.474"
    height="24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 2387.3 948"
    space="preserve"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          fill="#5F6368"
          d="M1129.1,463.2V741h-88.2V54.8h233.8c56.4-1.2,110.9,20.2,151.4,59.4c41,36.9,64.1,89.7,63.2,144.8 c1.2,55.5-21.9,108.7-63.2,145.7c-40.9,39-91.4,58.5-151.4,58.4L1129.1,463.2L1129.1,463.2z M1129.1,139.3v239.6h147.8 c32.8,1,64.4-11.9,87.2-35.5c46.3-45,47.4-119.1,2.3-165.4c-0.8-0.8-1.5-1.6-2.3-2.3c-22.5-24.1-54.3-37.3-87.2-36.4L1129.1,139.3 L1129.1,139.3z M1692.5,256.2c65.2,0,116.6,17.4,154.3,52.2c37.7,34.8,56.5,82.6,56.5,143.2V741H1819v-65.2h-3.8 c-36.5,53.7-85.1,80.5-145.7,80.5c-51.7,0-95-15.3-129.8-46c-33.8-28.5-53-70.7-52.2-115c0-48.6,18.4-87.2,55.1-115.9 c36.7-28.7,85.7-43.1,147.1-43.1c52.3,0,95.5,9.6,129.3,28.7v-20.2c0.2-30.2-13.2-58.8-36.4-78c-23.3-21-53.7-32.5-85.1-32.1 c-49.2,0-88.2,20.8-116.9,62.3l-77.6-48.9C1545.6,286.8,1608.8,256.2,1692.5,256.2L1692.5,256.2z M1578.4,597.3 c-0.1,22.8,10.8,44.2,29.2,57.5c19.5,15.3,43.7,23.5,68.5,23c37.2-0.1,72.9-14.9,99.2-41.2c29.2-27.5,43.8-59.7,43.8-96.8 c-27.5-21.9-65.8-32.9-115-32.9c-35.8,0-65.7,8.6-89.6,25.9C1590.4,550.4,1578.4,571.7,1578.4,597.3L1578.4,597.3z M2387.3,271.5 L2093,948h-91l109.2-236.7l-193.6-439.8h95.8l139.9,337.3h1.9l136.1-337.3L2387.3,271.5z"
        ></path>
      </g>
      <path
        fill="#4285F4"
        d="M772.8,403.2c0-26.9-2.2-53.7-6.8-80.2H394.2v151.8h212.9c-8.8,49-37.2,92.3-78.7,119.8v98.6h127.1 C729.9,624.7,772.8,523.2,772.8,403.2L772.8,403.2z"
      ></path>
      <path
        fill="#34A853"
        d="M394.2,788.5c106.4,0,196-34.9,261.3-95.2l-127.1-98.6c-35.4,24-80.9,37.7-134.2,37.7 c-102.8,0-190.1-69.3-221.3-162.7H42v101.6C108.9,704.5,245.2,788.5,394.2,788.5z"
      ></path>
      <path
        fill="#FBBC04"
        d="M172.9,469.7c-16.5-48.9-16.5-102,0-150.9V217.2H42c-56,111.4-56,242.7,0,354.1L172.9,469.7z"
      ></path>
      <path
        fill="#EA4335"
        d="M394.2,156.1c56.2-0.9,110.5,20.3,151.2,59.1L658,102.7C586.6,35.7,492.1-1.1,394.2,0 C245.2,0,108.9,84.1,42,217.2l130.9,101.6C204.1,225.4,291.4,156.1,394.2,156.1z"
      ></path>
    </g>
  </svg>
);

export const Paypal_Svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92.648"
    height="25"
    viewBox="0 0 92.648 25"
  >
    <g id="paypal" transform="translate(-19.4 -28.2)">
      <path
        id="Path_4275"
        data-name="Path 4275"
        d="M55.035,28.2H47.892a1.4,1.4,0,0,0-1.05.84L43.9,47.528a.557.557,0,0,0,.63.63h3.782a.557.557,0,0,0,.63-.63l.84-5.252a.961.961,0,0,1,1.05-.84h2.311q7.248,0,8.193-6.933a5.507,5.507,0,0,0-.84-4.622A8.473,8.473,0,0,0,55.035,28.2m.84,6.933c-.42,2.521-2.311,2.521-4.2,2.521H50.413l.84-4.832c0-.21.21-.42.63-.42h.42c1.261,0,2.521,0,3.151.84.42.21.42.84.42,1.891"
        transform="translate(26.971 0)"
        fill="#139ad6"
      />
      <path
        id="Path_4276"
        data-name="Path 4276"
        d="M30.535,28.2H23.392a1.4,1.4,0,0,0-1.05.84L19.4,47.528a.557.557,0,0,0,.63.63h3.361a1.4,1.4,0,0,0,1.05-.84l.84-5.042a.961.961,0,0,1,1.05-.84h2.311q7.248,0,8.193-6.933A5.507,5.507,0,0,0,36,29.881,8.075,8.075,0,0,0,30.535,28.2m.84,6.933c-.42,2.521-2.311,2.521-4.2,2.521h-1.05l.84-4.832c0-.21.21-.42.63-.42h.42c1.261,0,2.521,0,3.151.84a2.25,2.25,0,0,1,.21,1.891"
        transform="translate(0 0)"
        fill="#263b80"
      />
      <path
        id="Path_4277"
        data-name="Path 4277"
        d="M43.546,31.62H40.184c-.21,0-.63.21-.63.42l-.21,1.05-.21-.42A4.942,4.942,0,0,0,35.142,31.2a8.073,8.073,0,0,0-7.773,6.933,6.262,6.262,0,0,0,1.261,5.252,5.294,5.294,0,0,0,4.412,1.681,6.715,6.715,0,0,0,4.832-1.891l-.21,1.05a.557.557,0,0,0,.63.63h3.151a1.4,1.4,0,0,0,1.05-.84L44.386,32.25c-.21-.21-.63-.63-.84-.63m-4.832,6.723A4.063,4.063,0,0,1,34.722,41.7a3.36,3.36,0,0,1-2.311-.84,4.48,4.48,0,0,1-.63-2.521,3.747,3.747,0,0,1,6.092-2.521,3.668,3.668,0,0,1,.84,2.521"
        transform="translate(8.628 3.303)"
        fill="#263b80"
      />
      <path
        id="Path_4278"
        data-name="Path 4278"
        d="M67.946,31.62H64.584c-.21,0-.63.21-.63.42l-.21,1.05-.21-.42A4.942,4.942,0,0,0,59.542,31.2a8.073,8.073,0,0,0-7.773,6.933,6.262,6.262,0,0,0,1.261,5.252,5.294,5.294,0,0,0,4.412,1.681,6.715,6.715,0,0,0,4.832-1.891l-.21,1.05a.557.557,0,0,0,.63.63h3.151a1.4,1.4,0,0,0,1.05-.84L68.786,32.25c-.21-.21-.42-.63-.84-.63m-4.832,6.723A4.063,4.063,0,0,1,59.122,41.7a3.359,3.359,0,0,1-2.311-.84,4.48,4.48,0,0,1-.63-2.521,3.747,3.747,0,0,1,6.092-2.521,2.411,2.411,0,0,1,.84,2.521"
        transform="translate(35.489 3.303)"
        fill="#139ad6"
      />
      <path
        id="Path_4279"
        data-name="Path 4279"
        d="M52.177,31.4H48.605a1.091,1.091,0,0,0-.84.42l-4.622,7.143-2.1-6.723a.971.971,0,0,0-1.05-.63H36.63c-.42,0-.63.42-.63.84l3.782,11.135L36.21,48.627c-.21.42,0,1.05.42,1.05h3.361a1.091,1.091,0,0,0,.84-.42l11.555-16.6c.63-.63.21-1.261-.21-1.261"
        transform="translate(18.274 3.523)"
        fill="#263b80"
      />
      <path
        id="Path_4280"
        data-name="Path 4280"
        d="M62.841,28.763,59.9,47.671a.557.557,0,0,0,.63.63h2.941a1.4,1.4,0,0,0,1.05-.84l2.941-18.488a.557.557,0,0,0-.63-.63H63.471c-.21-.21-.42,0-.63.42"
        transform="translate(44.585 0.067)"
        fill="#139ad6"
      />
    </g>
  </svg>
);

export const Security_Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.499 18.901">
    <g transform="translate(-5.232 -31.651)">
      <g
        id="Group_2490"
        data-name="Group 2490"
        transform="translate(-2.513 26.857)"
      >
        <path
          id="Path_4283"
          data-name="Path 4283"
          d="M24.928,38.553c0-1.444-1.221-2.073-2.2-2.574-.651-.335-1.192-.613-1.184-1.041,0-.336.324-.691,1.012-.77a4.777,4.777,0,0,1,2.388.4l.425-1.975a6.7,6.7,0,0,0-2.247-.4c-2.408,0-4.048,1.244-4.048,3.022,0,1.291,1.191,1.962,2.1,2.473l.027.015c.911.514,1.255.731,1.255,1.126s-.749.889-1.437.889a5.124,5.124,0,0,1-2.51-.533l-.445,1.975a8.271,8.271,0,0,0,2.692.474c2.55,0,4.21-1.225,4.21-3.121ZM18.371,32.39l-2.024,9.145H13.938l2.024-9.145Zm10.12,5.926,1.275-3.437.89,3.417Zm4.939,3.239H31.2l-.344-1.363h-3.1l-.506,1.363h-2.55l3.643-8.474a1.114,1.114,0,0,1,1.032-.672h2.024Zm-22.446-.02L14.91,32.39H12.441L9.728,38.612,8.7,33.338a1.125,1.125,0,0,0-1.113-.948H3.535v.277a10.813,10.813,0,0,1,2.267.77,1.047,1.047,0,0,1,.567.81l2.024,7.288Z"
          transform="translate(4.21 -23.007)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
      <g
        id="Group_2491"
        data-name="Group 2491"
        transform="translate(44.352 29.957)"
      >
        <path
          id="Path_4286"
          data-name="Path 4286"
          d="M35.082,30.872h-6.3V40.907h6.3Z"
          transform="translate(-15.584 -25.079)"
          fill="#efefef"
        />
        <path
          id="Path_4287"
          data-name="Path 4287"
          d="M32.256,36.4a6.332,6.332,0,0,1,2.466-5.017,6.651,6.651,0,0,0-8.835.692,6.265,6.265,0,0,0,0,8.65,6.651,6.651,0,0,0,8.835.692A6.308,6.308,0,0,1,32.256,36.4Z"
          transform="translate(-18.38 -25.592)"
          fill="#d9d9d9"
        />
        <path
          id="Path_4288"
          data-name="Path 4288"
          d="M37.4,36.31v.253H37.26V36.49l.074-.18Zm-.162.116.026.064v-.12Zm-.048-.116.048.116-.048.1-.074-.156v-.06Zm-.21.048v.2H36.89v-.2Z"
          transform="translate(-10.684 -21.794)"
          fill="#f69e1e"
          fillRule="evenodd"
        />
        <path
          id="Path_4289"
          data-name="Path 4289"
          d="M41.32,36.405a6.377,6.377,0,0,1-3.685,5.73,6.68,6.68,0,0,1-6.893-.712,6.294,6.294,0,0,0,0-10.035,6.667,6.667,0,0,1,6.9-.726A6.365,6.365,0,0,1,41.32,36.405Z"
          transform="translate(-14.4 -25.595)"
          fill="#c9c9c9"
        />
      </g>
      <g transform="translate(66.873 5.438)">
        <path
          id="Path_4275"
          data-name="Path 4275"
          d="M50.685,28.2H46.332a.851.851,0,0,0-.64.512L43.9,39.978a.339.339,0,0,0,.384.384h2.3a.339.339,0,0,0,.384-.384l.512-3.2a.586.586,0,0,1,.64-.512h1.408q4.417,0,4.993-4.225a3.356,3.356,0,0,0-.512-2.817A5.164,5.164,0,0,0,50.685,28.2m.512,4.225c-.256,1.536-1.408,1.536-2.56,1.536h-.768l.512-2.945c0-.128.128-.256.384-.256h.256c.768,0,1.536,0,1.92.512.256.128.256.512.256,1.152"
          transform="translate(6.866 0)"
          fill="#fff"
        />
        <path
          id="Path_4276"
          data-name="Path 4276"
          d="M26.185,28.2H21.832a.851.851,0,0,0-.64.512L19.4,39.978a.339.339,0,0,0,.384.384h2.048a.851.851,0,0,0,.64-.512l.512-3.073a.586.586,0,0,1,.64-.512h1.408q4.417,0,4.993-4.225a3.356,3.356,0,0,0-.512-2.817A4.921,4.921,0,0,0,26.185,28.2m.512,4.225c-.256,1.536-1.408,1.536-2.56,1.536H23.5l.512-2.945c0-.128.128-.256.384-.256h.256c.768,0,1.536,0,1.92.512a1.371,1.371,0,0,1,.128,1.152"
          transform="translate(0 0)"
          fill="#fff"
        />
        <path
          id="Path_4277"
          data-name="Path 4277"
          d="M37.175,31.456H35.127c-.128,0-.384.128-.384.256l-.128.64-.128-.256a3.011,3.011,0,0,0-2.432-.9,4.919,4.919,0,0,0-4.737,4.225,3.816,3.816,0,0,0,.768,3.2,3.226,3.226,0,0,0,2.688,1.024A4.092,4.092,0,0,0,33.719,38.5l-.128.64a.339.339,0,0,0,.384.384H35.9a.851.851,0,0,0,.64-.512l1.152-7.169c-.128-.128-.384-.384-.512-.384m-2.945,4.1A2.476,2.476,0,0,1,31.8,37.6a2.047,2.047,0,0,1-1.408-.512,2.73,2.73,0,0,1-.384-1.536,2.283,2.283,0,0,1,3.713-1.536,2.235,2.235,0,0,1,.512,1.536"
          transform="translate(2.196 0.841)"
          fill="#fff"
        />
        <path
          id="Path_4278"
          data-name="Path 4278"
          d="M61.575,31.456H59.527c-.128,0-.384.128-.384.256l-.128.64-.128-.256a3.011,3.011,0,0,0-2.432-.9,4.919,4.919,0,0,0-4.737,4.225,3.816,3.816,0,0,0,.768,3.2,3.226,3.226,0,0,0,2.688,1.024A4.092,4.092,0,0,0,58.119,38.5l-.128.64a.339.339,0,0,0,.384.384H60.3a.851.851,0,0,0,.64-.512l1.152-7.169c-.128-.128-.256-.384-.512-.384m-2.945,4.1A2.476,2.476,0,0,1,56.2,37.6a2.047,2.047,0,0,1-1.408-.512,2.73,2.73,0,0,1-.384-1.536,2.283,2.283,0,0,1,3.713-1.536,1.469,1.469,0,0,1,.512,1.536"
          transform="translate(9.034 0.841)"
          fill="#fff"
        />
        <path
          id="Path_4279"
          data-name="Path 4279"
          d="M45.858,31.4H43.681a.665.665,0,0,0-.512.256l-2.817,4.353-1.28-4.1a.591.591,0,0,0-.64-.384H36.384c-.256,0-.384.256-.384.512l2.3,6.785L36.128,41.9c-.128.256,0,.64.256.64h2.048a.665.665,0,0,0,.512-.256l7.041-10.114c.384-.384.128-.768-.128-.768"
          transform="translate(4.652 0.897)"
          fill="#fff"
        />
        <path
          id="Path_4280"
          data-name="Path 4280"
          d="M61.692,28.567,59.9,40.089a.339.339,0,0,0,.384.384h1.792a.851.851,0,0,0,.64-.512L64.509,28.7a.339.339,0,0,0-.384-.384H62.076c-.128-.128-.256,0-.384.256"
          transform="translate(11.349 0.017)"
          fill="#fff"
        />
      </g>
      <g transform="translate(111.731 -657.349)">
        <rect
          id="Rectangle"
          width="38"
          height="18.901"
          rx="2"
          transform="translate(46 689)"
          fill="#fff"
        />
        <path
          id="Icon_awesome-apple-pay"
          data-name="Icon awesome-apple-pay"
          d="M4.865,10.175a1.635,1.635,0,0,1-1.311.62,1.846,1.846,0,0,1,.47-1.357,1.957,1.957,0,0,1,1.3-.67,1.981,1.981,0,0,1-.462,1.407m.454.716c-.724-.042-1.345.412-1.686.412s-.874-.391-1.449-.379a2.14,2.14,0,0,0-1.815,1.1c-.783,1.345-.2,3.33.554,4.425.371.541.812,1.137,1.395,1.116.554-.021.77-.358,1.436-.358s.866.358,1.449.35c.6-.012.982-.541,1.353-1.082a4.706,4.706,0,0,0,.6-1.245,1.967,1.967,0,0,1-1.178-1.786,2,2,0,0,1,.953-1.678,2.058,2.058,0,0,0-1.615-.878M9.5,9.384V17.5H10.76V14.725H12.5a2.575,2.575,0,0,0,2.71-2.677,2.545,2.545,0,0,0-2.669-2.664H9.5Zm1.261,1.062h1.453a1.514,1.514,0,0,1,1.719,1.607,1.521,1.521,0,0,1-1.724,1.615H10.76Zm6.752,7.115a2.078,2.078,0,0,0,1.857-1.037h.025V17.5H20.56V13.46c0-1.17-.937-1.927-2.377-1.927a2.117,2.117,0,0,0-2.365,1.815h1.137a1.108,1.108,0,0,1,1.191-.828c.77,0,1.2.358,1.2,1.02v.45l-1.574.1c-1.461.087-2.252.687-2.252,1.728A1.791,1.791,0,0,0,17.512,17.56Zm.341-.962c-.67,0-1.1-.325-1.1-.816,0-.512.412-.808,1.2-.853l1.4-.087V15.3A1.368,1.368,0,0,1,17.854,16.6ZM22.121,19.7c1.228,0,1.807-.47,2.31-1.89l2.211-6.2H25.36L23.878,16.4h-.025l-1.482-4.792H21.055l2.131,5.907-.117.358a1,1,0,0,1-1.062.845c-.1,0-.291-.012-.371-.021v.974A4.363,4.363,0,0,0,22.121,19.7Z"
          transform="translate(51.681 684.214)"
        />
      </g>
    </g>
  </svg>
);

export const Star_Svg = () => (
  <svg
    width={15}
    height={15}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.068 14.612"
  >
    <path
      id="Icon_fa-solid-star"
      data-name="Icon fa-solid-star"
      d="M10.051.514a.914.914,0,0,0-1.644,0L6.573,4.289l-4.1.6a.913.913,0,0,0-.508,1.552L4.941,9.388l-.7,4.158a.916.916,0,0,0,1.333.959L9.232,12.55,12.893,14.5a.916.916,0,0,0,1.333-.959l-.7-4.158,2.973-2.942a.913.913,0,0,0-.508-1.552l-4.1-.6Z"
      transform="translate(-1.698)"
      fill="#0045ff"
    />
  </svg>
);

export const Instagram_Svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g id="Group_2492" data-name="Group 2492" transform="translate(-4 -4)">
      <path
        id="Path_4310"
        data-name="Path 4310"
        d="M18.6,13.73a4.871,4.871,0,1,0,3.441,1.427A4.869,4.869,0,0,0,18.6,13.73Zm0,8.03a3.164,3.164,0,1,1,2.237-.929A3.161,3.161,0,0,1,18.6,21.76Z"
        transform="translate(-4.598 -4.598)"
        fill="#ababab"
      />
      <path
        id="Path_4311"
        data-name="Path 4311"
        d="M23.94,9.875a7.324,7.324,0,0,0-.465-2.425A4.893,4.893,0,0,0,22.32,5.68a4.81,4.81,0,0,0-1.77-1.155,7.3,7.3,0,0,0-2.425-.465C17.06,4.01,16.715,4,14,4s-3.055.01-4.12.06a7.328,7.328,0,0,0-2.43.465A4.893,4.893,0,0,0,5.68,5.68,4.851,4.851,0,0,0,4.525,7.45,7.324,7.324,0,0,0,4.06,9.875C4.01,10.945,4,11.285,4,14s.01,3.055.06,4.125a7.324,7.324,0,0,0,.465,2.425A4.851,4.851,0,0,0,5.68,22.32a4.893,4.893,0,0,0,1.77,1.155,7.328,7.328,0,0,0,2.43.465c1.065.05,1.4.06,4.12.06s3.055-.01,4.125-.06a7.3,7.3,0,0,0,2.425-.465,5.107,5.107,0,0,0,2.925-2.925,7.324,7.324,0,0,0,.465-2.425c.05-1.07.06-1.41.06-4.125S23.99,10.945,23.94,9.875Zm-1.8,8.165A5.5,5.5,0,0,1,21.8,19.9a3.306,3.306,0,0,1-1.9,1.9,5.5,5.5,0,0,1-1.86.345c-1.055.05-1.37.06-4.04.06s-2.985-.01-4.04-.06A5.591,5.591,0,0,1,8.105,21.8a3.3,3.3,0,0,1-1.9-1.9,5.62,5.62,0,0,1-.345-1.86C5.815,16.985,5.8,16.67,5.8,14s.01-2.985.055-4.04A5.62,5.62,0,0,1,6.2,8.1a3.3,3.3,0,0,1,1.9-1.9A5.467,5.467,0,0,1,9.96,5.86c1.055-.05,1.37-.06,4.04-.06s2.985.01,4.04.06A5.62,5.62,0,0,1,19.9,6.2a3.306,3.306,0,0,1,1.9,1.9,5.5,5.5,0,0,1,.345,1.86c.05,1.055.06,1.37.06,4.04S22.19,16.985,22.14,18.04Z"
        fill="#ababab"
      />
      <path
        id="Path_4312"
        data-name="Path 4312"
        d="M33.546,10.92h0a1.266,1.266,0,1,0,1.266,1.266A1.266,1.266,0,0,0,33.546,10.92Z"
        transform="translate(-14.246 -3.486)"
        fill="#ababab"
      />
    </g>
  </svg>
);

export const True_svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.068"
    height="10.609"
    viewBox="0 0 14.068 10.609"
  >
    <path
      data-name="Icon feather-check"
      d="M9.459,18.11A1.5,1.5,0,0,1,8.4,17.67L4.939,14.211A1.5,1.5,0,0,1,7.061,12.09l2.4,2.4,6.549-6.549a1.5,1.5,0,1,1,2.121,2.121L10.52,17.67A1.5,1.5,0,0,1,9.459,18.11Z"
      transform="translate(-4.5 -7.5)"
      fill="#419741"
    />
  </svg>
);

export const Faq_icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      data-name="Icon fa-solid-circle-question"
      d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20ZM6.633,6.457A2.191,2.191,0,0,1,8.7,5h2.277A2.467,2.467,0,0,1,12.2,9.605l-1.262.723a.938.938,0,0,1-1.875-.016V9.785a.935.935,0,0,1,.473-.812l1.73-.992a.59.59,0,0,0-.293-1.1H8.7a.308.308,0,0,0-.293.207l-.016.047a.936.936,0,0,1-1.766-.625l.016-.047ZM8.75,13.75A1.25,1.25,0,1,1,10,15,1.25,1.25,0,0,1,8.75,13.75Z"
      fill="#0045ff"
    />
  </svg>
);

export const Weight_Scale_Svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 512 512"
    fill="#B2B2B2"
  >
    <path d="M128 176a128 128 0 1 1 256 0 128 128 0 1 1 -256 0zM391.8 64C359.5 24.9 310.7 0 256 0S152.5 24.9 120.2 64L64 64C28.7 64 0 92.7 0 128L0 448c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64l-56.2 0zM296 224c0-10.6-4.1-20.2-10.9-27.4l33.6-78.3c3.5-8.1-.3-17.5-8.4-21s-17.5 .3-21 8.4L255.7 184c-22 .1-39.7 18-39.7 40c0 22.1 17.9 40 40 40s40-17.9 40-40z" />
  </svg>
);

export const Level_Svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
  >
    <g id="Level3" transform="translate(-231 -654)">
      <rect
        id="Rectangle_902"
        data-name="Rectangle 902"
        width="4"
        height="6"
        transform="translate(231 663)"
        fill="#b2b2b2"
      />
      <rect
        id="Rectangle_903"
        data-name="Rectangle 903"
        width="4"
        height="10"
        transform="translate(236 659)"
        fill="#b2b2b2"
      />
      <rect
        id="Rectangle_904"
        data-name="Rectangle 904"
        width="4"
        height="15"
        transform="translate(241 654)"
        fill="#b2b2b2"
      />
    </g>
  </svg>
);
