import { createSlice } from "@reduxjs/toolkit";

function check_session() {
  if (localStorage.getItem("token") !== null) {
    try {
      const data = JSON.parse(localStorage.getItem("token"));
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch {
      localStorage.removeItem("token");
      return false;
    }
  }
  return false;
}

const getToken = () => {
  const data = localStorage.getItem("token");
  if (data !== null && data !== undefined) {
    try {
      const token = JSON.parse(data);
      return token;
    } catch {
      localStorage.removeItem("token");
      return null;
    }
  } else {
    return null;
  }
};

const initialState = {
  user: getToken(),
  loggedIn: check_session(),
};

export const authSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    logoutState: (state) => {
      localStorage.removeItem("token");
      state.loggedIn = false;
      state.subscribed = false;
      state.user = null;
    },
    checkLogin: (state) => {
      state.loggedIn = check_session();
    },
    login: (state, action) => {
      state.loggedIn = true;
      state.user = "userr";
      localStorage.setItem("token", JSON.stringify("user"));
    },
  },
});

export const { logoutState, checkLogin, login } = authSlice.actions;

export default authSlice.reducer;
