import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Privacy_Policy, Terms_Conditions } from "../constants/pageRouteName";

const Footer = () => {
  const footer_Links = [
    {
      link: Terms_Conditions,
      label: t("Terms and Conditions"),
    },
    {
      link: Privacy_Policy,
      label: t("Privacy Policy"),
    },
    {
      link: "mailto:support@gainmuscle.app",
      label: "support@gainmuscle.app",
    },
  ];

  return (
    <div className="w-100 footer-container d-flex flex-column justify-content-center align-items-center gap-4 py-4 mt-4">
      <div className="d-flex flex-column justify-content-center align-items-center gap-3">
        {footer_Links.map((item, index) => (
          <Link key={index} className="footer-text fw-semibold" to={item.link}>
            {item.label}
          </Link>
        ))}
      </div>
      <div>
        <p className="footer-text fw-light text-center m-0">
          {t("copyright")}
          <br /> {t("rights")}
        </p>
      </div>
    </div>
  );
};

export default Footer;
