import { t } from "i18next";
import { useEffect, useState } from "react";

const TimerNotification = () => {
  const [timeLeft, setTimeLeft] = useState(900);
  const [visible, setVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const handleGetPlan = () => {
    const element2 = document.getElementById("get-plan-scroll-new");
    element2.scrollIntoView();
  };

  useEffect(() => {
    const { innerWidth: width } = window;
    setWindowWidth(width);
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft === 1) {
          return 900;
        } else {
          return timeLeft - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className={`timer-notification  ${visible ? "animate" : ""}`}>
      <div>
        <h6
          className="fw-regular m-0 text-inter"
          style={{ fontSize: "18px", lineHeight: "21px", color: "#FFFFFF" }}
        >
          00 : {minutes < 10 ? ` 0${minutes} ` : ` ${minutes}`}:
          {seconds < 10 ? ` 0${seconds} ` : ` ${seconds}`}
        </h6>
        <h6
          className="text-inter fw-regular m-0 text-start"
          style={{
            flex: "1",
            fontSize: "12px",
            color: "#FFFFFF",
            lineHeight: "15px",
          }}
        >
          {t("countdown_text")}
        </h6>
      </div>
      <button
        className="get-plan-btn fw-semibold text-inter text-uppercase"
        onClick={handleGetPlan}
      >
        {t("TRY NOW")}
      </button>
    </div>
  );
};

export default TimerNotification;
