import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { Part_Three_Eleven } from "../../../constants/pageRouteName";
import {
  calculateBMI,
  calculateInches,
  calculateLBSwithPoint,
  convertToKG,
  convertToLBS,
} from "../../../modules/Calculator";
import { updatePlanData } from "../../../modules/apiHelper";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { nextPageRoute } from "../../../utils/navigationTransition";

const CurrentWeight = () => {
  const [activeMode, setActiveMode] = React.useState("kg");
  const [weightKG, setWeightKG] = React.useState("");
  const [weightLBS, setWeightLBS] = React.useState("");
  const [heightInches, setHeightInches] = React.useState(1);
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const { details } = useSelector((state) => state.detailState);

  const handleInput = (e) => {
    if (activeMode === "kg") {
      if (e.target.value.length <= 3) {
        if (e.target.value === 0) {
          setWeightKG(null);
          setWeightLBS(null);
        } else {
          setWeightKG(e.target.value);
          setWeightLBS(convertToLBS(e.target.value));
        }
      }
    } else {
      if (e.target.value.length <= 3) {
        if (e.target.value === 0) {
          setWeightKG(null);
          setWeightLBS(null);
        } else {
          setWeightLBS(e.target.value);
          setWeightKG(convertToKG(e.target.value));
        }
      }
    }
  };

  const handleMode = (mode) => {
    setActiveMode(mode);
  };

  const getWidth = () => {
    if (activeMode === "kg") {
      return weightKG ? String(weightKG).length : 1;
    } else {
      return weightLBS ? String(weightLBS).length : 1;
    }
  };

  const gotoNextPage = () => {
    location.state?.isSettings
      ? updatePlanData(navigate, dispatch, {
          weight: details?.currentWeight,
        })
      : nextPageRoute(
          () => navigate(Part_Three_Eleven, { state: location.state }),
          Part_Three_Eleven
        );
  };

  const handleHeight = (value) => {
    if (value?.includes("cm")) {
      setHeightInches(calculateInches(value?.split(" cm")[0], "cm"));
    } else {
      const splittext = value.split("ft ");
      const feet = splittext[0];
      const inches = splittext[1]?.split("in")[0];
      setHeightInches(calculateInches({ feet, inches }, "ft"));
    }
  };

  React.useEffect(() => {
    if (location.state) {
      if (location.state.data) {
        handleHeight(location.state.data?.height || "1 cm");
        if (location.state.data?.weight?.includes("kg")) {
          setWeightKG(location.state.data.weight?.split(" kg")[0]);
          setWeightLBS(
            convertToLBS(location.state.data.weight?.split(" kg")[0])
          );
        } else {
          setWeightLBS(location.state.data.weight?.split(" lbs")[0]);
          setWeightKG(
            convertToKG(location.state.data.weight?.split(" lbs")[0])
          );
        }
      } else {
        handleHeight(details?.height || "1 cm");
      }
    }
  }, []);

  React.useEffect(() => {
    if (activeMode === "kg") {
      weightKG && isLoggedIn
        ? dispatch(setDetailsData({ currentWeight: weightKG + " kg" }))
        : dispatch(
            setDetailsData({
              weight: weightKG + " kg",
            })
          );
    } else {
      weightLBS && isLoggedIn
        ? dispatch(setDetailsData({ currentWeight: weightLBS + " lbs" }))
        : dispatch(
            setDetailsData({
              weight: weightLBS + " lbs",
            })
          );
    }

    dispatch(
      setDetailsData({
        BMI: calculateBMI(calculateLBSwithPoint(weightKG), heightInches || 1),
      })
    );
  }, [weightKG, activeMode]);

  const bmiString = () => {
    let bmi = calculateBMI(calculateLBSwithPoint(weightKG), heightInches || 1);

    if (bmi < 18.5) {
      return t("Underweight");
    } else if (bmi < 24.9) {
      return t("Normal");
    } else if (bmi < 29.9) {
      return t("Overweight");
    } else {
      return t("Obese");
    }
  };

  return (
    <div className="flex-grow-1 d-flex flex-column align-content-center main-padding pt-2 pb-3 gap-4">
      <h1 className="height-header my-0 text-inter fw-bold text-center mb-3">
        {t("CW_title_1")} <br />
        {t("CW_title_2")}
      </h1>

      <div className="w-100 d-flex flex-column">
        <div className="height-button-group">
          <button
            onClick={() => {
              handleMode("kg");
            }}
            className={`height-unit-button fw-bold text-inter ${
              activeMode === "kg" ? "active-unit-button" : ""
            }`}
          >
            kg
          </button>
          <button
            onClick={() => {
              handleMode("lbs");
            }}
            className={`height-unit-button fw-bold text-inter ${
              activeMode === "lbs" ? "active-unit-button" : ""
            }`}
          >
            {t("lbs")}
          </button>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="w-100 d-flex justify-content-center align-items-end input-container">
            <div className="input-div">
              <input
                placeholder="0"
                inputMode="numeric"
                type="number"
                className="height-input fw-bold text-inter text-custom-white"
                value={activeMode === "kg" ? weightKG : weightLBS}
                style={{
                  minWidth: "1ch",
                  width: `${getWidth()}ch`,
                }}
                onChange={handleInput}
              />
            </div>
            <p className="unit-text fw-bold text-inter mb-4">{t(activeMode)}</p>
          </div>
        </div>
      </div>
      <div className="help-text-banner">
        <p className="text-inter text-center fw-bold help-text-banner-title">
          {t("CW_text_1", {
            bmi: calculateBMI(
              calculateLBSwithPoint(weightKG),
              heightInches || 1
            ),
            bmiLevel: bmiString(),
          })}
        </p>
        <p className="text-inter text-center fw-regular help-text-banner-subtitle m-0">
          {t("CW_text_2")}
        </p>
      </div>
      <Button
        disabled={!weightKG && !weightLBS}
        label={location.state?.isSettings ? t("SAVE") : t("CONTINUE")}
        onClick={gotoNextPage}
      />
    </div>
  );
};

export default CurrentWeight;
