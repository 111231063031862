import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import Loader from "../../components/Loader";
import MobileView from "../../layouts/MobileView";
import ApiService from "../../service/api";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BackArrow } from "../../assets/svgs/BackArrow";
import { Course_Page } from "../../constants/pageRouteName";
import { addS3Url } from "../../modules/s3";
import { failureToast } from "../../modules/toaster";
import { checkLogin } from "../../redux/slices/AuthSlice";

const CourseDetail = () => {
  const [currentVideo, setCurrentVideo] = React.useState(null);
  const [videoList, setVideoList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const goBack = () => {
    navigate(Course_Page);
  };

  const fetchCourseVideos = async () => {
    await ApiService.getCourseVideoList(id)
      .then((res) => {
        if (res.status === 200) {
          setVideoList(res.data);
        } else if (res.status === 202) {
          setError(res.data.message);
          setTimeout(() => {
            localStorage.removeItem("token");
            dispatch(checkLogin());
          }, 6000);
        }
      })
      .catch((err) => {
        if (err?.request?.status === 401) {
          // dispatch(logoutState());
        } else {
          const res = JSON.parse(err?.request?.response);
          failureToast(res.message, 10000);
        }
      })
      .finally(() => setLoading(false));
  };

  const addView = async () => {
    await ApiService.addViewVideo({
      video_id: currentVideo?.obj?.id,
    })
      .then((res) => {
        if (res.status === 201) {
          if (currentVideo.index < videoList.length) {
            const nextInd = currentVideo.index + 1;
            const updatedList = [...videoList];
            updatedList[currentVideo.index].isViewed = true;
            setVideoList(updatedList);
            setCurrentVideo({ index: nextInd, obj: videoList[nextInd] });
          }
        }
      })
      .catch((err) => {
        if (err?.request?.status === 401) {
          // dispatch(logoutState());
        }
        console.log(err);
      });
  };

  React.useEffect(() => {
    fetchCourseVideos();
  }, []);

  React.useEffect(() => {
    if (videoList.length > 0 && currentVideo === null) {
      setCurrentVideo({ index: 0, obj: videoList[0] });
    }
  }, [videoList]);

  const updatedVimeoVideoId = (url) => {
    const splittedUrl = url?.split("/")[3];
    if (url.includes("https")) {
      if (!url.includes("https://player.vimeo.com/video")) {
        return `https://player.vimeo.com/video/${splittedUrl}`;
      } else {
        return url;
      }
    } else {
      return url;
    }
  };

  return (
    <MobileView>
      <div className="w-100">
        <div className="text-center w-100" style={{ padding: "36px 0 0 0" }}>
          <div className="d-flex position-relative main-padding">
            <button className="back-arrow-btn" onClick={goBack}>
              {BackArrow()}
            </button>
            <img
              src={addS3Url("/Big_Logo.png")}
              alt="logo"
              className="mx-auto course-logo"
            />
          </div>
          <h4 className="course-title fw-bold text-inter mb-0 main-padding">
            {currentVideo?.obj?.title}
          </h4>
          <h6
            className="mt-1 mb-3 course-subtitle-details fw-regular text-inter main-padding"
            style={{ color: "#6C6E79" }}
          >
            {currentVideo?.obj?.subtitle}
          </h6>
          {error && (
            <>
              <hr style={{ color: "#B2B2B2" }} />
              <h6 className="my-5 text-main main-padding text-custom-white fw-bold">
                {error}
              </h6>
              <hr style={{ color: "#c" }} />
            </>
          )}
          {loading ? (
            <Loader />
          ) : (
            <>
              {currentVideo !== null && currentVideo?.obj?.vimeo_video_id ? (
                <div className="py-3 w-100 border-y">
                  <div className="w-100 main-padding my-1">
                    <Vimeo
                      video={updatedVimeoVideoId(
                        currentVideo?.obj?.vimeo_video_id
                      )}
                      className="w-100 m-0 border"
                      style={{ borderColor: "#B2B2B2" }}
                      width={"100%"}
                      autoplay={true}
                      onError={(err) => console.log(err)}
                      loop={false}
                      responsive={true}
                      onEnd={() => addView()}
                    />
                  </div>
                </div>
              ) : (
                <Loader />
              )}

              <div className="w-100 main-padding">
                {videoList.map((e, index) => {
                  return (
                    <ListTile
                      e={e}
                      key={e.id}
                      index={index}
                      currentVideo={currentVideo}
                      setCurrentVideo={setCurrentVideo}
                      videoList={videoList}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </MobileView>
  );
};

const ListTile = ({ e, index, currentVideo, setCurrentVideo, videoList }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
    document
      .getElementById("scroll-div")
      .scroll({ top: 0, behavior: "smooth" });
    setCurrentVideo({ index: index, obj: videoList[index] });
  };
  return (
    <>
      <div
        className="text-start border-b "
        style={{ cursor: "pointer", padding: "20px 0px" }}
        onClick={handleClick}
      >
        <div className="d-flex align-items-start pe-2">
          <div className="d-flex">
            <div
              className={`rounded-circle me-3 course-index-div d-flex justify-content-center align-items-center  ${
                index === currentVideo?.index ? "active-course-index-div" : ""
              }`}
            >
              <p
                className={`m-0 course-video-index text-poppins fw-semibold ${
                  index === currentVideo?.index ? "text-custom-white" : ""
                }`}
              >
                {index + 1}
              </p>
            </div>
          </div>
          <div>
            <h5
              className={`m-0 color text-main text-inter text-custom-white ${
                index === currentVideo?.index ? "fw-bold" : "text-medium"
              }`}
              style={{ fontSize: "18px" }}
            >
              {e.title}
            </h5>
            <h6
              className="m-0 mt-1 text-inter fw-regular"
              style={{
                fontSize: "14px",
                color: "#B2B2B2",
              }}
            >
              {e.subtitle}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
