import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Course_Page, Landing_Page } from "../../constants/pageRouteName";

const NotFound = () => {
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  return <Navigate to={isLoggedIn ? Course_Page : Landing_Page} replace />;
};

export default NotFound;
