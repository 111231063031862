export const enTranslation = {
  START_NOW: "START NOW",
  "Privacy Policy": "Privacy Policy",
  "Terms of Use": "Terms of Use",
  "Refund Policy": "Refund Policy",
  "Terms and Conditions": "Terms and Conditions",
  LOGIN: "LOGIN",
  "1 MILLION": "1 MILLION",
  CONTINUE: "CONTINUE",
  SAVE: "SAVE",
  ft: "ft",
  lbs: "lbs",
  kg: "kg",
  //footer
  copyright: "Copyright ® GainMuscle, 2024.",
  rights: "All rights reserved.",
  //landing page
  LP_header: "BUILD MUSCLES IN 8 WEEKS",
  LP_sub_header: "GET A PERSONALIZED WORKOUT PLAN",
  LP_text_1: "By pressing the 'Start Now' button, you confirm and guarantee",
  LP_text_2: "that you have read, understood, and agreed to our",
  //users page
  UP_header: "USERS HAVE CHOSEN US",
  //actual body page
  AB_header: "CHOOSE YOUR <tag/>BODY TYPE",
  Skinny: "Skinny",
  Average: "Average",
  //goal page
  // GP_header: "WHAT'S YOUR GOAL?",
  GP_header: "CHOOSE TARGET<tag/> BODY TYPE",
  Cut: "Cut",
  Bulk: "Bulk",
  "Extra Bulk": "Extra Bulk",
  "Get Shredded": "Get Shredded",
  "Muscle Gain": "Muscle Gain",
  "Weight Loss": "Weight Loss",
  "GET SHREDDED": "Get Shredded",
  "MUSCLE GAIN": "Muscle Gain",
  "WEIGHT LOSS": "Weight Loss",
  //body type page
  BT_header: "THE BODY YOU WANT",
  Athlete: "Athlete",
  Hero: "Hero",
  Bodybuilder: "Bodybuilder",
  //motivation page
  MT_header: "WHAT MOTIVATES YOU TO EXERCISE?",
  "Improving Health": "Improving Health",
  "Boosting Immune System": "Boosting Immune System",
  "Looking Better": "Looking Better",
  "Building Strength": "Building Strength",
  "Boosting Libido": "Boosting Libido",
  //target-zones page
  TZ_header: "CHOOSE YOUR TARGET ZONES",
  ARMS: "ARMS",
  BELLY: "BELLY",
  PECS: "PECS",
  LEGS: "LEGS",
  //perfect-weight page
  PW_header: "WHEN WERE YOU AT YOUR PERFECT WEIGHT?",
  "0-6 Months ago": "0-6 Months ago",
  "7-12 Months ago": "7-12 Months ago",
  "1-3 Years ago": "1-3 Years ago",
  "More than 3 years ago": "More than 3 years ago",
  Never: "Never",
  //Interest page
  IT_header: "WHAT ARE YOU INTERESTED IN?",
  "Weight loss": "Weight loss",
  "Body sculpting": "Body sculpting",
  "Hearth Health": "Hearth Health",
  Mobility: "Mobility",
  Energy: "Energy",
  Recovery: "Recovery",
  //fitness level page
  FL_header: "WHAT'S YOUR FITNESS LEVEL?",
  Beginner: "Beginner",
  "I'm new to fitness": "I'm new to fitness",
  Intermediate: "Intermediate",
  "I workout from time to time": "I workout from time to time",
  Advanced: "Advanced",
  "I exercise regularily": "I exercise regularily",
  //push-up page
  PU_header: "HOW MANY PUSH-UPS CAN YOU DO?",
  "Fewer than 12": "Fewer than 12",
  "13-20": "13-20",
  "More than 20": "More than 20",
  "I don't know": "I don't know",
  //life-style page
  LS_title_1: "DO YOU HAVE",
  LS_title_2: "A SEDENTARY LIFESTYLE?",
  Yes: "Yes",
  No: "No",
  //walk page
  WL_title_1: "HOW MUCH",
  WL_title_2: "DO YOU WALK DAILY?",
  "Less than 1 hour": "Less than 1 hour",
  "1-2 hours": "1-2 hours",
  "More than 2 hours": "More than 2 hours",
  //Meal page
  ML_title_1: "HOW DO YOU FEEL",
  ML_title_2: "BETWEEN MEALS?",
  ML_Subtitle:
    "What's your energy like at different times of the day? Select the most appropriate options.",
  "I got sleepy when I'm hungry": "I got sleepy when I'm hungry",
  "I am tired after I eat": "I am tired after I eat",
  "I am always energized": "I am always energized",
  //Sleep page
  SL_title_1: "HOW MUCH SLEEP",
  SL_title_2: "DO YOU GET?",
  "Fewer than 5 hours": "Fewer than 5 hours",
  "Between 5 and 6 hours": "Between 5 and 6 hours",
  "Between 7 and 8 hours": "Between 7 and 8 hours",
  "Over 8 hours": "Over 8 hours",
  //Water page
  WT_title: "WHAT'S YOUR WATER CONSUMPTION LIKE?",
  WT_Subtitle_1: "How much water do you drink throughout the day?",
  WT_Subtitle_2: "Select the most appropriate answer.",
  "I only drink tea and coffee": "I only drink tea and coffee",
  "Fewer than 2 glasses of water": "Fewer than 2 glasses of water",
  "2-6 glasses of water": "2-6 glasses of water",
  "7-10 glasses of water": "7-10 glasses of water",
  "More than 10 glasses of water": "More than 10 glasses of water",
  //workout location page
  WL_title: "CHOOSE YOUR WORKOUT LOCATION",
  Home: "Home",
  Gym: "Gym",
  HOME: "Home",
  GYM: "Gym",
  //height page
  HT_title: "HOW TALL ARE YOU?",
  HT_text_1: "Calculating your body mass index",
  HT_text_2:
    "BMI is widely used as a risk factor for the development of or the prevalence of",
  HT_text_3: "several health issues.",
  HT_error_ft: "Enter a value from 4ft to 7ft 11in",
  HT_error_cm: "Enter a value from 122cm to 242cm",
  //current weight page
  CW_title_1: "WHAT IS YOUR",
  CW_title_2: "CURRENT WEIGHT?",
  CW_text_1: "Your BMI is {{bmi}}, which is considered {{bmiLevel}}.",
  CW_text_2:
    "You should try to focus on your weight. We will tailor a personal program.",
  Underweight: "Underweight",
  Normal: "Normal",
  Obese: "Obese",
  Overweight: "Overweight",
  //target weight page
  TW_title_1: "WHAT IS YOUR",
  TW_title_2: "TARGET WEIGHT?",
  //age page
  AGE_LP_text:
    "By proceeding, you confirm and guarantee that you have read, understood, and agreed to our",
  // AG_title: "HOW OLD ARE YOU?",
  AG_title: "BUILD YOUR PERFECT BODY",
  AG_text_1: "We ask your age to create",
  AG_text_2: "your personal plan",
  AG_text_3:
    "Older people tend to have more body fat than younger people with the same BMI.",
  age_text: "Age {{range}}",
  //fitness summary page
  FS_title: "SUMMARY OF YOUR FITNESS LEVEL",
  FS_bmi_text: "Body Mass Index (BMI)",
  FS_normal_bmi_text: "NORMAL BMI - {{bmi}}",
  FS_user_bmi_text: "You: {{bmi}}",
  FS_lifeStyle_text: "Lifestyle",
  FS_activity_text: "Activity",
  FS_exercise_text: "Exercise",
  FS_SEDENTARY: "SEDENTARY",
  FS_ACTIVE: "ACTIVE",
  FS_LOW: "LOW",
  FS_HIGH: "HIGH",
  "NO REGULAR WORKOUTS": "NO REGULAR WORKOUTS",
  "REGULAR WORKOUTS": "REGULAR WORKOUTS",
  FS_text_1: "Risks for an unhealthy BMI",
  FS_text_2:
    "High blood pressure, heart disease, stroke, type 2 diabetes, some types of cancer, osteoarthritis, back pain, all case mortality.",
  //event page
  EV_title: "IS THERE A SPECIAL OCCASION YOU WANT TO GAIN MUSCLE FOR?",
  Vacation: "Vacation",
  Competition: "Competition",
  "Important Date": "Important Date",
  "Extreme Sports": "Extreme Sports",
  Birthday: "Birthday",
  "Beach Trip": "Beach Trip",
  Reunion: "Reunion",
  No: "No",
  //event-date page
  ED_title: "WHEN'S YOUR EVENT?",
  ED_subtitle:
    "Once we know this, we’ll be able to put together a personalized plan to help you get in shape. Your data will not be shared with any third parties.",
  "Choose date": "Choose date",
  "SKIP THIS QUESTION": "SKIP THIS QUESTION",
  //weight plan page
  WP_title_1: "THE ONE AND ONLY PLAN YOU'LL EVER NEED",
  WP_title_2: "TO {{text}} WEIGHT",
  WP_text_1: "According to the information you have",
  WP_text_2: "provided us, you'll achieve your goal weight of",
  WP_text_3: "Get ready to amaze everyone",
  WP_text_4: "at your {{desiredWeight}}!",
  WP_weight_status: "{{target}} BY {{date}}",
  GAIN: "GAIN",
  LOSE: "LOSE",
  //personal-plan page
  creating_plan: "Creating your personalized plan...",
  //register page
  RG_header: "Your Personal Plan",
  RG_subheader:
    "Enter your email to get your personal <tag>GainMuscle</tag> plan!",
  "Your Email": "Your Email",
  email_error: "Enter valid email address",
  "Well done!": "Well done!",
  policy_text:
    "We respect your privacy. Your email can make the advertising experience more relevant. <tag> Privacy Policy </tag> .",
  //login page
  WELCOME: "WELCOME",
  "Don't have an account?": "Don't have an account?",
  "Start the survey": "Start the survey",
  Email: "Email",
  "please enter valid email": "please enter valid email",
  Login: "Login",
  "Need help?": "Need help?",
  "Contact us": "Contact us",
  //otp page
  OTP_header: "Enter OTP",
  OTP_subheader:
    "A one-time password has been sent to your <tag /> Email: {{email}}",
  Submit: "Submit",
  "Resend OTP": "Resend OTP",
  //payment page
  "3 time per week": "3 time per week",
  "30 minutes": "30 minutes",
  "Target weight": "Target weight",
  countdown_text:
    "Your personalized plan has been reserved for the next 15 minutes!",
  "get my plan": "GET MY PLAN",
  "TRY NOW": "TRY NOW",
  Now: "Now",
  "After the program": "After the program",
  Goal: "Goal",
  "Body fat": "Body fat",
  "Muscle Mass": "Muscle Mass",
  "Daily calorie intake": "Daily calorie intake",
  recommended: "recommended",
  "Daily water intake": "Daily water intake",
  "Personalized plan for You is ready!":
    "Personalized plan<tag/> for You is ready!",
  "Workout Duration": "Workout Duration",
  "Fitness Level": "Fitness Level",
  "Place of Workout": "Place of Workout",
  "Workout Frequency": "Workout Frequency",
  "Your personal workout plan is ready!":
    "Your personal<tag/> workout plan is ready!",
  "Current BMI": "Current BMI",
  BMI_text:
    "The body mass index (BMI) is a measure that uses your height and weight to work out if your weight is healthy.",
  Level: "Level",
  "Personal summary based on your answers":
    "Personal summary<tag/> based on your answers",
  noticable_text:
    "89% of users find the results of using GainMuscle's plan clearly noticeable",
  image_warning:
    "*The image is not intended to represent the user. Results vary per person and are not guaranteed.",
  ready_text: "YOUR WORKOUT PLAN IS READY!",
  help_text: "It was designed to help you reach your goals",
  try_header: "TRY <tag>GainMuscle 7 DAYS FOR $1</tag> AND YOU WILL:",
  try_and_get_1: "Build a habit and develop the correct exercising techniques",
  try_and_get_2: "Lose excessive body fat & improve workout intensity",
  try_and_get_3: "Achieve your goal and change your life forever",
  faq_header: "FAQ",
  faq_q_1: "How is my plan created?",
  faq_q_2: "How do I access my plan?",
  faq_q_3: "Can I make changes to my plan after it's been created?",
  faq_a_1:
    "Your plan is tailored specifically to your unique needs and goals using the answers you've provided and our advanced algorithms. Each program is validated by experts, ensuring it will be effective for you.",
  faq_a_2:
    "Every plan is customized by our dedicated team of nutritionists and fitness experts to meet your expectations. It typically takes a few hours for you to receive access to your plan via email.",
  faq_a_3:
    "Yes, you can make adjustments to your plan even after it's been created. Simply go to the app's settings to update your fitness level, personal details, or measurement units. If you wish to change your target weight goal, head to the ‘Weight’ tab and use the ‘Goal’ button in the upper right corner of the screen.",
  "Special Offer": "Special Offer",
  "7-Day Trial": "7-Day Trial",
  "Total Today": "Total Today",
  "CARD NUMBER": "CARD NUMBER",
  "EXPIRES ON": "EXPIRES ON",
  "Credit/Debit Card": "Credit/Debit Card",
  "Process Card": "Process Card",
  garrentee_text: "Guaranteed <tag>Safe</tag> Checkout",
  "WHAT YOU GET": "WHAT YOU GET",
  feature1: "1,000+ trainer-certified exercises",
  feature2: "120+ tasty and good-for-you recipes",
  feature3: "Guided workouts with step-by-step video tutorials",
  feature4:
    "A personalized fitness plan tailored to your needs and fitness level",
  feature5:
    "All-in-one: a combination of cardio, strength, and recovery workouts for the best results",
  "USERS LOVE OUR PLANS": "USERS LOVE OUR PLANS",
  review1:
    "Fantastic fitness app! Easy to use, keeps me motivated, and tracks my progress seamlessly.",
  review2:
    "Love this fitness app! Easy tracking, personalized plans, and great motivation. Highly recommend!",
  review3:
    "This fitness app is a game-changer! It's user-friendly, keeps me motivated, and the personalized plans make reaching my goals a breeze. Highly impressed!",
  review4:
    "This is the first program that really got me into a routine because it provided everything I needed to begin, including a menu and a grocery list.",
  review5:
    "I gave the program a try for a week; it has solid features, a well-structured workout plan, and a diet. The diet plan even comes with a grocery list and step-by-step instructions.",
  review6:
    "Great program! It works well and includes a thoughtfully designed set of exercises.",
  review7:
    "It's a fantastic way to get in shape. I should have started much earlier!",
  review8:
    "The plan is solid. I really appreciate how it customizes workouts based on fitness level, but it would be even better if there was an offline mode.",
  review9:
    "This is an amazing program! I managed to lose 5 pounds in just one week.",
  "OUR USERS MEET THEIR GOALS": "OUR USERS MEET THEIR GOALS",
  "100% MONEY-BACK GUARANTEE": "100% MONEY-BACK GUARANTEE",
  garrentee_text2:
    "We believe that our plan can work for you and you will get visible results in 4 weeks! We are even willing to return your money if you don't see visible results and can prove that you followed our plan.Please double check our <tag>Refund Policy</tag> to understand all the requirements.",
  //course page
  CP_Title: "All Courses",
  CP_NO_Courses: "No Courses Available",
  "Something went wrong.": "Something went wrong.",
  //meal plan page
  MPP_Title: "Personal Meal Plan",
  "We Are Preparing Your Plan.": "We Are Preparing Your Plan.",
  MPP_Remember_text:
    "Remember, Consistency Is Key To Achieving Your Goals. Stay Motivated, Focus On Progress, And Celebrate Your Successes Along The Way!",
  MPP_Understand_Needs_text: "Understanding Your Needs",
  MPP_Goal: "Goal",
  MPP_Current_status: "Current status",
  MPP_Challenges: "Challenges",
  MPP_Motivations: "Motivations",
  MPP_Diet_Preference: "Diet Preference",
  MPP_Tips_Title: "Additional Tips",
  MPP_Diet_Strategy: "Diet Strategy",
  MPP_Diet_Strategy_text:
    "We'll focus on a high-protein diet with balanced carbohydrates and healthy fats to fuel your workouts and build muscle.",
  MPP_View_Btn_Text: "View",
  MPP_Breakfast: "Breakfast",
  MPP_Lunch: "Lunch",
  MPP_Dinner: "Dinner",
  MPP_Snacks: "Snacks",
  //meal details page
  "We Are Preparing Veg Meal Details.": "We Are Preparing Veg Meal Details.",
  "We Are Preparing Non-Veg Meal Details.":
    "We Are Preparing Non-Veg Meal Details.",
  MD_Remember_text:
    "Remember, consistency is key to achieving your goals. Stay motivated, focus on progress, and celebrate your successes along the way!",
  MD_Nutritional_Info: "Nutritional information",
  "(per serving)": "(per serving)",
  Carbs: "Carbs",
  Protein: "Protein",
  Fat: "Fat",
  Ingredients: "Ingredients",
  Instructions: "Instructions",
  //veg non veg
  "Vegan Diet": "Vegan Diet",
  Vegan: "Vegan",
  "Non-vegan": "Non-vegan",
  VEGAN: "Vegan",
  "NON-VEGAN": "Non-Vegan",
  "Non-Vegan Diet": "Non-Vegan Diet",
  VNP_Title: "DIET <tag /> PREFERENCE",
  VNP_Banner_Veg:
    "diet provide ample fiber, antioxidants, and micronutrients from plant-based sources.",
  VNP_Banner_Non_Veg:
    "diets offer high-quality protein and essential amino acids, facilitating muscle repair and growth, which can be beneficial for strength and endurance training.",
  //settings page
  SP_toast: "New Meal Plan will be updated soon.",
  SP_Title: "PLAN SETTING",
  SP_Helper_Text: "A new plan will be created after saving",
  SP_Button_Text: "Update Plan",
  "Not Selected": "Not Selected",
  SP_Tile_1: "Your Goal",
  SP_Tile_2: "Target Zones",
  SP_Tile_3: "Workout Location",
  SP_Tile_4: "Current Weight",
  SP_Tile_5: "Target Weight",
  SP_Tile_6: "Diet Preference",
  //subscription page
  SBP_title: "MANAGE SUBSCRIPTION",
  SBP_Active_Text: "ACTIVE",
  SBP_Cancel_Text:
    "Do you want to cancel? <line /> Reach our friendly support team <tag>here</tag>",
  //bottom-tab
  "Exercise Plan": "Exercise Plan",
  "Meal Plan": "Meal Plan",
  //action sheet
  "Edit Your Plan": "Edit Your Plan",
  Support: "Support",
  "Terms of Service": "Terms of Service",
  "Privacy Policy": "Privacy Policy",
  "Manage Subscription": "Manage Subscription",
  Logout: "Logout",
};

export const privacy_policy_en_Translation = {
  PP_title: "Privacy and Policy",
  PP_commitment: "Our Commitment to Your Privacy",
  PP_commitment_text:
    "We respect and protect the privacy of our WebApp users. This Privacy Policy explains how we collect and use your information and is part of our Terms of Use when you use our WebApp. By using our WebApp and the service provided via the WebApp (“Service”), you consent to our Privacy Policy. If you don’t agree, please don’t use our WebApp.",
  PP_info_collect: "What information do we collect?",
  PP_info_collect_text:
    "If you make purchases or buy a subscription, our third-party billing service providers will collect information such as your full name, credit card number, and billing address. If you email us, we may use your email address to reply to your email. Information Sent by Your Mobile Device. We collect certain information that your mobile device sends when you use our WebApp, like a device identifier, user settings and the operating system of your device, as well as information about your use of our Services. Location Information. When you use our WebApp, we may collect and store information about your location by converting your IP address into a rough geo-location or by accessing your mobile device’s GPS coordinates or coarse location if you enable location services on your device. We may use location information to improve and personalize our Services for you. If you do not want us to collect location information, you may disable that feature on your mobile device.",
  PP_store_info: "Where do we store your information?",
  PP_store_info_text:
    "Your information may be stored in our own servers or in servers owned by third-party cloud storage providers. Third-party storage providers may not use your information except as provided in this Privacy Policy.",
  PP_use_info: "How do we use your information?",
  PP_use_info_text: "Information we collect from you might be used:",
  PP_store_duration: "How long do we store your information?",
  PP_store_duration_text:
    "We intend to store some of your information indefinitely. What about links to other websites? We may provide links to or compatibility with other websites. However, we’re not responsible for the privacy practices employed by those websites or the information or content they contain.",
  PP_protect_info: "How do we protect your information?",
  PP_protect_info_text:
    "We use appropriate physical, electronic, and other procedures to safeguard and secure the information we collect. However, please be aware that the Internet is an inherently unsafe environment, and that hackers are constantly working to defeat security measures. Thus, we cannot guarantee that your information will not be accessed, disclosed, altered or destroyed, and you accept this risk.",
  PP_protect_self: "How can you protect your information?",
  PP_protect_self_text:
    "We urge you to take steps to keep your personal information safe by not sharing it with others or posting it online.",
  PP_disclose: "Do we disclose any information to outside parties?",
  PP_disclose_text:
    "We do not collect, sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information (PII) for commercial or marketing purposes, except as stated herein. Personal information (and non- personal information) collected through the Site may be shared with companies and organizations that perform services on our behalf (for example, companies that provide data management or other support services to us such as data storage and Web hosting services). We may share your personal information (and non-personal information) with third parties to serve you relevant advertising and market our products, but we won’t sell your personal information. Information Disclosed in Connection with Business Transactions. If we are acquired by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including your PII, may be disclosed or transferred to a third-party acquirer in connection with the transaction. Information Disclosed for Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas); (ii) to protect our property, rights and safety and the property, rights and safety of a third party or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity.",
  PP_children: "Not Intended for Children",
  PP_children_text:
    "Our WebApp and Service are not intended for children under the age of 16. We do not knowingly or specifically collect information from or about children under the age of 16.",
  PP_changes: "Changes to our Privacy Policy",
  PP_changes_text:
    "If we decide to change our Privacy Policy, we will post those changes on this page. We may also, but are not required to, send you an email notice. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.",
  PP_contact: "Contact Us",
  PP_contact_text:
    "If you have questions about our Privacy Policy, please contact us at",
};

export const terms_en_Translation = {
  TC_title: "Terms and Conditions",
  main_desc:
    "This End User License Agreement is a valid agreement between You and the Company. Please, read this EULA carefully. The rules of the EULA are binding upon You. If You do not agree to be bound by the rules of the EULA, please, do not use the WebApp. If You continue to use the WebApp, this will mean that You have read and understood the EULA and agree to be bound by it.",
  category_1_title: "DEFINITIONS AND TERMS",
  category_1_desc:
    "In order to understand the rules of the EULA clearly you shall familiarize yourself with the meaning of terms used in the EULA. In this EULA the following terms, when capitalized, shall have the following meanings:",
  c_1_l_1:
    "“Account” – means a personal account of a user of the Platform services, which is provided by the Platform Administrator under the Platform Terms of Use or similar document. The Account is accessed with a Platform ID (account name) and a password.",
  c_1_l_2:
    "“Company”, “We” – mean RBCOM, a company incorporated in the Ireland at the following address: 1 Raffles Place 40-02 One Raffles Place Tower 1",
  c_1_l_3:
    "“Content” – means all kinds of content, including, among other, data, texts, images, videos, sounds, links, software and other pieces of information in any form, which are made available to You through the WebApp.",
  c_1_l_4:
    "“Device” – means a smartphone, a tablet or another similar mobile device that You either own or otherwise legally control exclusively for Your personal non-commercial use.",
  c_1_l_5:
    "“EULA” – means this End User License Agreement, which is a binding agreement between You and the Company. The EULA may incorporate other documents, such as Privacy Policy that regulate relations between You and Us with regard to using the WebApp. If a document represents a part of the EULA, it has a clear indication thereof.",
  c_1_l_6:
    "“Intellectual Property Rights” – means, collectively, rights under any patent, trademark, copyright and trade secret laws, and any other intellectual property or proprietary rights recognized in any country or jurisdiction worldwide, including, without limitation, moral or similar rights.",
  c_1_l_7:
    "“Subscription” – means a period, during which You use the WebApp as a whole or certain additional Content for a fee.",
  c_1_l_8:
    "“User-generated Content” – means any form of Content, including, among other, data, texts, images, videos, sounds, links, that have been posted by users to online platforms, for example, social networks (e.g. Instagram, Facebook).",
  c_1_l_9: "“You” – means an individual using the WebApp.",
  category_2_title: "CHANGES OF THE EULA",
  c_2_l_1:
    "We may introduce changes to this EULA from time to time. You shall regularly check the latest version of the EULA at the link, available on the web-page.",
  c_2_l_2:
    "If You do not agree with the terms of the updated EULA, You are not allowed to use the WebApp starting from the day, on which the updated EULA takes effect. In this case You shall not use the WebApp and cancel renewal of your Subscriptions.",
  category_3_title: "DEVICE",
  c_3_l_1:
    "If You intend use the WebApp on any Device not owned by You, You must have the owner’s permission to do so. You will be responsible for complying with this EULA whether or not You own the Device.",
  c_3_l_2:
    "If You do not own the Device, You are allowed to use the WebApp only through Your Account.",
  c_3_l_3:
    "If You use the Device together with other people, please, make sure that nobody except You can use the WebApp from Your Account. All actions taken in Your Account are considered Your actions in disregard of who actually takes such actions. You are fully responsible for all actions taken in Your Account.",
  c_3_l_4:
    "If You decide to sell or otherwise dispose of the Device, You shall delete the WebApp from the Device.",
  category_4_title: "LEGAL CAPACITY AND AGE",
  c_4_l_1:
    "The WebApp is designed for users over 16. If You use the WebApp, You confirm that You are at least 16 years old and have full legal capacity to enter into the Agreement.",
  c_4_l_2:
    "If You are between 16 and 18 years old, Your parent, guardian or other representative of legal age has to check and agree to this EULA on Your behalf. If You use the WebApp, You confirm that Your parent, guardian or other representative of legal age has agreed to the terms of this EULA and permits You to use the WebApp.",
  category_5_title: "INTELLECTUAL PROPERTY",
  c_5_l_1:
    "We hereby grant You a non-exclusive, personal, limited, revocable and non-transferable license to access, download and run the Webapp only on Your Device, provided that You agree to comply with all the terms and conditions of this EULA. Please, take into consideration that the WebApp is licensed, not sold, to You.",
  c_5_l_2:
    "The Company or the licensor of the Company is the only holder of Intellectual Property Rights with regard to the WebApp. The Company retains all rights, title and interest in and to the WebApp and reserves all rights not expressly granted to You in this EULA.",
  c_5_l_3:
    "You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other way transfer or grant any rights to the WebApp to any third party.",
  c_5_l_4:
    "You may not distribute or make the WebApp available over any network where it could be downloaded or used by multiple users at the same time, except when it is expressly authorized by Us.",
  c_5_l_5:
    "You may not use any automatic or manual device or process to interfere with the proper working of the WebApp. However, You may use any kind of device or process to delete the WebApp from Your Device.",
  c_5_l_6:
    "You may not decompile, disassemble, reverse engineer or create derivative works of the WebApp or any parts thereof. All modifications or enhancements to the WebApp remain the sole property of Company.",
  c_5_l_7:
    "You may not use the WebApp in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this EULA or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the WebApp.",
  category_6_title: "SUBSCRIPTION AND TRIAL PERIOD",
  c_6_l_1:
    "This WebApp as a whole or its certain part (additional Content) is provided on a paid subscription basis. The fee may vary depending on the period of the Subscription duration.",
  c_6_l_2:
    "The Subscription will renew automatically for the same period at the end of the Subscription at the cost of the chosen package. Your Account will be charged for the renewed Subscription within 24 hours prior to the end of the current Subscription.",
  c_6_l_3:
    "You may cancel renewal of the Subscription at least 24 hours before the end of the Subscription: in this case the WebApp as a whole or its additional Content will be available to You until the end of the Subscription. In order to cancel automatic renewal of the Subscription, please, change subscription settings of Your Account. After the end of the Subscription the WebApp as a whole or its additional Content will be available only if a new Subscription is purchased.",
  c_6_l_4:
    "Your subscription will not stop automatically. You must cancel automatic renewal of Your subscription in order not to be charged with the cost of the new Subscription.",
  c_6_l_5:
    "The WebApp provides for a free 7-day trial period. You may use the WebApp of its additional Content free of charge during this period. Please, take into consideration that at the end of the trial period it will automatically transform into a paid Subscription for the shortest period available and Your Account will be charged for the cost of such Subscription, unless You cancel the Subscription through the subscription settings of Your Account at least 24 hours prior to the end of the free trial period.",
  category_7_title: "FEES AND PURCHASE TERMS",
  c_7_l_1: "The costs of all Subscriptions are available on the web-page.",
  c_7_l_2:
    "When You purchase a Subscription, the Platform Administrator charges Your Account and collects money on Our behalf. The payment is processed by a payment processing system implemented by the Platform Administrator.",
  c_7_l_3:
    "Please, kindly notice that We neither process Your payment nor have any access to Your payment information, such as, among other, Your bank card data, Your name or any other related information.",
  c_7_l_4:
    "Should You have any question with regard to payment processing, please, submit Your issue to user support service of the Platform Administrator.",
  c_7_l_5:
    "Your Account will be charged for the cost of the new Subscription within 24 hours before the end of the current Subscription. If You cancel the automatic renewal within the last 24 hours of the Subscription, that cancellation will take effect at the end of the new Subscription.",
  c_7_l_6:
    "Please, kindly notice that We make the refund of Your last payment. The digital content is made available to You immediately after the Subscription fee is charged to Your Account.",
  category_8_title: "USER-GENERATED CONTENT",
  c_8_l_1:
    "As far as You may access posts of other Instagram users, You may be exposed to potentially objectionable User-generated Content. Please, take into account that the Company neither controls nor pre-checks any User-generated Content posted on any social network.",
  c_8_l_2:
    "Any kind of modifications or censorship by the Company with regard to the User-generated Content may be considered a violation of human rights or copyright and may potentially mislead You.",
  c_8_l_3:
    "If You have been exposed to any kind of objectionable content, You may use the functionality of the respective social network to track and delete objectionable User-generated Content.",
  c_8_l_4:
    "Under no circumstances shall the Company be responsible for any User-generated Content.",
  category_9_title: "DISCLAIMER",
  c_9_l_1:
    "To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents, partners, and licensors expressly disclaim all warranties of any kind, express or implied, as to the accuracy, adequacy, or completeness of any of the information contained in Our WebApp or created by the services.",
  c_9_l_2:
    "The Content in Our WebApp is provided to You on an “as is” basis, without any warranties as to merchantability or fitness for a particular purpose or use. We do not guarantee that You will attain any anticipated result from the use of the WebApp.",
  c_9_l_3: "We make no warranty that:",
  c_9_l_3a: "the WebApp will meet Your requirements;",
  c_9_l_3b: "the WebApp will be error-free, secure, or uninterrupted;",
  c_9_l_3c: "any WebApp functionality errors will be corrected;",
  c_9_l_3d: "the quality of the WebApp will meet Your expectations.",
  c_9_l_4:
    "We shall have no responsibility or liability for any errors or omissions. We are not responsible for any damages, whether direct or indirect, special or consequential, suffered by You or by any other person from the use of the WebApp, even if You have been advised of the possibility of such damages. No advice or information, whether oral or written, obtained by You from Us or Our WebApp shall create any warranty not expressly stated in the EULA. Under no circumstances shall Our liability or that of any of Our affiliates pursuant to any cause of action, whether in contract, tort, or otherwise, exceed the fee paid by You for access to the WebApp. Furthermore, We shall have no responsibility or liability for delays or failures due to circumstances beyond Our control.",
  category_10_title: "TRANSFER OF RIGHTS",
  c_10_l_1:
    "We may transfer Our rights and obligations under this EULA to any other organization or individual without Your consent. We will always tell You in writing if this happens and We will ensure that the transfer will not affect Your rights under the EULA.",
  c_10_l_2:
    "You may only transfer Your rights or Your obligations under this EULA to another person if We agree in writing.",
  category_11_title: "TERMINATION",
  c_11_l_1:
    "We may end Your rights to use the WebApp at any time by contacting You if You have broken the rules of this EULA in a serious way. If what You have done can be put right We will give You a reasonable opportunity to do so.",
  c_11_l_2: "If We end Your rights to use the WebApp:",
  c_11_l_2a:
    "You must stop all activities authorized by this EULA, including Your use of the WebApp.",
  c_11_l_2b:
    "You must delete or remove the WebApp from all Devices and immediately destroy all copies of the WebApp which You have and confirm to Us that You have done this.",
  category_12_title: "MISCELLANEOUS",
  c_12_l_1:
    "Each of the paragraphs of this EULA operates separately. If any court or relevant authority decides that any of them is unlawful, the remaining paragraphs will remain in full force and effect.",
  c_12_l_2:
    "Even if We delay enforcing this EULA, We can still enforce it later. If We do not insist immediately that You do anything You are required to do under this EULA terms, or if We delay taking steps against You in respect of Your breaking the EULA, that will not mean that You do not have to do those things and it will not prevent Us from taking steps against You at a later date.",
  c_12_l_3:
    "This EULA shall be governed and construed in accordance with the laws of Singapore, without regard to its conflict of law provisions.",
  c_12_l_4:
    "If any dispute, controversy or difference arises between You and the Company, it shall be settled amicably through negotiations. In case such dispute, controversy or difference cannot be settled amicably through negotiations within a thirty 30-day period it shall be settled by the Ireland Arbitration Association in accordance with its International Expedited Procedures.",
  last_text1: "The number of arbitrators shall be one.",
  last_text2: "The place of arbitration shall be Singapore.",
  last_text3:
    "The arbitration shall be held, and the award rendered, in English.",
  last_text4:
    "Each party (i.e. You and the Company) shall bear its own expenses, but the parties shall share equally the expenses of the arbitration tribunal.",
  support_message:
    "Support. If You want to learn more about the WebApp or have any problems using it, please, contact Us at",
};
