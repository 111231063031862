import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { Part_Four_One } from "../../../constants/pageRouteName";
import { nextPageRoute } from "../../../utils/navigationTransition";

const FitnessSummary = () => {
  const { details } = useSelector((state) => state.detailState);
  const navigate = useNavigate();

  const gotoNextPage = () => {
    nextPageRoute(navigate, Part_Four_One);
  };

  React.useEffect(() => {
    setTimeout(() => {
      document.getElementById("summary-thumb").style.left =
        details.BMI < 24.9 ? 0 : details.BMI > 30 ? "100%" : "50%";
    }, 100);
  }, []);

  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-between pt-2 main-padding-b main-padding gap-3">
      <h1 className="goal-header my-0 text-inter fw-bold text-center mb-3">
        {t("FS_title")}
      </h1>
      <div
        className="d-flex flex-column align-items-center"
        style={{ gap: 20 }}
      >
        <div className="banner w-100">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-custom-white text-inter fw-bold text-custom-white m-0 fitness-summary-bmi">
              {t("FS_bmi_text")}
            </p>
            <p className="normal-bmi m-0">
              {t("FS_normal_bmi_text", {
                bmi: 21.5,
              })}
            </p>
          </div>

          <div>
            <div className="gradient-slider position-relative">
              <div
                className={`slider-thumb position-absolute start-0 w-100 m-0 d-flex ${
                  details.BMI < 24.9
                    ? "justify-content-start"
                    : details.BMI > 30
                    ? "justify-content-end"
                    : "justify-content-center"
                }`}
              >
                <div
                  id="summary-thumb"
                  className="position-absolute summary-thumb"
                  style={{
                    backgroundColor: "#0045FF",
                    border: "1px solid #FFFFFF",
                    borderRadius: "50%",
                    height: "26px",
                    width: "26px",
                  }}
                >
                  <div className="popover-custom">
                    <p className="popover-text-custom m-0">
                      {t("FS_user_bmi_text", {
                        bmi: details.BMI || 0,
                      })}
                    </p>
                    <div className="arrow-down"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="bmi-text fw-regular text-inter m-0 text-uppercase">
                {t("Normal")}
              </p>
              <p className="bmi-text fw-regular text-inter m-0 text-uppercase">
                {t("Overweight")}
              </p>
              <p className="bmi-text fw-regular text-inter m-0 text-uppercase">
                {t("Obese")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-between" style={{ gap: 10 }}>
        <div className="banner w-50">
          <p className="bmi-text fw-regular text-inter mb-1  text-center">
            {t("FS_lifeStyle_text")}
          </p>
          <p className="bmi-text fw-bold text-inter text-custom-white m-0 text-center text-uppercase">
            {details?.sedentaryLife?.text.toLowerCase() === "yes"
              ? t("FS_SEDENTARY")
              : t("FS_ACTIVE")}
          </p>
        </div>
        <div className="banner w-50">
          <p className="bmi-text fw-regular text-inter mb-1  text-center">
            {t("FS_activity_text")}
          </p>
          <p className="bmi-text fw-bold text-inter text-custom-white m-0 text-center">
            {details?.sedentaryLife?.text.toLowerCase() === "yes"
              ? t("FS_LOW")
              : t("FS_HIGH")}
          </p>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="banner w-75">
          <p className="bmi-text fw-regular text-inter mb-1 text-center">
            {t("FS_exercise_text")}
          </p>
          <p className="bmi-text fw-bold text-inter text-custom-white m-0 text-center">
            {details?.pushups?.text.toLowerCase() === "Fewer than 12" ||
            details?.pushups?.text.toLowerCase() === "I don't know"
              ? t("NO REGULAR WORKOUTS")
              : t("REGULAR WORKOUTS")}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="help-text-banner">
          <p className="text-inter text-center fw-bold help-text-banner-title ">
            {t("FS_text_1")}
          </p>
          <p className="text-inter text-center fw-regular banner-text-fitness-summary help-text-banner-subtitle m-0">
            {t("FS_text_2")}
          </p>
        </div>
        <Button label={t("CONTINUE")} onClick={gotoNextPage} />
      </div>
    </div>
  );
};

export default FitnessSummary;
