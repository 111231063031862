import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Part_Three_Nine } from "../../../constants/pageRouteName";
import BigIconLayout from "../../../layouts/BigIconLayout";
import { updatePlanData } from "../../../modules/apiHelper";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import { setPlanData } from "../../../redux/slices/PlanSettingSlice";
import { placeHolderImages, s3Images } from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const tile_Items = [
  {
    id: 1,
    text: t("Home"),
    value: "Home",
    icon: s3Images.workoutLocation[0],
    placeHolderImage: placeHolderImages.workoutLocation[0],
  },
  {
    id: 2,
    text: t("Gym"),
    value: "Gym",
    icon: s3Images.workoutLocation[1],
    placeHolderImage: placeHolderImages.workoutLocation[1],
  },
];

const WorkOutLocation = () => {
  const [selected, setSelected] = React.useState(null);
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const gotoNextPage = (item) => {
    location.state?.isSettings
      ? updatePlanData(navigate, dispatch, {
          workoutLocation: item.value,
        })
      : nextPageRoute(navigate, Part_Three_Nine);
  };

  const handleSelect = (item) => {
    if (isLoggedIn) {
      dispatch(setPlanData({ workOutLocation: item.value }));
      dispatch(
        setDetailsData({ workOutLocation: { text: item.value, id: item.id } })
      );
    } else {
      dispatch(
        setDetailsData({ workOutLocation: { text: item.value, id: item.id } })
      );
    }
    setSelected(item);
    gotoNextPage(item);
  };

  React.useEffect(() => {
    if (location.state?.data) {
      const selectedItem = tile_Items.filter(
        (item) => item.value.toLowerCase() === location.state.data.toLowerCase()
      );
      if (selectedItem.length > 0) {
        setSelected(selectedItem[0]);
      }
    }
  }, []);

  return (
    <BigIconLayout
      titleClasses="custom-workout-title"
      title={t("WL_title")}
      items={tile_Items}
      handleSelect={handleSelect}
      selected={selected?.id}
    />
  );
};

export default WorkOutLocation;
