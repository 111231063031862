import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import "./assets/styles/course.css";
import "./assets/styles/custom.css";
import "./assets/styles/mealPlan.css";
import "./assets/styles/payment.css";
import i18next from "./i18n";
import reportWebVitals from "./reportWebVitals";
import Router from "./router/Router";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Router />
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
