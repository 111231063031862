import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { True_svg } from "../../assets/svgs/payment";
import { try_and_get } from "../../utils/data";

const TryGainmuscle = () => {
  return (
    <div className="d-flex flex-column" style={{ gap: "30px" }}>
      <p className="try-text-header text-inter fw-bold m-0">
        <Trans
          i18nKey={"try_header"}
          components={{ tag: <span style={{ color: "#006FFF" }} /> }}
        />
      </p>
      <div>
        {try_and_get.map((item, index) => (
          <div className="d-flex" style={{ gap: "6px" }} key={index}>
            <div className="d-flex" style={{ paddingTop: "5px" }}>
              {True_svg()}
            </div>
            <p className="try-and-get-text text-inter fw-regular">{item}</p>
          </div>
        ))}
        <p className="trial-text text-inter fw-bold text-uppercase">
          {t("7-Day Trial")}
        </p>
      </div>
    </div>
  );
};

export default TryGainmuscle;
