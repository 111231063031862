import { setImageLoaded } from "../redux/slices/ImageSlice";

export const preLoadImage = (images, dispatch) => {
  const image = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        dispatch(setImageLoaded(src));
        resolve();
      };
      img.onerror = reject;
    });
  };
  return Promise.all(images.map((src) => image(src)));
};

export const preLoadImageWithCallback = (images, dispatch, callback) => {
  const image = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.fetchPriority = "high";
      img.onload = () => {
        dispatch(setImageLoaded(src));
        resolve();
      };
      img.onerror = reject;
    });
  };
  return Promise.all(images.map((src) => image(src)))
    .then(() => callback())
    .catch(() => callback());
};
