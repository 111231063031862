import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../../components/ImageLoader";
import { Part_Three_Six } from "../../../constants/pageRouteName";
import PartThreeLayout from "../../../layouts/PartThreeLayout";
import { setDetailsData } from "../../../redux/slices/DetailsSlice";
import {
  pagePreloadImages,
  placeHolderImages,
  s3Images,
} from "../../../utils/image";
import { nextPageRoute } from "../../../utils/navigationTransition";

const items = [
  {
    id: 1,
    text: t("I got sleepy when I'm hungry"),
    value: "I got sleepy when I'm hungry",
  },
  {
    id: 2,
    text: t("I am tired after I eat"),
    value: "I am tired after I eat",
  },
  {
    id: 3,
    text: t("I am always energized"),
    value: "I am always energized",
  },
];

const Image = () => {
  return (
    <ImageLoader
      placeHolderImage={placeHolderImages.meal}
      src={s3Images.meal}
      alt="Meal"
      className="w-100 lifestyle-img"
    />
  );
};

const Meal = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (id) => {
    dispatch(
      setDetailsData({
        feelBtwnMeal: { text: items[id - 1].value },
      })
    );
    nextPageRoute(navigate, Part_Three_Six, dispatch, pagePreloadImages.meal);
  };
  return (
    <PartThreeLayout
      title={
        <>
          {t("ML_title_1")}
          <br />
          {t("ML_title_2")}
        </>
      }
      subText={t("ML_Subtitle")}
      subTextClass={"meal-sub-text-responsive"}
      handleSelect={handleSelect}
      items={items}
      selected={selected?.id}
      Image={Image}
    />
  );
};

export default Meal;
