import average_s_img from "../assets/images/actualBody/average.png";
import overweight_s_img from "../assets/images/actualBody/overweight.png";
import skinny_s_img from "../assets/images/actualBody/skinny.png";
import personalPlan from "../assets/images/blurred/part-four/personal_plan.webp";
import landingPage from "../assets/images/blurred/part-one/Landing_page.png";
import usersPage from "../assets/images/blurred/part-one/Users_Page.png";
import advanced from "../assets/images/blurred/part-three/Advanced.png";
import age1 from "../assets/images/blurred/part-three/Age1.jpg";
import age2 from "../assets/images/blurred/part-three/Age2.jpg";
import age3 from "../assets/images/blurred/part-three/Age3.jpg";
import age4 from "../assets/images/blurred/part-three/Age4.jpg";
import beginner from "../assets/images/blurred/part-three/Beginner.png";
import gym from "../assets/images/blurred/part-three/Gym.png";
import home from "../assets/images/blurred/part-three/Home.png";
import intermediate from "../assets/images/blurred/part-three/Intermediate.png";
import lifeStyle from "../assets/images/blurred/part-three/LifeStyle.png";
import meal from "../assets/images/blurred/part-three/Meal.png";
import sleep from "../assets/images/blurred/part-three/Sleep.png";
import walk from "../assets/images/blurred/part-three/Walk.png";
import water from "../assets/images/blurred/part-three/Water.png";
import shredded from "../assets/images/blurred/part-two/1-Tile.png";
import muscleGain from "../assets/images/blurred/part-two/2-Tile.png";
import weightLoss from "../assets/images/blurred/part-two/3-Tile.png";
import athleteBody from "../assets/images/blurred/part-two/Athelete.png";
import average from "../assets/images/blurred/part-two/average.png";
import bodyBuilderBody from "../assets/images/blurred/part-two/BodyBuilder.png";
import heroBody from "../assets/images/blurred/part-two/Hero.png";
import overweight from "../assets/images/blurred/part-two/overweight.png";
import skinny from "../assets/images/blurred/part-two/skinny.png";
import average_payment from "../assets/images/blurred/payment/Average.png";
import bulk_payment from "../assets/images/blurred/payment/Bulk.png";
import burger from "../assets/images/blurred/payment/burger.png";
import workoutFrequency from "../assets/images/blurred/payment/calendar.png";
import card from "../assets/images/blurred/payment/Card.png";
import character from "../assets/images/blurred/payment/character.png";
import cut_payment from "../assets/images/blurred/payment/Cut.png";
import workoutDuration from "../assets/images/blurred/payment/duration.png";
import empty_glass from "../assets/images/blurred/payment/empty_glass.png";
import extraBulk_payment from "../assets/images/blurred/payment/Extrabulk.png";
import full_glass from "../assets/images/blurred/payment/filled_glass.png";
import fitCharacter from "../assets/images/blurred/payment/Fit_Character.png";
import fitnessLevelPayment from "../assets/images/blurred/payment/fitness level.png";
import guarantee from "../assets/images/blurred/payment/Guarantee.png";
import overweight_payment from "../assets/images/blurred/payment/Overweight.png";
import skinny_payment from "../assets/images/blurred/payment/Skinny.png";
import water_glass from "../assets/images/blurred/payment/water.png";
import workoutPlace from "../assets/images/blurred/payment/workoutplace.png";
import average_img from "../assets/images/payment/Average.png";
import bulk_img from "../assets/images/payment/Bulk.png";
import burger_img from "../assets/images/payment/burger.png";
import workoutFrequency_img from "../assets/images/payment/calendar.png";
import cut_img from "../assets/images/payment/Cut.png";
import workoutDuration_img from "../assets/images/payment/duration.png";
import empty_glass_img from "../assets/images/payment/empty_glass.png";
import extraBulk_img from "../assets/images/payment/Extrabulk.png";
import full_glass_img from "../assets/images/payment/filled_glass.png";
import fitnessLevelPayment_img from "../assets/images/payment/fitness level.png";
import overweight_img from "../assets/images/payment/Overweight.png";
import skinny_img from "../assets/images/payment/Skinny.png";
import water_glass_img from "../assets/images/payment/water.png";
import workoutPlace_img from "../assets/images/payment/workoutplace.png";

import { addS3Url } from "../modules/s3";
export const s3Urls = [
  addS3Url("/part-one/Landing_page.jpg"),
  addS3Url("/part-one/Users_Page.jpg"),
  addS3Url("/part-two/1-Tile.png"),
  addS3Url("/part-two/2-Tile.png"),
  addS3Url("/part-two/3-Tile.png"),
  addS3Url("/part-two/Athelete.jpg"),
  addS3Url("/part-two/Hero.jpg"),
  addS3Url("/part-two/BodyBuilder.jpg"),
  addS3Url("/part-three/Beginner.jpg"),
  addS3Url("/part-three/Intermediate.jpg"),
  addS3Url("/part-three/Advanced.jpg"),
  addS3Url("/part-three/LifeStyle.jpg"),
  addS3Url("/part-three/Walk.jpg"),
  addS3Url("/part-three/Meal.jpg"),
  addS3Url("/part-three/Sleep.jpg"),
  addS3Url("/part-three/Water.jpg"),
  addS3Url("/part-three/Home.png"),
  addS3Url("/part-three/Gym.png"),
  addS3Url("/part-three/Age1.jpg"),
  addS3Url("/part-three/Age2.jpg"),
  addS3Url("/part-three/Age3.jpg"),
  addS3Url("/part-three/Age4.jpg"),
  addS3Url("/part-four/personal_plan.jpg"),
  addS3Url("/payment/character.png"),
  addS3Url("/payment/Card.jpg"),
  addS3Url("/payment/Fit_Character.jpg"),
  addS3Url("/payment/Guarantee.jpg"),
];

export const placeHolderImages = {
  landingPage,
  usersPage,
  actualBody: [skinny, average, overweight],
  goalPage: [shredded, muscleGain, weightLoss],
  body: [athleteBody, heroBody, bodyBuilderBody],
  fitnessLevel: [beginner, intermediate, advanced],
  lifeStyle,
  walk,
  meal,
  sleep,
  water,
  workoutLocation: [home, gym],
  age: [age1, age2, age3, age4],
  personalPlan: personalPlan,
  payment: [character, card, fitCharacter, guarantee],
  payment_character: [
    skinny_payment,
    average_payment,
    overweight_payment,
    cut_payment,
    bulk_payment,
    extraBulk_payment,
  ],
  payment_recommendations: [empty_glass, full_glass, burger, water_glass],
  payment_personalPlan: [
    workoutDuration,
    fitnessLevelPayment,
    workoutPlace,
    workoutFrequency,
  ],
};

export const s3Images = {
  landingPage: s3Urls[0],
  usersPage: s3Urls[1],
  actualBody: [skinny_s_img, average_s_img, overweight_s_img],
  goalPage: [s3Urls[2], s3Urls[3], s3Urls[4]],
  body: [s3Urls[5], s3Urls[6], s3Urls[7]],
  fitnessLevel: [s3Urls[8], s3Urls[9], s3Urls[10]],
  lifeStyle: s3Urls[11],
  walk: s3Urls[12],
  meal: s3Urls[13],
  sleep: s3Urls[14],
  water: s3Urls[15],
  workoutLocation: [s3Urls[16], s3Urls[17]],
  age: [s3Urls[18], s3Urls[19], s3Urls[20], s3Urls[21]],
  personalPlan: s3Urls[22],
  payment: [s3Urls[23], s3Urls[24], s3Urls[25], s3Urls[26]],

  payment_character: [
    skinny_img,
    average_img,
    overweight_img,
    cut_img,
    bulk_img,
    extraBulk_img,
  ],
  payment_recommendations: [
    empty_glass_img,
    full_glass_img,
    burger_img,
    water_glass_img,
  ],
  payment_personalPlan: [
    workoutDuration_img,
    fitnessLevelPayment_img,
    workoutPlace_img,
    workoutFrequency_img,
  ],
};

export const pagePreloadImages = {
  // landingPage: [s3Urls[1]],
  agePage: [s3Urls[2], s3Urls[3], s3Urls[4]],
  goalPage: [s3Urls[5]],
  // motivation: [targetZoneImageData],
  interest: [s3Urls[8]],
  pushUps: [s3Urls[11]],
  lifeStyle: [s3Urls[12]],
  walk: [s3Urls[13]],
  meal: [s3Urls[14]],
  sleep: [s3Urls[15]],
  water: [s3Urls[16], s3Urls[17]],
  targetWeight: [s3Urls[18], s3Urls[19], s3Urls[20], s3Urls[21]],
  weightPlan: [s3Urls[22]],
};
